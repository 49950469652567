import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccCurrentMonthPreviousPayrollCycleOutput = {
  __typename?: 'AccCurrentMonthPreviousPayrollCycleOutput';
  EmployeeID: Scalars['String'];
  TotalExemptNormalRemunerationAmount: Scalars['Float'];
  TotalExemptAdditionalRemunerationAmount: Scalars['Float'];
  TotalChargableTax1: Scalars['Float'];
  TotalChargableTax2: Scalars['Float'];
  TotalChargableEPF: Scalars['Float'];
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  EPFPaidByNormalRemunerationPaid: Scalars['Float'];
  EPFPaidByAdditionalRemuneration: Scalars['Float'];
  TotalChargableEPFVE: Scalars['Float'];
  TotalChargableSocso: Scalars['Float'];
  TotalChargableEIS: Scalars['Float'];
  TotalChargableHRDF: Scalars['Float'];
  EmployeeEPF: Scalars['Float'];
  EmployerEPF: Scalars['Float'];
  EmployeeEPFVE: Scalars['Float'];
  EmployerEPFVE: Scalars['Float'];
  TotalChargableTax: Scalars['Float'];
  TotalChargableAddRemuneration: Scalars['Float'];
  Tax: Scalars['Float'];
  Zakat: Scalars['Float'];
  EmployeeEIS: Scalars['Float'];
  EmployerEIS: Scalars['Float'];
  EmployeeSocso: Scalars['Float'];
  EmployerSocso: Scalars['Float'];
};

export type AccessControl = {
  __typename?: 'AccessControl';
  ID: Scalars['Float'];
};

export type AccessControlInput = {
  ID: Scalars['Float'];
};

export type AccumulatedPreviousMonthOutput = {
  __typename?: 'AccumulatedPreviousMonthOutput';
  AccEPFPaid: Scalars['Float'];
  AccEPFVEPaid: Scalars['Float'];
  AccNormalRemunerationEPFPaid: Scalars['Float'];
  AccAdditionalRemunerationEPFPaid: Scalars['Float'];
  AccPCBPaid: Scalars['Float'];
  AccZakatPaid: Scalars['Float'];
  AccGrossTaxablePaid: Scalars['Float'];
  AccSocsoPaid: Scalars['Float'];
  AccEISPaid: Scalars['Float'];
  AccTaxPaid: Scalars['Float'];
  AccTaxRelief: Scalars['Float'];
};

export type ActivityListJson = {
  __typename?: 'ActivityListJson';
  Description?: Maybe<Scalars['String']>;
};

export type ActivityListJsonInput = {
  Description?: Maybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type Address2 = {
  __typename?: 'Address2';
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput2 = {
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdhocSetupDetails = {
  EmployeeIDs: Array<Scalars['String']>;
  SuperiorSelfReviewFormTemplateID: Scalars['String'];
  PeerReviewFormTemplateID: Scalars['String'];
  SubordinateReviewFormTemplateID: Scalars['String'];
};

export type AdvancePolicyAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  AdvancePolicy: AdvancePolicyEntity;
};

export type AdvancePolicyConditionEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyConditionEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyConditionID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  ConditionList: Scalars['JSON'];
  AdvancePolicy: AdvancePolicyEntity;
  Sequence: Scalars['Float'];
};

export type AdvancePolicyEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  AdvancePolicyCondition?: Maybe<Array<AdvancePolicyConditionEntity>>;
  InUse: Scalars['Boolean'];
  PaymentItem: PayItemEntity;
  PaymentCycle: PayrollCycleEntity;
  DeductionItem: PayItemEntity;
  DeductionCycle: PayrollCycleEntity;
};

export type AdvancePolicyInput = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  ConditionList: Array<PayrollConditionListInput>;
  UserLogs: Array<UserLogInput>;
};

export type AdvanceProcessingDetailEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingDetailEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingDetailID: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  PaymentType: PayItemType;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemID: Scalars['String'];
  Amount: Scalars['Float'];
  OneTimePayID?: Maybe<Scalars['String']>;
  AdvanceProcessing: AdvanceProcessingEntity;
  PeriodMonth: Scalars['String'];
  PayrollCycle: PayrollCycleEntity;
  PayItem: PayItemEntity;
  IsModified?: Maybe<Scalars['JSON']>;
};

export type AdvanceProcessingEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  IsPosted?: Maybe<Scalars['Boolean']>;
  AdvanceProcessingDetail?: Maybe<Array<AdvanceProcessingDetailEntity>>;
  Employee: EmployeeEntity;
  AdvancePolicyName: Scalars['String'];
  IsProcessed: Scalars['Boolean'];
};

export type AllowanceBenefitInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AllowanceBenfitEntity = InterfaceBase & {
  __typename?: 'AllowanceBenfitEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceBenefitID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Company: CompanyEntity;
};

export type AllowanceDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Name?: Maybe<Scalars['String']>;
};

export type AllowanceDeductionRoundingEntity = InterfaceBase & {
  __typename?: 'AllowanceDeductionRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  DailyAllowance?: Maybe<Array<DailyAllowancePolicyEntity>>;
  MonthlyAllowance?: Maybe<Array<MonthlyAllowancePolicyEntity>>;
  PeriodicDeduction?: Maybe<Array<PeriodicDeductionPolicyEntity>>;
};

export enum AllowanceFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Lifetime = 'Lifetime'
}

export type AllowanceItemEntity = InterfaceBase & {
  __typename?: 'AllowanceItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceItemID: Scalars['String'];
  AllowanceBenefitID: Scalars['String'];
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  AllowanceFrequency: AllowanceFrequency;
  TotalAmount: Scalars['Float'];
  AllowanceBenefit: AllowanceBenfitEntity;
};

export type AllowanceItemInput = {
  AllowanceBenefitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  AllowanceFrequency?: Maybe<AllowanceFrequency>;
  TotalAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AnnouncementDashboardCardOutput = {
  __typename?: 'AnnouncementDashboardCardOutput';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type AppraisalCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategoryItems?: Maybe<Array<AppraisalCategoryItemEntity>>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalCategoryInput = {
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID: Scalars['String'];
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<Scalars['String']>;
  QuantitativeType?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalCategoryItemInput = {
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<KpiType>;
  QuantitativeType?: Maybe<QuantitativeType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCompanySettingEntity = InterfaceBase & {
  __typename?: 'AppraisalCompanySettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalPolicy?: Maybe<AppraisalPolicyEntity>;
  AppraisalTempleteSetting?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalCompanySettingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalEmployeeLisitngOutput = InterfaceBase & {
  __typename?: 'AppraisalEmployeeLisitngOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  AppraisalStatus?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubordinateAppraisalHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export type AppraisalPolicyEntity = InterfaceBase & {
  __typename?: 'AppraisalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalPolicyID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalPolicyInput = {
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleDetailEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID: Scalars['String'];
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeader?: Maybe<AppraisalScheduleHeaderEntity>;
};

export type AppraisalScheduleDetailInput = {
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  AppraisalScheduleDetails?: Maybe<Array<AppraisalScheduleDetailEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalScheduleHeaderInput = {
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<AppraisalScheduleDetailInput>>;
};

export type AppraisalTemplateHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalTemplateHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalTempleteByJobPositionEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteByJobPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID: Scalars['String'];
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalSchedule?: Maybe<AppraisalScheduleHeaderEntity>;
  KPITemplete?: Maybe<AppraisalTempleteEntity>;
  CompetencyTemplete?: Maybe<AppraisalTempleteEntity>;
  OthersTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalTemplateHeader?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalTempleteByJobPositionHeaderInput = {
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateByJobPositionList?: Maybe<Array<AppraisalTempleteByJobPositionInput>>;
};

export type AppraisalTempleteByJobPositionInput = {
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID: Scalars['String'];
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalTempleteCategoryInput = {
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Items?: Maybe<Array<AppraisalTempleteCategoryItemInput>>;
};

export type AppraisalTempleteCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryItemID: Scalars['String'];
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<AppraisalTempleteCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
};

export type AppraisalTempleteCategoryItemInput = {
  AppraisalTempleteCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalTempleteInput = {
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<AppraisalTempleteCategoryInput>>;
};

export enum AppraisalType {
  Kpi = 'KPI',
  Competency = 'Competency',
  Others = 'Others'
}

export enum AppraiseeType {
  Self = 'Self',
  Superior = 'Superior',
  Subordinate = 'Subordinate',
  Peer = 'Peer',
  SelfAssesment = 'Self_Assesment',
  Employee = 'Employee'
}

export type ApprovalClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ApprovalList = {
  __typename?: 'ApprovalList';
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalListInput = {
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Status?: Maybe<WorkflowLogStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  ApprovalList: Array<ApprovalListJson>;
  ApprovalPolicyAssignment: Array<ApprovalPolicyAssignmentEntity>;
  InUse: Scalars['Boolean'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalProjectExpenseClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export enum ApprovalStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Withdraw = 'Withdraw',
  Withdrawn = 'Withdrawn'
}

export type ApproveClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApproveClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ApproveClaimVerificationOutput = {
  __typename?: 'ApproveClaimVerificationOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
};

export type ApproveProjectExpenseClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApprovedClaimDetail = {
  __typename?: 'ApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type ApprovedClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
};

export type ApprovedProjectExpenseClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedProjectExpenseClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type AttachmentInput = {
  Index?: Maybe<Scalars['Float']>;
  Filename?: Maybe<Scalars['String']>;
  Format?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  Index?: Maybe<Scalars['Float']>;
  Info?: Maybe<Scalars['String']>;
};

export type AttendanceCalculationEntity = InterfaceBase & {
  __typename?: 'AttendanceCalculationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
};

export type AttendanceCalculationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceCalculationID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  PostStatus?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
};

export type AttendanceCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'AttendanceCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type AttendanceCutOffPeriodInput = {
  AttendanceCutOffPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AttendanceDashboardCardOutput = {
  __typename?: 'AttendanceDashboardCardOutput';
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  sortType: Scalars['String'];
  description: Scalars['String'];
  ClockingRecord?: Maybe<Scalars['JSON']>;
};

export type AttendanceDetailOutput = {
  __typename?: 'AttendanceDetailOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['String']>;
  Long?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendancePostInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  AttendanceCalculationIDs?: Maybe<Array<Scalars['String']>>;
};

export type AttendancePostingOutput = InterfaceBase & {
  __typename?: 'AttendancePostingOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
};

export type AttendanceProcessingBatchEntity = {
  __typename?: 'AttendanceProcessingBatchEntity';
  AttendanceProcessingBatchID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  Errorlog?: Maybe<Array<Scalars['JSON']>>;
  CompletedTime?: Maybe<Scalars['DateTime']>;
};

export type AttendanceProcessingOutput = {
  __typename?: 'AttendanceProcessingOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  ShiftPatternName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AbsentOccurence?: Maybe<Scalars['Float']>;
  LateInOccurence?: Maybe<Scalars['Float']>;
  EarlyOutOccurence?: Maybe<Scalars['Float']>;
  ImcompleteClockingOccurence?: Maybe<Scalars['Float']>;
  LeaveCount?: Maybe<Scalars['Float']>;
};

export type AttendanceRecordEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WearMask?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  DateTime?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordHikVisionEntity = {
  __typename?: 'AttendanceRecordHikVisionEntity';
  ID?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['String']>;
  AccessDate?: Maybe<Scalars['String']>;
  AcessTime?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Direction?: Maybe<Scalars['String']>;
  AcessDT?: Maybe<Scalars['String']>;
  Temperature?: Maybe<Scalars['Float']>;
  TemperatureStatus?: Maybe<Scalars['String']>;
  WearMask?: Maybe<Scalars['String']>;
  DeviceSerial?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  DateTime?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordOutput = {
  __typename?: 'AttendanceRecordOutput';
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
};

export type AttendanceRecordPairedEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordPairedEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Array<Scalars['JSON']>>;
  ClockingRecord?: Maybe<Array<Scalars['JSON']>>;
  TimeOffRecord?: Maybe<Array<Scalars['JSON']>>;
  ApprovedOTRecord?: Maybe<Array<Scalars['JSON']>>;
  LeaveTakenRecord?: Maybe<Array<Scalars['JSON']>>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Array<Scalars['JSON']>>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordPairedInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Scalars['JSON']>;
  ClockingRecord?: Maybe<Scalars['JSON']>;
  TimeOffRecord?: Maybe<Scalars['JSON']>;
  ApprovedOTRecord?: Maybe<Scalars['JSON']>;
  LeaveTakenRecord?: Maybe<Scalars['JSON']>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Scalars['JSON']>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
};

export type AttendanceRecordPairedObj = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  OrgunitID?: Maybe<Scalars['String']>;
  PairedData?: Maybe<Array<Scalars['JSON']>>;
};

export enum AttendanceTransactionType {
  Daily = 'Daily',
  Periodic = 'Periodic'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AuthorisedTrainingProviderEntity = InterfaceBase & {
  __typename?: 'AuthorisedTrainingProviderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type AuthorisedTrainingProviderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactInput>;
};

export type AutomatedClockDataImportEntity = {
  __typename?: 'AutomatedClockDataImportEntity';
  AutomatedClockDataImportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AutomatedClockDataImportInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
};

export enum AvailableBasis {
  AnnualEntitlement = 'Annual_Entitlement',
  EarnBasis = 'Earn_Basis',
  NoProration = 'No_Proration'
}

export enum BackdatedOtMethod {
  CalendarDay = 'CALENDAR_DAY',
  WorkingDay = 'WORKING_DAY'
}

export enum Bank {
  AffinBankBerhad = 'Affin_Bank_Berhad',
  AffinIslamicBankBerhad = 'Affin_Islamic_Bank_Berhad',
  AlRajhiBankingInvestmentCorporationMalaysiaBerhad = 'Al_Rajhi_Banking_Investment_Corporation_Malaysia_Berhad',
  AllianceBankMalaysiaBerhad = 'Alliance_Bank_Malaysia_Berhad',
  AllianceIslamicBankBerhad = 'Alliance_Islamic_Bank_Berhad',
  AmBankMBerhad = 'Am_Bank_M_Berhad',
  AmBankIslamicBerhad = 'Am_Bank_Islamic_Berhad',
  BangkokBankBerhad = 'Bangkok_Bank_Berhad',
  BankIslamMalaysiaBerhad = 'Bank_Islam_Malaysia_Berhad',
  BankMuamalatMalaysiaBerhad = 'Bank_Muamalat_Malaysia_Berhad',
  BankOfAmericaMalaysiaBerhad = 'Bank_of_America_Malaysia_Berhad',
  BankOfChinaMalaysiaBerhad = 'Bank_of_China_Malaysia_Berhad',
  BankRakyat = 'Bank_Rakyat',
  BankSimpananNasional = 'Bank_Simpanan_Nasional',
  BankPertanianMalaysiaBerhadAgroBank = 'Bank_Pertanian_Malaysia_Berhad_Agro_Bank',
  BnpParibasMalaysiaBerhad = 'BNP_Paribas_Malaysia_Berhad',
  ChinaConstructionBankMalaysiaBerhad = 'China_Construction_Bank_Malaysia_Berhad',
  CimbBankBerhad = 'CIMB_Bank_Berhad',
  CimbIslamicBankBerhad = 'CIMB_Islamic_Bank_Berhad',
  CitibankBerhad = 'Citibank_Berhad',
  DeutscheBankMalaysiaBerhad = 'Deutsche_Bank_Malaysia_Berhad',
  HongLeongBankBerhad = 'Hong_Leong_Bank_Berhad',
  HongLeongIslamicBankBerhad = 'Hong_Leong_Islamic_Bank_Berhad',
  HsbcAmanahMalaysiaBerhad = 'HSBC_Amanah_Malaysia_Berhad',
  HsbcBankMalaysiaBerhad = 'HSBC_Bank_Malaysia_Berhad',
  IndiaInternationalBankMalaysiaBerhad = 'India_International_Bank_Malaysia_Berhad',
  IndustrialAndCommercialBankOfChinaMalaysiaBerhad = 'Industrial_and_Commercial_Bank_of_China_Malaysia_Berhad',
  JpMorganChaseBank = 'JP_Morgan_Chase_Bank',
  KuwaitFinanceHouseMalaysiaBerhad = 'Kuwait_Finance_House_Malaysia_Berhad',
  MalayanBankingBerhad = 'Malayan_Banking_Berhad',
  MaybankIslamicBerhad = 'Maybank_Islamic_Berhad',
  MbsbBankBerhad = 'MBSB_Bank_Berhad',
  MizuhoBankMalaysiaBerhad = 'Mizuho_Bank_Malaysia_Berhad',
  MufgBankMalaysiaBerhad = 'MUFG_Bank_Malaysia_Berhad',
  OcbcAlAminBankBerhad = 'OCBC_Al_Amin_Bank_Berhad',
  OcbcBankMalaysiaBerhad = 'OCBC_Bank_Malaysia_Berhad',
  PtBankMuamalatIndonesiaTbk = 'PT_Bank_Muamalat_Indonesia_Tbk',
  PublicBankBerhad = 'Public_Bank_Berhad',
  PublicIslamicBankBerhad = 'Public_Islamic_Bank_Berhad',
  RhbBankBerhad = 'RHB_Bank_Berhad',
  RhbIslamicBankBerhad = 'RHB_Islamic_Bank_Berhad',
  StandardCharteredBankMalaysiaBerhad = 'Standard_Chartered_Bank_Malaysia_Berhad',
  StandardCharteredSaadiqBerhad = 'Standard_Chartered_Saadiq_Berhad',
  SumitomoMitsuiBankingCorporationMalaysiaBerhad = 'Sumitomo_Mitsui_Banking_Corporation_Malaysia_Berhad',
  TheBankOfNovaScotiaBerhad = 'The_Bank_of_Nova_Scotia_Berhad',
  UnitedOverseasBankMalaysiaBerhad = 'United_Overseas_Bank_Malaysia_Berhad',
  DbsBankLimited = 'DBS_Bank_Limited'
}

export type BankInfoEntity = InterfaceBase & {
  __typename?: 'BankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BankInfoID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  AccountName: Scalars['String'];
  AccountNo: Scalars['String'];
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  CompanyBank: CompanyEntity;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoInputs = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoOutput = {
  __typename?: 'BankInfoOutput';
  BankInfoID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BasicDashboardCardOutput = {
  __typename?: 'BasicDashboardCardOutput';
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  CompanyName?: Maybe<Scalars['String']>;
  type?: Maybe<DashboardCardType>;
  ObjectDate?: Maybe<Scalars['DateTime']>;
  Due: Scalars['Float'];
};

export type BatchEntryByShiftEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Shift?: Maybe<ShiftEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BatchEntryByShiftPatternEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftPatternID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftPatternID: Scalars['String'];
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BiYearError = {
  __typename?: 'BiYearError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BiYearlyError = {
  __typename?: 'BiYearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BlockLeaveEntity = InterfaceBase & {
  __typename?: 'BlockLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockLeaveID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type BlockLeaveInput = {
  BlockLeaveID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum BloodType {
  OMinus = 'O_Minus',
  OPlus = 'O_Plus',
  AMinus = 'A_Minus',
  APlus = 'A_Plus',
  BMinus = 'B_Minus',
  BPlus = 'B_Plus',
  AbMinus = 'AB_Minus',
  AbPlus = 'AB_Plus'
}

export type BluetoothBeaconDevicesEntity = {
  __typename?: 'BluetoothBeaconDevicesEntity';
  BluetoothBeaconDevicesID: Scalars['String'];
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type BluetoothBeaconDevicesInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
};

export type BonusSimulationEntity = InterfaceBase & {
  __typename?: 'BonusSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BonusSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type BonusSimulationInput = {
  BonusSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BreakTimeEntity = InterfaceBase & {
  __typename?: 'BreakTimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BreakTimeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type: BreakTimeType;
  Start: Scalars['String'];
  End: Scalars['String'];
  ShiftID: Scalars['String'];
  shift?: Maybe<ShiftEntity>;
  ShiftDayID: Scalars['String'];
  shiftDay?: Maybe<ShiftDayEntity>;
};

export type BreakTimeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Type: BreakTimeType;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Start: Scalars['String'];
  End: Scalars['String'];
  BreakTimeID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  dayIndex?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type BreakTimeListInput = {
  Type?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  ShiftDuration?: Maybe<Scalars['Float']>;
  ShiftMinDuration?: Maybe<Scalars['Float']>;
  dayIndex?: Maybe<Scalars['Float']>;
};

export enum BreakTimeType {
  OtBreak = 'OT_BREAK',
  ShiftBreak = 'SHIFT_BREAK',
  OtBreakPrePost = 'OT_BREAK_Pre_Post'
}

export type BusinessInsightClaimTakenOuput = {
  __typename?: 'BusinessInsightClaimTakenOuput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  ClaimTakenByLeaveType?: Maybe<Array<ClaimTakenByLeaveType>>;
};

export type BusinessInsightCompanyOutput = {
  __typename?: 'BusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDepartmentOutput>>;
};

export type BusinessInsightDepartmentOutput = {
  __typename?: 'BusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDivisionOutput>>;
};

export type BusinessInsightDivisionOutput = {
  __typename?: 'BusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessInsightGroupingOutput = {
  __typename?: 'BusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTakenOutput = {
  __typename?: 'BusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTypeOutput = {
  __typename?: 'BusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type BusinessInsightListingOutput = {
  __typename?: 'BusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type BusinessInsightOutput = {
  __typename?: 'BusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollPayItemOutput = {
  __typename?: 'BusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'BusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollStatutoryOutput = {
  __typename?: 'BusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type BusinessInsightSummaryOutput = {
  __typename?: 'BusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<BusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<BusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<BusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<BusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<BusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<BusinessInsightGroupingOutput>>;
};

export type BusinessInsightSummaryPayrollOutput = {
  __typename?: 'BusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<BusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<BusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<BusinessInsightPayrollPayItemOutput>>;
};

export type CcMappingListEntity = InterfaceBase & {
  __typename?: 'CCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export enum CfMethod {
  No = 'No',
  PercentageEntitlement = 'Percentage_Entitlement',
  PercentageBalance = 'Percentage_Balance',
  Day = 'Day'
}

export type Cp159Entity = InterfaceBase & {
  __typename?: 'CP159Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CP159ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
};

export type Cp159Input = {
  CP159ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum CalculationMethod {
  ServiceDay = 'Service_Day',
  ServiceMonth = 'Service_Month'
}

export type CalendarOffDayEmployeeOutput = {
  __typename?: 'CalendarOffDayEmployeeOutput';
  StateID: Scalars['String'];
  EmployeeID: Scalars['String'];
  DesignationName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
};

export type CalendarOffDayEntity = InterfaceBase & {
  __typename?: 'CalendarOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  States?: Maybe<StatesEntity>;
  Employee?: Maybe<EmployeeEntity>;
  TotalEmployee?: Maybe<Scalars['Float']>;
};

export type CalendarOffDayInput = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  StateID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarOffDayOutput = {
  __typename?: 'CalendarOffDayOutput';
  StateID: Scalars['String'];
  Description: Scalars['String'];
  TotalEmployee?: Maybe<Scalars['Float']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type CalendarPolicyEntity = InterfaceBase & {
  __typename?: 'CalendarPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  StateID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  States?: Maybe<StatesEntity>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyDelaredHolidays?: Maybe<Array<CompanyDelaredHolidayEntity>>;
};

export type CalendarPolicyInput = {
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarPolicyOutput = {
  __typename?: 'CalendarPolicyOutput';
  CalendarPolicyID?: Maybe<Scalars['String']>;
  StateID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Array<Scalars['Float']>>;
  Default?: Maybe<Scalars['Boolean']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  CompanyOffDayID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  RestOffDay?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  YearlyHolidaysGroup?: Maybe<Array<Scalars['JSON']>>;
};

export type CareerLogEntity = InterfaceBaseV3 & {
  __typename?: 'CareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  ToOrgUnitTable?: Maybe<CompanyOrgUnitEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<PayGroupEntity>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
  IsAllowToCancel: Scalars['Boolean'];
  ShiftGroupName: Scalars['String'];
  ReportToSuperiorName: Scalars['String'];
  PrevCompReportToSuperiorName: Scalars['String'];
};

export type CareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
};

export type ChangeShiftEntity = InterfaceBase & {
  __typename?: 'ChangeShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ChangeShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  FromShift?: Maybe<ShiftEntity>;
  FromShiftID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShift?: Maybe<ShiftEntity>;
  ToShiftID?: Maybe<Scalars['String']>;
  ChangeShiftStatus: ChangeShiftStatus;
  RejectReason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ReplacedEmployee?: Maybe<EmployeeEntity>;
  ReplacedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ChangeShiftHistory?: Maybe<Array<ChangeShiftEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ChangeShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ChangeShiftID?: Maybe<Scalars['String']>;
  FromShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShiftID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  ReplacedBy?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
};

export enum ChangeShiftStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type ChangesData = {
  __typename?: 'ChangesData';
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Address2>;
  Address?: Maybe<Address2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<Address2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export type ChangesDataInput = {
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<AddressInput2>;
  Address?: Maybe<AddressInput2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<AddressInput2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export enum CheckInMethod {
  Qr = 'QR',
  Location = 'Location',
  Bluetooth = 'Bluetooth',
  ManualEntry = 'ManualEntry'
}

export type ClaimAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ClaimAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ClaimAccountDetailEntity = InterfaceBase & {
  __typename?: 'ClaimAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  ClaimAccountDetail?: Maybe<Array<ClaimAccountDetailEntity>>;
};

export type ClaimAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderOutput = {
  __typename?: 'ClaimAccountHeaderOutput';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  DeleteDisable?: Maybe<Scalars['Boolean']>;
};

export type ClaimAppDetailsOutput = {
  __typename?: 'ClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ClaimAppOutput = {
  __typename?: 'ClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimAppDetailsOutput>>;
};

export type ClaimBenefitEntity = InterfaceBase & {
  __typename?: 'ClaimBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  InUse: Scalars['Boolean'];
};

export type ClaimBenefitInput = {
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
};

export enum ClaimCategory {
  Normal = 'Normal',
  Mileage = 'Mileage',
  Daily = 'Daily',
  Meal = 'Meal'
}

export type ClaimCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'ClaimCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export enum ClaimDailyFrequency {
  Day = 'Day',
  Night = 'Night'
}

export type ClaimDailyRateEntity = InterfaceBase & {
  __typename?: 'ClaimDailyRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDailyRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Array<ClaimParameterEntity>>;
  dailyRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
  VenueName: Scalars['String'];
};

export type ClaimDailyRateInput = {
  ClaimDailyRateID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDetailListingReportInput = {
  CompanyID: Scalars['String'];
  SelectAllEmp: Scalars['Boolean'];
  ClaimTypeIDs: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  GroupBy: Scalars['String'];
  ApproveStartDate?: Maybe<Scalars['DateTime']>;
  ApproveEndDate?: Maybe<Scalars['DateTime']>;
  CreateStartDate?: Maybe<Scalars['DateTime']>;
  VerifyStartDate?: Maybe<Scalars['DateTime']>;
  VerifyEndDate?: Maybe<Scalars['DateTime']>;
  CreateEndDate?: Maybe<Scalars['DateTime']>;
  ReceiptStartDate?: Maybe<Scalars['DateTime']>;
  ReceiptEndDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimDetails = {
  __typename?: 'ClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ClaimDetailsListing?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimDetailsEntity = InterfaceBase & {
  __typename?: 'ClaimDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDetailsID: Scalars['String'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Array<Scalars['JSON']>>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  Distance?: Maybe<Scalars['Float']>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimHeader?: Maybe<ClaimHeaderEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  MultiCurrencySetup?: Maybe<MultiCurrencySetupEntity>;
  ClaimMileage?: Maybe<ClaimMileageTableEntity>;
};

export type ClaimDetailsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks: Scalars['String'];
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus: ApprovalStatus;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDuplicateList = {
  __typename?: 'ClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export enum ClaimFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  BiYearly = 'Bi_Yearly',
  Lifetime = 'Lifetime'
}

export type ClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimHeaderEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CancelRemark?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ClaimNo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimHistory?: Maybe<Array<ClaimHeaderEntity>>;
  ClaimDetails: Array<ClaimDetailsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  JobAccount: JobAccountEntity;
  Approver?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
};

export type ClaimHistoryListingOutput = {
  __typename?: 'ClaimHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimInsertOutput = {
  __typename?: 'ClaimInsertOutput';
  RespStatus?: Maybe<Scalars['String']>;
  RespMsg?: Maybe<Array<ArrMesg>>;
};

export type ClaimItemEntity = InterfaceBase & {
  __typename?: 'ClaimItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimItemID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  MileageTable?: Maybe<ClaimParameterEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  MileageTableName?: Maybe<Scalars['String']>;
};

export type ClaimItemInput = {
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
};

export type ClaimItemsWithAmountInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailInput>>;
};

export type ClaimItemsWithAmountList = {
  __typename?: 'ClaimItemsWithAmountList';
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailList>>;
};

export type ClaimManagementListing = {
  __typename?: 'ClaimManagementListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimMealRateEntity = InterfaceBase & {
  __typename?: 'ClaimMealRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMealRateID: Scalars['String'];
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  MealRate?: Maybe<Array<ClaimParameterEntity>>;
  MealRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  MealType: Scalars['String'];
  VenueName: Scalars['String'];
};

export type ClaimMealRateInput = {
  ClaimMealRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableEntity = InterfaceBase & {
  __typename?: 'ClaimMileageTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMileageTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  mileageTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableInput = {
  ClaimMileageTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimParameterEntity = InterfaceBase & {
  __typename?: 'ClaimParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimMileageTableEntity>>;
  UserFieldsDetails?: Maybe<Array<ClaimUserDefineFieldsEntity>>;
  VehicleDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealDetails?: Maybe<Array<ClaimMealRateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type ClaimParameterInput = {
  ClaimParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  Status?: Maybe<Status>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClaimParameterType {
  VehicleType = 'Vehicle_Type',
  MileageLocation = 'Mileage_Location',
  MileageTable = 'Mileage_Table',
  MealType = 'Meal_Type',
  Venue = 'Venue',
  ExpenseAccounts = 'Expense_Accounts',
  ClaimUserDefineFields = 'Claim_User_Define_Fields'
}

export type ClaimPoolConditionEntity = InterfaceBase & {
  __typename?: 'ClaimPoolConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimPool?: Maybe<ClaimPoolEntity>;
};

export type ClaimPoolConditionInput = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type ClaimPoolEntity = InterfaceBase & {
  __typename?: 'ClaimPoolEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ClaimTypes?: Maybe<Array<ClaimTypeEntity>>;
  ClaimPoolCondition: Array<ClaimPoolConditionEntity>;
};

export type ClaimPoolInput = {
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  ClaimPoolConditionList?: Maybe<Array<ClaimPoolConditionInput>>;
};

export type ClaimPostedListingOutput = {
  __typename?: 'ClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type ClaimPostingInput = {
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimPostingListingOutput = {
  __typename?: 'ClaimPostingListingOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<ClaimPostedListingOutput>;
};

export type ClaimPostingManagementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['String']>;
  DateTo?: Maybe<Scalars['String']>;
};

export type ClaimPostingManagementOutput = {
  __typename?: 'ClaimPostingManagementOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingOutput = {
  __typename?: 'ClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<ClaimPostingPeriodStatus>>;
};

export type ClaimPostingPeriodStatus = {
  __typename?: 'ClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type ClaimRecordHistoryInput = {
  EmployeeID?: Maybe<Scalars['String']>;
};

export type ClaimRecordHistoryOutput = {
  __typename?: 'ClaimRecordHistoryOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimRecordOutput = {
  __typename?: 'ClaimRecordOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  VerifyBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  History?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetail = {
  __typename?: 'ClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  UserDefineField1?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimTypePaymentMethod?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailHeaderOutput = {
  __typename?: 'ClaimRecordsDetailHeaderOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeBankName?: Maybe<Scalars['String']>;
  EmployeeBankAccountNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<ClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailInput = {
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailList = {
  __typename?: 'ClaimRecordsDetailList';
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimRecordsItemInput = {
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  Fields?: Maybe<Scalars['JSON']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
};

export type ClaimRecordsListing = {
  __typename?: 'ClaimRecordsListing';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimSettingEntity = InterfaceBase & {
  __typename?: 'ClaimSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimSettingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
};

export type ClaimSettingInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimSettingsListing = {
  __typename?: 'ClaimSettingsListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CountRecords?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimTakenByLeaveType = {
  __typename?: 'ClaimTakenByLeaveType';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type ClaimTemplateEntity = InterfaceBase & {
  __typename?: 'ClaimTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type ClaimTemplateInput = {
  ClaimTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimToApproveListingOutput = {
  __typename?: 'ClaimToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
  ClaimHistory?: Maybe<Array<ClaimHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ClaimTrxError = {
  __typename?: 'ClaimTrxError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ClaimTypeEntity = InterfaceBase & {
  __typename?: 'ClaimTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  PayItem?: Maybe<PayItemEntity>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  ClaimPolicies?: Maybe<Array<ClaimItemEntity>>;
};

export type ClaimTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
};

export type ClaimUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'ClaimUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimUserDefineFieldsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
  userTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
};

export type ClaimUserDefineFieldsInput = {
  ClaimUserDefineFieldsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
};

export type ClaimVehicleRateEntity = InterfaceBase & {
  __typename?: 'ClaimVehicleRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimVehicleRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  vehicleRate?: Maybe<Array<ClaimParameterEntity>>;
  vehicleRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VehicleName: Scalars['String'];
};

export type ClaimVehicleRateInput = {
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClockPairingType {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW'
}

export type CommonSettingsInfoLinesOutput = {
  __typename?: 'CommonSettingsInfoLinesOutput';
  JobGradeTotal?: Maybe<Scalars['Float']>;
  SkillSetTotal?: Maybe<Scalars['Float']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate2?: Maybe<Scalars['DateTime']>;
  JobPositionTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  RecSrcTotal?: Maybe<Scalars['Float']>;
  UsrDefTotal?: Maybe<Scalars['Float']>;
  DataChangeApprovalPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
  PersonnelEFileSecurityPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyAccessPermEntity = InterfaceBase & {
  __typename?: 'CompanyAccessPermEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAccessPermID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
};

export type CompanyAccessPermInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressEntity = InterfaceBase & {
  __typename?: 'CompanyAltAddressEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyCyclePeriodListOutput = {
  __typename?: 'CompanyCyclePeriodListOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  MMMYYYY?: Maybe<Scalars['String']>;
  PayCycleName?: Maybe<Scalars['String']>;
};

export type CompanyDelaredHolidayEntity = InterfaceBase & {
  __typename?: 'CompanyDelaredHolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyDelaredHolidayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyDelaredHolidayInput = {
  CompanyDelaredHolidayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type CompanyEntity = InterfaceBase & {
  __typename?: 'CompanyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Departments?: Maybe<Array<DepartmentEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  BankInfos?: Maybe<Array<BankInfoEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  Contact?: Maybe<ContactEntity>;
  CompanyStatutory?: Maybe<CompanyStatutoryEntity>;
  LeaveCompulsory?: Maybe<Array<LeaveCompulsoryPolicyEntity>>;
  JobGrade?: Maybe<Array<JobGradePolicyEntity>>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  State?: Maybe<StatesEntity>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
  ModerationTableModifiedDate?: Maybe<Scalars['DateTime']>;
};

export type CompanyHierarchyEntity = InterfaceBase & {
  __typename?: 'CompanyHierarchyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyHierarchyID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type CompanyHierarchyInput = {
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
};

export type CompanyOffDayEntity = InterfaceBase & {
  __typename?: 'CompanyOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Holiday?: Maybe<HolidayEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyOffDayInput = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyDayOffIDs?: Maybe<Array<Scalars['String']>>;
};

export type CompanyOffDayOutput = {
  __typename?: 'CompanyOffDayOutput';
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
};

export type CompanyOffDayWithFilterOutput = {
  __typename?: 'CompanyOffDayWithFilterOutput';
  CalendarOffDayID: Scalars['String'];
};

export type CompanyOrgUnitEntity = InterfaceBase & {
  __typename?: 'CompanyOrgUnitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOrgUnitID: Scalars['String'];
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyHierarchy?: Maybe<CompanyHierarchyEntity>;
};

export type CompanyOrgUnitInput = {
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CompanyPayGroupListingOutput = {
  __typename?: 'CompanyPayGroupListingOutput';
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  UserLogs: Array<UserLogs>;
};

export type CompanyRunningNoEntity = InterfaceBase & {
  __typename?: 'CompanyRunningNoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyRunningNoID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyRunningNoInput = {
  CompanyRunningNoID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyStatutoryAccountEntity = {
  __typename?: 'CompanyStatutoryAccountEntity';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
  StatutoryAccount?: Maybe<CompanyStatutoryEntity>;
};

export type CompanyStatutoryAccountInput = {
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryAccountOutput = {
  __typename?: 'CompanyStatutoryAccountOutput';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryEntity = InterfaceBase & {
  __typename?: 'CompanyStatutoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyStatutoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  SignByCompany?: Maybe<CompanyEntity>;
  EPFAccount?: Maybe<Array<StatutoryAccount>>;
  SocsoAccount?: Maybe<Array<StatutoryAccount>>;
  PCBAccount?: Maybe<Array<StatutoryAccount>>;
  EISAccount?: Maybe<Array<StatutoryAccount>>;
  StatutoryAccount?: Maybe<Array<CompanyStatutoryAccountEntity>>;
};

export type CompanyStatutoryInput = {
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryOutput = {
  __typename?: 'CompanyStatutoryOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  AccNoDesc?: Maybe<Scalars['String']>;
};

export type CompanyViewLastUpdatedOutput = {
  __typename?: 'CompanyViewLastUpdatedOutput';
  CompanyInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyInfoIsNewLastUpdated?: Maybe<Scalars['Boolean']>;
  StatutoryLastPostedDate?: Maybe<Scalars['DateTime']>;
  DepartmentLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessAuthLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyHierarchyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyOrgUnitLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyReportingStructureLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyJobPositionLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAutoNumberingLastPostedDate?: Maybe<Scalars['DateTime']>;
  ExpensesAccountLastPostedDate?: Maybe<Scalars['DateTime']>;
  BankInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarOffDayPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EmployeeLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  CalendarPolicyListLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfGeneralDocument?: Maybe<Scalars['Float']>;
  TotalSizeOfGeneralDocument?: Maybe<Scalars['Float']>;
  RoleLastUpdated?: Maybe<Scalars['DateTime']>;
  RoleLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfDepartment?: Maybe<Scalars['Float']>;
  NoOfRoleAssignment?: Maybe<Scalars['Float']>;
  DefaultState?: Maybe<Scalars['String']>;
  NoOfStates?: Maybe<Scalars['Float']>;
};

export type CompositionRulesInput = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Min: Scalars['Float'];
  Max: Scalars['Float'];
};

export type CompulsaryCourseByJobGradeEntity = InterfaceBase & {
  __typename?: 'CompulsaryCourseByJobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
};

export type CompulsaryCourseByJobGradeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompulsoryLeaveOutput = {
  __typename?: 'CompulsoryLeaveOutput';
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  CountEmployees?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedByName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Employees?: Maybe<Array<EmployeeIndentity>>;
};

export type ConditionListInput = {
  Criteria: Array<CriteriaListInput>;
  Description: Scalars['String'];
};

export type ConditionObject = {
  __typename?: 'ConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ConditionObjectSpecialCase = {
  __typename?: 'ConditionObjectSpecialCase';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ContractDocType {
  WorkPermit = 'Work_Permit',
  EmploymentLetter = 'Employment_Letter',
  EmploymentContract = 'Employment_Contract',
  EmploymentPass = 'Employment_Pass'
}

export type ContractEmployeeOutput = {
  __typename?: 'ContractEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ContractInfo?: Maybe<ContractInfoEntity>;
  Document?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<Scalars['String']>;
  IsContractOverdue?: Maybe<Scalars['Boolean']>;
  IsContractEnded?: Maybe<Scalars['Boolean']>;
};

export type ContractInfoEntity = InterfaceBase & {
  __typename?: 'ContractInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  ContractDocumentType?: Maybe<PersonnelParameterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ContractInfoDocument?: Maybe<DocumentsEntity>;
};

export type ContractInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CostAllocationEntity = InterfaceBase & {
  __typename?: 'CostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CostAllocationID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CostAllocationInput = {
  CostAllocationID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CostCenterMappingEntity = InterfaceBase & {
  __typename?: 'CostCenterMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type CostCenterMappingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryEntity = InterfaceBase & {
  __typename?: 'CountryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CountryID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  PhoneCode?: Maybe<Scalars['Float']>;
};

export type CourseClassificationEntity = InterfaceBase & {
  __typename?: 'CourseClassificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  TrainingCourses?: Maybe<Array<TrainingCourseEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type CourseClassificationInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseEvalEntity = InterfaceBase & {
  __typename?: 'CourseEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CourseEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type CourseEvalInput = {
  CourseEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseFulfilmentOutput = {
  __typename?: 'CourseFulfilmentOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Array<EmployeeFulfilmentOutput>>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type CourseProviderOutput = {
  __typename?: 'CourseProviderOutput';
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<AuthorisedTrainingProviderEntity>>;
};

export enum CourseType {
  Hour = 'Hour',
  Day = 'Day'
}

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['String']>;
};

export enum CrossDayType {
  No = 'NO',
  Yes = 'YES',
  ShiftStartTime = 'SHIFT_START_TIME',
  ShiftFirstHalfEndTime = 'SHIFT_FIRST_HALF_END_TIME',
  ShiftSecondHalfStartTime = 'SHIFT_SECOND_HALF_START_TIME',
  ShiftEndTime = 'SHIFT_END_TIME',
  ClockOutTime = 'CLOCK_OUT_TIME'
}

export enum CurrencyEffectiveMethod {
  ByReceiptDate = 'By_Receipt_Date',
  BySubmissionDate = 'By_Submission_Date'
}

export type DmImported = {
  __typename?: 'DMImported';
  MigratedData_Bank?: Maybe<Scalars['Float']>;
  MigratedData_Company?: Maybe<Scalars['Float']>;
  MigratedData_DepartmentDivision?: Maybe<Scalars['Float']>;
  MigratedData_Employee?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeDependent?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeJobInfo?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeSalary?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeStatutoryInfo?: Maybe<Scalars['Float']>;
  MigratedData_FixedAllwDed?: Maybe<Scalars['Float']>;
  MigratedData_JobDesignation?: Maybe<Scalars['Float']>;
  MigratedData_JobGrade?: Maybe<Scalars['Float']>;
  MigratedData_PayItem?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCutOff?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCycle?: Maybe<Scalars['Float']>;
  MigratedData_PayrollHistory?: Maybe<Scalars['Float']>;
  MigratedData_RecruitmentSource?: Maybe<Scalars['Float']>;
};

export type DailyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DailyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'DailyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type DailyTemplateEntity = InterfaceBase & {
  __typename?: 'DailyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DailyTemplateInput = {
  DailyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DashboardCardSetting = {
  isExpand: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  DashboardCardType: DashboardCardType;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum DashboardCardType {
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager'
}

export type DataChangesDetails = {
  __typename?: 'DataChangesDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  DataChangesID: Scalars['String'];
  Action: Scalars['String'];
  Type: Scalars['String'];
};

export type DataChangesEntity = {
  __typename?: 'DataChangesEntity';
  DataChangesID: Scalars['String'];
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  DataChangeHistory?: Maybe<Array<DataChangesEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type DataChangesInput = {
  DataChangesID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  OldData?: Maybe<ChangesDataInput>;
  Status?: Maybe<Status>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
};


export enum DayType {
  NormalDay = 'NORMAL_DAY',
  OffDay = 'OFF_DAY',
  RestDay = 'REST_DAY',
  PublicHoliday = 'PUBLIC_HOLIDAY'
}

export type DeductionPolicyEntity = InterfaceBase & {
  __typename?: 'DeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type DeductionPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DeductionTemplateEntity = InterfaceBase & {
  __typename?: 'DeductionTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DeductionTemplateInput = {
  DeductionTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Company: CompanyEntity;
};

export type DepartmentInput = {
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DepartmentType {
  Department = 'Department',
  Division = 'Division'
}

export type DepartmentUpdateInput = {
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DietaryPreference {
  CellacDisease = 'Cellac_Disease',
  Diabetic = 'Diabetic',
  Halal = 'Halal',
  Kosher = 'Kosher',
  LactoseIntolerance = 'Lactose_Intolerance',
  PeanutAllergies = 'Peanut_Allergies',
  VegetarianAndVegans = 'Vegetarian_And_Vegans'
}

export type DisplayReportTypeSequenceOutput = {
  __typename?: 'DisplayReportTypeSequenceOutput';
  CP22aReportSeq?: Maybe<Scalars['Float']>;
  CP21ReportSeq?: Maybe<Scalars['Float']>;
  EAFormReportSeq?: Maybe<Scalars['Float']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  EAFormSetupID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type DocumentGrouping = {
  __typename?: 'DocumentGrouping';
  DocumentType: DocumentType;
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberAssignmentEntity = InterfaceBase & {
  __typename?: 'DocumentNumberAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberAssignmentInput = {
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberDetailID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocNumHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  HasNextNumber?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
  IsStandard?: Maybe<Scalars['Boolean']>;
  DocNumDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  DocumentNumberingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  StartFrom?: Maybe<Scalars['Float']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
};

export type DocumentProfilePictureOutput = {
  __typename?: 'DocumentProfilePictureOutput';
  Employee?: Maybe<EmployeeEntity>;
};

export enum DocumentType {
  Payslip = 'Payslip',
  EaForm = 'EA_FORM',
  TaxRelief = 'Tax_Relief',
  GeneralDoc = 'General_Doc',
  PersonalDoc = 'Personal_Doc',
  ContractDoc = 'Contract_Doc',
  Claim = 'Claim',
  Leave = 'Leave',
  Employee = 'Employee',
  News = 'News',
  Requisition = 'Requisition',
  Company = 'Company',
  ReportTemplate = 'ReportTemplate',
  Appraisal = 'Appraisal',
  Tms = 'TMS',
  ProjectExpense = 'ProjectExpense',
  DataChanges = 'DataChanges',
  AttendanceRecord = 'AttendanceRecord',
  EmployeeSalary = 'EmployeeSalary',
  MonthlyTransaction = 'MonthlyTransaction',
  FixedAllowanceDeduction = 'FixedAllowanceDeduction',
  Helpdesk = 'Helpdesk',
  PayrollProcess = 'Payroll_Process',
  Overtime = 'Overtime'
}

export type DocumentsEntity = InterfaceBase & {
  __typename?: 'DocumentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentID: Scalars['String'];
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  Company: CompanyEntity;
};

export type DocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DutyRosterEntity = InterfaceBase & {
  __typename?: 'DutyRosterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DutyRosterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Color?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type DutyRosterInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  DutyRosterID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EaFormSetupEntity = InterfaceBase & {
  __typename?: 'EAFormSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAFormSetupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
};

export type EaFormSetupInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EaPartFEntity = InterfaceBase & {
  __typename?: 'EAPartFEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAPartFID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
};

export type EaPartFInput = {
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  EISChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type EisCalculationOutput = {
  __typename?: 'EISCalculationOutput';
  EmployeeID: Scalars['String'];
  EISAmount: Scalars['Float'];
  EISRequirement?: Maybe<Scalars['String']>;
  EmployeeEISAmount?: Maybe<Scalars['Float']>;
  EmployerEISAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
};

export type EisPolicyEntity = InterfaceBase & {
  __typename?: 'EISPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Formula: Scalars['String'];
  Remarks: Scalars['String'];
};

export type EisPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisRefEntity = InterfaceBase & {
  __typename?: 'EISRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISRefID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount: Scalars['Float'];
  EmpAmount: Scalars['Float'];
};

export type EisRefInput = {
  EISRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount?: Maybe<Scalars['Float']>;
  EmpAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfCalculationInput = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  CurrentMonthEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
  EPFNormalRemunerationChargableAmount?: Maybe<Scalars['Float']>;
  AccumulatedEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
};

export type EpfCalculationInputV2 = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  BasicSalary: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
  NormalRemuneration: Scalars['Float'];
  AdditionalRemuneration?: Maybe<Scalars['Float']>;
  EPFEmployeeToMinus?: Maybe<Scalars['Float']>;
  ForcastedRemuneration?: Maybe<Scalars['Float']>;
  MonthlyWageRemuneration?: Maybe<Scalars['Float']>;
  EPFChargableAmount: Scalars['Float'];
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
};

export type EpfCalculationOutput = {
  __typename?: 'EPFCalculationOutput';
  EmployeeID: Scalars['String'];
  EPFAmount: Scalars['Float'];
  EPFRoundingAmount: Scalars['Float'];
  EmployeeEPFAmount?: Maybe<Scalars['Float']>;
  EmployerEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EmployerVERate: Scalars['Float'];
  EmployeeVERate: Scalars['Float'];
  TotalEmployerRate: Scalars['Float'];
  TotalEmployeeRate: Scalars['Float'];
  EmpUserDefineEPF?: Maybe<Scalars['JSON']>;
};

export type EpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'EPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyDetailID: Scalars['String'];
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  EPFPolicy?: Maybe<EpfPolicyEntity>;
};

export type EpfPolicyDetailInput = {
  EPFPolicyDetailID?: Maybe<Scalars['String']>;
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfPolicyEntity = InterfaceBase & {
  __typename?: 'EPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  Formula: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EPFPolicyDetails?: Maybe<Array<EpfPolicyDetailEntity>>;
};

export type EpfPolicyInput = {
  EPFPolicyID?: Maybe<Scalars['String']>;
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  Age?: Maybe<Scalars['Float']>;
  EPFChargableAmount?: Maybe<Scalars['Float']>;
  EmployerRate?: Maybe<Scalars['Float']>;
  EmployeeRate?: Maybe<Scalars['Float']>;
  Formula?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfRateCalculationOutput = {
  __typename?: 'EPFRateCalculationOutput';
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
};

export type EarnLeaveEntity = InterfaceBase & {
  __typename?: 'EarnLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EarnLeaveID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
};

export enum Education {
  No = 'No',
  Under_18YearsOld = 'Under_18_years_old',
  CertificateMatriculation = 'CertificateMatriculation',
  DiplomaDegree = 'DiplomaDegree'
}

export type EmmployeeListingInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type EmpCalendarLeaveListing = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimBalance = {
  __typename?: 'EmpClaimBalance';
  ClaimItems?: Maybe<ClaimItemEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurenceBalance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimBalanceOutput = {
  __typename?: 'EmpClaimBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimBalance?: Maybe<Array<EmpClaimBalance>>;
};

export type EmpClaimEntitlementByClaimTypeOutput = {
  __typename?: 'EmpClaimEntitlementByClaimTypeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summary?: Maybe<EntitlementSummary>;
};

export type EmpClaimEntitlementByEmployeeOutput = {
  __typename?: 'EmpClaimEntitlementByEmployeeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summarries?: Maybe<Array<EntitlementSummary>>;
};

export type EmpClaimEntitlementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimEntitlementOutput = {
  __typename?: 'EmpClaimEntitlementOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
};

export type EmpClaimPoolBalance = {
  __typename?: 'EmpClaimPoolBalance';
  ClaimPool?: Maybe<ClaimPoolEntity>;
  ClaimPoolCondition?: Maybe<ClaimPoolConditionEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimPoolBalanceOutput = {
  __typename?: 'EmpClaimPoolBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimPoolBalance?: Maybe<Array<EmpClaimPoolBalance>>;
};

export type EmpClaimTypeValidationOutput = {
  __typename?: 'EmpClaimTypeValidationOutput';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveApplicationInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  SaveAsType: ApprovalStatus;
};

export type EmpLeaveBalanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpLeaveBalanceOuput = {
  __typename?: 'EmpLeaveBalanceOuput';
  Employee?: Maybe<EmployeeEntity>;
  LeaveTypeBalance?: Maybe<Array<LeaveTypeBalance>>;
};

export type EmpLeaveDetailInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
};

export type EmpLeaveDetailOuput = {
  __typename?: 'EmpLeaveDetailOuput';
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type EmpLeaveEntitlementOuput = {
  __typename?: 'EmpLeaveEntitlementOuput';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmpLeaveItems?: Maybe<Array<EmpLeaveItemEntitlement>>;
};

export type EmpLeaveItemEntitlement = {
  __typename?: 'EmpLeaveItemEntitlement';
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBenefitRules?: Maybe<Array<LeaveBenefitRuleEntity>>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
};

export type EmpLeaveListingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatusList?: Maybe<Array<ApprovalStatus>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveListingOutput = {
  __typename?: 'EmpLeaveListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveItemName?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type EmpLeaveTypeValidationOutput = {
  __typename?: 'EmpLeaveTypeValidationOutput';
  LeaveTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpProjectExpenseTypeValidationOutput = {
  __typename?: 'EmpProjectExpenseTypeValidationOutput';
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpRequisitionInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
};

export type EmpSalary = {
  __typename?: 'EmpSalary';
  EmployeeID?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  PreStandardWages?: Maybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  SalaryType?: Maybe<Scalars['String']>;
};

export type EmpWorkTime = {
  SysID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  StartDateTime: Scalars['DateTime'];
  EndDateTime: Scalars['DateTime'];
  PunchInDateTime: Scalars['String'];
  PunchOutDateTime: Scalars['String'];
  CalStartDateTime: Scalars['String'];
  CalEndDateTime: Scalars['String'];
  ProductivityMins: Scalars['Float'];
  OvertimeMins: Scalars['Float'];
  ErrorDay: Scalars['String'];
  ErrorDate: Scalars['String'];
  TranStatus: Scalars['String'];
};

export type EmployeeAppLoginResponse = {
  __typename?: 'EmployeeAppLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  employee?: Maybe<EmployeeResponse>;
};

export type EmployeeAppSecurityAssignmentEntity = {
  __typename?: 'EmployeeAppSecurityAssignmentEntity';
  EmployeeAppSecurityAssignmentID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityAssignmentInput = {
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityEntity = {
  __typename?: 'EmployeeAppSecurityEntity';
  EmployeeAppSecurityID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurityPermission?: Maybe<Array<EmployeeAppSecurityPermissionEntity>>;
};

export type EmployeeAppSecurityInput = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityOutput = {
  __typename?: 'EmployeeAppSecurityOutput';
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PermissionList?: Maybe<Array<EmployeeAppSecurityPermissionOutput>>;
  InUse: Scalars['Boolean'];
};

export type EmployeeAppSecurityPermissionEntity = {
  __typename?: 'EmployeeAppSecurityPermissionEntity';
  EmployeeAppSecurityPermissionID: Scalars['String'];
  EmployeeAppSecurityID: Scalars['String'];
  PermissionID: Scalars['String'];
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityPermissionOutput = {
  __typename?: 'EmployeeAppSecurityPermissionOutput';
  EmployeeAppSecurityPermissionID?: Maybe<Scalars['String']>;
  PermissionID?: Maybe<Scalars['Float']>;
  PermissionName?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalDetailID: Scalars['String'];
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeader?: Maybe<EmployeeAppraisalHeaderEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalDetailInput = {
  EmployeeAppraisalDetailID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Array<Scalars['JSON']>>;
  Signature?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeAppraisalDetails?: Maybe<Array<EmployeeAppraisalDetailEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  SignatureDocument?: Maybe<DocumentsEntity>;
  FinalRating?: Maybe<Scalars['Float']>;
  AttachmentList?: Maybe<Array<DocumentsEntity>>;
  GetSignatureDocument?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderInput = {
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<AppraiseeType>;
  EmployeeID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  SignatureInput?: Maybe<DocumentsInput>;
  SignatureToRemove?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeAppraisalDetailInput>>;
};

export type EmployeeAppraisalInput = {
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAssetEntity = InterfaceBase & {
  __typename?: 'EmployeeAssetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAssetID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeAssetInput = {
  EmployeeAssetID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAttendanceOutput = {
  __typename?: 'EmployeeAttendanceOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseRecord?: Maybe<Array<EmployeeCourseRecordAttendanceOutput>>;
  FinalTotalAttendanceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'EmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeBankInfoInput = {
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
};

export type EmployeeBenefitInKindEntity = InterfaceBase & {
  __typename?: 'EmployeeBenefitInKindEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBenefitInKindID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBenefitInKindInput = {
  EmployeeBenefitInKindID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBirthdaysOutput = {
  __typename?: 'EmployeeBirthdaysOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type EmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Entity = InterfaceBase & {
  __typename?: 'EmployeeCP22Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeCP22ID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LHDNReportID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LHDNReport?: Maybe<LhdnReportEntity>;
};

export type EmployeeCp22Input = {
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Output = {
  __typename?: 'EmployeeCP22Output';
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReportSequence?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type EmployeeCalendarOutput = {
  __typename?: 'EmployeeCalendarOutput';
  companyOffDays?: Maybe<Array<CompanyOffDayOutput>>;
  employeeBirthdays?: Maybe<Array<EmployeeBirthdaysOutput>>;
  employeeLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
  employeeStaffLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
};

export type EmployeeCheckIn = {
  EmployeeNo: Scalars['String'];
  ClockDateTime: Scalars['DateTime'];
  ClockType: Scalars['String'];
  ClockRemark: Scalars['String'];
};

export type EmployeeConditionsDetails = {
  __typename?: 'EmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<ConditionObject>;
  JobPosition?: Maybe<ConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<ConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<ConditionObject>;
  ShiftGroup?: Maybe<ConditionObjectSpecialCase>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  ConfirmedDueDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<PolicyOutput>>;
  EmployeeCareerLogs?: Maybe<Array<Scalars['JSON']>>;
  Age?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeCourseRecordAttendanceOutput = {
  __typename?: 'EmployeeCourseRecordAttendanceOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  TotalAttendaceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'EmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeDetailsInput = {
  Date: Scalars['DateTime'];
  Company: Scalars['String'];
  JoinedDate: Scalars['DateTime'];
  ConfirmedDate: Scalars['DateTime'];
  JobPosition: Scalars['String'];
  JobGrade: Scalars['String'];
  EmploymentType: Scalars['String'];
  OrgUnit: Scalars['String'];
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EmployeeDiaryEntity = InterfaceBase & {
  __typename?: 'EmployeeDiaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDiaryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeDiaryInput = {
  EmployeeID: Scalars['String'];
  EmployeeDiaryID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeEntity = InterfaceBase & {
  __typename?: 'EmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
};

export type EmployeeFilterInput = {
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeFulfilmentOutput = {
  __typename?: 'EmployeeFulfilmentOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
};

export type EmployeeIndentity = {
  __typename?: 'EmployeeIndentity';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  IsSpecial?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeInsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'EmployeeInsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Employee?: Maybe<EmployeeEntity>;
  InsuranceScheme?: Maybe<InsuranceSchemeEntity>;
};

export type EmployeeInsuranceSchemeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiCategoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKPICategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID: Scalars['String'];
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  EmployeeKPIHeader?: Maybe<EmployeeKpiHeaderEntity>;
  EmployeeKPIDetail?: Maybe<Array<EmployeeKpiDetailEntity>>;
};

export type EmployeeKpiCategoryInput = {
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeKpiDetailInput>>;
};

export type EmployeeKpiDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID: Scalars['String'];
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<EmployeeKpiCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  EmployeeKPIDetailRating?: Maybe<Array<EmployeeKpiDetailRatingEntity>>;
};

export type EmployeeKpiDetailInput = {
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Scale?: Maybe<Array<EmployeeKpiDetailRatingInput>>;
};

export type EmployeeKpiDetailRatingEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailRatingID: Scalars['String'];
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPIDetail?: Maybe<EmployeeKpiDetailEntity>;
};

export type EmployeeKpiDetailRatingInput = {
  EmployeeKPIDetailRatingID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<Array<EmployeeKpiCategoryEntity>>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiHeaderInput = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<EmployeeKpiCategoryInput>>;
};

export type EmployeeKpiEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseDetailsID: Scalars['String'];
  EmployeeKpiDetails: Array<Scalars['JSON']>;
  Comment?: Maybe<Scalars['String']>;
  KpiExerciseDetail: KpiExerciseDetailsEntity;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  EmployeeKpiDetails: Array<KpiMinMaxWeightage>;
  Status: Status;
};

export type EmployeeKpiRepositoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiRepositoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: Scalars['JSON'];
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiRepositoryInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: KpiCategoryItemInput;
};

export type EmployeeLeavePolicy = {
  __typename?: 'EmployeeLeavePolicy';
  LeavePolicy?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type EmployeeLeavesOutput = {
  __typename?: 'EmployeeLeavesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<Scalars['String']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  LeaveApprovalStatus?: Maybe<Scalars['String']>;
  OwnSubordinates?: Maybe<Scalars['Boolean']>;
  TotalDay?: Maybe<Scalars['Float']>;
};

export type EmployeeLeavesSummary = {
  __typename?: 'EmployeeLeavesSummary';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmployeeLeavePolicies?: Maybe<Array<EmployeeLeavePolicy>>;
};

export type EmployeeListOutput = {
  __typename?: 'EmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type EmployeeListingOutput = {
  __typename?: 'EmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};

export type EmployeeNotificationOutput = {
  __typename?: 'EmployeeNotificationOutput';
  NotificationID?: Maybe<Scalars['String']>;
  Topic?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<LeaveDetails>;
  ClaimDetails?: Maybe<ClaimDetails>;
  RequisitionDetails?: Maybe<RequisitionDetails>;
  OvertimeDetails?: Maybe<OvertimeEntity>;
  TimeOffDetails?: Maybe<TimeOffEntity>;
  ChangeShiftDetails?: Maybe<ChangeShiftEntity>;
  DataChangesDetails?: Maybe<DataChangesDetails>;
  ProjectExpenseClaimDetails?: Maybe<ProjectExpenseClaimDetails>;
  PerformanceKpiDetail?: Maybe<KpiExerciseDetailsEntity>;
  PerformanceReviewDetail?: Maybe<EmployeeReviewEntity>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  ProjectTimesheetDetail?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
  Status?: Maybe<Status>;
};

export type EmployeePlanningOutput = {
  __typename?: 'EmployeePlanningOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  QuaterType?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PlanningList?: Maybe<Array<EmployeeKpiHeaderEntity>>;
};

export type EmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'EmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type EmployeeReportingOutput = {
  __typename?: 'EmployeeReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  SuperiorID: Scalars['String'];
  Superior?: Maybe<EmployeeEntity>;
  SubordinateID: Scalars['String'];
  Subordinate?: Maybe<EmployeeEntity>;
};

export type EmployeeResponse = {
  __typename?: 'EmployeeResponse';
  subscriptionAccountID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  SuperUserID?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
};

export type EmployeeReviewEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewDate?: Maybe<Scalars['DateTime']>;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog?: Maybe<Scalars['JSON']>;
  ReviewExercise?: Maybe<ReviewExerciseEntity>;
  EmployeeReviewSection: Array<EmployeeReviewSectionEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Reviewer?: Maybe<EmployeeEntity>;
  ReviewFormTemplate?: Maybe<ReviewFormTemplateEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
  ReviewFormTemplateName: Scalars['String'];
  ApprovalHistory: Array<EmployeeReviewEntity>;
  ReviewGradingTable: Scalars['JSON'];
  HasNextLevelApproval?: Maybe<Scalars['Boolean']>;
  ReviewType: Scalars['String'];
  WeightageType: QuantitativeType;
  IsSendBackToInitaite?: Maybe<Scalars['Boolean']>;
};

export type EmployeeReviewInput = {
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  EmployeeReviewSection: Array<EmployeeReviewSectionInput>;
};

export type EmployeeReviewSectionEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable?: Maybe<Scalars['Boolean']>;
  Recommendation?: Maybe<Scalars['JSON']>;
  Weightage: Scalars['Float'];
  EmployeeReview?: Maybe<EmployeeReviewEntity>;
  ReviewSection?: Maybe<ReviewSectionEntity>;
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemEntity>;
};

export type EmployeeReviewSectionInput = {
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable: Scalars['Boolean'];
  Weightage: Scalars['Float'];
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemInput>;
};

export type EmployeeReviewSectionItemEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionID: Scalars['String'];
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
  ReviewItemDetail: Scalars['JSON'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReviewSection?: Maybe<EmployeeReviewSectionEntity>;
  ReviewConversationLog?: Maybe<Array<ReviewConversationLogEntity>>;
  ItemDetails: Scalars['JSON'];
};

export type EmployeeReviewSectionItemInput = {
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type EmployeeReviewSetupInput = {
  ID: Scalars['String'];
  ReviewerID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export enum EmployeeSalaryReason {
  AnnualReview = 'Annual_Review',
  ChangePosition = 'Change_Position',
  ConfirmationOfProbation = 'Confirmation_of_Probation',
  ContractRenewal = 'Contract_Renewal',
  Increment = 'Increment',
  InternalTransfer = 'Internal_Transfer',
  MarketAdjustment = 'Market_Adjustment',
  Others = 'Others',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  NewHire = 'New_Hire'
}

export type EmployeeStatutoryEntity = {
  __typename?: 'EmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeStatutoryInput = {
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
};

export type EmployeeSummary = {
  __typename?: 'EmployeeSummary';
  TimeOff?: Maybe<Scalars['JSON']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  Roster?: Maybe<Scalars['Float']>;
  OT?: Maybe<Scalars['JSON']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ScanDate?: Maybe<Scalars['DateTime']>;
  Shift?: Maybe<Scalars['JSON']>;
  ClockType?: Maybe<IntegrationClockType>;
  TimeOffDate?: Maybe<Scalars['DateTime']>;
  ChangeShiftDate?: Maybe<Scalars['DateTime']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeTaxReliefEntity = InterfaceBase & {
  __typename?: 'EmployeeTaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTaxReliefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  TaxRelief?: Maybe<TaxReliefEntity>;
};

export type EmployeeTaxReliefInput = {
  EmployeeTaxReliefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingEntity = InterfaceBase & {
  __typename?: 'EmployeeTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTrainingID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeTrainingInput = {
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTransactionOutput = {
  __typename?: 'EmployeeTransactionOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeFullName?: Maybe<Scalars['String']>;
  PayItems?: Maybe<Array<PayItemOutput>>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  InUse?: Maybe<Scalars['Boolean']>;
  IsProcessed: Scalars['Boolean'];
};

export type EmployeeUserDefineFieldsDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsDetailID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type EmployeeUserDefineFieldsDetailInput = {
  EmployeeUserDefineFieldsDetailID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  EmployeeUserDefineFieldsDetail?: Maybe<EmployeeUserDefineFieldsDetailEntity>;
};

export type EmployeeUserDefineFieldsInput = {
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeesBalanceLeaveSummary = {
  __typename?: 'EmployeesBalanceLeaveSummary';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export enum EmploymentType {
  Contract = 'Contract',
  Internship = 'Internship',
  Permanent = 'Permanent',
  PartTime = 'Part_Time',
  CasualWorker = 'Casual_Worker',
  FixedTermContract = 'Fixed_Term_Contract'
}

export type EncashCarryForwardLeaveEntity = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
};

export type EncashCarryForwardLeaveInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashCarryForwardLeaveOutput = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  PayrollProcessed?: Maybe<Scalars['Boolean']>;
};

export type EncashOffboadingLeaveEntity = InterfaceBase & {
  __typename?: 'EncashOffboadingLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashOffboadingLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export type EncashOffboadingLeaveInput = {
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashedLeaveHistory = {
  __typename?: 'EncashedLeaveHistory';
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export enum EntDistributionMethod {
  LosPeriod = 'LOS_Period',
  EqualMonthly = 'Equal_Monthly'
}

export type EntRoleUsrArgCountOutput = {
  __typename?: 'EntRoleUsrArgCountOutput';
  roleId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type EntRoleUsrArgEntity = {
  __typename?: 'EntRoleUsrArgEntity';
  id: Scalars['String'];
  entId: Scalars['String'];
  roleId: Scalars['String'];
  accId: Scalars['String'];
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Role?: Maybe<RoleEntity>;
};

export type EntRoleUsrArgInput = {
  id?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  accId?: Maybe<Scalars['String']>;
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum EntitlementAvailability {
  LengthOfService = 'Length_of_Service',
  BeginningOfYear = 'Beginning_of_Year'
}

export enum EntitlementBasedOn {
  DateJoin = 'Date_Join',
  JoinReferenceDate = 'Join_Reference_Date'
}

export enum EntitlementComputation {
  Max = 'Max',
  ByService = 'By_Service'
}

export type EntitlementSummary = {
  __typename?: 'EntitlementSummary';
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<Scalars['String']>;
  Entitled?: Maybe<Scalars['Float']>;
  Draft?: Maybe<Scalars['Float']>;
  Pending?: Maybe<Scalars['Float']>;
  Claimed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID?: Maybe<Array<Scalars['String']>>;
  roleID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};

export type EonEaDocumentEntity = {
  __typename?: 'EonEaDocumentEntity';
  EonEaDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type EonPayslipDocumentEntity = {
  __typename?: 'EonPayslipDocumentEntity';
  EonPayslipDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type ExcelPayItemTransactionOutput = {
  __typename?: 'ExcelPayItemTransactionOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  TotalNonPayable?: Maybe<Scalars['Float']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum ExitReason {
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Illness = 'Illness',
  Absconded = 'Absconded',
  DepartureFromMalaysia = 'DepartureFromMalaysia',
  InterCompanyTransfer = 'InterCompanyTransfer',
  Null = 'Null'
}

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinalClaimApprovalUpdateInput = {
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingDetailEntity = InterfaceBase & {
  __typename?: 'FinalRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingDetailID: Scalars['String'];
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
};

export type FinalRatingDetailInput = {
  FinalRatingDetailID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingHeaderEntity = InterfaceBase & {
  __typename?: 'FinalRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  FinalRatingDetails?: Maybe<Array<FinalRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type FinalRatingHeaderInput = {
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<FinalRatingDetailInput>>;
};

export type FormItemRatingEntity = InterfaceBase & {
  __typename?: 'FormItemRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrderType: SequenceOrderType;
  From: Scalars['Float'];
  To: Scalars['Float'];
  RatingScaleTable: Array<Scalars['JSON']>;
  InputMethod: RatingInputMethod;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  InUse: Scalars['Boolean'];
};

export type FormItemRatingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  OrderType?: Maybe<SequenceOrderType>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RatingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type FormItemRatingSetup = {
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
};

export enum FormStatus {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type FreezePayrollEntity = InterfaceBase & {
  __typename?: 'FreezePayrollEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Employees?: Maybe<EmployeeEntity>;
};

export type FreezePayrollInput = {
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GlAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'GLAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};

export type GlAccountDetailEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type GlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type GlAccountHeaderEntity = InterfaceBase & {
  __typename?: 'GLAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  GLAccountDetail?: Maybe<Array<GlAccountDetailEntity>>;
};

export type GlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type GlClaimRecordsDetail = {
  __typename?: 'GLClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralSettingsLastUpdatedOutput = {
  __typename?: 'GeneralSettingsLastUpdatedOutput';
  RoleSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  RoleSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayrollSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  LeaveSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeaveSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  ClaimSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobGradeIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeNoOfActiveRecords?: Maybe<Scalars['Float']>;
  JobDesignationLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobDesignationIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobDesignationNoOfActiveRecords?: Maybe<Scalars['Float']>;
  RecruitmentSourceCount?: Maybe<Scalars['Float']>;
  RecruitmentSourceIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollItemIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemNoOfActiveRecords?: Maybe<Scalars['Float']>;
  LHDNReportsNoOfActiveRecords?: Maybe<Scalars['Float']>;
  CompanylastUpdate?: Maybe<Scalars['Boolean']>;
  CompanylastPosted?: Maybe<Scalars['DateTime']>;
  NewslastUpdate?: Maybe<Scalars['Boolean']>;
  NewslastPosted?: Maybe<Scalars['DateTime']>;
  AutoNumberSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
};

export type GenerateClaimCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GenerateLeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailOutput = {
  __typename?: 'GetApprovedDetailOutput';
  Date?: Maybe<Scalars['String']>;
  ApprovedBySuperior?: Maybe<Scalars['String']>;
  ApprovedAmt?: Maybe<Scalars['Float']>;
};

export type GetCompletedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetCompletedDetailOutput = {
  __typename?: 'GetCompletedDetailOutput';
  ClaimType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['String']>;
  ClaimApprovedAmount?: Maybe<Scalars['Float']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  JobCode?: Maybe<Scalars['String']>;
  ClaimLimit?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type GetEmpClaimListingInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
};

export type GetEmpClaimListingOutput = {
  __typename?: 'GetEmpClaimListingOutput';
  Subject?: Maybe<Scalars['String']>;
  SummaryTotalAmount?: Maybe<Scalars['Float']>;
  TotalClaimItem?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['String']>;
};

export type GetMainClaimListingOutput = {
  __typename?: 'GetMainClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  TotalClaimApproved?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type GetOsFulfilmentByJobGradeOutput = {
  __typename?: 'GetOSFulfilmentByJobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type GradingTable = {
  __typename?: 'GradingTable';
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GradingTableInput = {
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GroupCountEmployeeRoleListingOutput = {
  __typename?: 'GroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type HrdfCalculationInput = {
  EmployeeID: Scalars['String'];
  HRDFChargableAmount: Scalars['Float'];
  HRDFRate: Scalars['Float'];
};

export type HrdfCalculationOutput = {
  __typename?: 'HRDFCalculationOutput';
  EmployeeID: Scalars['String'];
  EmployerHRDFAmount?: Maybe<Scalars['Float']>;
  HRDFRate: Scalars['Float'];
};

export type HrdfClaimEntity = InterfaceBase & {
  __typename?: 'HRDFClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HRDFClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type HrdfClaimInput = {
  HRDFClaimID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HrdfClaimOutput = {
  __typename?: 'HRDFClaimOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  LatestClaimRefNo?: Maybe<Scalars['String']>;
  LatestClaimAmount?: Maybe<Scalars['Float']>;
};

export type HelpdeskListingReportInput = {
  CompanyID: Scalars['String'];
  SubmissionStartDate?: Maybe<Scalars['DateTime']>;
  SubmissionEndDate?: Maybe<Scalars['DateTime']>;
  ClosedStartDate?: Maybe<Scalars['DateTime']>;
  ClosedEndDate?: Maybe<Scalars['DateTime']>;
  AssigneeIDs: Array<Scalars['JSON']>;
  StatusLists: Array<Status>;
};

export type HelpdeskSubjectEntity = InterfaceBase & {
  __typename?: 'HelpdeskSubjectEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment?: Maybe<Scalars['Boolean']>;
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Assignees: Scalars['JSON'];
  InUse: Scalars['Boolean'];
};

export type HelpdeskSubjectInput = {
  ID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment: Scalars['Boolean'];
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
};

export type HelpdeskTicketEntity = InterfaceBase & {
  __typename?: 'HelpdeskTicketEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  HelpdeskSubjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubjectDetails: Scalars['JSON'];
  TicketNo: Scalars['String'];
  ContactID: Scalars['String'];
  RequestDescription: Scalars['String'];
  RequestAttachment?: Maybe<Array<Scalars['JSON']>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<Scalars['JSON']>>;
  ReassignedDT?: Maybe<Scalars['DateTime']>;
  OpenDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
  Employee?: Maybe<EmployeeEntity>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  RequestDocuments?: Maybe<Array<DocumentsEntity>>;
  ResolutionDocuments?: Maybe<Array<DocumentsEntity>>;
  CurrentAssignees: Scalars['JSON'];
  AssigneeClosedTicket?: Maybe<Scalars['String']>;
};

export type HelpdeskTicketInput = {
  HelpdeskTicketID?: Maybe<Scalars['String']>;
  HelpdeskSubjectID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TicketNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  RequestDescription?: Maybe<Scalars['String']>;
  RequestAttachment?: Maybe<Array<DocumentsInput>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<DocumentsInput>>;
  ReassignedDT?: Maybe<Scalars['String']>;
  OpenDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
};

export type HolidayEntity = InterfaceBase & {
  __typename?: 'HolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HolidayID: Scalars['String'];
  RestOffType: RestOffType;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
};

export type HolidayInput = {
  HolidayID?: Maybe<Scalars['String']>;
  RestOffType?: Maybe<RestOffType>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  currentEmployeeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveTypeHeader?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  UntillDate?: Maybe<Scalars['DateTime']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  myBirthDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  leaveType?: Maybe<Scalars['String']>;
  HolidayDescription?: Maybe<Scalars['String']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export enum HrPermission {
  HomeRead = 'HOME__READ',
  HomeCalendarRead = 'HOME_CALENDAR_READ',
  HomeCalendarBirthday = 'HOME_CALENDAR_BIRTHDAY',
  HomeDashboardRead = 'HOME_DASHBOARD____READ',
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager',
  HomeMymenuMyPersonalInfoRead = 'HOME_MYMENU_MY_PERSONAL_INFO_____READ',
  HomeMymenuRead = 'HOME_MYMENU_____READ',
  HomeMymenuMyPersonalInfoPersonalProfileRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_PROFILE_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEducationMymenuView = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____VIEW',
  HomeMymenuMyPersonalInfoEducationMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEducationMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoCertificateMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____READ',
  HomeMymenuMyPersonalInfoCertificateMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoCertificateMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoCertificateMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____READ',
  HomeMymenuMyPersonalInfoReferencesMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoReferencesMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoDependentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_DEPENDENT_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____READ',
  HomeMymenuMyPersonalInfoPersonalDocumentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoJobInfoMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_JOB_INFO_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_____READ',
  HomeMymenuMyEmploymentInfoAssetAssignedMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoPerformanceJournalMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoInsuranceSchemeMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoCareerMovementMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoGeneralDocumentMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_GENERAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoEmployeeUserDefineFieldsRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomeMymenuMyPayrollInfoRead = 'HOME_MYMENU_MY_PAYROLL_INFO_____READ',
  HomeMymenuMyPayrollInfoViewPayrollHistoryMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_VIEW_PAYROLL_HISTORY_MYMENU_____READ',
  HomeMymenuMyPayrollInfoStatutoryInfoMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_STATUTORY_INFO_MYMENU_____READ',
  HomeMymenuMyPayrollInfoTp3MymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_TP3_MYMENU_____READ',
  HomeMymenuMyPayrollInfoPayslipMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_PAYSLIP_MYMENU_____READ',
  HomeMymenuMyPayrollInfoEaFormMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_EA_FORM_MYMENU_____READ',
  HomeMymenuMyLeaveRead = 'HOME_MYMENU_MY_LEAVE_____READ',
  HomeMymenuMyLeaveCreate = 'HOME_MYMENU_MY_LEAVE_____CREATE',
  HomeMymenuMyLeaveUpdate = 'HOME_MYMENU_MY_LEAVE_____UPDATE',
  HomeMymenuMyLeaveSubmit = 'HOME_MYMENU_MY_LEAVE_____SUBMIT',
  HomeMymenuMyLeaveDelete = 'HOME_MYMENU_MY_LEAVE_____DELETE',
  HomeMymenuMyLeaveCancel = 'HOME_MYMENU_MY_LEAVE_____CANCEL',
  HomeMymenuMyLeaveWithdraw = 'HOME_MYMENU_MY_LEAVE_____WITHDRAW',
  HomeMymenuMyLeaveEntitlementRead = 'HOME_MYMENU_MY_LEAVE_ENTITLEMENT_____READ',
  HomeMymenuMyClaimEntitlementRead = 'HOME_MYMENU_MY_CLAIM_ENTITLEMENT_____READ',
  HomeMymenuMyClaimRead = 'HOME_MYMENU_MY_CLAIM_____READ',
  HomeMymenuMyClaimCreate = 'HOME_MYMENU_MY_CLAIM_____CREATE',
  HomeMymenuMyClaimUpdate = 'HOME_MYMENU_MY_CLAIM_____UPDATE',
  HomeMymenuMyClaimDelete = 'HOME_MYMENU_MY_CLAIM_____DELETE',
  HomeMymenuMyClaimWithdraw = 'HOME_MYMENU_MY_CLAIM_____WITHDRAW',
  HomeMymenuMyClaimSubmit = 'HOME_MYMENU_MY_CLAIM_____SUBMIT',
  HomeMymenuMyClaimCancel = 'HOME_MYMENU_MY_CLAIM_____CANCEL',
  HomeMymenuMyProjectExpenseClaimWithdraw = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMymenuMyProjectExpenseClaimSubmit = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMymenuMyProjectExpenseClaimCreate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMymenuMyProjectExpenseClaimRead = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMymenuMyProjectExpenseClaimUpdate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____UPDATE',
  HomeMymenuMyProjectExpenseClaimDelete = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____DELETE',
  HomeMymenuMyProjectExpenseClaimCancel = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMymenuMyRequisitionDelete = 'HOME_MYMENU_MY_REQUISITION_____DELETE',
  HomeMymenuMyRequisitionSubmit = 'HOME_MYMENU_MY_REQUISITION_____SUBMIT',
  HomeMymenuMyRequisitionRead = 'HOME_MYMENU_MY_REQUISITION_____READ',
  HomeMymenuMyRequisitionCreate = 'HOME_MYMENU_MY_REQUISITION_____CREATE',
  HomeMymenuMyRequisitionUpdate = 'HOME_MYMENU_MY_REQUISITION_____UPDATE',
  HomeMymenuMyRequisitionWithdraw = 'HOME_MYMENU_MY_REQUISITION_____WITHDRAW',
  HomeMymenuMyRequisitionCancel = 'HOME_MYMENU_MY_REQUISITION_____CANCEL',
  HomeMymenuMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_____READ',
  HomeMymenuMyTimesheetMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____READ',
  HomeMymenuMyTimesheetMyTimesheetCreate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____CREATE',
  HomeMymenuMyTimesheetMyTimesheetDelete = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____DELETE',
  HomeMymenuMyTimesheetMyTimesheetUpdate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryCreate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeMymenuMyTimesheetProjectTimesheetEntryDelete = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeMymenuMyTimesheetProjectTimesheetEntryUpdate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionSubmit = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeMymenuMyTimeAttendanceRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_____READ',
  HomeMymenuMyTimeAttendanceDutyRosterMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_DUTY_ROSTER_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceAttendanceMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_MYMENU_____READ',
  HomeMymenuMyPerformanceRead = 'HOME_MYMENU_MY_PERFORMANCE_____READ',
  HomeMymenuMyPerformanceKpiMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuDelete = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____DELETE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiRepositoryMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____UPDATE',
  HomeMymenuMyDataChangeRequestRead = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____READ',
  HomeMymenuMyDataChangeRequestCreate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CREATE',
  HomeMymenuMyDataChangeRequestSubmit = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____SUBMIT',
  HomeMymenuMyDataChangeRequestCancel = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CANCEL',
  HomeMymenuMyDataChangeRequestUpdate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____UPDATE',
  HomeMymenuMyDataChangeRequestDelete = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____DELETE',
  HomeMysubordinateRead = 'HOME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_____READ',
  HomeMysubordinateSubordinatePersonalInfoPersonalProfileMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_PROFILE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoDependentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_DEPENDENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoPersonalDocumentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_DOCUMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_____READ',
  HomeMysubordinateSubordinateEmploymentInfoJobInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_JOB_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoStatutoryInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_STATUTORY_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoTp3MysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_TP3_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoAssetAssignedMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoPerformanceJournalMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoInsuranceSchemeMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoCareerMovementMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoEmployeeUserDefineFieldsMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateTimesheetRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TIMESHEET_____READ',
  HomeMysubordinateSubordinateLeaveRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____READ',
  HomeMysubordinateSubordinateLeaveSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____SUBMIT',
  HomeMysubordinateSubordinateLeaveCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CREATE',
  HomeMysubordinateSubordinateLeaveWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____WITHDRAW',
  HomeMysubordinateSubordinateLeaveCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CANCEL',
  HomeMysubordinateSubordinateClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CREATE',
  HomeMysubordinateSubordinateClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____READ',
  HomeMysubordinateSubordinateClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateLeaveEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____SUBMIT',
  HomeMysubordinateSubordinateRequisitionCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CREATE',
  HomeMysubordinateSubordinateRequisitionRead = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____READ',
  HomeMysubordinateSubordinateRequisitionCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateProjectExpenseClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMysubordinateSubordinateProjectExpenseClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateProjectExpenseClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSave = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SAVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceDutyRosterMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_DUTY_ROSTER_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateOrganizationChartRead = 'HOME_MYSUBORDINATE_SUBORDINATE_ORGANIZATION_CHART_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateApprove = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____APPROVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateReInitiate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____RE_INITIATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSendBack = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SEND_BACK',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateProceed = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____PROCEED',
  HomeTimesheetRead = 'HOME_TIMESHEET_______READ',
  CompanySettingTimesheetRead = 'COMPANY_SETTING_TIMESHEET_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______CREATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______UPDATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityDelete = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______DELETE',
  CompanySettingTimesheetTimesheetAccountCodeRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______READ',
  CompanySettingTimesheetTimesheetAccountCodeCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______CREATE',
  CompanySettingTimesheetTimesheetAccountCodeUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______UPDATE',
  HomeTimesheetEmployeeTimesheetRead = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______READ',
  HomeTimesheetEmployeeTimesheetExport = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______EXPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetEntrySubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY_SUBMISSION_______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryCreate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryDelete = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryUpdate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionSubmit = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverGenerateReport = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______GENERATE_REPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverSendEmail = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______SEND_EMAIL',
  HomeTimesheetMandayAnalysisByAccountCodeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______READ',
  HomeTimesheetMandayAnalysisByAccountCodeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______EXPORT',
  HomeTimesheetMandayAnalysisByAccountTypeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______READ',
  HomeTimesheetMandayAnalysisByAccountTypeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______EXPORT',
  HomeAdminStandardAutoNumberingRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______UPDATE',
  HomeAdminStandardAutoNumberingClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingTravelRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingTravelRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingAssetRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingAssetRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingOtherRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingOtherRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______READ',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______UPDATE',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______READ',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  CompanySettingAdminRead = 'COMPANY_SETTING_ADMIN_______READ',
  CompanySettingAdminCreate = 'COMPANY_SETTING_ADMIN_______CREATE',
  CompanySettingAdminCompanyInfoRead = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______READ',
  CompanySettingAdminCompanyInfoUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______UPDATE',
  CompanySettingAdminStatutoryInfoRead = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______READ',
  CompanySettingAdminStatutoryInfoUpdate = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______UPDATE',
  CompanySettingAdminCompanyHierarchyStructureRead = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______READ',
  CompanySettingAdminCompanyHierarchyStructureUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______UPDATE',
  CompanySettingAdminOrgUnitStructureRead = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______READ',
  CompanySettingAdminOrgUnitStructureUpdate = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______UPDATE',
  CompanySettingAdminJobPositionByOrgUnitRead = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______READ',
  CompanySettingAdminJobPositionByOrgUnitUpdate = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______UPDATE',
  CompanySettingAdminReportingStructureRead = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______READ',
  CompanySettingAdminReportingStructureCreate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______CREATE',
  CompanySettingAdminReportingStructureUpdate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______UPDATE',
  CompanySettingAdminReportingStructureDelete = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______DELETE',
  CompanySettingAdminCalendarPolicyRead = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______READ',
  CompanySettingAdminCalendarPolicyCreate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______CREATE',
  CompanySettingAdminCalendarPolicyUpdate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______UPDATE',
  CompanySettingAdminCalendarPolicyDelete = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______DELETE',
  CompanySettingAdminCalendarPolicyInactive = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______INACTIVE',
  CompanySettingAdminCalendarPolicyReactivate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______REACTIVATE',
  CompanySettingAdminCompanyAccessLevelAuthorizationRead = 'COMPANY_SETTING_ADMIN_COMPANY_ACCESS_LEVEL_AUTHORIZATION_______READ',
  CompanySettingAdminAccessPermissionRead = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______READ',
  CompanySettingAdminAccessPermissionUpdate = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  CompanySettingAdminGeneralDocumentRead = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______READ',
  CompanySettingAdminGeneralDocumentView = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______VIEW',
  CompanySettingAdminGeneralDocumentCreate = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentDelete = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______DELETE',
  CompanySettingAdminEmployeeAppSecurityAssignmentRead = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______READ',
  CompanySettingAdminEmployeeAppSecurityAssignmentView = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______VIEW',
  CompanySettingAdminEmployeeAppSecurityAssignmentCreate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentUpdate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______UPDATE',
  CompanySettingAdminGeneralDocumentDelete = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______DELETE',
  CommonSettingAdminRead = 'COMMON_SETTING_ADMIN_______READ',
  HomeAdminExecSummaryRead = 'HOME_ADMIN_EXEC_SUMMARY____READ',
  HomeAdminExternalUserRead = 'HOME_ADMIN_EXTERNAL_USER____READ',
  HomeAdminExternalUserReadUser = 'HOME_ADMIN_EXTERNAL_USER_READ_USER',
  HomeAdminExternalUserReadEmployee = 'HOME_ADMIN_EXTERNAL_USER_READ_EMPLOYEE',
  HomeAdminExternalUserView = 'HOME_ADMIN_EXTERNAL_USER_______VIEW',
  HomeAdminExternalUserCreate = 'HOME_ADMIN_EXTERNAL_USER_______CREATE',
  HomeAdminExternalUserUpdate = 'HOME_ADMIN_EXTERNAL_USER_______UPDATE',
  HomeAdminExternalUserUnblock = 'HOME_ADMIN_EXTERNAL_USER_______UNBLOCK',
  HomeAdminExternalUserResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_______RESEND_INVITATION',
  HomeAdminExternalUserChangePassword = 'HOME_ADMIN_EXTERNAL_USER_______CHANGE_PASSWORD',
  HomeAdminExternalUserCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_READ',
  HomeAdminExternalUserCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_UPDATE',
  HomeAdminExternalUserChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeView = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_VIEW',
  HomeAdminExternalUserEmployeeChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeInactive = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_INACTIVE',
  HomeAdminExternalUserEmployeeReactivate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_REACTIVATE',
  HomeAdminExternalUserEmployeeChangePassword = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_PASSWORD',
  HomeAdminExternalUserEmployeeResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_RESEND_INVITATION',
  HomeAdminExternalUserEmployeeHelpdeskAssignee = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_HELPDESK_ASSIGNEE',
  HomeAdminExternalUserEmployeeCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_READ',
  HomeAdminExternalUserEmployeeCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_UPDATE',
  HomeAdminAccessLevelRead = 'HOME_ADMIN_ACCESS_LEVEL____READ',
  HomeAdminAccessLevelView = 'HOME_ADMIN_ACCESS_LEVEL_______VIEW',
  HomeAdminAccessLevelCreate = 'HOME_ADMIN_ACCESS_LEVEL_______CREATE',
  HomeAdminAccessLevelUpdate = 'HOME_ADMIN_ACCESS_LEVEL_______UPDATE',
  HomeAdminAccessLevelDelete = 'HOME_ADMIN_ACCESS_LEVEL_______DELETE',
  HomeAdminEmployeeAppSecurityRead = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY____READ',
  HomeAdminEmployeeAppSecurityView = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______VIEW',
  HomeAdminEmployeeAppSecurityCreate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______CREATE',
  HomeAdminEmployeeAppSecurityUpdate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______UPDATE',
  HomeAdminEmployeeAppSecurityDelete = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______DELETE',
  HomePersonnelExecSummaryRead = 'HOME_PERSONNEL_EXEC_SUMMARY____READ',
  HomePersonnelRead = 'HOME_PERSONNEL_______READ',
  HomeAdminBulletinRead = 'HOME_ADMIN_BULLETIN______READ',
  HomeAdminBulletinCreate = 'HOME_ADMIN_BULLETIN______CREATE',
  HomeAdminBulletinUpdate = 'HOME_ADMIN_BULLETIN______UPDATE',
  HomeAdminBulletinDelete = 'HOME_ADMIN_BULLETIN______DELETE',
  HomeAdminBulletinPublish = 'HOME_ADMIN_BULLETIN______PUBLISH',
  HomeAdminBulletinUnpublish = 'HOME_ADMIN_BULLETIN______UNPUBLISH',
  HomeAdminBulletinDuplicate = 'HOME_ADMIN_BULLETIN______DUPLICATE',
  HomeAdminBulletinAcknowledgedList = 'HOME_ADMIN_BULLETIN______ACKNOWLEDGED_LIST',
  HomeAdminReportingStructureGroupRead = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______READ',
  HomeAdminReportingStructureGroupCreate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______CREATE',
  HomeAdminReportingStructureGroupUpdate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______UPDATE',
  HomeAdminReportingStructureGroupDelete = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______DELETE',
  CommonSettingPersonnelRead = 'COMMON_SETTING_PERSONNEL_______READ',
  CommonSettingPersonnelPersonnelParameterRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___READ',
  CommonSettingPersonnelPersonnelParameterCreate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___CREATE',
  CommonSettingPersonnelPersonnelParameterUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___UPDATE',
  CommonSettingPersonnelPersonnelParameterDelete = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___DELETE',
  CommonSettingPersonnelPersonnelParameterInactive = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___INACTIVE',
  CommonSettingPersonnelPersonnelParameterReactivate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___REACTIVATE',
  CommonSettingPersonnelJobGradeRead = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___READ',
  CommonSettingPersonnelJobGradeCreate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___CREATE',
  CommonSettingPersonnelJobGradeUpdate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___UPDATE',
  CommonSettingPersonnelJobGradeDelete = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___DELETE',
  CommonSettingPersonnelSkillSetupRead = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___READ',
  CommonSettingPersonnelSkillSetupCreate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___CREATE',
  CommonSettingPersonnelSkillSetupUpdate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___UPDATE',
  CommonSettingPersonnelSkillSetupDelete = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___DELETE',
  CommonSettingPersonnelJobPositionRead = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___READ',
  CommonSettingPersonnelJobPositionCreate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___CREATE',
  CommonSettingPersonnelJobPositionUpdate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___UPDATE',
  CommonSettingPersonnelJobPositionDelete = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___DELETE',
  CommonSettingPersonnelInsuranceSchemeRead = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CommonSettingPersonnelInsuranceSchemeCreate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___CREATE',
  CommonSettingPersonnelInsuranceSchemeUpdate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CommonSettingPersonnelInsuranceSchemeDelete = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___DELETE',
  CommonSettingPersonnelRecruitmentSourceRead = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CommonSettingPersonnelRecruitmentSourceCreate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___CREATE',
  CommonSettingPersonnelRecruitmentSourceUpdate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CommonSettingPersonnelRecruitmentSourceDelete = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___DELETE',
  CommonSettingPersonnelUserDefinedRead = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___READ',
  CommonSettingPersonnelUserDefinedCreate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___CREATE',
  CommonSettingPersonnelUserDefinedUpdate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CommonSettingPersonnelUserDefinedDelete = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___DELETE',
  CommonSettingPersonnelELetterTemplateRead = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CommonSettingPersonnelELetterTemplateUpdate = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___UPDATE',
  CompanySettingPersonnelRead = 'COMPANY_SETTING_PERSONNEL_______READ',
  CompanySettingPersonnelInsuranceSchemeRead = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CompanySettingPersonnelInsuranceSchemeUpdate = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CompanySettingPersonnelRecruitmentSourceRead = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CompanySettingPersonnelRecruitmentSourceUpdate = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CompanySettingPersonnelUserDefinedRead = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___READ',
  CompanySettingPersonnelUserDefinedUpdate = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CompanySettingPersonnelCostCenterMappingRead = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___READ',
  CompanySettingPersonnelCostCenterMappingUpdate = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___UPDATE',
  CompanySettingPersonnelELetterTemplateRead = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CompanySettingPersonnelELetterTemplatedUpdate = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATED___UPDATE',
  CompanySettingPersonnelEmployeeUserDefineFieldsRead = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  CompanySettingPersonnelEmployeeUserDefineFieldsUpdate = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelPersonnelEFileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE____READ',
  HomePersonnelPersonnelEFilePersonnelProfileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___READ',
  HomePersonnelPersonnelEFilePersonnelProfileCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___CREATE',
  HomePersonnelPersonnelEFilePersonnelProfileUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___UPDATE',
  HomePersonnelPersonnelEFileEducationRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___READ',
  HomePersonnelPersonnelEFileEducationCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___CREATE',
  HomePersonnelPersonnelEFileEducationUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___UPDATE',
  HomePersonnelPersonnelEFileEducationDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___DELETE',
  HomePersonnelPersonnelEFileCertificateRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___READ',
  HomePersonnelPersonnelEFileCertificateCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___CREATE',
  HomePersonnelPersonnelEFileCertificateUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___UPDATE',
  HomePersonnelPersonnelEFileCertificateDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___DELETE',
  HomePersonnelPersonnelEFileReferencesRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___READ',
  HomePersonnelPersonnelEFileReferencesCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___CREATE',
  HomePersonnelPersonnelEFileReferencesUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___UPDATE',
  HomePersonnelPersonnelEFileReferencesDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___DELETE',
  HomePersonnelPersonnelEFileHealthInfoRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___READ',
  HomePersonnelPersonnelEFileHealthInfoUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___UPDATE',
  HomePersonnelPersonnelEFileDependentRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___READ',
  HomePersonnelPersonnelEFileDependentCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___CREATE',
  HomePersonnelPersonnelEFileDependentUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___UPDATE',
  HomePersonnelPersonnelEFileDependentDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___DELETE',
  HomePersonnelPersonnelEFileJobHistoryRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__READ',
  HomePersonnelPersonnelEFileJobHistoryCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__CREATE',
  HomePersonnelPersonnelEFileJobHistoryUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__UPDATE',
  HomePersonnelPersonnelEFileJobHistoryDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__DELETE',
  HomePersonnelPersonnelEFileUserDefinedRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___READ',
  HomePersonnelPersonnelEFileUserDefinedUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___UPDATE',
  HomePersonnelPersonnelEFileDocumentsRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___READ',
  HomePersonnelPersonnelEFileDocumentsCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___CREATE',
  HomePersonnelPersonnelEFileDocumentsDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___DELETE',
  HomePersonnelEmployeeInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO____CREATE',
  HomePersonnelEmployeeInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO____READ',
  HomePersonnelEmployeeInfoJobInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_READ',
  HomePersonnelEmployeeInfoJobInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_UPDATE',
  HomePersonnelEmployeeInfoPersonalInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_READ',
  HomePersonnelEmployeeInfoPersonalInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_CREATE',
  HomePersonnelEmployeeInfoStatutoryInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_READ',
  HomePersonnelEmployeeInfoStatutoryInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_READ',
  HomePersonnelEmployeeInfoZakatContributionCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_CREATE',
  HomePersonnelEmployeeInfoZakatContributionUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_DELETE',
  HomePersonnelEmployeeInfoTp3Read = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_READ',
  HomePersonnelEmployeeInfoTp3Update = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____READ',
  HomePersonnelEmployeeInfoAssetAssignedCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____CREATE',
  HomePersonnelEmployeeInfoAssetAssignedUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____DELETE',
  HomePersonnelEmployeeInfoPerformanceJournalRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____READ',
  HomePersonnelEmployeeInfoPerformanceJournalCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____CREATE',
  HomePersonnelEmployeeInfoPerformanceJournalUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____UPDATE',
  HomePersonnelEmployeeInfoPerformanceJournalDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____DELETE',
  HomePersonnelEmployeeInfoInsuranceSchemeRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_INSURANCE_SCHEME____READ',
  HomePersonnelEmployeeInfoCareerMovementRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CAREER_MOVEMENT____READ',
  HomePersonnelEmployeeInfoSubordinateRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_SUBORDINATE____READ',
  HomePersonnelEmployeeInfoDigitalDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_DIGITAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoGeneralDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_GENERAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____CREATE',
  HomePersonnelEmployeeInfoContractDocumentUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____UPDATE',
  HomePersonnelEmployeeInfoContractDocumentDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____DELETE',
  HomePersonnelEmployeeInfoPaySlipRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PAY_SLIP____READ',
  HomePersonnelEmployeeInfoEaFormRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EA_FORM____READ',
  HomePersonnelEmployeeInfoELetterRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____READ',
  HomePersonnelEmployeeInfoELetterDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____DELETE',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelStaffConfirmationRead = 'HOME_PERSONNEL_STAFF_CONFIRMATION____READ',
  HomePersonnelStaffConfirmationUpdate = 'HOME_PERSONNEL_STAFF_CONFIRMATION____UPDATE',
  HomePersonnelProbationExtensionRead = 'HOME_PERSONNEL_PROBATION_EXTENSION____READ',
  HomePersonnelStaffPromotionRead = 'HOME_PERSONNEL_STAFF_PROMOTION____READ',
  HomePersonnelStaffPromotionCreate = 'HOME_PERSONNEL_STAFF_PROMOTION____CREATE',
  HomePersonnelStaffPromotionUpdate = 'HOME_PERSONNEL_STAFF_PROMOTION____UPDATE',
  HomePersonnelStaffPromotionDelete = 'HOME_PERSONNEL_STAFF_PROMOTION____DELETE',
  HomePersonnelStaffDemotionRead = 'HOME_PERSONNEL_STAFF_DEMOTION____READ',
  HomePersonnelStaffDemotionCreate = 'HOME_PERSONNEL_STAFF_DEMOTION____CREATE',
  HomePersonnelStaffDemotionUpdate = 'HOME_PERSONNEL_STAFF_DEMOTION____UPDATE',
  HomePersonnelStaffDemotionDelete = 'HOME_PERSONNEL_STAFF_DEMOTION____DELETE',
  HomePersonnelStaffRedesignationRead = 'HOME_PERSONNEL_STAFF_REDESIGNATION____READ',
  HomePersonnelStaffRedesignationCreate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____CREATE',
  HomePersonnelStaffRedesignationUpdate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____UPDATE',
  HomePersonnelStaffRedesignationDelete = 'HOME_PERSONNEL_STAFF_REDESIGNATION____DELETE',
  HomePersonnelStaffInternalTransferRead = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____READ',
  HomePersonnelStaffInternalTransferCreate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____CREATE',
  HomePersonnelStaffInternalTransferUpdate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____UPDATE',
  HomePersonnelStaffInternalTransferDelete = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____DELETE',
  HomePersonnelStaffInterCompanyTransferRead = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____READ',
  HomePersonnelStaffInterCompanyTransferCreate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____CREATE',
  HomePersonnelStaffInterCompanyTransferUpdate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____UPDATE',
  HomePersonnelStaffInterCompanyTransferDelete = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____DELETE',
  HomePersonnelStaffTerminationRead = 'HOME_PERSONNEL_STAFF_TERMINATION____READ',
  HomePersonnelStaffTerminationCreate = 'HOME_PERSONNEL_STAFF_TERMINATION____CREATE',
  HomePersonnelStaffTerminationUpdate = 'HOME_PERSONNEL_STAFF_TERMINATION____UPDATE',
  HomePersonnelStaffTerminationDelete = 'HOME_PERSONNEL_STAFF_TERMINATION____DELETE',
  HomePersonnelStaffOffboardingRead = 'HOME_PERSONNEL_STAFF_OFFBOARDING____READ',
  HomePersonnelStaffOffboardingCreate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____CREATE',
  HomePersonnelStaffOffboardingUpdate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____UPDATE',
  HomePersonnelStaffOffboardingDelete = 'HOME_PERSONNEL_STAFF_OFFBOARDING____DELETE',
  HomePersonnelStaffRetirementRead = 'HOME_PERSONNEL_STAFF_RETIREMENT____READ',
  HomePersonnelStaffRetirementUpdate = 'HOME_PERSONNEL_STAFF_RETIREMENT____UPDATE',
  HomePersonnelStaffRetirementDelete = 'HOME_PERSONNEL_STAFF_RETIREMENT____DELETE',
  HomePersonnelStaffInsuranceSchemeRead = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____READ',
  HomePersonnelStaffInsuranceSchemeCreate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____CREATE',
  HomePersonnelStaffInsuranceSchemeUpdate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____UPDATE',
  HomePayrollExecSummaryRead = 'HOME_PAYROLL_EXEC_SUMMARY____READ',
  HomePayrollRead = 'HOME_PAYROLL_______READ',
  CommonSettingPayrollRead = 'COMMON_SETTING_PAYROLL_______READ',
  CommonSettingPayrollPayItemRead = 'COMMON_SETTING_PAYROLL_PAY_ITEM___READ',
  CommonSettingPayrollPayItemCreate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___CREATE',
  CommonSettingPayrollPayItemUpdate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___UPDATE',
  CommonSettingPayrollPayItemDelete = 'COMMON_SETTING_PAYROLL_PAY_ITEM___DELETE',
  CommonSettingPayrollLhdnReportsRead = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___READ',
  CommonSettingPayrollLhdnReportsUpdate = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___UPDATE',
  CommonSettingPayrollLhdnReportsCopy = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___COPY',
  CommonSettingPayrollPayrollCycleRead = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___READ',
  CommonSettingPayrollPayrollCycleCreate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___CREATE',
  CommonSettingPayrollPayrollCycleUpdate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___UPDATE',
  CommonSettingPayrollPayrollCycleDelete = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___DELETE',
  CommonSettingPayrollAdvancePolicyRead = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___READ',
  CommonSettingPayrollAdvancePolicyCreate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___CREATE',
  CommonSettingPayrollAdvancePolicyUpdate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___UPDATE',
  CommonSettingPayrollAdvancePolicyDelete = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___DELETE',
  CommonSettingPayrollGlAccountMappingRead = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___READ',
  CommonSettingPayrollGlAccountMappingCreate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingPayrollGlAccountMappingUpdate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingPayrollGlAccountMappingDelete = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___DELETE',
  CommonSettingPayrollUserDefineEpfPolicyRead = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_READ',
  CommonSettingPayrollUserDefineEpfPolicyCreate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_CREATE',
  CommonSettingPayrollUserDefineEpfPolicyUpdate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_UPDATE',
  CommonSettingPayrollUserDefineEpfPolicyDelete = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_DELETE',
  CommonSettingPayrollPayGroupRead = 'COMMON_SETTING_PAYROLL_PAY_GROUP_READ',
  CommonSettingPayrollPayGroupCreate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_CREATE',
  CommonSettingPayrollPayGroupUpdate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_UPDATE',
  CommonSettingPayrollPayGroupDelete = 'COMMON_SETTING_PAYROLL_PAY_GROUP_DELETE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixRead = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_READ',
  CompanySettingPayrollUserDefineEpfPolicyMatrixCreate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_CREATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixUpdate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_UPDATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixDelete = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_DELETE',
  CompanySettingPayrollRead = 'COMPANY_SETTING_PAYROLL_______READ',
  CompanySettingPayrollCutoffDateUpdate = 'COMPANY_SETTING_PAYROLL_CUTOFF_DATE____UPDATE',
  CompanySettingPayrollPayrollCalendarRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____READ',
  CompanySettingPayrollPayrollCalendarUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____UPDATE',
  CompanySettingPayrollPayrollSettingRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_SETTING____READ',
  CompanySettingPayrollPayItemRead = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____READ',
  CompanySettingPayrollPayItemUpdate = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____UPDATE',
  CompanySettingPayrollPayItemDelete = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____DELETE',
  CompanySettingPayrollPayrollCycleRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____READ',
  CompanySettingPayrollPayrollCycleUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____UPDATE',
  CompanySettingPayrollAdvancePolicyRead = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____READ',
  CompanySettingPayrollAdvancePolicyUpdate = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____UPDATE',
  CompanySettingPayrollGlAccountAssignmentRead = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____READ',
  CompanySettingPayrollGlAccountAssignmentUpdate = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____UPDATE',
  CompanySettingPayrollBankCreate = 'COMPANY_SETTING_PAYROLL_BANK____CREATE',
  CompanySettingPayrollBankRead = 'COMPANY_SETTING_PAYROLL_BANK____READ',
  CompanySettingPayrollBankUpdate = 'COMPANY_SETTING_PAYROLL_BANK____UPDATE',
  CompanySettingPayrollBankDelete = 'COMPANY_SETTING_PAYROLL_BANK____DELETE',
  CompanySettingPayrollBankInterbankCreate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_CREATE',
  CompanySettingPayrollBankInterbankRead = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_READ',
  CompanySettingPayrollBankInterbankUpdate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_UPDATE',
  CompanySettingPayrollBankInterbankDelete = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_DELETE',
  CompanySettingPayrollPayGroupRead = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___READ',
  CompanySettingPayrollPayGroupUpdate = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___UPDATE',
  HomePayrollEmployeeSalaryRead = 'HOME_PAYROLL_EMPLOYEE_SALARY____READ',
  HomePayrollEmployeeSalarySalaryRecordsRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____READ',
  HomePayrollEmployeeSalarySalaryRecordsCreate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____CREATE',
  HomePayrollEmployeeSalarySalaryRecordsUpdate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____UPDATE',
  HomePayrollEmployeeSalarySalaryRecordsDelete = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____DELETE',
  HomePayrollEmployeeSalaryExcelUploadRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_READ',
  HomePayrollEmployeeSalaryExcelUploadUpload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_UPLOAD',
  HomePayrollEmployeeSalaryExcelUploadDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollEmployeeSalarySalaryAdjustmentRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_READ',
  HomePayrollEmployeeSalarySalaryAdjustmentDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_DOWNLOAD',
  HomePayrollGlExportRead = 'HOME_PAYROLL_GL_EXPORT____READ',
  HomePayrollGlExportDownload = 'HOME_PAYROLL_GL_EXPORT____DOWNLOAD',
  HomePayrollMonthlyRemunerationRead = 'HOME_PAYROLL_MONTHLY_REMUNERATION____READ',
  HomePayrollMonthlyRemunerationUpdate = 'HOME_PAYROLL_MONTHLY_REMUNERATION____UPDATE',
  HomePayrollBikRead = 'HOME_PAYROLL_BIK____READ',
  HomePayrollBikUpdate = 'HOME_PAYROLL_BIK____UPDATE',
  HomePayrollTaxReliefCreate = 'HOME_PAYROLL_TAX_RELIEF____CREATE',
  HomePayrollTaxReliefRead = 'HOME_PAYROLL_TAX_RELIEF____READ',
  HomePayrollTaxReliefUpdate = 'HOME_PAYROLL_TAX_RELIEF____UPDATE',
  HomePayrollOtherPayrollRelatedRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingPost = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____POST',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRevert = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____REVERT',
  HomePayrollFixedAllowanceDeductionRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION____READ',
  HomePayrollFixedAllowanceDeductionManualEntryCreate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_CREATE',
  HomePayrollFixedAllowanceDeductionManualEntryRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_READ',
  HomePayrollFixedAllowanceDeductionManualEntryUpdate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_UPDATE',
  HomePayrollFixedAllowanceDeductionManualEntryDelete = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_DELETE',
  HomePayrollFixedAllowanceDeductionExcelUploadRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_READ',
  HomePayrollFixedAllowanceDeductionExcelUploadUpload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_UPLOAD',
  HomePayrollFixedAllowanceDeductionExcelUploadDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollFixedAllowanceDeductionExportDataDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXPORT_DATA_DOWNLOAD',
  HomePayrollMonthlyTrxRead = 'HOME_PAYROLL_MONTHLY_TRX____READ',
  HomePayrollMonthlyTrxByEmployeeCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_CREATE',
  HomePayrollMonthlyTrxByEmployeeRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_READ',
  HomePayrollMonthlyTrxByEmployeeUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_UPDATE',
  HomePayrollMonthlyTrxByEmployeeDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_DELETE',
  HomePayrollMonthlyTrxByPayitemCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_CREATE',
  HomePayrollMonthlyTrxByPayitemRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_READ',
  HomePayrollMonthlyTrxByPayitemUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_UPDATE',
  HomePayrollMonthlyTrxByPayitemDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_DELETE',
  HomePayrollMonthlyTrxExcelUploadRead = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_READ',
  HomePayrollMonthlyTrxExcelUploadUpload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_UPLOAD',
  HomePayrollMonthlyTrxExcelUploadDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollMonthlyTrxExportDataDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXPORT_DATA_DOWNLOAD',
  HomePayrollFreezePayrollRead = 'HOME_PAYROLL_FREEZE_PAYROLL____READ',
  HomePayrollFreezePayrollCreate = 'HOME_PAYROLL_FREEZE_PAYROLL____CREATE',
  HomePayrollFreezePayrollDelete = 'HOME_PAYROLL_FREEZE_PAYROLL____DELETE',
  HomePayrollPayrollProcessRead = 'HOME_PAYROLL_PAYROLL_PROCESS____READ',
  HomePayrollShopeeImportPayrollProcessRead = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____READ',
  HomePayrollShopeeImportPayrollProcessImport = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____IMPORT',
  HomePayrollShopeeImportPayrollProcessDelete = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____DELETE',
  HomePayrollPayrollProcessProcess = 'HOME_PAYROLL_PAYROLL_PROCESS____PROCESS',
  HomePayrollPayrollProcessDelete = 'HOME_PAYROLL_PAYROLL_PROCESS____DELETE',
  HomePayrollPayrollClosingRead = 'HOME_PAYROLL_PAYROLL_CLOSING____READ',
  HomePayrollPayrollClosingLock = 'HOME_PAYROLL_PAYROLL_CLOSING____LOCK',
  HomePayrollPayrollClosingUnlock = 'HOME_PAYROLL_PAYROLL_CLOSING____UNLOCK',
  HomePayrollReadPayrollHistoryRead = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____READ',
  HomePayrollReadPayrollHistoryDelete = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____DELETE',
  HomePayrollPublishPayslipRead = 'HOME_PAYROLL_PUBLISH_PAYSLIP____READ',
  HomePayrollPublishPayslipPublish = 'HOME_PAYROLL_PUBLISH_PAYSLIP____PUBLISH',
  HomePayrollStatutoryAdjustmentRead = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_READ',
  HomePayrollStatutoryAdjustmentUpdate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_UPDATE',
  HomePayrollStatutoryAdjustmentCreate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_CREATE',
  HomePayrollStatutoryAdjustmentDelete = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_DELETE',
  HomePayrollPublishEaformRead = 'HOME_PAYROLL_PUBLISH_EAFORM____READ',
  HomePayrollPublishEaformPublish = 'HOME_PAYROLL_PUBLISH_EAFORM____PUBLISH',
  HomePayrollPublishEaformUnpublish = 'HOME_PAYROLL_PUBLISH_EAFORM____UNPUBLISH',
  HomePayrollPcbcp39PaymentRead = 'HOME_PAYROLL_PCBCP39_PAYMENT____READ',
  HomePayrollPayrollInitiationRead = 'HOME_PAYROLL_PAYROLL_INITIATION____READ',
  HomePayrollPcbcp39PaymentUpdate = 'HOME_PAYROLL_PCBCP39_PAYMENT____UPDATE',
  HomePayrollPayrollInitiationInitiate = 'HOME_PAYROLL_PAYROLL_INITIATION____INITIATE',
  HomeLeaveExecSummaryRead = 'HOME_LEAVE_EXEC_SUMMARY____READ',
  HomeLeaveRead = 'HOME_LEAVE____READ',
  CommonSettingLeaveRead = 'COMMON_SETTING_LEAVE_______READ',
  CommonSettingLeaveLeaveReasonRead = 'COMMON_SETTING_LEAVE_LEAVE_REASON___READ',
  CommonSettingLeaveLeaveReasonCreate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___CREATE',
  CommonSettingLeaveLeaveReasonUpdate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___UPDATE',
  CommonSettingLeaveLeaveReasonInactive = 'COMMON_SETTING_LEAVE_LEAVE_REASON___INACTIVE',
  CommonSettingLeaveLeaveReasonReactivate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___REACTIVATE',
  CommonSettingLeaveDiseaseCodeRead = 'COMMON_SETTING_LEAVE_DISEASE_CODE___READ',
  CommonSettingLeaveDiseaseCodeCreate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___CREATE',
  CommonSettingLeaveDiseaseCodeUpdate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___UPDATE',
  CommonSettingLeaveDiseaseCodeInactive = 'COMMON_SETTING_LEAVE_DISEASE_CODE___INACTIVE',
  CommonSettingLeaveDiseaseCodeReactivate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___REACTIVATE',
  CommonSettingLeaveLeaveTypeRead = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___READ',
  CommonSettingLeaveLeaveTypeCreate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___CREATE',
  CommonSettingLeaveLeaveTypeUpdate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___UPDATE',
  CommonSettingLeaveLeaveTypeDelete = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___DELETE',
  CommonSettingLeaveLeavePolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___READ',
  CommonSettingLeaveLeavePolicyView = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___VIEW',
  CommonSettingLeaveLeavePolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___CREATE',
  CommonSettingLeaveLeavePolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___UPDATE',
  CommonSettingLeaveLeavePolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___DELETE',
  CommonSettingLeaveLeaveRoundingRead = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___READ',
  CommonSettingLeaveLeaveRoundingView = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___VIEW',
  CommonSettingLeaveLeaveRoundingCreate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___CREATE',
  CommonSettingLeaveLeaveRoundingUpdate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___UPDATE',
  CommonSettingLeaveLeaveRoundingDelete = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___READ',
  CommonSettingLeaveLeaveApprovalPolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___CREATE',
  CommonSettingLeaveLeaveApprovalPolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___UPDATE',
  CommonSettingLeaveLeaveApprovalPolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyInactive = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___INACTIVE',
  CommonSettingLeaveLeaveApprovalPolicyReactivate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___REACTIVATE',
  CompanySettingLeaveRead = 'COMPANY_SETTING_LEAVE_______READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixRead = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixCreate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___CREATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixDelete = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___DELETE',
  CompanySettingLeaveLeaveSettingRead = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___READ',
  CompanySettingLeaveLeaveSettingUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___UPDATE',
  CompanySettingLeaveCompulsoryLeaveDeductionRead = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___READ',
  CompanySettingLeaveCompulsoryLeaveDeductionUpdate = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___UPDATE',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentRead = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeLeaveOutOfStateCreate = 'HOME_LEAVE_OUT_OF_STATE____CREATE',
  HomeLeaveOutOfStateRead = 'HOME_LEAVE_OUT_OF_STATE____READ',
  HomeLeaveOutOfStateUpdate = 'HOME_LEAVE_OUT_OF_STATE____UPDATE',
  HomeLeaveOutOfStateDelete = 'HOME_LEAVE_OUT_OF_STATE____DELETE',
  HomeLeaveApplicationCreate = 'HOME_LEAVE_APPLICATION____CREATE',
  HomeLeaveApplicationRead = 'HOME_LEAVE_APPLICATION____READ',
  HomeLeaveApplicationUpdate = 'HOME_LEAVE_APPLICATION____UPDATE',
  HomeLeaveApplicationDelete = 'HOME_LEAVE_APPLICATION____DELETE',
  HomeLeaveApplicationApprove = 'HOME_LEAVE_APPLICATION____APPROVE',
  HomeLeaveApplicationReject = 'HOME_LEAVE_APPLICATION____REJECT',
  HomeLeaveApplicationWithdraw = 'HOME_LEAVE_APPLICATION____WITHDRAW',
  HomeLeaveApplicationView = 'HOME_LEAVE_APPLICATION____VIEW',
  HomeLeaveApplicationCalendarRead = 'HOME_LEAVE_APPLICATION_CALENDAR____READ',
  HomeLeaveLeaveTakenImportFromExcelRead = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____READ',
  HomeLeaveLeaveTakenImportFromExcelDownload = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____DOWNLOAD',
  HomeLeaveLeaveTakenImportFromExcelImport = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____IMPORT',
  HomeLeaveAdjustmentCreate = 'HOME_LEAVE_ADJUSTMENT____CREATE',
  HomeLeaveAdjustmentRead = 'HOME_LEAVE_ADJUSTMENT____READ',
  HomeLeaveAdjustmentUpdate = 'HOME_LEAVE_ADJUSTMENT____UPDATE',
  HomeLeaveAdjustmentDelete = 'HOME_LEAVE_ADJUSTMENT____DELETE',
  HomeLeaveLeaveEntitlementByEmployeeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeLeaveLeaveEntitlementByLeaveTypeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE____READ',
  HomeLeaveBlockLeaveCreate = 'HOME_LEAVE_BLOCK_LEAVE____CREATE',
  HomeLeaveBlockLeaveRead = 'HOME_LEAVE_BLOCK_LEAVE____READ',
  HomeLeaveBlockLeaveUpdate = 'HOME_LEAVE_BLOCK_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveCreate = 'HOME_LEAVE_COMPULSORY_LEAVE____CREATE',
  HomeLeaveCompulsoryLeaveUpdate = 'HOME_LEAVE_COMPULSORY_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveDelete = 'HOME_LEAVE_COMPULSORY_LEAVE____DELETE',
  HomeLeaveCompulsoryLeaveRead = 'HOME_LEAVE_COMPULSORY_LEAVE____READ',
  HomeLeavePostingRead = 'HOME_LEAVE_POSTING____READ',
  HomeLeavePostingPost = 'HOME_LEAVE_POSTING____POST',
  HomeLeavePostingRevert = 'HOME_LEAVE_POSTING____REVERT',
  HomeLeaveYearendCarryforwardRead = 'HOME_LEAVE_YEAREND_CARRYFORWARD____READ',
  HomeLeaveYearendCarryforwardPost = 'HOME_LEAVE_YEAREND_CARRYFORWARD____POST',
  HomeLeaveOffboardingEncashmentRead = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____READ',
  HomeLeaveOffboardingEncashmentPost = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____POST',
  HomeLeaveOffboardingEncashmentRevert = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____REVERT',
  HomeLeaveLeaveCarryForwardEncashmentRead = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____READ',
  HomeLeaveLeaveCarryForwardEncashmentPost = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____POST',
  GeneralSettingLeaveTemplateCreate = 'GENERAL_SETTING_LEAVE_TEMPLATE____CREATE',
  GeneralSettingLeaveTemplateRead = 'GENERAL_SETTING_LEAVE_TEMPLATE____READ',
  GeneralSettingLeaveTemplateUpdate = 'GENERAL_SETTING_LEAVE_TEMPLATE____UPDATE',
  HomeClaimExecSummaryCreate = 'HOME_CLAIM_EXEC_SUMMARY____CREATE',
  HomeClaimExecSummaryRead = 'HOME_CLAIM_EXEC_SUMMARY____READ',
  HomeClaimRead = 'HOME_CLAIM_______READ',
  CommonSettingClaimRead = 'COMMON_SETTING_CLAIM_______READ',
  CommonSettingClaimClaimParameterRead = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___READ',
  CommonSettingClaimClaimParameterCreate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___CREATE',
  CommonSettingClaimClaimParameterUpdate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___UPDATE',
  CommonSettingClaimClaimParameterDelete = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___DELETE',
  CommonSettingClaimClaimParameterInactive = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___INACTIVE',
  CommonSettingClaimClaimParameterReactivate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___REACTIVATE',
  CommonSettingClaimClaimTypeRead = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___READ',
  CommonSettingClaimClaimTypeCreate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___CREATE',
  CommonSettingClaimClaimTypeUpdate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___UPDATE',
  CommonSettingClaimClaimTypeDelete = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___DELETE',
  CommonSettingClaimClaimTypeInactive = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___INACTIVE',
  CommonSettingClaimClaimTypeReactivate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___REACTIVATE',
  CommonSettingClaimClaimPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___READ',
  CommonSettingClaimClaimPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___CREATE',
  CommonSettingClaimClaimPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___UPDATE',
  CommonSettingClaimClaimPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___DELETE',
  CommonSettingClaimClaimPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___INACTIVE',
  CommonSettingClaimClaimPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___REACTIVATE',
  CommonSettingClaimClaimApprovalPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingClaimClaimApprovalPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingClaimClaimApprovalPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingClaimClaimApprovalPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingClaimClaimApprovalPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingClaimClaimApprovalPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingClaimGlAccountMappingRead = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CommonSettingClaimGlAccountMappingCreate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingClaimGlAccountMappingUpdate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingClaimGlAccountMappingDelete = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___DELETE',
  CompanySettingClaimRead = 'COMPANY_SETTING_CLAIM_______READ',
  CompanySettingClaimJobGradePolicyRead = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___READ',
  CompanySettingClaimJobGradePolicyUpdate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___UPDATE',
  CompanySettingClaimJobGradePolicyCreate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___CREATE',
  CompanySettingClaimJobGradePolicyDelete = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___DELETE',
  CompanySettingClaimGlAccountMappingRead = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CompanySettingClaimGlAccountMappingUpdate = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CompanySettingClaimClaimSettingRead = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___READ',
  CompanySettingClaimClaimSettingUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___UPDATE',
  CompanySettingClaimClaimApprovalPolicyAssignmentRead = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingClaimClaimApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeClaimOutOfStateCreate = 'HOME_CLAIM_OUT_OF_STATE____CREATE',
  HomeClaimOutOfStateRead = 'HOME_CLAIM_OUT_OF_STATE____READ',
  HomeClaimOutOfStateUpdate = 'HOME_CLAIM_OUT_OF_STATE____UPDATE',
  HomeClaimOutOfStateDelete = 'HOME_CLAIM_OUT_OF_STATE____DELETE',
  HomeClaimApplicationCreate = 'HOME_CLAIM_APPLICATION____CREATE',
  HomeClaimApplicationRead = 'HOME_CLAIM_APPLICATION____READ',
  HomeClaimApplicationUpdate = 'HOME_CLAIM_APPLICATION____UPDATE',
  HomeClaimApplicationDelete = 'HOME_CLAIM_APPLICATION____DELETE',
  HomeClaimApplicationApprove = 'HOME_CLAIM_APPLICATION____APPROVE',
  HomeClaimApplicationReject = 'HOME_CLAIM_APPLICATION____REJECT',
  HomeClaimApplicationWithdraw = 'HOME_CLAIM_APPLICATION____WITHDRAW',
  HomeClaimApprovedClaimVerifyRead = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____READ',
  HomeClaimApprovedClaimVerifyReject = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REJECT',
  HomeClaimApprovedClaimVerifyVerify = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____VERIFY',
  HomeClaimApprovedClaimVerifyReverse = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REVERSE',
  HomeClaimPostingRead = 'HOME_CLAIM_POSTING____READ',
  HomeClaimPostingPost = 'HOME_CLAIM_POSTING____POST',
  HomeClaimPostingReverse = 'HOME_CLAIM_POSTING____REVERSE',
  HomeClaimPaymentRead = 'HOME_CLAIM_PAYMENT____READ',
  HomeClaimPaymentPost = 'HOME_CLAIM_PAYMENT____POST',
  HomeClaimPaymentReverse = 'HOME_CLAIM_PAYMENT____REVERSE',
  HomeClaimClaimEntitlementByEmployeeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeClaimClaimEntitlementByClaimTypeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_CLAIM_TYPE____READ',
  HomeClaimGlProcessingRead = 'HOME_CLAIM_GL_PROCESSING____READ',
  HomeClaimGlProcessingProcess = 'HOME_CLAIM_GL_PROCESSING____PROCESS',
  HomeClaimGlProcessingExport = 'HOME_CLAIM_GL_PROCESSING____EXPORT',
  HomeClaimGlExportRead = 'HOME_CLAIM_GL_EXPORT____READ',
  HomeClaimGlExportExport = 'HOME_CLAIM_GL_EXPORT____EXPORT',
  GeneralSettingClaimTemplateCreate = 'GENERAL_SETTING_CLAIM_TEMPLATE____CREATE',
  GeneralSettingClaimTemplateRead = 'GENERAL_SETTING_CLAIM_TEMPLATE____READ',
  GeneralSettingClaimTemplateUpdate = 'GENERAL_SETTING_CLAIM_TEMPLATE____UPDATE',
  HomeTmsExecSummaryRead = 'HOME_TMS_EXEC_SUMMARY____READ',
  HomeTmsRead = 'HOME_TMS_______READ',
  CommonSettingTmsRead = 'COMMON_SETTING_TMS_______READ',
  CommonSettingTmsShiftSetupRead = 'COMMON_SETTING_TMS_SHIFT_SETUP___READ',
  CommonSettingTmsShiftSetupCreate = 'COMMON_SETTING_TMS_SHIFT_SETUP___CREATE',
  CommonSettingTmsShiftSetupUpdate = 'COMMON_SETTING_TMS_SHIFT_SETUP___UPDATE',
  CommonSettingTmsShiftSetupDelete = 'COMMON_SETTING_TMS_SHIFT_SETUP___DELETE',
  CommonSettingTmsShiftSetupDuplicate = 'COMMON_SETTING_TMS_SHIFT_SETUP___DUPLICATE',
  CommonSettingTmsShiftPatternRead = 'COMMON_SETTING_TMS_SHIFT_PATTERN___READ',
  CommonSettingTmsShiftPatternCreate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___CREATE',
  CommonSettingTmsShiftPatternUpdate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___UPDATE',
  CommonSettingTmsShiftPatternDelete = 'COMMON_SETTING_TMS_SHIFT_PATTERN___DELETE',
  CommonSettingTmsShiftGroupRead = 'COMMON_SETTING_TMS_SHIFT_GROUP___READ',
  CommonSettingTmsShiftGroupCreate = 'COMMON_SETTING_TMS_SHIFT_GROUP___CREATE',
  CommonSettingTmsShiftGroupUpdate = 'COMMON_SETTING_TMS_SHIFT_GROUP___UPDATE',
  CommonSettingTmsShiftGroupDelete = 'COMMON_SETTING_TMS_SHIFT_GROUP___DELETE',
  CommonSettingTmsOvertimePolicyRead = 'COMMON_SETTING_TMS_OVERTIME_POLICY___READ',
  CommonSettingTmsOvertimePolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___CREATE',
  CommonSettingTmsOvertimePolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___UPDATE',
  CommonSettingTmsOvertimePolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_POLICY___DELETE',
  CommonSettingTmsOvertimeClaimTableRead = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___READ',
  CommonSettingTmsOvertimeClaimTableCreate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___CREATE',
  CommonSettingTmsOvertimeClaimTableUpdate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___UPDATE',
  CommonSettingTmsOvertimeClaimTableDelete = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___DELETE',
  CommonSettingTmsOvertimeClaimTableView = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___VIEW',
  CommonSettingTmsOvertimeReasonCodesRead = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___READ',
  CommonSettingTmsOvertimeReasonCodesCreate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___CREATE',
  CommonSettingTmsOvertimeReasonCodesUpdate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___UPDATE',
  CommonSettingTmsOvertimeReasonCodesDelete = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___DELETE',
  CommonSettingTmsOvertimeRoundingPolicyRead = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___READ',
  CommonSettingTmsOvertimeRoundingPolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___CREATE',
  CommonSettingTmsOvertimeRoundingPolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsOvertimeRoundingPolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___DELETE',
  CommonSettingTmsDailyAllowancePolicyRead = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsDailyAllowancePolicyCreate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsDailyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsDailyAllowancePolicyDelete = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsMonthlyAllowancePolicyRead = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsMonthlyAllowancePolicyCreate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsMonthlyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsMonthlyAllowancePolicyDelete = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsPeriodicDeductionPolicyRead = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___READ',
  CommonSettingTmsPeriodicDeductionPolicyCreate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsPeriodicDeductionPolicyUpdate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsPeriodicDeductionPolicyDelete = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsDeductionPolicyRead = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___READ',
  CommonSettingTmsDeductionPolicyCreate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsDeductionPolicyUpdate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsDeductionPolicyDelete = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyRead = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___READ',
  CommonSettingTmsAllowanceDeductionRoundingPolicyCreate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___CREATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyUpdate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyDelete = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___DELETE',
  CommonSettingTmsTimeOffPolicyRead = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___READ',
  CommonSettingTmsTimeOffPolicyCreate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___CREATE',
  CommonSettingTmsTimeOffPolicyUpdate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___UPDATE',
  CommonSettingTmsTimeOffPolicyDelete = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___DELETE',
  CommonSettingTmsTimeOffReasonCodesRead = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___READ',
  CommonSettingTmsTimeOffReasonCodesCreate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___CREATE',
  CommonSettingTmsTimeOffReasonCodesUpdate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___UPDATE',
  CommonSettingTmsTimeOffReasonCodesDelete = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___DELETE',
  CommonSettingTmsPeriodicAllowanceTypeRead = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___READ',
  CommonSettingTmsMobileCheckLocationRead = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___READ',
  CommonSettingTmsMobileCheckLocationCreate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___CREATE',
  CommonSettingTmsMobileCheckLocationUpdate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___UPDATE',
  CommonSettingTmsMobileCheckLocationDelete = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___DELETE',
  CommonSettingTmsMobileCheckLocationView = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___VIEW',
  CommonSettingTmsTmsApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY___READ',
  CommonSettingTmsPeriodicAllowanceTypeCreate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___CREATE',
  CommonSettingTmsPeriodicAllowanceTypeUpdate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___UPDATE',
  CommonSettingTmsPeriodicAllowanceTypeDelete = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___REACTIVATE',
  CompanySettingTmsRead = 'COMPANY_SETTING_TMS_______READ',
  CompanySettingTmsShiftAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___READ',
  CompanySettingTmsShiftAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftPatternAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___READ',
  CompanySettingTmsShiftPatternAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftGroupAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___READ',
  CompanySettingTmsShiftGroupAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___UPDATE',
  CompanySettingTmsTimeOffPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTimeOffPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingTmsCsOvertimeReasonCodesRead = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___READ',
  CompanySettingTmsCsOvertimeReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___UPDATE',
  CompanySettingTmsCsTimeOffReasonCodesRead = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___READ',
  CompanySettingTmsCsTimeOffReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___UPDATE',
  CompanySettingTmsJobGradePolicyMatrixRead = 'COMPANY_SETTING_TMS_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___CREATE',
  CompanySettingTmsDailyPolicyMatrixRead = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDailyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___DELETE',
  CompanySettingTmsMonthlyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___CREATE',
  CompanySettingTmsMonthlyPolicyMatrixRead = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___READ',
  CompanySettingTmsMonthlyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMonthlyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___DELETE',
  CompanySettingTmsDeductionPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___CREATE',
  CompanySettingTmsDeductionPolicyMatrixRead = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___READ',
  CompanySettingTmsDeductionPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDeductionPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___DELETE',
  CompanySettingTmsOvertimePolicyMatrixCreate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___CREATE',
  CompanySettingTmsOvertimePolicyMatrixRead = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___READ',
  CompanySettingTmsOvertimePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___UPDATE',
  CompanySettingTmsOvertimePolicyMatrixDelete = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___DELETE',
  CompanySettingTmsCsJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_CS_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMobileCheckLocationAssignmentRead = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___READ',
  CompanySettingTmsMobileCheckLocationAssignmentUpdate = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___UPDATE',
  CompanySettingTmsOvertimeSettingRead = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___READ',
  CompanySettingTmsOvertimeSettingUpdate = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___UPDATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTmsApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeTmsAttendanceRecordRead = 'HOME_TMS_ATTENDANCE_RECORD____READ',
  HomeTmsAttendanceRecordManualEntryRead = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____READ',
  HomeTmsAttendanceRecordManualEntryCreate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____CREATE',
  HomeTmsAttendanceRecordManualEntryUpdate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____UPDATE',
  HomeTmsAttendanceRecordManualEntryDelete = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____DELETE',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____READ',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelDownload = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____DOWNLOAD',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelImport = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____IMPORT',
  HomeTmsAttendanceRecordExportAttendanceDataToExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_EXPORT_ATTENDANCE_DATA_TO_EXCEL____READ',
  HomeTmsShiftGroupEmployeeAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____DELETE',
  HomeTmsShiftGroupShiftPatternAssignmentRead = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____READ',
  HomeTmsShiftGroupShiftPatternAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupShiftPatternAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____DELETE',
  HomeTmsDutyRosterRead = 'HOME_TMS_DUTY_ROSTER____READ',
  HomeTmsDutyRosterDutyRosterByIndividualRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____READ',
  HomeTmsDutyRosterDutyRosterByIndividualUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____UPDATE',
  HomeTmsDutyRosterDutyRosterByEmployeeListRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____READ',
  HomeTmsDutyRosterDutyRosterByEmployeeListUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____UPDATE',
  HomeTmsDutyRosterShiftAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterExcelUploadMaintenanceRead = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsDutyRosterExcelUploadMaintenanceDownload = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsDutyRosterExcelUploadMaintenanceImport = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsDutyRosterDutyRosterSummaryRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_SUMMARY____READ',
  HomeTmsOvertimeApplicationRead = 'HOME_TMS_OVERTIME_APPLICATION_______READ',
  HomeTmsOvertimeApplicationManualEntryRead = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____READ',
  HomeTmsOvertimeApplicationManualEntryCreate = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____CREATE',
  HomeTmsOvertimeApplicationManualEntryWithdraw = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____WITHDRAW',
  HomeTmsOvertimeApplicationManualEntryApprove = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____APPROVE',
  HomeTmsOvertimeApplicationManualEntryReject = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____REJECT',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceRead = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceDownload = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceImport = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsOvertimeApplicationExportOvertimeDataRead = 'HOME_TMS_OVERTIME_APPLICATION_EXPORT_OVERTIME_DATA____READ',
  HomeTmsChangeShiftRequestCreate = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______CREATE',
  HomeTmsChangeShiftRequestRead = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______READ',
  HomeTmsChangeShiftRequestDelete = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______DELETE',
  HomeTmsChangeShiftRequestApprove = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______APPROVE',
  HomeTmsChangeShiftRequestReject = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______REJECT',
  HomeTmsTimeOffApplicationCreate = 'HOME_TMS_TIME_OFF_APPLICATION_______CREATE',
  HomeTmsTimeOffApplicationRead = 'HOME_TMS_TIME_OFF_APPLICATION_______READ',
  HomeTmsTimeOffApplicationApprove = 'HOME_TMS_TIME_OFF_APPLICATION_______APPROVE',
  HomeTmsTimeOffApplicationReject = 'HOME_TMS_TIME_OFF_APPLICATION_______REJECT',
  HomeTmsTimeOffApplicationWithdraw = 'HOME_TMS_TIME_OFF_APPLICATION_______WITHDRAW',
  HomeTmsNewAttendanceRecordRead = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______READ',
  HomeTmsNewAttendanceRecordUpdate = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______UPDATE',
  HomeTmsNewAttendanceRecordRepair = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______REPAIR',
  HomeTmsAttendanceAnalysisRead = 'HOME_TMS_ATTENDANCE_ANALYSIS_______READ',
  HomeTmsAttendanceAnalysisExport = 'HOME_TMS_ATTENDANCE_ANALYSIS_______EXPORT',
  HomeTmsAttendanceProcessingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______CALCULATE',
  HomeTmsAttendanceProcessingDailyTransactionListingExport = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______EXPORT',
  HomeTmsAttendanceProcessingMonthlySummaryRead = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______READ',
  HomeTmsAttendanceProcessingMonthlySummaryCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______CALCULATE',
  HomeTmsAttendanceProcessingMonthlySummaryExport = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______EXPORT',
  HomeTmsAttendancePostingRead = 'HOME_TMS_ATTENDANCE_POSTING_______READ',
  HomeTmsAttendancePostingPost = 'HOME_TMS_ATTENDANCE_POSTING_______POST',
  HomeTmsAttendancePostingRevert = 'HOME_TMS_ATTENDANCE_POSTING_______REVERT',
  HomeTmsAttendancePostingPopulate = 'HOME_TMS_ATTENDANCE_POSTING_______POPULATE',
  HomeTmsEarnLeavePostingRead = 'HOME_TMS_EARN_LEAVE_POSTING_______READ',
  HomeTmsEarnLeavePostingPost = 'HOME_TMS_EARN_LEAVE_POSTING_______POST',
  HomeTmsEarnLeavePostingRevert = 'HOME_TMS_EARN_LEAVE_POSTING_______REVERT',
  HomeTmsAttendanceExclusionListRead = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______READ',
  HomeTmsAttendanceExclusionListCreate = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______CREATE',
  HomeTmsAttendanceExclusionListDelete = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______DELETE',
  HomeTmsContactTracingRead = 'HOME_TMS_CONTACT_TRACING_______READ',
  HomeTmsContactTracingExport = 'HOME_TMS_CONTACT_TRACING_______EXPORT',
  CommonSettingRequisitionRead = 'COMMON_SETTING_REQUISITION_______READ',
  CommonSettingRequisitionTypeRead = 'COMMON_SETTING_REQUISITION_TYPE______READ',
  CommonSettingRequisitionTypeCreate = 'COMMON_SETTING_REQUISITION_TYPE______CREATE',
  CommonSettingRequisitionTypeUpdate = 'COMMON_SETTING_REQUISITION_TYPE______UPDATE',
  CommonSettingRequisitionRequisitionApprovalPolicyRead = 'COMMON_SETTING_REQUISITION_REQUISITION_APPROVAL_POLICY___READ',
  CommonSettingRequisitionAssetRequisitionRead = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___READ',
  CommonSettingRequisitionAssetRequisitionCreate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___CREATE',
  CommonSettingRequisitionAssetRequisitionUpdate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___UPDATE',
  CommonSettingRequisitionAssetRequisitionDelete = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___DELETE',
  CommonSettingRequisitionAssetRequisitionInactive = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___INACTIVE',
  CommonSettingRequisitionAssetRequisitionReactivate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___REACTIVATE',
  CommonSettingRequisitionCashAdvanceRead = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___READ',
  CommonSettingRequisitionCashAdvanceCreate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___CREATE',
  CommonSettingRequisitionCashAdvanceUpdate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___UPDATE',
  CommonSettingRequisitionCashAdvanceDelete = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___DELETE',
  CommonSettingRequisitionCashAdvanceInactive = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___INACTIVE',
  CommonSettingRequisitionCashAdvanceReactivate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___REACTIVATE',
  CommonSettingRequisitionOtherRequisitionRead = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___READ',
  CommonSettingRequisitionOtherRequisitionCreate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___CREATE',
  CommonSettingRequisitionOtherRequisitionUpdate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___UPDATE',
  CommonSettingRequisitionOtherRequisitionDelete = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___DELETE',
  CommonSettingRequisitionOtherRequisitionInactive = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___INACTIVE',
  CommonSettingRequisitionOtherRequisitionReactivate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___REACTIVATE',
  CommonSettingRequisitionTravelRequestRead = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___READ',
  CommonSettingRequisitionTravelRequestCreate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___CREATE',
  CommonSettingRequisitionTravelRequestUpdate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___UPDATE',
  CommonSettingRequisitionTravelRequestDelete = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___DELETE',
  CommonSettingRequisitionTravelRequestInactive = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___INACTIVE',
  CommonSettingRequisitionTravelRequestReactivate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___REACTIVATE',
  CommonSettingRequisitionStaffMovementRequestRead = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__READ',
  CommonSettingRequisitionStaffMovementRequestCreate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__CREATE',
  CommonSettingRequisitionStaffMovementRequestUpdate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__UPDATE',
  CommonSettingRequisitionStaffMovementRequestDelete = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__DELETE',
  CommonSettingRequisitionStaffMovementRequestInactive = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__INACTIVE',
  CommonSettingRequisitionStaffMovementRequestReactivate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__REACTIVATE',
  CompanySettingRequisitionRead = 'COMPANY_SETTING_REQUISITION_______READ',
  CompanySettingRequisitionStaffMovementRequestRead = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______READ',
  CompanySettingRequisitionStaffMovementRequestUpdate = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______UPDATE',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentRead = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeRequisitionExecSummaryRead = 'HOME_REQUISITION_EXEC_SUMMARY____READ',
  HomeRequisitionRead = 'HOME_REQUISITION_______READ',
  HomeRequisitionUpdate = 'HOME_REQUISITION_______UPDATE',
  HomeRequisitionRequisitionApplicationRead = 'HOME_REQUISITION_REQUISITION_APPLICATION_______READ',
  HomeRequisitionRequisitionApplicationCreate = 'HOME_REQUISITION_REQUISITION_APPLICATION_______CREATE',
  HomeRequisitionRequisitionApplicationApprove = 'HOME_REQUISITION_REQUISITION_APPLICATION_______APPROVE',
  HomeRequisitionRequisitionApplicationReject = 'HOME_REQUISITION_REQUISITION_APPLICATION_______REJECT',
  HomeRequisitionRequisitionApplicationWithdraw = 'HOME_REQUISITION_REQUISITION_APPLICATION_______WITHDRAW',
  GeneralSettingRequisitionNotificationRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION____READ',
  DigitalReportingPersonnelRptBasicinfoView = 'DIGITAL_REPORTING_PERSONNEL_RPT_BASICINFO____VIEW',
  DigitalReportingPersonnelRptConfirmationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_CONFIRMATION____VIEW',
  DigitalReportingPersonnelRptDependentView = 'DIGITAL_REPORTING_PERSONNEL_RPT_DEPENDENT____VIEW',
  DigitalReportingPersonnelRptEduBackgroundView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EDU_BACKGROUND_VIEW',
  DigitalReportingPersonnelRptNewView = 'DIGITAL_REPORTING_PERSONNEL_RPT_NEW____VIEW',
  DigitalReportingPersonnelRptRegisnationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_REGISNATION____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceSalaryMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_SALARY_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeMasterExcelView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_MASTER_EXCEL_VIEW',
  DigitalReportingPayrollRptBankBankPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankCashcheckPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_CASHCHECK_PAYOUT_VIEW',
  DigitalReportingPayrollRptPayrollEmployeePayView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_PAY_VIEW',
  DigitalReportingPayrollRptPayrollEmployeeSalaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_SALARY_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollPayrollDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollSalaryvarianceView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYVARIANCE_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbystaffcategoryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYSTAFFCATEGORY_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbyjobpositionView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYJOBPOSITION_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_EXPORT',
  DigitalReportingPayrollRptPayrollSalarysummaryByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptPayrollTrxDetailExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_TRX_DETAIL_EXPORT',
  DigitalReportingPayrollRptPayrollStaffcurrentcompensationView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_STAFFCURRENTCOMPENSATION_VIEW',
  DigitalReportingPayrollRptPayslipView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP____VIEW',
  DigitalReportingPayrollRptPayslipMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_MULTI_VIEW',
  DigitalReportingPayrollRptPayslipBmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptAsbView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB____VIEW',
  DigitalReportingPayrollRptAsbRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_RPT_VIEW',
  DigitalReportingPayrollRptAsbCsvTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_CSV_TXT_VIEW',
  DigitalReportingPayrollRptHrdfView = 'DIGITAL_REPORTING_PAYROLL_RPT_HRDF_VIEW',
  DigitalReportingPayrollRptHrDevelopmentFundListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_HR_DEVELOPMENT_FUND_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_VIEW',
  DigitalReportingPayrollRptZakatListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatListingWpklMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_WPKL_MULTI_VIEW',
  DigitalReportingPayrollRptTabungHajiView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_VIEW',
  DigitalReportingPayrollRptTabungHajiRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_RPT_VIEW',
  DigitalReportingPayrollRptTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptKwspBorangaView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_VIEW',
  DigitalReportingPayrollRptKwspBorangaMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_MULTI_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_CSV_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_VIEW',
  DigitalReportingPayrollRptSocsoBorang2MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG2_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang3MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG3_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1View = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_VIEW',
  DigitalReportingPayrollRptEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangeView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGE_VIEW',
  DigitalReportingPayrollRptLhdnBorangeaView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2017MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2017_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2021MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2021_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2023BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2023_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnCp159View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP159_VIEW',
  DigitalReportingPayrollRptLhdnCp21View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP21_VIEW',
  DigitalReportingPayrollRptLhdnCp22View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22_VIEW',
  DigitalReportingPayrollRptLhdnCp22AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_VIEW',
  DigitalReportingPayrollRptLhdnCp22ATxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_TXT_VIEW',
  DigitalReportingPayrollRptLhdnCp39View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39_VIEW',
  DigitalReportingPayrollRptLhdnCp39AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39A_VIEW',
  DigitalReportingPayrollRptLhdnCp8DView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8D_VIEW',
  DigitalReportingPayrollRptLhdnCp8DtxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8DTXT_VIEW',
  DigitalReportingPayrollRptLhdnTaxView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_TAX_VIEW',
  DigitalReportingPayrollRptLhdnLampiranBView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_LAMPIRAN_B_VIEW',
  DigitalReportingPayrollRptIntegrationAffbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_VIEW',
  DigitalReportingPayrollRptIntegrationAffbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_VIEW',
  DigitalReportingPayrollRptIntegrationBimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbOnlinePayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_ONLINE_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_VIEW',
  DigitalReportingPayrollRptIntegrationCimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbZakatTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_ZAKAT_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationHlbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingPayrollRptIntegrationHlbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbLhdnExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_LHDN_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbZakatExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_ZAKAT_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2UCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2U_CSV_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollMasapsTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_MASAPS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankKwspM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_KWSP_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankSocsoM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_SOCSO_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankEisM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_EIS_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankLhdnM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_LHDN_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankZakatM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_ZAKAT_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollIbgExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_IBG_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_VIEW',
  DigitalReportingPayrollRptIntegrationUobPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_TXT_VIEW',
  DigitalReportingLeaveView = 'DIGITAL_REPORTING_LEAVE_______VIEW',
  DigitalReportingLeaveRptLeaveAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ANALYSIS____VIEW',
  DigitalReportingLeaveRptLeaveEntitlementBalanceAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ENTITLEMENT_BALANCE_ANALYSIS____VIEW',
  DigitalReportingClaimView = 'DIGITAL_REPORTING_CLAIM_______VIEW',
  DigitalReportingClaimRptClaimBalanceAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_BALANCE_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimStatusAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_STATUS_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimDetailListingView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_DETAIL_LISTING____VIEW',
  DigitalReportingClaimRptStaffClaimView = 'DIGITAL_REPORTING_CLAIM_RPT_STAFF_CLAIM_VIEW',
  DigitalReportingClaimRptIntegrationView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION____VIEW',
  DigitalReportingClaimRptIntegrationHlbView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingHelpdeskView = 'DIGITAL_REPORTING_HELPDESK_______VIEW',
  DigitalReportingHelpdeskRptHelpdeskListingView = 'DIGITAL_REPORTING_HELPDESK_RPT_HELPDESK_LISTING____VIEW',
  HomeDigitalReportingView = 'HOME_DIGITAL_REPORTING_______VIEW',
  DigitalReportingPersonnelView = 'DIGITAL_REPORTING_PERSONNEL_______VIEW',
  DigitalReportingPayrollBankPayoutView = 'DIGITAL_REPORTING_PAYROLL____BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK____VIEW',
  DigitalReportingPayrollRptPayrollView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL____VIEW',
  DigitalReportingPayrollRptLhdnView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN____VIEW',
  DigitalReportingPayrollRptIntegrationView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION____VIEW',
  DigitalReportingPayrollView = 'DIGITAL_REPORTING_PAYROLL_______VIEW',
  DigitalReportingTmsView = 'DIGITAL_REPORTING_TMS_VIEW',
  DigitalReportingTmsAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsEmployeeClockingListingRptView = 'DIGITAL_REPORTING_TMS_EMPLOYEE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsPeriodicOvertimeForecastListingRptView = 'DIGITAL_REPORTING_TMS_PERIODIC_OVERTIME_FORECAST_LISTING_RPT_VIEW',
  DigitalReportingTmsAbsenteeismListingRptView = 'DIGITAL_REPORTING_TMS_ABSENTEEISM_LISTING_RPT_VIEW',
  DigitalReportingTmsIncompleteClockingListingRptView = 'DIGITAL_REPORTING_TMS_INCOMPLETE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsIrregularAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_IRREGULAR_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsLateInEarlyOutListingRptView = 'DIGITAL_REPORTING_TMS_LATE_IN_EARLY_OUT_LISTING_RPT_VIEW',
  DigitalReportingProjectTimesheetView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_VIEW',
  DigitalReportingProjectTimesheetRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_RPT_VIEW',
  DigitalReportingProjectTimesheetSummaryRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_SUMMARY_RPT_VIEW',
  MulticurrencySetupView = 'MULTICURRENCY_SETUP_VIEW',
  MulticurrencySetupManualEntryView = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_VIEW',
  MulticurrencySetupManualEntryCreate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_CREATE',
  MulticurrencySetupManualEntryUpdate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_UPDATE',
  MulticurrencySetupManualEntryDelete = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_DELETE',
  MulticurrencySetupManualEntryReactivate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_REACTIVATE',
  MulticurrencySetupManualEntryInactive = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_INACTIVE',
  MulticurrencySetupManualExcelUploadView = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_VIEW',
  MulticurrencySetupManualExcelUploadImport = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_IMPORT',
  MulticurrencySetupManualExcelUploadDownload = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_DOWNLOAD',
  CommonSettingProjectExpenseRead = 'COMMON_SETTING_PROJECT_EXPENSE_READ',
  CommonSettingProjectExpenseProjectMasterRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_READ',
  CommonSettingProjectExpenseProjectMasterCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_CREATE',
  CommonSettingProjectExpenseProjectMasterUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_UPDATE',
  CommonSettingProjectExpenseProjectMasterDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_DELETE',
  CommonSettingProjectExpenseProjectExpenseTypeRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_READ',
  CommonSettingProjectExpenseProjectExpenseTypeCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_CREATE',
  CommonSettingProjectExpenseProjectExpenseTypeUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_UPDATE',
  CommonSettingProjectExpenseProjectExpenseTypeDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyRead = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyCreate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyUpdate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyDelete = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyInactive = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyReactivate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingProjectExpenseGlAccountMappingRead = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_READ',
  CommonSettingProjectExpenseGlAccountMappingCreate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_CREATE',
  CommonSettingProjectExpenseGlAccountMappingUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_UPDATE',
  CommonSettingProjectExpenseGlAccountMappingDelete = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_DELETE',
  HomeProjectExecSummaryRead = 'HOME_PROJECT_EXEC_SUMMARY____READ',
  CompanySettingProjectRead = 'COMPANY_SETTING_PROJECT_______READ',
  CompanySettingProjectProjectExpenseClaimSettingRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___READ',
  CompanySettingProjectProjectExpenseClaimSettingUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___UPDATE',
  CompanySettingProjectGlAccountMappingAssignmentRead = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___READ',
  CompanySettingProjectGlAccountMappingAssignmentUpdate = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___UPDATE',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___READ',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___UPDATE',
  HomeProjectRead = 'HOME_PROJECT_______READ',
  HomeProjectStaffProjectCostAllocationRead = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____READ',
  HomeProjectStaffProjectCostAllocationCreate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____CREATE',
  HomeProjectStaffProjectCostAllocationUpdate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____UPDATE',
  HomeProjectStaffProjectCostAllocationDelete = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____DELETE',
  HomeProjectProjectExpenseClaimApplicationRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____READ',
  HomeProjectProjectExpenseClaimApplicationCreate = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____CREATE',
  HomeProjectProjectExpenseClaimApplicationApprove = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____APPROVE',
  HomeProjectProjectExpenseClaimApplicationReject = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____REJECT',
  HomeProjectProjectExpenseClaimApplicationWithdraw = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____WITHDRAW',
  HomeProjectApprovedProjectExpenseClaimVerificationRead = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____READ',
  HomeProjectApprovedProjectExpenseClaimVerificationVerify = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____VERIFY',
  HomeProjectApprovedProjectExpenseClaimVerificationReject = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REJECT',
  HomeProjectApprovedProjectExpenseClaimVerificationReverse = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REVERSE',
  HomeProjectProjectExpenseClaimPostingToPayrollRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____READ',
  HomeProjectProjectExpenseClaimPostingToPayrollPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____POST',
  HomeProjectProjectExpenseClaimPostingToPayrollReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____REVERSE',
  HomeProjectProjectExpenseClaimPaymentRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____READ',
  HomeProjectProjectExpenseClaimPaymentPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____POST',
  HomeProjectProjectExpenseClaimPaymentReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____REVERSE',
  HomeProjectGlProcessingRead = 'HOME_PROJECT_GL_PROCESSING____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportRead = 'HOME_PROJECT_GL_EXPORT____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportClaimGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportClaimGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  CommonSettingPersonnelDataChangesRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CommonSettingPersonnelDataChangesCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___CREATE',
  CommonSettingPersonnelDataChangesUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  CommonSettingPersonnelDataChangesDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___DELETE',
  CommonSettingPersonnelDataChangesInactive = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___INACTIVE',
  CommonSettingPersonnelDataChangesReactivate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___REACTIVATE',
  CommonSettingPersonnelDataChangesPolicyView = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___VIEW',
  CommonSettingPersonnelDataChangesPolicyCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___CREATE',
  CommonSettingPersonnelDataChangesPolicyRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___READ',
  CommonSettingPersonnelDataChangesPolicyUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___UPDATE',
  CommonSettingPersonnelDataChangesPolicyDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___DELETE',
  CompanySettingPersonnelDataChangesRead = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CompanySettingPersonnelDataChangesUpdate = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  HomePersonnelDataChangesRead = 'HOME_PERSONNEL_DATA_CHANGES____READ',
  HomePersonnelDataChangesApprove = 'HOME_PERSONNEL_DATA_CHANGES____APPROVE',
  HomePersonnelDataChangesReject = 'HOME_PERSONNEL_DATA_CHANGES____REJECT',
  CommonSettingTmsBluetoothBeaconDevicesRead = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___READ',
  CommonSettingTmsBluetoothBeaconDevicesCreate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___CREATE',
  CommonSettingTmsBluetoothBeaconDevicesUpdate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___UPDATE',
  CommonSettingTmsBluetoothBeaconDevicesDelete = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___DELETE',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentRead = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___READ',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentUpdate = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___UPDATE',
  CommonSettingPerformanceRead = 'COMMON_SETTING_PERFORMANCE_______READ',
  CommonSettingPerformanceFormItemRatingSetupRead = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______READ',
  CommonSettingPerformanceFormItemRatingSetupCreate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______CREATE',
  CommonSettingPerformanceFormItemRatingSetupUpdate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______UPDATE',
  CommonSettingPerformanceFormItemRatingSetupDelete = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______DELETE',
  CommonSettingPerformanceReviewGradingTableRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______READ',
  CommonSettingPerformanceReviewGradingTableCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______CREATE',
  CommonSettingPerformanceReviewGradingTableUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______UPDATE',
  CommonSettingPerformanceReviewGradingTableDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______DELETE',
  CommonSettingPerformanceReviewPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______READ',
  CommonSettingPerformanceReviewPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______CREATE',
  CommonSettingPerformanceReviewPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______UPDATE',
  CommonSettingPerformanceReviewPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______DELETE',
  CommonSettingPerformanceKpiCategoryRead = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______READ',
  CommonSettingPerformanceKpiCategoryCreate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______CREATE',
  CommonSettingPerformanceKpiCategoryUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______UPDATE',
  CommonSettingPerformanceKpiCategoryDelete = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______READ',
  CommonSettingPerformanceKpiPredefinedItemCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______CREATE',
  CommonSettingPerformanceKpiPredefinedItemUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______UPDATE',
  CommonSettingPerformanceKpiPredefinedItemDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemReactivate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______REACTIVATE',
  CommonSettingPerformanceKpiPredefinedItemInactive = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______INACTIVE',
  CommonSettingPerformanceKpiPredefinedTemplateRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______READ',
  CommonSettingPerformanceKpiPredefinedTemplateCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______CREATE',
  CommonSettingPerformanceKpiPredefinedTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______UPDATE',
  CommonSettingPerformanceKpiPredefinedTemplateDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewItemRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______READ',
  CommonSettingPerformanceReviewItemCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______CREATE',
  CommonSettingPerformanceReviewItemUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______UPDATE',
  CommonSettingPerformanceReviewItemDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______DELETE',
  CommonSettingPerformanceReviewTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______READ',
  CommonSettingPerformanceReviewTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______READ',
  CommonSettingPerformanceReviewProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceReviewProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceReviewProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______DELETE',
  CommonSettingPerformanceReviewFormTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______READ',
  CommonSettingPerformanceReviewFormTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewFormTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewFormTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewGroupRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______READ',
  CommonSettingPerformanceReviewGroupCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______CREATE',
  CommonSettingPerformanceReviewGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______UPDATE',
  CommonSettingPerformanceReviewGroupDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______DELETE',
  CommonSettingPerformanceRewardGroupRead = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______READ',
  CommonSettingPerformanceRewardGroupCreate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______CREATE',
  CommonSettingPerformanceRewardGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______UPDATE',
  CommonSettingPerformanceRewardGroupDelete = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______DELETE',
  HomePerformanceRead = 'HOME_PERFORMANCE_______READ',
  CompanySettingPerformanceRead = 'COMPANY_SETTING_PERFORMANCE_______READ',
  CompanySettingPerformanceReviewGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___READ',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___READ',
  CompanySettingPerformanceReviewGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceReviewGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceReviewGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___DELETE',
  CompanySettingPerformanceRewardGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___READ',
  CompanySettingPerformanceRewardGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceRewardGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceRewardGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___DELETE',
  HomePerformanceOrganizationalGoalRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____READ',
  HomePerformanceOrganizationalGoalCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____CREATE',
  HomePerformanceOrganizationalGoalUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____UPDATE',
  HomePerformanceOrganizationalGoalDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____DELETE',
  HomePerformanceOrganizationalKpiPlanningRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____READ',
  HomePerformanceOrganizationalKpiPlanningCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CREATE',
  HomePerformanceOrganizationalKpiPlanningUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____UPDATE',
  HomePerformanceOrganizationalKpiPlanningDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____DELETE',
  HomePerformanceOrganizationalKpiPlanningChangeOwner = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CHANGE_OWNER',
  HomePerformanceKpiExerciseRead = 'HOME_PERFORMANCE_KPI_EXERCISE____READ',
  HomePerformanceKpiExerciseSetupRead = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____READ',
  HomePerformanceKpiExerciseSetupCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____CREATE',
  HomePerformanceKpiExerciseSetupUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____UPDATE',
  HomePerformanceKpiExerciseSetupDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DELETE',
  HomePerformanceKpiExerciseSetupGenerateKpi = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____GENERATE_KPI',
  HomePerformanceKpiExerciseSetupDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceKpiExerciseListingRead = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____READ',
  HomePerformanceKpiExerciseListingCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CREATE',
  HomePerformanceKpiExerciseListingUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____UPDATE',
  HomePerformanceKpiExerciseListingDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DELETE',
  HomePerformanceKpiExerciseListingInitiate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____INITIATE',
  HomePerformanceKpiExerciseListingRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____REVERT',
  HomePerformanceKpiExerciseListingClearRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceKpiExerciseListingDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DUPLICATE',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___UPDATE',
  CommonSettingPerformanceKpiProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______READ',
  CommonSettingPerformanceKpiProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceKpiProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceKpiProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______DELETE',
  HomePerformanceEmployeeKpiRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____READ',
  HomePerformanceEmployeeKpiRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____VIEW',
  HomePerformanceEmployeeReviewRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____READ',
  HomePerformanceEmployeeReviewRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____VIEW',
  HomePerformanceReviewExerciseRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE____READ',
  HomePerformanceReviewExerciseSetupRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____READ',
  HomePerformanceReviewExerciseSetupCreate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____CREATE',
  HomePerformanceReviewExerciseSetupView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____VIEW',
  HomePerformanceReviewExerciseSetupUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____UPDATE',
  HomePerformanceReviewExerciseSetupDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DELETE',
  HomePerformanceReviewExerciseSetupGenerate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____GENERATE',
  HomePerformanceReviewExerciseSetupDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceReviewExerciseSetupPeerRegistration = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____PEER_REGISTRATION',
  HomePerformanceReviewExerciseListingRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____READ',
  HomePerformanceReviewExerciseListingProceed = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____PROCEED',
  HomePerformanceReviewExerciseListingView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____VIEW',
  HomePerformanceReviewExerciseListingUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____UPDATE',
  HomePerformanceReviewExerciseListingDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DELETE',
  HomePerformanceReviewExerciseListingInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____INITIATE',
  HomePerformanceReviewExerciseListingReInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____RE_INITIATE',
  HomePerformanceReviewExerciseListingRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____REVERT',
  HomePerformanceReviewExerciseListingClearRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceReviewExerciseListingDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DUPLICATE',
  HomeMymenuMyPerformancePerformanceReviewMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____READ',
  HomeMymenuMyPerformancePerformanceReviewMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____VIEW',
  HomeMymenuMyPerformancePerformanceReviewMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____SUBMIT',
  HomeMymenuMyPerformanceReviewActionMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____READ',
  HomeMymenuMyPerformanceReviewActionMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____VIEW',
  HomeMymenuMyPerformanceReviewActionMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceReviewActionMymenuApprove = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____APPROVE',
  HomeMymenuMyPerformanceReviewActionMymenuSendBack = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SEND_BACK',
  HomeMymenuMyPerformanceReviewActionMymenuProceed = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____PROCEED',
  HomeMymenuMyPerformanceReviewActionMymenuSave = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SAVE',
  HomeMymenuMyPerformanceReviewActionMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SUBMIT',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateView = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____VIEW',
  CommonSettingTmsAutomatedDataImportRead = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___READ',
  CommonSettingTmsAutomatedDataImportCreate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___CREATE',
  CommonSettingTmsAutomatedDataImportUpdate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___UPDATE',
  CommonSettingTmsAutomatedDataImportDelete = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___DELETE',
  CommonSettingHelpdeskRead = 'COMMON_SETTING_HELPDESK_______READ',
  CommonSettingHelpdeskHelpdeskSubjectCreate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___CREATE',
  CommonSettingHelpdeskHelpdeskSubjectRead = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___READ',
  CommonSettingHelpdeskHelpdeskSubjectUpdate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___UPDATE',
  CommonSettingHelpdeskHelpdeskSubjectDelete = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___DELETE',
  CommonSettingHelpdeskHelpdeskSubjectInactive = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___INACTIVE',
  CommonSettingHelpdeskHelpdeskSubjectReactivate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___REACTIVATE',
  HomeHelpdeskRead = 'HOME_HELPDESK_______READ',
  HomeHelpdeskHelpdeskMasterListingRead = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___READ',
  HomeHelpdeskHelpdeskMasterListingView = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___VIEW',
  HomeHelpdeskHelpdeskMasterListingUpdate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___UPDATE',
  HomeHelpdeskHelpdeskMasterListingCreate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___CREATE',
  HomeHelpdeskHelpdeskMasterListingDelete = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___DELETE',
  HomeHelpdeskHelpdeskMasterListingReassign = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___REASSIGN',
  HomeHelpdeskHelpdeskMasterListingWithdraw = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___WITHDRAW',
  HomeHelpdeskAssigneeListingRead = 'HOME_HELPDESK_ASSIGNEE_LISTING___READ',
  HomeHelpdeskAssigneeListingView = 'HOME_HELPDESK_ASSIGNEE_LISTING___VIEW',
  HomeHelpdeskAssigneeListingUpdate = 'HOME_HELPDESK_ASSIGNEE_LISTING___UPDATE',
  HomeHelpdeskMyTicketsListingRead = 'HOME_HELPDESK_MY_TICKETS_LISTING___READ',
  HomeHelpdeskMyTicketsListingView = 'HOME_HELPDESK_MY_TICKETS_LISTING___VIEW',
  HomeHelpdeskMyTicketsListingCreate = 'HOME_HELPDESK_MY_TICKETS_LISTING___CREATE',
  HomeHelpdeskMyTicketsListingWithdraw = 'HOME_HELPDESK_MY_TICKETS_LISTING___WITHDRAW',
  HomeBusinessInsightsRead = 'HOME_BUSINESS_INSIGHTS_______READ',
  HomeBusinessInsightsHeadcountRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_______READ',
  HomeBusinessInsightsHeadcountHeadcountSalaryRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_HEADCOUNT_SALARY_______READ',
  HomeBusinessInsightsHeadcountYearsOfServiceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_YEARS_OF_SERVICE_______READ',
  HomeBusinessInsightsHeadcountSalaryRangeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_SALARY_RANGE_______READ',
  HomeBusinessInsightsHeadcountAgeGroupRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_AGE_GROUP_______READ',
  HomeBusinessInsightsHeadcountEmploymentTypeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYMENT_TYPE_______READ',
  HomeBusinessInsightsHeadcountEmployeeRaceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYEE_RACE_______READ',
  HomeBusinessInsightsHeadcountGenderRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_GENDER_______READ',
  HomeBusinessInsightsOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadStatutoryContributionRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_STATUTORY_CONTRIBUTION_______READ',
  HomeBusinessInsightsOvertimeRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_______READ',
  HomeBusinessInsightsOvertimeOtHoursCostRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_OT_HOURS_COST_______READ',
  HomeBusinessInsightsLeaveRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_______READ',
  HomeBusinessInsightsLeaveLeaveAnalysisRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_LEAVE_ANALYSIS_______READ',
  HomeBusinessInsightsStaffClaimRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_______READ',
  HomeBusinessInsightsStaffClaimClaimAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_CLAIM_ANALYSIS_______READ',
  HomeBusinessInsightsStaffTurnoverRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_______READ',
  HomeBusinessInsightsStaffTurnoverOffboardingAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_OFFBOARDING_ANALYSIS_______READ',
  CommonSettingTimesheetRead = 'COMMON_SETTING_TIMESHEET_______READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___DELETE',
  CommonSettingTimesheetProjectTimesheetApproverMasterResendEmail = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___RESEND_EMAIL',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___DELETE'
}

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncrementSimulationEntity = InterfaceBase & {
  __typename?: 'IncrementSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IncrementSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type IncrementSimulationInput = {
  IncrementSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'InsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  Document?: Maybe<DocumentsEntity>;
};

export type InsuranceSchemeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name: Scalars['String'];
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum IntegrationClockType {
  In = 'IN',
  Out = 'OUT',
  Empty = 'Empty'
}

export type InterbankGiroEntity = InterfaceBase & {
  __typename?: 'InterbankGiroEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InterbankGiroID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
};

export type InterbankGiroInput = {
  InterbankGiroID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBase = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBaseInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
};

export type InterfaceBaseV2 = {
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
};

export type InterfaceBaseV3 = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  InvoiceDate?: Maybe<Scalars['String']>;
  InvoiceId?: Maybe<Scalars['String']>;
  InvoiceTotal?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
};

export enum ItemActionType {
  New = 'New',
  View = 'View',
  Edit = 'Edit',
  Delete = 'Delete'
}

export enum ItemRatingMethod {
  Comment = 'Comment',
  FormItemRating = 'Form_Item_Rating'
}



export type JobAccountEntity = InterfaceBase & {
  __typename?: 'JobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  MyJobAccount?: Maybe<Array<MyJobAccountEntity>>;
  ClaimHeader?: Maybe<Array<ClaimHeaderEntity>>;
};

export type JobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobCompetencyRclEntity = InterfaceBase & {
  __typename?: 'JobCompetencyRCLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobCompetencyRCLID: Scalars['String'];
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
};

export type JobCompetencyRclInput = {
  JobCompetencyRCLID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobDesignationEntity = InterfaceBase & {
  __typename?: 'JobDesignationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
  IsInUse: Scalars['Boolean'];
};

export type JobDesignationGradeEntity = InterfaceBase & {
  __typename?: 'JobDesignationGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationGradeID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobGrade: JobGradeEntity;
  JobDesignation: JobDesignationEntity;
};

export type JobDesignationGradeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
};

export type JobDesignationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type JobDesignationSkillSetEntity = InterfaceBase & {
  __typename?: 'JobDesignationSkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationSkillSetID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetDetail?: Maybe<SkillSetDetailEntity>;
  JobDesignation?: Maybe<JobDesignationEntity>;
};

export type JobDesignationSkillSetInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};

export type JobGradeBulkInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradeByJobDesignationOutput = {
  __typename?: 'JobGradeByJobDesignationOutput';
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<JobGradeOutput>;
};

export type JobGradeEntity = InterfaceBase & {
  __typename?: 'JobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobGradePolicies?: Maybe<Array<JobGradePolicyEntity>>;
};

export type JobGradeInput = {
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradeOutput = {
  __typename?: 'JobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradePolicyEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
};

export type JobGradePolicyInput = {
  JobGradePolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradePolicyMatrixEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyMatrixID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGradeID: Scalars['String'];
  OvertimeClaimTableID: Scalars['String'];
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
  OvertimeClaimTable?: Maybe<OvertimeClaimTableEntity>;
};

export type JobGradePolicyMatrixInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};

export type JobGradesEntity = InterfaceBase & {
  __typename?: 'JobGradesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type JobGradesInput = {
  name?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentEntity = InterfaceBase & {
  __typename?: 'JobPositionAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobPositionAssignmentID: Scalars['String'];
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentInput = {
  JobPositionAssignmentID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobPositionListingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobPositionReportingID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToCompanyID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingEntity = NewInterfaceBase & {
  __typename?: 'JobPositionReportingEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  job_position_reporting_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_structure_id?: Maybe<Scalars['String']>;
  reporting_structure?: Maybe<ReportingStructureEntity>;
  from_org_unit_id?: Maybe<Scalars['String']>;
  from_org_unit?: Maybe<CompanyOrgUnitEntity>;
  to_org_unit_id?: Maybe<Scalars['String']>;
  to_org_unit?: Maybe<CompanyOrgUnitEntity>;
  from_job_position_id?: Maybe<Scalars['String']>;
  from_job_position?: Maybe<JobDesignationEntity>;
  to_job_position_id?: Maybe<Scalars['String']>;
  to_job_position?: Maybe<JobDesignationEntity>;
  company?: Maybe<CompanyEntity>;
  to_company_id?: Maybe<Scalars['String']>;
  to_company?: Maybe<CompanyEntity>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JobPositionList: Array<JobPositionListingInput>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type JobPositionReportingOutput = {
  __typename?: 'JobPositionReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  JobPositionList?: Maybe<Array<JobPositionReportingEntity>>;
};

export type JoinedDateError = {
  __typename?: 'JoinedDateError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum KpiType {
  Quantitative = 'Quantitative',
  Qualitative = 'Qualitative'
}

export type KpiActiveInactiveInput = {
  ID: Scalars['String'];
  Status: Status;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryEntity = InterfaceBase & {
  __typename?: 'KpiCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrgGoals?: Maybe<Array<OrganizationalGoalEntity>>;
  OrgKpiPlannings?: Maybe<Array<OrgKpiPlanningDetailsEntity>>;
  InUse: Scalars['Boolean'];
  PredefinedItemCount: Scalars['Float'];
};

export type KpiCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryItemInput = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  RatingScaleTable: Array<RatingScaleTableInput>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiExerciseDetailsEditInput = {
  ID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExercise?: Maybe<KpiExerciseEntity>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  EmployeeKpiExerciseID?: Maybe<Scalars['String']>;
  EmployeeSubmitDT?: Maybe<Scalars['DateTime']>;
};

export type KpiExerciseEntity = InterfaceBase & {
  __typename?: 'KpiExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExerciseSetup?: Maybe<KpiExerciseSetupEntity>;
};

export type KpiExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseSetupEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupDetails?: Maybe<Array<KpiExerciseSetupDetailsEntity>>;
  InUse?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  KpiExerciseSetupDetails: Array<KpiExerciseSetupDetailsInput>;
};

export type KpiMinMaxWeightage = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Weightage?: Maybe<Scalars['Float']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  KpiItem?: Maybe<Array<KpiCategoryItemInput>>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiPredefinedItemEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  FormItemRatingID: Scalars['String'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  KpiCategory: KpiCategoryEntity;
  FormItemRating: FormItemRatingEntity;
  InUse: Scalars['Boolean'];
};

export type KpiPredefinedItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiPredefinedItemWeightage = {
  __typename?: 'KpiPredefinedItemWeightage';
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedItemWeightageInput = {
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedTemplateEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  WeightageType: QuantitativeType;
  KpiWeightage: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  CategoryCount: Scalars['Float'];
  ItemCount: Scalars['Float'];
};

export type KpiPredefinedTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  KpiWeightage?: Maybe<Array<KpiWeightageInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum KpiSource {
  KpiPredefinedTemplate = 'Kpi_Predefined_Template',
  EmployeeKpi = 'Employee_Kpi'
}

export type KpiWeightage = {
  __typename?: 'KpiWeightage';
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightage>>;
};

export type KpiWeightageInput = {
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightageInput>>;
};

export type LhdnReportEntity = InterfaceBase & {
  __typename?: 'LHDNReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LHDNReportID: Scalars['String'];
  ReportType: ReportType;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
};

export type LhdnReportInput = {
  ReportType?: Maybe<ReportType>;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LosInput = {
  Condition?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['String']>;
  Day?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
};

export type LastUpdatedDatesOutput = {
  __typename?: 'LastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type Leave = {
  Name?: Maybe<Scalars['String']>;
  NoOfDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
};

export type LeaveAdjustmentListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type LeaveAdjustmentOutput = {
  __typename?: 'LeaveAdjustmentOutput';
  LeaveHeaderID?: Maybe<Scalars['String']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
};

export type LeaveAppOutput = {
  __typename?: 'LeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveApprovalUpdateInput = {
  LeaveTypeID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type LeaveBalanceSummary = {
  __typename?: 'LeaveBalanceSummary';
  BroughtForward?: Maybe<Scalars['Float']>;
  Adjustment?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  Earned?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  TakenYTD?: Maybe<Scalars['Float']>;
  Forfeited?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceYTD?: Maybe<Scalars['Float']>;
  CarryForward?: Maybe<Scalars['Float']>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  Encashment?: Maybe<Scalars['Float']>;
  Emergency?: Maybe<Scalars['Float']>;
  ApplicationFrequency?: Maybe<Scalars['Float']>;
  Advance?: Maybe<Scalars['Float']>;
  Occurred?: Maybe<Scalars['Float']>;
  CurrentYearAdd?: Maybe<Scalars['Float']>;
  CurrentYearUtilized?: Maybe<Scalars['Float']>;
  CurrentYearBalance?: Maybe<Scalars['Float']>;
  CarryForwardList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveBenefitEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveBenefitInput = {
  LeaveBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveBenefitRuleEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitRuleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID: Scalars['String'];
  LeaveItemID: Scalars['String'];
  FormulaText: Scalars['String'];
  FormulaValue: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveRule: LeaveRuleType;
  LOSRange: Scalars['String'];
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export type LeaveBenefitRuleInput = {
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  FormulaText?: Maybe<Scalars['String']>;
  FormulaValue?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveRule?: Maybe<LeaveRuleType>;
  LOSRange?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEmployeeEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeID: Scalars['String'];
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveCompulsory?: Maybe<LeaveCompulsoryEntity>;
};

export type LeaveCompulsoryEmployeeInput = {
  LeaveCompulsoryEmployeeID?: Maybe<Scalars['String']>;
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveCompulsoryEmployees?: Maybe<Array<LeaveCompulsoryEmployeeEntity>>;
};

export type LeaveCompulsoryInput = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryPolicyEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Sequence?: Maybe<Sequence>;
};

export type LeaveCompulsoryPolicyInput = {
  LeaveCompulsoryPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'LeaveCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type LeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveDetails = {
  __typename?: 'LeaveDetails';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type LeaveDetailsEntity = InterfaceBase & {
  __typename?: 'LeaveDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
  EmployeeID: Scalars['String'];
};

export type LeaveDetailsInput = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseEntity = InterfaceBase & {
  __typename?: 'LeaveDiseaseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDiseaseID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveEntitlementByLeaveTypeListing = {
  __typename?: 'LeaveEntitlementByLeaveTypeListing';
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeavePolicy?: Maybe<LeaveItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  EncashedLeaveHistory?: Maybe<EncashedLeaveHistory>;
};

export type LeaveEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveEntitlementID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Scalars['String']>;
  EmployeeLeavePolicies?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Level?: Maybe<Scalars['Float']>;
  Sort?: Maybe<Scalars['Float']>;
};

export type LeaveEntitlementTemplate = {
  __typename?: 'LeaveEntitlementTemplate';
  LeaveEntitlementName?: Maybe<Scalars['String']>;
  LeaveEntitlementDesc?: Maybe<Scalars['String']>;
  LeaveEntitlementTypes?: Maybe<Array<LeaveEntitlementTypes>>;
};

export type LeaveEntitlementTypes = {
  __typename?: 'LeaveEntitlementTypes';
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export enum LeaveFrequency {
  AnnualIncrement = 'Annual_Increment',
  Occurrence = 'Occurrence',
  ManualEntry = 'Manual_Entry'
}

export type LeaveHeaderEntity = InterfaceBase & {
  __typename?: 'LeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type LeaveHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  VoiceDocument?: Maybe<DocumentsInput>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  LeaveDetailsInput?: Maybe<Array<LeaveDetailsInput>>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
};

export enum LeaveHeaderType {
  OpeningBalance = 'Opening_Balance',
  ClosingBalance = 'Closing_Balance',
  LeaveApplication = 'Leave_Application',
  LeaveAdjustment = 'Leave_Adjustment',
  SystemAdjustment = 'System_Adjustment',
  CompulsoryLeave = 'Compulsory_Leave',
  CarryForward = 'Carry_Forward',
  BroughtForward = 'Brought_Forward',
  InterCoBroughtForward = 'InterCo_BroughtForward',
  InterCoTaken = 'InterCo_Taken',
  InterCoAdjustment = 'InterCo_Adjustment',
  InterCoEarned = 'InterCo_Earned'
}

export type LeaveHomeSummaryOutput = {
  __typename?: 'LeaveHomeSummaryOutput';
  OutOfStateActiveRecords?: Maybe<Scalars['Float']>;
  OutOfStateIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PendingApproval?: Maybe<Scalars['Float']>;
  PendingApprovalIsLastUpdated?: Maybe<Scalars['Boolean']>;
  AdjustmentNoOfRecords?: Maybe<Scalars['Float']>;
  AdjustmentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  EmployeeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfLeaveType?: Maybe<Scalars['Float']>;
  LeaveTypeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  CompulsoryNoOfRecords?: Maybe<Scalars['Float']>;
  CompulsoryIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayDeductionLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayDeductionIsLastUpdated?: Maybe<Scalars['Boolean']>;
  YearEndCarryForwardLastPostedDate?: Maybe<Scalars['DateTime']>;
  YearEndCarryForwardIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type LeaveIndentity = {
  __typename?: 'LeaveIndentity';
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveEntitledDay?: Maybe<Scalars['Float']>;
  PendingApprovalTotalDay?: Maybe<Scalars['Float']>;
};

export type LeaveItemEntity = InterfaceBase & {
  __typename?: 'LeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID: Scalars['String'];
  LeaveBenefitID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  PlatformLeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  LOS?: Maybe<Array<LeaveBenefitRuleEntity>>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type LeaveItemInput = {
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveManagementListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
};

export type LeaveOutOfStateEntity = InterfaceBase & {
  __typename?: 'LeaveOutOfStateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveOutOfStateID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  FromState?: Maybe<StatesEntity>;
  ToState?: Maybe<StatesEntity>;
  FromCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
  ToCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
};

export type LeaveOutOfStateInput = {
  LeaveOutOfStateID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePolicyInput = {
  Title: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveFrequency: LeaveFrequency;
  AvailableBasis?: Maybe<AvailableBasis>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
};

export type LeavePostingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeaderIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsIDs?: Maybe<Array<Scalars['String']>>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
};

export type LeavePostingListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePostingOutput = {
  __typename?: 'LeavePostingOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
};

export type LeavePostingPeriodOutput = {
  __typename?: 'LeavePostingPeriodOutput';
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  CountUnpostedLeave?: Maybe<Scalars['Float']>;
  CountPostedLeave?: Maybe<Scalars['Float']>;
  CountLeaveRecords?: Maybe<Scalars['Float']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  isPostedInTheListing?: Maybe<Scalars['Boolean']>;
};

export type LeaveReasonEntity = InterfaceBase & {
  __typename?: 'LeaveReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveReasonInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveRecordsListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};

export type LeaveRecordsOutput = {
  __typename?: 'LeaveRecordsOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type LeaveRoundingDetailEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingDetailID: Scalars['String'];
  LeaveRoundingID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
};

export type LeaveRoundingDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingDetailID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
};

export type LeaveRoundingEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveRoundingDetails?: Maybe<Array<LeaveRoundingDetailEntity>>;
  Companies?: Maybe<Array<CompanyEntity>>;
};

export type LeaveRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export enum LeaveRuleType {
  Los = 'LOS',
  Gender = 'Gender',
  IsConfirmed = 'Is_Confirmed'
}

export type LeaveTakenOutput = {
  __typename?: 'LeaveTakenOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  TotalRecords?: Maybe<Scalars['Float']>;
};

export type LeaveTempEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveTempEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTempEntitlementID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
};

export type LeaveTempEntitlementInput = {
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTemplateEntity = InterfaceBase & {
  __typename?: 'LeaveTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveTemplateInput = {
  LeaveTemplateID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTypeBalance = {
  __typename?: 'LeaveTypeBalance';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Entitlement?: Maybe<Scalars['Float']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurrenceBalance?: Maybe<Scalars['Float']>;
};

export type LeaveTypeEntity = InterfaceBase & {
  __typename?: 'LeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  LeavePolicies?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LifeTimeError = {
  __typename?: 'LifeTimeError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
  isEmployeeSuperUser?: Maybe<Scalars['Boolean']>;
  onPremServer?: Maybe<Scalars['String']>;
};

export type Md_BankEntity = {
  __typename?: 'MD_BankEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNumber?: Maybe<Scalars['String']>;
  BankSwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Scalars['String']>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_CompanyEntity = {
  __typename?: 'MD_CompanyEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  CompanyWebsiteURL?: Maybe<Scalars['String']>;
  TelNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDNNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['String']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['String']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['String']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['String']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  StatutorySignatoryEmployeeNo?: Maybe<Scalars['String']>;
  StatutorySignatoryEmployeeName?: Maybe<Scalars['String']>;
  StatutorySignatoryCompany?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
};

export type Md_DepartmentDivisionEntity = {
  __typename?: 'MD_DepartmentDivisionEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DepartmentType?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeDependentEntity = {
  __typename?: 'MD_EmployeeDependentEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  DependentName?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  EmergencyContactPerson?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  ChildEducation?: Maybe<Scalars['String']>;
  ChildEligibleRate?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeEntity = {
  __typename?: 'MD_EmployeeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  MaritalStatus?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Scalars['String']>;
  PermanentPostcode?: Maybe<Scalars['String']>;
  PermanentCity?: Maybe<Scalars['String']>;
  PermanentState?: Maybe<Scalars['String']>;
  PermanentCountry?: Maybe<Scalars['String']>;
  IsSameAddress?: Maybe<Scalars['String']>;
  PresentAddress?: Maybe<Scalars['String']>;
  PresentPostcode?: Maybe<Scalars['String']>;
  PresentCity?: Maybe<Scalars['String']>;
  PresentState?: Maybe<Scalars['String']>;
  PresentCountry?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeJobInfoEntity = {
  __typename?: 'MD_EmployeeJobInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  HeadofDepartment?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  SupervisorEmployeeNo?: Maybe<Scalars['String']>;
  SupervisorName?: Maybe<Scalars['String']>;
  SupervisorCompany?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Scalars['String']>;
  JoinDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['String']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarState?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeSalaryEntity = {
  __typename?: 'MD_EmployeeSalaryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeStatutoryInfoEntity = {
  __typename?: 'MD_EmployeeStatutoryInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  PayMethod?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFEEVERate?: Maybe<Scalars['String']>;
  EPFERVERate?: Maybe<Scalars['String']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  LHDNNo?: Maybe<Scalars['String']>;
  TaxMaritalStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['Boolean']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatPercentage?: Maybe<Scalars['String']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_FixedAllwDedEntity = {
  __typename?: 'MD_FixedAllwDedEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayCategory?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobDesignationEntity = {
  __typename?: 'MD_JobDesignationEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobDesignationName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobGradeEntity = {
  __typename?: 'MD_JobGradeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayItemEntity = {
  __typename?: 'MD_PayItemEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  EAPartF?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  AddRemuneration?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  Bonus?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22A?: Maybe<Scalars['String']>;
  CP22B?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCutOffEntity = {
  __typename?: 'MD_PayrollCutOffEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCycleEntity = {
  __typename?: 'MD_PayrollCycleEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollHistoryEntity = {
  __typename?: 'MD_PayrollHistoryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_RecruitmentSourceEntity = {
  __typename?: 'MD_RecruitmentSourceEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type MtdCalcOutput = {
  __typename?: 'MTDCalcOutput';
  MTD?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  CurrentMonthMTD?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  CalcInput?: Maybe<MtdCalculationInput>;
  TotalOneTimeChargableTax?: Maybe<Scalars['Float']>;
  TotalOneTimeChargableEPF?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  PrevTax?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  EPF?: Maybe<Scalars['Float']>;
  MTD_A?: Maybe<Scalars['Float']>;
  MTD_B?: Maybe<Scalars['Float']>;
  MTD_C?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  NonResidentRate?: Maybe<Scalars['Float']>;
};

export type MtdCalculationInput = {
  __typename?: 'MTDCalculationInput';
  Y?: Maybe<Scalars['Float']>;
  K?: Maybe<Scalars['Float']>;
  Y1?: Maybe<Scalars['Float']>;
  K1?: Maybe<Scalars['Float']>;
  Yt?: Maybe<Scalars['Float']>;
  Kt?: Maybe<Scalars['Float']>;
  Y2?: Maybe<Scalars['Float']>;
  K2?: Maybe<Scalars['Float']>;
  KA?: Maybe<Scalars['Float']>;
  YtA?: Maybe<Scalars['Float']>;
  KtA?: Maybe<Scalars['Float']>;
  K2A?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  B2?: Maybe<Scalars['Float']>;
  T2?: Maybe<Scalars['Float']>;
  P2?: Maybe<Scalars['Float']>;
  M2?: Maybe<Scalars['Float']>;
  R2?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Float']>;
  n1?: Maybe<Scalars['Float']>;
  D?: Maybe<Scalars['Float']>;
  Du?: Maybe<Scalars['Float']>;
  S?: Maybe<Scalars['Float']>;
  Su?: Maybe<Scalars['Float']>;
  Qc?: Maybe<Scalars['Float']>;
  ELP?: Maybe<Scalars['Float']>;
  LP1?: Maybe<Scalars['Float']>;
  P?: Maybe<Scalars['Float']>;
  P1?: Maybe<Scalars['Float']>;
  K22?: Maybe<Scalars['Float']>;
  Y22?: Maybe<Scalars['Float']>;
  M?: Maybe<Scalars['Float']>;
  R?: Maybe<Scalars['Float']>;
  B?: Maybe<Scalars['Float']>;
  T?: Maybe<Scalars['Float']>;
  Z?: Maybe<Scalars['Float']>;
  X?: Maybe<Scalars['Float']>;
  TaxResult?: Maybe<Scalars['Float']>;
};

export type MtdOutput = {
  __typename?: 'MTDOutput';
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  IsBasicSalary?: Maybe<Scalars['Boolean']>;
  NetMTDBeforeZakat?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  RoundedNetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxPartA1MTDOutput?: Maybe<MtdCalcOutput>;
  TaxPartA2MTDOutput?: Maybe<MtdCalcOutput>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Separated = 'Separated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum MaxApplyLeave {
  ApplyDate = 'Apply_Date',
  LeaveDate = 'Leave_Date'
}

export type MaxOtPayItemInput = {
  PayItemID?: Maybe<Scalars['String']>;
};

export type MaxOtPayItemObject = {
  __typename?: 'MaxOTPayItemObject';
  PayItemID?: Maybe<Scalars['String']>;
};

export type MedicalRecordEntity = InterfaceBase & {
  __typename?: 'MedicalRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
};

export type MedicalRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type MobileCheckInEntity = InterfaceBase & {
  __typename?: 'MobileCheckInEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileCheckInID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius: Scalars['Float'];
  IsByLocation?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type MobileCheckInInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MobileCheckInID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
};

export type MobileVersionEntity = {
  __typename?: 'MobileVersionEntity';
  MobileVersionID: Scalars['String'];
  Version?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Android?: Maybe<Scalars['Boolean']>;
  iOS?: Maybe<Scalars['Boolean']>;
};

export type ModerationRatingEntity = InterfaceBase & {
  __typename?: 'ModerationRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModerationRatingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ModerationRatingInput = {
  ModerationRatingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Module {
  Tms = 'TMS',
  TmsJobGradePolicy = 'TMS__JOB_GRADE_POLICY',
  TmsShiftGroupAssignment = 'TMS__SHIFT_GROUP_ASSIGNMENT',
  TmsAttendanceExclusionList = 'TMS__ATTENDANCE_EXCLUSION_LIST',
  Personnel = 'PERSONNEL',
  Payroll = 'PAYROLL',
  TmsOvertimePolicyMatrix = 'TMS_OVERTIME_POLICY_MATRIX'
}

export enum MonthPeriod {
  CurrentMonth = 'Current_Month',
  NextMonth = 'Next_Month'
}

export type MonthlyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type MonthlyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'MonthlyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicAllowancePolicyCondition?: Maybe<Array<PeriodicAllowancePolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export enum MonthlyCutOff {
  EarnOnStartingOfTheMonth = 'Earn_On_Starting_Of_The_Month',
  EarnOnEndOfTheMonth = 'Earn_On_End_Of_The_Month',
  EarnAfterCompletedMonth = 'Earn_After_Completed_Month'
}

export type MonthlyError = {
  __typename?: 'MonthlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type MonthlyTemplateEntity = InterfaceBase & {
  __typename?: 'MonthlyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTemplateInput = {
  MonthlyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTransactionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export enum MovementType {
  ProbationExtension = 'Probation_Extension',
  NewHire = 'NewHire',
  Confirmation = 'Confirmation',
  Promotion = 'Promotion',
  Transfer = 'Transfer',
  Demotion = 'Demotion',
  ExitCompany = 'Exit_Company',
  Secondment = 'Secondment',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer'
}

export type MultiCurrencyDetailsEntity = InterfaceBase & {
  __typename?: 'MultiCurrencyDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type MultiCurrencyDetailsInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MultiCurrencySetupEntity = InterfaceBase & {
  __typename?: 'MultiCurrencySetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  IsSystemDefault?: Maybe<Scalars['Boolean']>;
  SoftDelete?: Maybe<Status>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<MultiCurrencyDetailsEntity>>;
};

export type MultiCurrencySetupInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  InUse?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDutyRoster: Scalars['Boolean'];
  DutyRosterBatch: Scalars['Boolean'];
  ShiftPatternAssignmentToEmployee: Scalars['Boolean'];
  createBatchEntryByShiftPattern: Scalars['Boolean'];
  DeleteDutyRosterByEmployee: Scalars['Boolean'];
  DeleteDutyRosterForAll: Scalars['Boolean'];
  NewsReadInsert: Scalars['Boolean'];
  NotificationUpdate: Scalars['Boolean'];
  deleteAttendanceRecord: Scalars['Boolean'];
  createAttendanceRecord: AttendanceRecordEntity;
  submitPairedAttandanceRecord: Scalars['Boolean'];
  updateAttendanceRecord: Scalars['Float'];
  DeleteTimeAttendanceRecord: Scalars['Boolean'];
  AttendanceExcelUpload?: Maybe<Array<ProcessAttendanceEntity>>;
  AttendanceExcelDelete: Scalars['Boolean'];
  AttendanceExcelInsertActual: Scalars['Boolean'];
  AttendanceRecordBatchDelete: Scalars['Boolean'];
  AttendanceRecordBatch: Scalars['Boolean'];
  AutomatedClockPairing: Scalars['Boolean'];
  DocumentInsert: DocumentsEntity;
  DocumentUpdate: DocumentsEntity;
  DocumentUpdateParentID: Scalars['Boolean'];
  DocumentDelete: Scalars['Boolean'];
  EmployeeProfileInsertUpdate: Scalars['Boolean'];
  AppraisalCategoryInsert: AppraisalCategoryEntity;
  AppraisalCategoryUpdate: Scalars['Boolean'];
  AppraisalCategoryDelete: Scalars['Boolean'];
  AppraisalCategoryItemInsert: AppraisalCategoryItemEntity;
  AppraisalCategoryItemUpdate: Scalars['Boolean'];
  AppraisalCategoryItemDelete: Scalars['Boolean'];
  AppraisalPolicyInsert: AppraisalPolicyEntity;
  AppraisalPolicyDelete: Scalars['Boolean'];
  AppraisalTemplateByJobPositionSubmit: Scalars['Boolean'];
  AppraisalScheduleSubmit: AppraisalScheduleHeaderEntity;
  AppraisalCompanySettingSubmit: AppraisalCompanySettingEntity;
  FinalRatingSubmit: FinalRatingHeaderEntity;
  FinalRatingDelete: Scalars['Boolean'];
  ScaleRatingSubmit: ScaleRatingHeaderEntity;
  ScaleRatingDelete: Scalars['Boolean'];
  BonusSimulationSubmit: Scalars['Boolean'];
  IncrementSimulationSubmit: Scalars['Boolean'];
  SubmitAppraisalTemplete: Scalars['Boolean'];
  SubmitAppraisal?: Maybe<Scalars['Boolean']>;
  ModerationSubmit: Scalars['Boolean'];
  SubmitStaffKPIPlanning?: Maybe<Scalars['Boolean']>;
  StaffKPIPlannigDelete: Scalars['Boolean'];
  StaffPerformanceAnalysisSummarySubmit: Scalars['Boolean'];
  ApprovalPolicyInsert: Scalars['Boolean'];
  ApprovalPolicyUpdate: Scalars['Boolean'];
  ApprovalPolicySubmit: Scalars['Boolean'];
  ApprovalPolicyDelete: Scalars['Boolean'];
  ApprovalPolicyAssignmentUpdate: Scalars['Boolean'];
  PostAttendanceRecord: Scalars['Boolean'];
  ReverseAttendanceRecord?: Maybe<Scalars['Boolean']>;
  AttendancePeriodEdit?: Maybe<Scalars['Boolean']>;
  AttendanceProccessBulkDelete: Scalars['Boolean'];
  SubmitManagementClaimRecords?: Maybe<Scalars['Boolean']>;
  ApproveClaimRecords?: Maybe<Scalars['Boolean']>;
  CancelClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedClaimDetail?: Maybe<Scalars['Boolean']>;
  PostClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseClaimRecords?: Maybe<Scalars['Boolean']>;
  SubmitMyClaim: ClaimHeaderEntity;
  DeleteClaimRecords: Scalars['Boolean'];
  SubmitClaimForApproval: Scalars['Boolean'];
  DoUpdateClaim: Scalars['Boolean'];
  MyClaimInsert: ClaimInsertOutput;
  ClaimValidityUpdate: Scalars['Boolean'];
  ClaimValidityInsert: Scalars['Boolean'];
  ClaimTypeInsert: ClaimTypeEntity;
  ClaimTypeUpdate: Scalars['Boolean'];
  ClaimTypeDelete: Scalars['Boolean'];
  ClaimTypeTemplateInsert: Scalars['Boolean'];
  ClaimTypeTemplateUpdate: Scalars['Boolean'];
  ClaimTypeTemplateDelete: Scalars['Boolean'];
  ClaimTypeTemplateUpdateStatus: Scalars['Boolean'];
  ClaimTypeConditionUpdate: Scalars['Boolean'];
  ClaimTypeConditionDelete: Scalars['Boolean'];
  ClaimJobGradePolicyInsert: Scalars['Boolean'];
  ClaimJobGradePolicyUpdate: Scalars['Boolean'];
  ClaimPoolDelete: Scalars['Boolean'];
  ClaimPoolSubmit: Scalars['Boolean'];
  InsertClaimCutOffPeriod: Scalars['Boolean'];
  BankInsert: BankInfoEntity;
  BankUpdate: Scalars['Boolean'];
  BankDelete: Scalars['Boolean'];
  CompanyOffDaysDelete: Scalars['Boolean'];
  updateCompanyOffDaysStatus: Scalars['Boolean'];
  updateRestOffDayStatus: Scalars['Boolean'];
  CompanyOffDaysBulkInsert: Scalars['Boolean'];
  CalendarPolicyStatusUpdate: Scalars['Boolean'];
  CalendarPolicyDelete: Scalars['Boolean'];
  OffRestDayDelete: Scalars['Boolean'];
  OffRestDayBulkInsert: Scalars['Boolean'];
  CalendarOffDayUpdate: Scalars['Boolean'];
  CalendarOffDayInsert: CalendarOffDayEntity;
  CalanderPolicySubmit: Scalars['Boolean'];
  CompanyHolidaysBulkInsert: Scalars['Boolean'];
  CompanyDocumentInsert: DocumentsEntity;
  CompanyDocumentDelete: Scalars['Boolean'];
  CompanyInsert: Scalars['Boolean'];
  CompanyUpdate: Scalars['Boolean'];
  CompanyLeaveSettingUpdate: Scalars['Boolean'];
  CompanyTaxReliefUpdate: Scalars['Boolean'];
  CompanyClaimSettingUpdate: Scalars['Boolean'];
  CompanyProjectExpenseClaimSettingUpdate: Scalars['Boolean'];
  CompanyOnlyUpdate: Scalars['Boolean'];
  JobDesignationInsert: Scalars['Boolean'];
  JobPositionInsert: Scalars['Boolean'];
  JobDesignationUpdate: Scalars['Boolean'];
  JobDesignationSequenceUpdate: Scalars['Boolean'];
  JobPositionUpdate: Scalars['Boolean'];
  JobDesignationDelete: Scalars['Boolean'];
  JobPositionDelete: Scalars['Boolean'];
  RecruitmentSourceBulkInsert: Scalars['Boolean'];
  RecruitmentSourceInsert: RecruitmentSourceEntity;
  RecruitmentSourceUpdate: Scalars['Boolean'];
  RecruitmentSourceDelete: Scalars['Boolean'];
  CompanyAltAddressInsert: Scalars['Boolean'];
  CompanyAltAddressUpdate: Scalars['Boolean'];
  CompanyAltAddressDelete: Scalars['Boolean'];
  CompanyStatutoryAccountUpdate: Scalars['Boolean'];
  CompanyStatutoryInsert: CompanyStatutoryEntity;
  CompanyStatutoryUpdate: Scalars['Boolean'];
  CompanyStructureInsert: Scalars['Boolean'];
  CompanyOrgUnitInsert: Scalars['Boolean'];
  CompanyOrgUnitUpdate: Scalars['Boolean'];
  AssignJobPosition: Scalars['Boolean'];
  CreateCompanyAccessPerm: Scalars['Boolean'];
  DepartmentInsert: DepartmentEntity;
  DepartmentUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  DivisionInsert: DepartmentEntity;
  DivisionUpdate: Scalars['Boolean'];
  DivisionDelete: Scalars['Boolean'];
  CreateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeInsert: Scalars['Boolean'];
  UpdateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeUpdate: Scalars['Boolean'];
  DeleteInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeDelete: Scalars['Boolean'];
  InterbankInsert: InterbankGiroEntity;
  InterbankBulkInsert: Scalars['Boolean'];
  InterbankDelete: Scalars['Boolean'];
  InterbankUpdate: Scalars['Boolean'];
  InterbankSingleUpdate: Scalars['Boolean'];
  JobAccountInsert: Scalars['Boolean'];
  JobAccountUpdate: Scalars['Boolean'];
  JobAccountDelete: Scalars['Boolean'];
  JobGradeBulkInsert: Scalars['Boolean'];
  JobGradeInsert: JobGradeEntity;
  JobGradeUpdate: Scalars['Boolean'];
  JobGradeDelete: Scalars['Boolean'];
  LeaveRoundingBulkInsert: Scalars['Boolean'];
  LeaveRoundingInsert: Scalars['Boolean'];
  UpdateLeaveRounding: Scalars['Boolean'];
  LeaveRoundingUpdate: Scalars['Boolean'];
  LeaveRoundingDelete: Scalars['Boolean'];
  LeaveCompulsoryPolicyBulkInsert: Scalars['Boolean'];
  LeaveCompulsoryPolicyUpdate: Scalars['Boolean'];
  LHDNReportInsert: Scalars['Boolean'];
  LHDNReportUpdate: Scalars['Boolean'];
  LHDNReportCopyInsert: Scalars['Boolean'];
  NotificationPolicyInsert: NotificationPolicyEntity;
  NotificationPolicyUpdate: Scalars['Boolean'];
  NotificationPolicyDelete: Scalars['Boolean'];
  NotificationPolicyConditionInsert: NotificationPolicyConditionEntity;
  NotificationPolicyConditionUpdate: Scalars['Boolean'];
  PayrollCycleInsert: PayrollCycleEntity;
  PayrollCycleUpdate: Scalars['Boolean'];
  PayrollCycleDelete: Scalars['Boolean'];
  ReportingGroupSubmit: Scalars['Boolean'];
  ReportingGroupDelete: Scalars['Boolean'];
  ReportingCompanyGroupDelete: Scalars['Boolean'];
  ReportingStructureGroupSubmit: Scalars['Boolean'];
  CreateSkillSet: Scalars['Boolean'];
  UpdateSkillSet: Scalars['Boolean'];
  DeleteSkillSet: Scalars['Boolean'];
  UserDefineBulkInsert: Scalars['Boolean'];
  PostOffboardingLeaveEncashment: Scalars['Boolean'];
  RevertOffboardingLeaveEncashment: Scalars['Boolean'];
  PostFromPlatformLeaveTypes: Scalars['String'];
  SubmitMyLeaveApplication: Scalars['Boolean'];
  PostLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplicationV2: LeaveHeaderEntity;
  DeleteLeaveApplication: Scalars['Boolean'];
  DeletePendingLeaveApplication: Scalars['Boolean'];
  DeleteApprovedLeaveApplication: Scalars['Boolean'];
  PostLeaveOpeningYear: Scalars['Boolean'];
  LeaveTypeInsert: LeaveTypeEntity;
  LeaveTypeUpdate: Scalars['Boolean'];
  LeaveTypeDelete: Scalars['Boolean'];
  LeaveReasonInsert: LeaveReasonEntity;
  LeaveReasonUpdate: Scalars['Boolean'];
  LeaveReasonDelete: Scalars['Boolean'];
  LeaveDiseaseInsert: LeaveDiseaseEntity;
  LeaveDiseaseUpdate: Scalars['Boolean'];
  LeaveDiseaseDelete: Scalars['Boolean'];
  LeaveJobGradePolicyInsert: Scalars['Boolean'];
  LeaveJobGradePolicyUpdate: Scalars['Boolean'];
  LeaveTypeConditionUpdate: Scalars['Boolean'];
  LeaveTypeConditionDelete: Scalars['Boolean'];
  LeavePolicyUpdate: Scalars['Boolean'];
  LeavePolicyDelete: Scalars['Boolean'];
  LeavePolicyInsert: Scalars['Boolean'];
  LeaveTypeTemplateInsert: Scalars['Boolean'];
  LeaveTypeTemplateUpdate: Scalars['Boolean'];
  LeaveTypeTemplateDelete: Scalars['Boolean'];
  UserDashboardControlSubmit: Scalars['Boolean'];
  DataChangesInsert: DataChangesEntity;
  SubmitDataChanges: Scalars['Boolean'];
  DeleteDataChanges: Scalars['Boolean'];
  CancelDataChanges: Scalars['Boolean'];
  ApproveDataChanges: Scalars['Boolean'];
  RejectDataChanges: Scalars['Boolean'];
  DataChangesApproval: Scalars['Boolean'];
  DataChangesReject: Scalars['Boolean'];
  ShopeeDataImportVerify: Scalars['Boolean'];
  ShopeeDataImportVerifyRedirect: Scalars['Boolean'];
  DataImportVerify: Scalars['Boolean'];
  DataImportVerifyRedirect: Scalars['Boolean'];
  RemoveTempData: Scalars['Boolean'];
  ProceedForImport: Scalars['Boolean'];
  ActivateEmployees: Scalars['Boolean'];
  ActivateEmployeesInBulk: Scalars['Boolean'];
  NewsDelete: Scalars['Boolean'];
  NewsInsert: Scalars['Boolean'];
  NewsUpdate: Scalars['Boolean'];
  NewsPublish: Scalars['Boolean'];
  NewsDuplicate: Scalars['Boolean'];
  UpdatePasswordInBulk: Scalars['Boolean'];
  NewDocumentNumberingInsert: Scalars['Boolean'];
  CompanyDocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingDelete: Scalars['Boolean'];
  DocumentNumberingUpdateStatus: Scalars['Boolean'];
  DocumentNumberAssignment: Scalars['Boolean'];
  BatchEntryByShiftInsert: Scalars['Boolean'];
  updateBatchEntryByShiftPattern: Scalars['Boolean'];
  CreateEmployeeAppraisal: Scalars['Boolean'];
  UpdateEmployeeAppraisal: Scalars['Boolean'];
  DeleteEmployeeAppraisal: Scalars['Boolean'];
  CareerLogInsert: Scalars['Boolean'];
  CareerLogUpdate: Scalars['Boolean'];
  CreateEmployeeDiary: Scalars['Boolean'];
  UpdateEmployeeDiary: Scalars['Boolean'];
  DeleteEmployeeDiary: Scalars['Boolean'];
  EmployeeInfoInsert: EmployeeEntity;
  EmployeeProfilePicUpdate: Scalars['Boolean'];
  EmployeeInfoUpdate: Scalars['Boolean'];
  EmployeeCareerLogUpdate: Scalars['Boolean'];
  EmployeeStatutoryInsert: EmployeeStatutoryEntity;
  EmployeeStatutoryUpdate: Scalars['Boolean'];
  EmployeePrevContributionInsert: EmployeePrevContributionEntity;
  EmployeePrevContributionUpdate: Scalars['Boolean'];
  EmployeeDependentInsert: EmployeeDependentsEntity;
  EmployeeDependentUpdate: Scalars['Boolean'];
  EmployeeDependentInsertByEmployee: EmployeeDependentsEntity;
  EmployeeDependentUpdateByEmployee: Scalars['Boolean'];
  EmployeeTaxReliefBulkInsert: Scalars['Boolean'];
  EmployeeTaxReliefBulkUpdate: Scalars['Boolean'];
  EmployeeAssetInsert: EmployeeAssetEntity;
  EmployeeAssetUpdate: Scalars['Boolean'];
  EmployeeAssetDelete: Scalars['Boolean'];
  EmployeeBenefitInKindInsert: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindUpdate: Scalars['Boolean'];
  EmployeeBenefitInKindDelete: Scalars['Boolean'];
  EmployeeDependentDelete: Scalars['Boolean'];
  EmployeeSalaryInsert: Scalars['Boolean'];
  EmployeeSalaryUpdate: Scalars['Boolean'];
  EmployeeSalaryDelete: Scalars['Boolean'];
  EmployeeProfileUpload: EmployeeEntity;
  UpdateEmployeeLastLoginCompany: Scalars['Boolean'];
  EmployeeCP22Update: Scalars['Boolean'];
  CreateEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateEmployeeInsuranceScheme: Scalars['Boolean'];
  DeleteEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateNotificationReadStatus: Scalars['Boolean'];
  UpdateAllNotificationReadStatus: Scalars['Boolean'];
  UpdateBulkNotificationReadStatus: Scalars['Boolean'];
  NotificationReadStatusDelete: Scalars['Boolean'];
  CreateEmployeeTraining: Scalars['Boolean'];
  UpdateEmployeeTraining: Scalars['Boolean'];
  DeleteEmployeeTraining: Scalars['Boolean'];
  EmployeeUserDefineFieldsInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsUpdate: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailUpdate: Scalars['Boolean'];
  ZakatContributionInsert: Scalars['Boolean'];
  ZakatContributionUpdate: Scalars['Boolean'];
  ZakatContributionDelete: Scalars['Boolean'];
  CreateUpdateAppSecurity: Scalars['Boolean'];
  CreateUpdateAppSecurityAssignment: Scalars['Boolean'];
  DownloadDocuments: Scalars['JSON'];
  UploadEaDocuments: Scalars['Boolean'];
  UploadPayslipDocuments: Scalars['Boolean'];
  RemoveEonDocumentsByBatch: Scalars['Boolean'];
  HelpdeskSubjectSubmit: Scalars['Boolean'];
  HelpdeskSubjectAction: Scalars['Boolean'];
  SubmitHelpdeskTicket: Scalars['Boolean'];
  ReassignAssignee: Scalars['Boolean'];
  UpdateOpenDT?: Maybe<Scalars['Boolean']>;
  WithdrawOpenTicket: Scalars['Boolean'];
  AttendanceClocking: Scalars['JSON'];
  GetUserInfo: Scalars['JSON'];
  GetStaffAttendance: Scalars['JSON'];
  GetEmpWorkTime: Scalars['JSON'];
  BlockLeaveInsert: Scalars['Boolean'];
  BlockLeaveUpdate: Scalars['Boolean'];
  BlockLeaveStatusUpdate: Scalars['Boolean'];
  PostCarryForwardEncashment: Scalars['Boolean'];
  LeaveRecordsApproval: Scalars['Boolean'];
  LeaveRecordsInsert: LeaveHeaderEntity;
  LeaveRecordsUpdate: Scalars['Boolean'];
  LeaveAdjustmentInsert: Scalars['Boolean'];
  LeaveStatusUpdate: Scalars['Boolean'];
  LeaveAdjustmentUpdate: Scalars['Boolean'];
  LeaveAdjustmentDelete: Scalars['Boolean'];
  PostLeaveRecords: Scalars['Boolean'];
  RevertLeaveRecords: Scalars['Boolean'];
  SaveCompulsoryLeave: Scalars['Boolean'];
  SaveUpdateCompulsoryLeave: Scalars['Boolean'];
  DeleteCompulsoryLeave: Scalars['Boolean'];
  InsertLeaveCutOffPeriods: Scalars['Boolean'];
  SubmitYearEndCarryForward: Scalars['Boolean'];
  ReversePostedYECFRecords?: Maybe<Scalars['Boolean']>;
  ReversePostedLCFE: Scalars['Boolean'];
  LeaveTakenUpload?: Maybe<Array<Process_LeaveDetails_ImportEntity>>;
  LeaveTakenDelete: Scalars['Boolean'];
  LeaveTakenInsertActual: Scalars['Boolean'];
  LeaveTakenBulkInsert: Scalars['Boolean'];
  LeaveOutOfStateInsert: LeaveOutOfStateEntity;
  LeaveOufOfStateUpdate: Scalars['Boolean'];
  LeaveOutOfStateDelete: Scalars['Boolean'];
  AccountMappingInsert: Scalars['Boolean'];
  AccountMappingUpdate: Scalars['Boolean'];
  AccountMappingDelete: Scalars['Boolean'];
  AccountMappingAssignmentInsert: Scalars['Boolean'];
  AccountMappingAssignmentUpdate: Scalars['Boolean'];
  ClaimAccountMappingInsert: Scalars['Boolean'];
  ClaimAccountMappingUpdate: Scalars['Boolean'];
  ClaimAccountMappingDelete: Scalars['Boolean'];
  ClaimAccountAssignmentInsert: Scalars['Boolean'];
  ClaimAccountAssignmentUpdate: Scalars['Boolean'];
  CostMappingUpdate: Scalars['Boolean'];
  DeleteProcessedGL: Scalars['Boolean'];
  FlagGLData: Scalars['JSON'];
  UnflagGLData: Scalars['JSON'];
  HR365DocumentMigrations: Scalars['Boolean'];
  CreateMobileVersion: Scalars['Boolean'];
  MobileVersionDeleting: Scalars['Boolean'];
  MultiCurrencyUpdateStatus: Scalars['Boolean'];
  MultiCurrencyUpdateDelete: Scalars['Boolean'];
  MultiCurrencyInsert: Scalars['Boolean'];
  MultiCurrencyUpdate: Scalars['Boolean'];
  MultiCurrencySetupExcelUpload?: Maybe<Array<Process_Multicurrencysetup_ImportEntity>>;
  MultiCurrencyExcelDelete: Scalars['Boolean'];
  MultiCurrencyInsertActual: Scalars['Boolean'];
  PopulateAttendanceRecord: Scalars['Boolean'];
  OvertimeInsert: OvertimeEntity;
  OvertimeDraft: Scalars['Boolean'];
  OvertimeBatch: Scalars['Boolean'];
  OvertimeUpdate: Scalars['Boolean'];
  OvertimeDelete: Scalars['Boolean'];
  OvertimeExcelUpload?: Maybe<Array<Process_Overtime_ImportEntity>>;
  OvertimeExcelDelete: Scalars['Boolean'];
  OvertimeExcelInsertActual: Scalars['Boolean'];
  OvertimeRoundingInsert: OvertimeRoundingSettingEntity;
  OvertimeRoundingSettingUpdate: Scalars['Boolean'];
  OvertimeRoundingSettingDelete: Scalars['Boolean'];
  OvertimeTemplateInsert: OvertimeTemplateEntity;
  OvertimeTemplateUpdate: Scalars['Boolean'];
  OvertimeTemplateDelete: Scalars['Boolean'];
  SubmitAdvancePolicy: Scalars['Boolean'];
  DeleteAdvancePolicy: Scalars['Boolean'];
  AssignAdvancePolicy: Scalars['Boolean'];
  GenerateAdvanceProcessing: Array<AdvanceProcessingEntity>;
  PostUnpostAdvanceProcessing: Scalars['Boolean'];
  SubmitPayGroup: Scalars['Boolean'];
  PayGroupDelete: Scalars['Boolean'];
  AssignCompanyPayGroup: Scalars['Boolean'];
  UpdateStatutoryAdjustment: Scalars['Boolean'];
  DeleteStatutoryAdjustment: Scalars['Boolean'];
  DeletePayrollHistory: Scalars['Boolean'];
  DeletePayrollProcess: Scalars['Boolean'];
  UpdatePayrollCalendar: Scalars['Boolean'];
  GenerateDefaultOrderforAllPayPeriods: Scalars['Boolean'];
  UpdatePayrollProcessStatus: Scalars['Boolean'];
  UpdateMonthlyTransactionByFixedAllowance: Scalars['Boolean'];
  InsertPayPeriodListing: Scalars['Boolean'];
  InsertSubsequentPayPeriodListing: Array<PayPeriodEntity>;
  PayItemInsert: Scalars['Boolean'];
  PayItemUpdate: Scalars['Boolean'];
  PayItemConditionTemplateLibraryUpdate: Scalars['Boolean'];
  SalaryBatch: Scalars['Boolean'];
  PayItemDelete: Scalars['Boolean'];
  RecurringPayInsert: Scalars['Boolean'];
  RecurringPayUpdate: Scalars['Boolean'];
  RecurringPayDelete: Scalars['Boolean'];
  CP159Update: Scalars['Boolean'];
  OneTimePayDelete: Scalars['Boolean'];
  OneTimePayInsert: Scalars['Boolean'];
  OneTimePayUpdate: Scalars['Boolean'];
  PublishPaySlip: Scalars['Boolean'];
  PublishEA: Scalars['Boolean'];
  UnPublishEA: Scalars['Boolean'];
  DeleteMonthlyTrx: Scalars['Boolean'];
  FreezePayrollInsert: Scalars['Boolean'];
  EmployeeFreezePayrollDelete: Scalars['Boolean'];
  SubmitEmployeeKpi: Scalars['Boolean'];
  SaveEmployeeKpiInRepo: Scalars['Boolean'];
  EmployeeKpiRepoDelete: Scalars['Boolean'];
  KpiExerciseChangeSuperior: Scalars['Boolean'];
  KpiExerciseChangeStatus: Scalars['Boolean'];
  KpiExerciseSubmit: Scalars['Boolean'];
  KpiExerciseDelete: Scalars['Boolean'];
  KpiExerciseInitiateDraft: Scalars['Boolean'];
  KpiExerciseCopy: Scalars['Boolean'];
  KpiExerciseSetupSubmit: Scalars['Boolean'];
  KpiExerciseSetupCopy: Scalars['Boolean'];
  KpiExerciseSetupDelete: Scalars['Boolean'];
  KpiExerciseSetupGenerate: Scalars['Boolean'];
  OrganizationalGoalSubmit: Scalars['Boolean'];
  OrganizationalGoalDelete: Scalars['Boolean'];
  OrgKpiPlanningSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailsDelete: Scalars['Boolean'];
  PerformanceGroupMatrixSubmit: Scalars['Boolean'];
  PerformanceGroupMatrixDelete: Scalars['Boolean'];
  EmployeeReviewUpdateSetup: Scalars['Boolean'];
  CopyEmployeeReviewExercise: Scalars['Boolean'];
  EmployeeReviewFormStatusUpdate: Scalars['Boolean'];
  SubmitEmployeeReviewForm: Scalars['Boolean'];
  AddConversationLog: Scalars['Boolean'];
  PeerRegistrationSubmit: Scalars['Boolean'];
  ReviewExerciseSetupSubmit: Scalars['Boolean'];
  ReviewExerciseSetupCopy: Scalars['Boolean'];
  ReviewExerciseSetupDelete: Scalars['Boolean'];
  ReviewExerciseSetupGenerate: Scalars['Boolean'];
  PeriodicAllowanceTypeInput: PeriodicAllowanceTypeEntity;
  PeriodicAllowanceTypeUpdate: Scalars['Boolean'];
  PeriodicAllowanceTypeDelete: Scalars['Boolean'];
  PersonnelCertificateInsert: Scalars['Boolean'];
  PersonnelCertificateUpdate: Scalars['Boolean'];
  PersonnelCertificateDelete: Scalars['Boolean'];
  ContractInfoInsert: Scalars['Boolean'];
  ContractInfoUpdate: Scalars['Boolean'];
  ContractInfoEnd: Scalars['Boolean'];
  ContractInfoRenewal: Scalars['Boolean'];
  ReportDocDelete: Scalars['Boolean'];
  ContractInfoDelete: Scalars['Boolean'];
  PersonnelEducationInsert: Scalars['String'];
  PersonnelEducationUpdate: Scalars['Boolean'];
  PersonnelEducationDelete: Scalars['Boolean'];
  PersonnelHealthInfoInsert: Scalars['Boolean'];
  PersonnelHealthInfoUpdate: Scalars['Boolean'];
  MedicalRecordDelete: Scalars['Boolean'];
  PersonnelJobHistoryInsert: Scalars['Boolean'];
  PersonnelJobHistoryUpdate: Scalars['Boolean'];
  PersonnelJobHistoryInsertByEmployee: Scalars['String'];
  PersonnelJobHistoryUpdateByEmployee: Scalars['Boolean'];
  PersonnelJobHistoryDelete: Scalars['Boolean'];
  PersonnelReferenceInsert: Scalars['Boolean'];
  PersonnelReferenceUpdate: Scalars['Boolean'];
  PersonnelReferenceDelete: Scalars['Boolean'];
  UserDefineDetailBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeUpdate: Scalars['Boolean'];
  StaffConfirmationInsert: Scalars['Boolean'];
  StaffConfirmationUpdate: Scalars['Boolean'];
  StaffConfirmationCancelConfirmed: Scalars['Boolean'];
  PersonnelStaffExitCompanyInsert: Scalars['Boolean'];
  PersonnelStaffExitCompanyUpdate: Scalars['Boolean'];
  StaffExitCompanyCancel: Scalars['Boolean'];
  ProcessStaffMovement: Scalars['Boolean'];
  StaffPromotionDemotionInsert: Scalars['Boolean'];
  StaffPromotionDemotionUpdate: Scalars['Boolean'];
  StaffPromotionDemotionCancel: Scalars['Boolean'];
  StaffTransferInsert: Scalars['Boolean'];
  StaffTransferUpdate: Scalars['Boolean'];
  StaffTransferCancel: Scalars['Boolean'];
  DeleteProjectExpenseClaimRecords: Scalars['Boolean'];
  SubmitProjectExpenseClaimForApproval: Scalars['Boolean'];
  SubmitMyProjectClaim: ProjectExpenseClaimHeaderEntity;
  ApproveProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  PostPEClaimRecords?: Maybe<Scalars['Boolean']>;
  ReversePEClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedProjectExpenseClaimDetail?: Maybe<Scalars['Boolean']>;
  ReverseApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  ProjectExpenseTypeSubmit: ProjectExpenseTypeEntity;
  ProjectExpenseTypeDelete: Scalars['Boolean'];
  ProjectGLAccountMappingSubmit: Scalars['Boolean'];
  ProjectGLAccountMappingDelete: Scalars['Boolean'];
  ProjectAccountAssignmentInsert: Scalars['Boolean'];
  ProjectAccountAssignmentUpdate: Scalars['Boolean'];
  DeleteClaimProcessedGL: Scalars['Boolean'];
  ProjectMasterSubmit: ProjectMasterEntity;
  ProjectMasterDelete: Scalars['Boolean'];
  ProjectCostAllocationSubmit: Scalars['Boolean'];
  ProjectCostAllocationDelete: Scalars['Boolean'];
  SubmitApproverMaster: Scalars['Boolean'];
  ResendConfirmationEmail: Scalars['Boolean'];
  ApproverConfirmedEmailAction: Scalars['Boolean'];
  DeleteApproverMaster: Scalars['Boolean'];
  DeleteApproverEmployeeListing: Scalars['Boolean'];
  SubmitApproverAssignment: Scalars['Boolean'];
  SubmitDailyEntry: Scalars['Boolean'];
  DeleteDailyEntry: Scalars['Boolean'];
  SubmitProjectTimesheet: Scalars['Boolean'];
  SubmitProjectTimesheetApproval: Scalars['Boolean'];
  ProjectTimesheetReportForApproverSendEmail: Scalars['Boolean'];
  DeleteApprovedRequisition: Scalars['Boolean'];
  RequisitionManagementApprovalUpdate: Scalars['Boolean'];
  RequisitionInsert: RequisitionEntity;
  RequisitionUpdate: Scalars['Boolean'];
  RequisitionDelete: Scalars['Boolean'];
  RequisitionSubmit: Scalars['Boolean'];
  submitRequisitionType: Scalars['Boolean'];
  InvoiceRPA: Array<InvoiceRpaResult>;
  ClaimParameterInsert: ClaimParameterEntity;
  ClaimUserDefineFieldsInsert: Scalars['Boolean'];
  MileageTableInsert: Scalars['Boolean'];
  VehicleRateInsert: Scalars['Boolean'];
  ClaimUserDefineFieldsUpdate: Scalars['Boolean'];
  ClaimUserDefineFieldsDuplicate: Scalars['Boolean'];
  MileageTableUpdate: Scalars['Boolean'];
  MileageTableDelete: Scalars['Boolean'];
  ClaimParameterUpdate: Scalars['Boolean'];
  ClaimParameterDelete: Scalars['Boolean'];
  PersonnelParameterInsert: PersonnelParameterEntity;
  PersonnelParameterUpdate: Scalars['Boolean'];
  PersonnelParameterDelete: Scalars['Boolean'];
  ReportTemplateInsert: Scalars['Boolean'];
  ReportTemplateAssignment: Scalars['Boolean'];
  ReportTemplateInactive: Scalars['Boolean'];
  ReportTemplateUpdate: Scalars['Boolean'];
  submitStaffMovementRequest: Scalars['Boolean'];
  EAPartFInsert: Scalars['Boolean'];
  EAPartFUpdate: Scalars['Boolean'];
  EAPartFDelete: Scalars['Boolean'];
  EAPartFCopyInsert: Scalars['Boolean'];
  EISPolicyUpdate: Scalars['Boolean'];
  EPFPolicyUpdate: Scalars['Boolean'];
  FormItemRatingSubmit: Scalars['Boolean'];
  FormItemRatingDelete: Scalars['Boolean'];
  KpiCategorySubmit: Scalars['Boolean'];
  KpiCategoryDelete: Scalars['Boolean'];
  KpiPredefinedItemSubmit: Scalars['Boolean'];
  KpiPredefinedItemUpdateStatus: Scalars['Boolean'];
  KpiPredefinedItemDelete: Scalars['Boolean'];
  KpiPredefinedTemplateSubmit: Scalars['Boolean'];
  KpiPredefinedTemplateDelete: Scalars['Boolean'];
  ReviewFormTemplateSubmit: Scalars['Boolean'];
  ReviewFormTemplateDelete: Scalars['Boolean'];
  ReviewGradingTableSubmit: Scalars['Boolean'];
  ReviewGradingTableDelete: Scalars['Boolean'];
  ReviewGroupSubmit: Scalars['Boolean'];
  ReviewGroupDelete: Scalars['Boolean'];
  ReviewItemSubmit: Scalars['Boolean'];
  ReviewItemDelete: Scalars['Boolean'];
  ReviewPolicySubmit: Scalars['Boolean'];
  ReviewPolicyDelete: Scalars['Boolean'];
  ReviewProcessPolicySubmit: Scalars['Boolean'];
  ReviewProcessPolicyDelete: Scalars['Boolean'];
  ReviewTemplateSubmit: Scalars['Boolean'];
  ReviewTemplateDelete: Scalars['Boolean'];
  RewardGroupSubmit: Scalars['Boolean'];
  RewardGroupDelete: Scalars['Boolean'];
  PersonnelSecurityPolicySubmit: Scalars['Boolean'];
  HolidayBulkInsert: Scalars['Boolean'];
  HolidayBulkDelete: Scalars['Boolean'];
  HolidayBulkUpdate: Scalars['Boolean'];
  SocsoPolicyUpdate: Scalars['Boolean'];
  TaxReliefInsert: Scalars['Boolean'];
  TaxReliefUpdate: Scalars['Boolean'];
  TaxReliefDelete: Scalars['Boolean'];
  TaxReliefCopyInsert: Scalars['Boolean'];
  MonthlyAttendanceProcessing: Scalars['Boolean'];
  AttendanceProcessingRedirect: Scalars['Boolean'];
  AttendanceProcessing: Scalars['Boolean'];
  UpdateAdjustedValue: Scalars['Boolean'];
  DeleteAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateBeaconDevice: Scalars['Boolean'];
  createChangeShift: ChangeShiftEntity;
  updateChangeShift: Scalars['Boolean'];
  deleteChangeShift: Scalars['Boolean'];
  updateChangeShiftSubmit: Scalars['Boolean'];
  createDailyAllowance: Scalars['Boolean'];
  updateDailyAllowance: Scalars['Boolean'];
  deleteDailyAllowance: Scalars['Boolean'];
  createDeductionPolicy: DeductionPolicyEntity;
  updateDeductionPolicyStatus: Scalars['Boolean'];
  updateDeductionPolicy: Scalars['Boolean'];
  PostEarnedLeave: Scalars['Boolean'];
  ReverseEarnedLeave: Scalars['Boolean'];
  createJobGradePolicyMatrix: JobGradePolicyMatrixEntity;
  createMobileCheckIn: Scalars['Boolean'];
  updateMobileCheckIn: Scalars['Boolean'];
  deleteMobileCheckIn: Scalars['Boolean'];
  InsertUpdateLocationRadiusSetting: Scalars['Boolean'];
  createMonthlyAllowance: Scalars['Boolean'];
  updateMonthlyAllowance: Scalars['Boolean'];
  deleteMonthlyAllowance: Scalars['Boolean'];
  createOvertimeClaimTable: OvertimeClaimTableEntity;
  updateOvertimeClaimTableStatus: Scalars['Boolean'];
  OTReasonUpdate: Scalars['Boolean'];
  OTReasonDelete: Scalars['Boolean'];
  OTReasonStatus: Scalars['Boolean'];
  OTReasonInsert: OvertimeReasonEntity;
  OvertimePolicyInsert: Scalars['Boolean'];
  OvertimePolicyUpdate: Scalars['Boolean'];
  OvertimePolicyDelete: Scalars['Boolean'];
  createPeriodicDeduction: Scalars['Boolean'];
  updatePeriodicDeduction: Scalars['Boolean'];
  deletePeriodicDeduction: Scalars['Boolean'];
  createAllowanceDeduction: Scalars['Boolean'];
  updateAllowanceDeduction: Scalars['Boolean'];
  deleteAllowanceDeduction: Scalars['Boolean'];
  createOTRounding: Scalars['Boolean'];
  updateOTRounding: Scalars['Boolean'];
  deleteOTRounding: Scalars['Boolean'];
  ShiftSettingInsert: Scalars['Boolean'];
  ShiftSetupUpdate: Scalars['Boolean'];
  ShiftSetupDelete: Scalars['Boolean'];
  createShiftGroup: Scalars['Boolean'];
  updateShiftGroup: Scalars['Boolean'];
  updateShiftGroupStatus: Scalars['Boolean'];
  SubmitShiftGroupPattern: Scalars['Boolean'];
  DeleteShiftGroupPattern: Scalars['Boolean'];
  logShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  updateShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  deleteShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  createShiftPattern: Scalars['Boolean'];
  updateShiftPattern: Scalars['Boolean'];
  deleteShiftPattern: Scalars['Boolean'];
  UserDefineEPFPolicyUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyDelete: Scalars['Boolean'];
  UserDefineEPFPolicyConditionInsertOrUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyConditionDelete: Scalars['Boolean'];
  updateTemplateLibraryAssignment: TemplateLibraryAssignmentEntity;
  createTemplateLibraryAssignment: Scalars['Float'];
  OvertimeSettingUpdate: Scalars['Boolean'];
  replaceTemplateLibraryAssignment: Scalars['Float'];
  replaceTemplateLibraryAssignmentForTMSShiftGroup: Scalars['Float'];
  replaceTemplateLibraryAssignmentv2: Scalars['Float'];
  deleteTemplateLibraryAssignment: Scalars['Float'];
  ShiftGroupAssignment: Scalars['Float'];
  DailyTypeConditionUpdate: Scalars['Boolean'];
  DailyTypeConditionDelete: Scalars['Boolean'];
  DeductionTypeConditionUpdate: Scalars['Boolean'];
  DeductionTypeConditionDelete: Scalars['Boolean'];
  TMSTypeConditionUpdate: Scalars['Boolean'];
  TMSTypeConditionDelete: Scalars['Boolean'];
  TmsotConditionTemplateLibraryUpdate: Scalars['Boolean'];
  MonthlyTypeConditionUpdate: Scalars['Boolean'];
  MonthlyTypeConditionDelete: Scalars['Boolean'];
  TimeOffInsert: TimeOffEntity;
  TimeOffUpdate: Scalars['Boolean'];
  TimeOffDelete: Scalars['Boolean'];
  createTimeOff: Scalars['Boolean'];
  TimeOffReasonInput: TimeOffReasonEntity;
  TimeOffReasonUpdate: Scalars['Boolean'];
  TimeOffReasonDelete: Scalars['Boolean'];
  TimeOffSettingInsert: TimeOffSettingEntity;
  TimeOffSettingUpdate: Scalars['Boolean'];
  TimeOffSettingDelete: Scalars['Boolean'];
  TsAccountTypeInsert: Scalars['Boolean'];
  TsAccountTypeUpdate: Scalars['Boolean'];
  TsAccountTypeDelete: Scalars['Boolean'];
  TsAccountCodeInsert: Scalars['Boolean'];
  TsAccountCodeUpdate: Scalars['Boolean'];
  TsAccountCodeDelete: Scalars['Boolean'];
  StaffTimeSheetInsert: Scalars['Boolean'];
  StaffTimesheetDelete: Scalars['Boolean'];
  ToDoStatusUpdate: Scalars['Boolean'];
  createTrainingAttendanceRecord: TrainingAttendanceEntity;
  updateTrainingAttendanceRecord: Scalars['Float'];
  CompulsaryCourseByJobGradeSubmit: Scalars['Boolean'];
  CourseClassificationBulkInsert: Scalars['Boolean'];
  CourseClassificationDelete: Scalars['Boolean'];
  TrainingCourseSubmit: Scalars['Boolean'];
  TrainingCourseDelete: Scalars['Boolean'];
  AuthorisedTrainingProviderSubmit: Scalars['Boolean'];
  TrainingCostItemBulkInsert: Scalars['Boolean'];
  TrainingCostItemDelete: Scalars['Boolean'];
  TraineeEvalSubmit: Scalars['Boolean'];
  SupervisorEvalSubmit: Scalars['Boolean'];
  CourseEvalSubmit: Scalars['Boolean'];
  TrainerEvalSubmit: Scalars['Boolean'];
  ScheduleTrainingSubmit: Scalars['Boolean'];
  ScheduleTableSubmit: Scalars['Boolean'];
  ScheduleTrainingDelete: Scalars['Boolean'];
  ScheduleTrainingCancel: Scalars['Boolean'];
  RegisteredTraineeInsert: Scalars['Boolean'];
  RegisteredTraineeDelete: Scalars['Boolean'];
  TrainingBudgetInputBulkInsert: Scalars['Boolean'];
  TrainingCostSubmit: Scalars['Boolean'];
  CostAllocationSubmit: Scalars['Boolean'];
  HRDFClaimSubmit: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  AllowancePolicyInsert: AllowanceBenfitEntity;
  AllowancePolicyUpdate: Scalars['Boolean'];
  AllowanceEntitlementInsert: AllowanceItemEntity;
  AllowanceEntitlementUpdate: Scalars['Boolean'];
  UpdateLastProcess: Scalars['Boolean'];
  PayProcessInsert: Scalars['Boolean'];
  employeeAppLogin: EmployeeAppLoginResponse;
  createUser: UserEntity;
  genHashPassword: Scalars['JSON'];
  createSuperUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  RedirectDomainToken: Scalars['String'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  changeUserPassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  employeeLogout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  forgotEmployeePassword: Scalars['Boolean'];
  resetEmployeePassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  userActionStatus: Scalars['Boolean'];
  updateHelpdeskAssignee: Scalars['Boolean'];
  changeEmployeePassword: Scalars['Boolean'];
  UpdateLastLoginCompany: Scalars['Boolean'];
  UserProfilePicUpdate: Scalars['Boolean'];
  ResendInvitationById: Scalars['Boolean'];
};


export type MutationCreateDutyRosterArgs = {
  DutyRosterInput: DutyRosterInput;
};


export type MutationDutyRosterBatchArgs = {
  removeList?: Maybe<Array<DutyRosterInput>>;
  Input?: Maybe<Array<DutyRosterInput>>;
};


export type MutationShiftPatternAssignmentToEmployeeArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  ShiftPatternID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type MutationCreateBatchEntryByShiftPatternArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  Input: DutyRosterInput;
};


export type MutationDeleteDutyRosterByEmployeeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationDeleteDutyRosterForAllArgs = {
  CompanyID: Scalars['String'];
};


export type MutationNewsReadInsertArgs = {
  NewsReadInput: NewsReadInput;
};


export type MutationNotificationUpdateArgs = {
  input: NotificationInput;
};


export type MutationDeleteAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationCreateAttendanceRecordArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  input: AttendanceRecordInput;
};


export type MutationSubmitPairedAttandanceRecordArgs = {
  DataToDelete?: Maybe<Array<Scalars['String']>>;
  input: Array<AttendanceRecordPairedObj>;
};


export type MutationUpdateAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationDeleteTimeAttendanceRecordArgs = {
  AttendanceRecordID: Array<Scalars['String']>;
};


export type MutationAttendanceExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationAttendanceExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationAttendanceExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationAttendanceRecordBatchDeleteArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAttendanceRecordBatchArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAutomatedClockPairingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeList: Array<Scalars['String']>;
};


export type MutationDocumentInsertArgs = {
  IsStatic?: Maybe<Scalars['Boolean']>;
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateArgs = {
  DocumentsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateParentIdArgs = {
  DocumentID: Scalars['String'];
  ParentID: Scalars['String'];
};


export type MutationDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationEmployeeProfileInsertUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
};


export type MutationAppraisalCategoryInsertArgs = {
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryUpdateArgs = {
  AppraisalCategoryID: Scalars['String'];
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryDeleteArgs = {
  AppraisalCategoryID: Scalars['String'];
};


export type MutationAppraisalCategoryItemInsertArgs = {
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemUpdateArgs = {
  AppraisalCategoryItemID: Scalars['String'];
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemDeleteArgs = {
  AppraisalCategoryItemID: Scalars['String'];
};


export type MutationAppraisalPolicyInsertArgs = {
  input: AppraisalPolicyInput;
};


export type MutationAppraisalPolicyDeleteArgs = {
  AppraisalPolicyID: Scalars['String'];
};


export type MutationAppraisalTemplateByJobPositionSubmitArgs = {
  input: AppraisalTempleteByJobPositionHeaderInput;
};


export type MutationAppraisalScheduleSubmitArgs = {
  input: AppraisalScheduleHeaderInput;
};


export type MutationAppraisalCompanySettingSubmitArgs = {
  input: AppraisalCompanySettingInput;
};


export type MutationFinalRatingSubmitArgs = {
  input: FinalRatingHeaderInput;
};


export type MutationFinalRatingDeleteArgs = {
  FinalRatingHeaderID: Scalars['String'];
};


export type MutationScaleRatingSubmitArgs = {
  input: ScaleRatingHeaderInput;
};


export type MutationScaleRatingDeleteArgs = {
  ScaleRatingHeaderID: Scalars['String'];
};


export type MutationBonusSimulationSubmitArgs = {
  BonusSimulationInput: Array<BonusSimulationInput>;
};


export type MutationIncrementSimulationSubmitArgs = {
  IncrementSimulationInput: Array<IncrementSimulationInput>;
};


export type MutationSubmitAppraisalTempleteArgs = {
  input: AppraisalTempleteInput;
};


export type MutationSubmitAppraisalArgs = {
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DocList?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<Array<DocumentsInput>>;
  Input: EmployeeAppraisalHeaderInput;
};


export type MutationModerationSubmitArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  input: Array<ModerationRatingInput>;
};


export type MutationSubmitStaffKpiPlanningArgs = {
  LatestVersion?: Maybe<Scalars['String']>;
  Submmit_Type: Scalars['String'];
  Input: EmployeeKpiHeaderInput;
};


export type MutationStaffKpiPlannigDeleteArgs = {
  EmployeeKPIHeaderID: Scalars['String'];
};


export type MutationStaffPerformanceAnalysisSummarySubmitArgs = {
  input: Array<StaffPerformanceAnalysisSummaryInput>;
};


export type MutationApprovalPolicyInsertArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicyUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicySubmitArgs = {
  input: NewApprovalPolicyInput;
};


export type MutationApprovalPolicyDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationApprovalPolicyAssignmentUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyAssignmentInput;
};


export type MutationPostAttendanceRecordArgs = {
  input: AttendancePostInput;
};


export type MutationReverseAttendanceRecordArgs = {
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  AttendanceCalculationIDs: Array<Scalars['String']>;
};


export type MutationAttendancePeriodEditArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type MutationAttendanceProccessBulkDeleteArgs = {
  AttendanceCalculationID: Array<Scalars['String']>;
};


export type MutationSubmitManagementClaimRecordsArgs = {
  input: SubmitClaimRecordsInput;
};


export type MutationApproveClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveClaimRecordsInput;
};


export type MutationCancelClaimRecordsArgs = {
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type MutationDeleteApprovedClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  CancelRemark?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  input: ApproveClaimRecordsInput;
};


export type MutationReverseApprovedClaimRecordsArgs = {
  input: ApproveClaimRecordsInput;
};


export type MutationVerifyApprovedClaimDetailArgs = {
  input: VerifyApprovedClaimDetailInput;
};


export type MutationPostClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostClaimRecordsInput;
};


export type MutationReverseClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyClaimArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  workFlowStepInput?: Maybe<Array<WorkFlowStepInput>>;
  input: SubmitClaimRecordsInput;
};


export type MutationDeleteClaimRecordsArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitClaimForApprovalArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationDoUpdateClaimArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  input: ClaimHeaderInput;
  ClaimHeaderID: Scalars['String'];
};


export type MutationMyClaimInsertArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  ClaimHeaderInput: ClaimHeaderInput;
};


export type MutationClaimValidityUpdateArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  ClaimTrxDayLimit: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationClaimValidityInsertArgs = {
  ClaimSettingInput: ClaimSettingInput;
};


export type MutationClaimTypeInsertArgs = {
  input: ClaimTypeInput;
};


export type MutationClaimTypeUpdateArgs = {
  ClaimTypeID: Scalars['String'];
  input: ClaimTypeInput;
};


export type MutationClaimTypeDeleteArgs = {
  ClaimTypeID: Scalars['String'];
};


export type MutationClaimTypeTemplateInsertArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateUpdateArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateDeleteArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type MutationClaimTypeTemplateUpdateStatusArgs = {
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeConditionUpdateArgs = {
  ClaimTemplateInput: ClaimTemplateInput;
};


export type MutationClaimTypeConditionDeleteArgs = {
  ClaimTemplateID: Scalars['String'];
};


export type MutationClaimJobGradePolicyInsertArgs = {
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimPoolDeleteArgs = {
  ClaimPoolID: Scalars['String'];
};


export type MutationClaimPoolSubmitArgs = {
  ClaimPoolInput: ClaimPoolInput;
};


export type MutationInsertClaimCutOffPeriodArgs = {
  input: GenerateClaimCutOffPeriodInput;
};


export type MutationBankInsertArgs = {
  BankInfoInputs: BankInfoInputs;
  CompanyID: Scalars['String'];
};


export type MutationBankUpdateArgs = {
  BankInfoID: Scalars['String'];
  input: BankInfoInputs;
};


export type MutationBankDeleteArgs = {
  BankInfoID: Scalars['String'];
};


export type MutationCompanyOffDaysDeleteArgs = {
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateCompanyOffDaysStatusArgs = {
  Status: Status;
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateRestOffDayStatusArgs = {
  Status: Status;
  RestOffDayID: Array<Scalars['String']>;
};


export type MutationCompanyOffDaysBulkInsertArgs = {
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCalendarPolicyStatusUpdateArgs = {
  Status: Status;
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationCalendarPolicyDeleteArgs = {
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationOffRestDayDeleteArgs = {
  RecurringRestOffDayID: Array<Scalars['String']>;
};


export type MutationOffRestDayBulkInsertArgs = {
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
};


export type MutationCalendarOffDayUpdateArgs = {
  input: CalendarOffDayInput;
  CalendarOffDayID: Scalars['String'];
};


export type MutationCalendarOffDayInsertArgs = {
  input: CalendarOffDayInput;
};


export type MutationCalanderPolicySubmitArgs = {
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  CalendarPolicyInput?: Maybe<CalendarPolicyInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyHolidaysBulkInsertArgs = {
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyDocumentInsertArgs = {
  DocumentsInput: DocumentsInput;
};


export type MutationCompanyDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationCompanyInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyLeaveSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyTaxReliefUpdateArgs = {
  ExcludeTaxRelief: Scalars['Boolean'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyProjectExpenseClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyOnlyUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationJobDesignationInsertArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobPositionInsertArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationUpdateArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationSequenceUpdateArgs = {
  JobDesignationIDs: Array<Scalars['String']>;
};


export type MutationJobPositionUpdateArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationJobPositionDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationRecruitmentSourceBulkInsertArgs = {
  Input: Array<RecruitmentSourceBulkInput>;
};


export type MutationRecruitmentSourceInsertArgs = {
  input: RecruitmentSourceInput;
};


export type MutationRecruitmentSourceUpdateArgs = {
  input: RecruitmentSourceInput;
  RecruitmentSourceID: Scalars['String'];
};


export type MutationRecruitmentSourceDeleteArgs = {
  RecruitmentSourceID: Scalars['String'];
};


export type MutationCompanyAltAddressInsertArgs = {
  input: CompanyAltAddressInput;
};


export type MutationCompanyAltAddressUpdateArgs = {
  input: CompanyAltAddressInput;
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyAltAddressDeleteArgs = {
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyStatutoryAccountUpdateArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  CompanyStatutoryID: Scalars['String'];
  input: Array<CompanyStatutoryAccountInput>;
};


export type MutationCompanyStatutoryInsertArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
};


export type MutationCompanyStatutoryUpdateArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
  CompanyStatutoryID: Scalars['String'];
};


export type MutationCompanyStructureInsertArgs = {
  input: Array<CompanyHierarchyInput>;
};


export type MutationCompanyOrgUnitInsertArgs = {
  input: Array<CompanyOrgUnitInput>;
};


export type MutationCompanyOrgUnitUpdateArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  Inputs: Array<CompanyOrgUnitInput>;
};


export type MutationAssignJobPositionArgs = {
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  input: Array<JobPositionAssignmentInput>;
};


export type MutationCreateCompanyAccessPermArgs = {
  RoleID: Scalars['String'];
  CompanyID: Scalars['String'];
  input: Array<CompanyAccessPermInput>;
};


export type MutationDepartmentInsertArgs = {
  input: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDepartmentDeleteArgs = {
  DepartmentUpdateInput: DepartmentUpdateInput;
};


export type MutationDivisionInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDivisionUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDivisionDeleteArgs = {
  DepartmentID: Scalars['String'];
};


export type MutationCreateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
};


export type MutationInsuranceSchemeInsertArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
};


export type MutationUpdateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeUpdateArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationDeleteInsuranceSchemeArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeDeleteArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInterbankInsertArgs = {
  InterbankGiroInput: InterbankGiroInput;
};


export type MutationInterbankBulkInsertArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankDeleteArgs = {
  InterbankGiroID: Scalars['String'];
};


export type MutationInterbankUpdateArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankSingleUpdateArgs = {
  InterbankGiroInput: InterbankGiroInput;
  InterbankGiroID: Scalars['String'];
};


export type MutationJobAccountInsertArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountUpdateArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountDeleteArgs = {
  JobAccountID: Scalars['String'];
};


export type MutationJobGradeBulkInsertArgs = {
  input: Array<JobGradeBulkInsertInput>;
};


export type MutationJobGradeInsertArgs = {
  input: JobGradeInput;
};


export type MutationJobGradeUpdateArgs = {
  JobGradeID: Scalars['String'];
  input: JobGradeInput;
};


export type MutationJobGradeDeleteArgs = {
  JobGradeID: Scalars['String'];
};


export type MutationLeaveRoundingBulkInsertArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingInsertArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
};


export type MutationUpdateLeaveRoundingArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveRoundingUpdateArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingDeleteArgs = {
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveCompulsoryPolicyBulkInsertArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLeaveCompulsoryPolicyUpdateArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLhdnReportInsertArgs = {
  input: EaFormSetupInput;
};


export type MutationLhdnReportUpdateArgs = {
  input: EaFormSetupInput;
  EAFormSetupID: Scalars['String'];
};


export type MutationLhdnReportCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationNotificationPolicyInsertArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyUpdateArgs = {
  NotificationPolicyID: Scalars['String'];
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyDeleteArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyConditionInsertArgs = {
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationNotificationPolicyConditionUpdateArgs = {
  NotificationPolicyConditionID: Scalars['String'];
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationPayrollCycleInsertArgs = {
  input: PayrollCycleInput;
};


export type MutationPayrollCycleUpdateArgs = {
  PayrollCycleID: Scalars['String'];
  input: PayrollCycleInput;
};


export type MutationPayrollCycleDeleteArgs = {
  PayrollCycleID: Scalars['String'];
};


export type MutationReportingGroupSubmitArgs = {
  input: ReportingGroupInput;
};


export type MutationReportingGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingCompanyGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingStructureGroupSubmitArgs = {
  input: ReportingStructureGroupInput;
};


export type MutationCreateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
};


export type MutationUpdateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
  SkillSetID: Scalars['String'];
};


export type MutationDeleteSkillSetArgs = {
  SkillSetID: Scalars['String'];
};


export type MutationUserDefineBulkInsertArgs = {
  input: Array<UserDefineBulkInput>;
};


export type MutationPostOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationRevertOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationPostFromPlatformLeaveTypesArgs = {
  SubscriptionUserID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationPostLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationSubmitLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationSubmitLeaveApplicationV2Args = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeletePendingLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteApprovedLeaveApplicationArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  Remark: Scalars['String'];
  input: EmpLeaveDetailInput;
};


export type MutationPostLeaveOpeningYearArgs = {
  Year: Scalars['Float'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationLeaveTypeInsertArgs = {
  input: LeaveTypeInput;
};


export type MutationLeaveTypeUpdateArgs = {
  input: LeaveTypeInput;
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveTypeDeleteArgs = {
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveReasonInsertArgs = {
  input: LeaveReasonInput;
};


export type MutationLeaveReasonUpdateArgs = {
  input: LeaveReasonInput;
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveReasonDeleteArgs = {
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveDiseaseInsertArgs = {
  input: LeaveDiseaseInput;
};


export type MutationLeaveDiseaseUpdateArgs = {
  input: LeaveDiseaseInput;
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveDiseaseDeleteArgs = {
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveJobGradePolicyInsertArgs = {
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveTypeConditionUpdateArgs = {
  LeaveTemplateInput: LeaveTemplateInput;
};


export type MutationLeaveTypeConditionDeleteArgs = {
  LeaveTemplateID: Scalars['String'];
};


export type MutationLeavePolicyUpdateArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeavePolicyDeleteArgs = {
  LeavePolicyID: Scalars['String'];
};


export type MutationLeavePolicyInsertArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeaveTypeTemplateInsertArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateUpdateArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateDeleteArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type MutationUserDashboardControlSubmitArgs = {
  input: UserDashboardControlInput;
};


export type MutationDataChangesInsertArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelEducationInsertInput?: Maybe<PersonnelEducationInsertInput>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelCertificateInput?: Maybe<PersonnelCertificateInput>;
  DocumentsInput?: Maybe<DocumentsInput>;
  input: DataChangesInput;
};


export type MutationSubmitDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDeleteDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationCancelDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationApproveDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationRejectDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDataChangesApprovalArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationDataChangesRejectArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationShopeeDataImportVerifyArgs = {
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationShopeeDataImportVerifyRedirectArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationDataImportVerifyArgs = {
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationDataImportVerifyRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationRemoveTempDataArgs = {
  Type: Scalars['String'];
};


export type MutationProceedForImportArgs = {
  Type: Scalars['String'];
};


export type MutationActivateEmployeesArgs = {
  Emails: Array<Scalars['String']>;
  IsSelectAll: Scalars['Boolean'];
};


export type MutationActivateEmployeesInBulkArgs = {
  accountID: Scalars['String'];
};


export type MutationNewsDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
};


export type MutationNewsInsertArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsUpdateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentID: Scalars['String'];
  NewsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsPublishArgs = {
  PostStatus: PostStatus;
  NewsID: Scalars['String'];
};


export type MutationNewsDuplicateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  NewsID: Scalars['String'];
};


export type MutationNewDocumentNumberingInsertArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationCompanyDocumentNumberingUpdateArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationDocumentNumberingInsertArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingDeleteArgs = {
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberingUpdateStatusArgs = {
  DocStatus: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberAssignmentArgs = {
  input: Array<DocumentNumberAssignmentInput>;
};


export type MutationBatchEntryByShiftInsertArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  input: DutyRosterInput;
};


export type MutationUpdateBatchEntryByShiftPatternArgs = {
  BatchEntryByShiftPatternID: Scalars['String'];
  BatchEntryByShiftPatternInput: BatchEntryByShiftPatternInput;
};


export type MutationCreateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationUpdateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationDeleteEmployeeAppraisalArgs = {
  EmployeeAppraisalID: Scalars['String'];
};


export type MutationCareerLogInsertArgs = {
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCareerLogUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCreateEmployeeDiaryArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationUpdateEmployeeDiaryArgs = {
  DocumentID: Scalars['String'];
  EmployeeDiaryID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationDeleteEmployeeDiaryArgs = {
  EmployeeDiaryID: Scalars['String'];
};


export type MutationEmployeeInfoInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeInfoUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeCareerLogUpdateArgs = {
  EmployeeInput: EmployeeInput;
};


export type MutationEmployeeStatutoryInsertArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeeStatutoryUpdateArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeePrevContributionInsertArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeePrevContributionUpdateArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeeDependentInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentInsertByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeDependentUpdateByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeTaxReliefBulkInsertArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeTaxReliefBulkUpdateArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeAssetInsertArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
};


export type MutationEmployeeAssetUpdateArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeAssetDeleteArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindInsertArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
};


export type MutationEmployeeBenefitInKindUpdateArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindDeleteArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeDependentDeleteArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type MutationEmployeeSalaryInsertArgs = {
  Input: CareerLogInput;
};


export type MutationEmployeeSalaryUpdateArgs = {
  Input: CareerLogInput;
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeSalaryDeleteArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeProfileUploadArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
  DocumentID: Scalars['String'];
  EmployeeInput: EmployeeInput;
};


export type MutationUpdateEmployeeLastLoginCompanyArgs = {
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  LastestAccessedEntity: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeCp22UpdateArgs = {
  Input: Array<EmployeeCp22Input>;
};


export type MutationCreateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationUpdateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationDeleteEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationUpdateNotificationReadStatusArgs = {
  NotificationID: Scalars['String'];
};


export type MutationUpdateAllNotificationReadStatusArgs = {
  EmployeeID: Scalars['String'];
};


export type MutationUpdateBulkNotificationReadStatusArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationNotificationReadStatusDeleteArgs = {
  ID: Array<Scalars['String']>;
};


export type MutationCreateEmployeeTrainingArgs = {
  EmployeeTrainingInput: EmployeeTrainingInput;
};


export type MutationUpdateEmployeeTrainingArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeTrainingInput: EmployeeTrainingInput;
  EmployeeTrainingID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingArgs = {
  EmployeeTrainingID: Scalars['String'];
};


export type MutationEmployeeUserDefineFieldsInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsDetailInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationEmployeeUserDefineFieldsDetailUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationZakatContributionInsertArgs = {
  ZakatContributionInput: ZakatContributionInput;
};


export type MutationZakatContributionUpdateArgs = {
  input: ZakatContributionInput;
  ID: Scalars['String'];
};


export type MutationZakatContributionDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUpdateAppSecurityArgs = {
  permissionArr?: Maybe<Array<HrPermission>>;
  input: EmployeeAppSecurityInput;
};


export type MutationCreateUpdateAppSecurityAssignmentArgs = {
  input: EmployeeAppSecurityAssignmentInput;
};


export type MutationDownloadDocumentsArgs = {
  File: Scalars['String'];
};


export type MutationRemoveEonDocumentsByBatchArgs = {
  BatchDateTime: Scalars['String'];
  Type: Scalars['String'];
};


export type MutationHelpdeskSubjectSubmitArgs = {
  input: HelpdeskSubjectInput;
};


export type MutationHelpdeskSubjectActionArgs = {
  Action: Scalars['String'];
  SubjectID: Scalars['String'];
};


export type MutationSubmitHelpdeskTicketArgs = {
  mode: Scalars['String'];
  input: HelpdeskTicketInput;
  CompanyID: Scalars['String'];
};


export type MutationReassignAssigneeArgs = {
  Assignee: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  HelpdeskTicketID: Scalars['String'];
};


export type MutationUpdateOpenDtArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationWithdrawOpenTicketArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationAttendanceClockingArgs = {
  EmployeeCheckIn: Array<EmployeeCheckIn>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetUserInfoArgs = {
  FromDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetStaffAttendanceArgs = {
  ShiftDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetEmpWorkTimeArgs = {
  Refrence: Array<Scalars['JSON']>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationBlockLeaveInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  IsUpdateAttachment: Scalars['Boolean'];
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveStatusUpdateArgs = {
  Input: BlockLeaveInput;
};


export type MutationPostCarryForwardEncashmentArgs = {
  input: Array<EncashCarryForwardLeaveInput>;
};


export type MutationLeaveRecordsApprovalArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<LeaveApprovalUpdateInput>;
};


export type MutationLeaveRecordsInsertArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
};


export type MutationLeaveRecordsUpdateArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
  leaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentInsertArgs = {
  input: Array<LeaveHeaderInput>;
};


export type MutationLeaveStatusUpdateArgs = {
  ApprovalStatus: ApprovalStatus;
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationLeaveAdjustmentUpdateArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentDeleteArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationPostLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationRevertLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationSaveCompulsoryLeaveArgs = {
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationSaveUpdateCompulsoryLeaveArgs = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationDeleteCompulsoryLeaveArgs = {
  LeaveCompulsoryID: Scalars['String'];
};


export type MutationInsertLeaveCutOffPeriodsArgs = {
  input: GenerateLeaveCutOffPeriodInput;
};


export type MutationSubmitYearEndCarryForwardArgs = {
  leaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationReversePostedYecfRecordsArgs = {
  Year: Scalars['Float'];
  Input: Array<ReversePostedYecfInput>;
};


export type MutationReversePostedLcfeArgs = {
  Year: Scalars['Float'];
  Inputs: Array<ReversePostedLcfeInput>;
};


export type MutationLeaveTakenUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationLeaveTakenDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationLeaveTakenInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationLeaveTakenBulkInsertArgs = {
  LeaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationLeaveOutOfStateInsertArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOufOfStateUpdateArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOutOfStateDeleteArgs = {
  LeaveOutOfStateID: Scalars['String'];
};


export type MutationAccountMappingInsertArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingUpdateArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingDeleteArgs = {
  GLAccountHeaderID: Scalars['String'];
};


export type MutationAccountMappingAssignmentInsertArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationAccountMappingAssignmentUpdateArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationClaimAccountMappingInsertArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingUpdateArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingDeleteArgs = {
  ClaimAccountHeaderID: Scalars['String'];
};


export type MutationClaimAccountAssignmentInsertArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationClaimAccountAssignmentUpdateArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationCostMappingUpdateArgs = {
  input: Array<CostCenterMappingInput>;
};


export type MutationDeleteProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationFlagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationUnflagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationCreateMobileVersionArgs = {
  Android: Scalars['Boolean'];
  iOS: Scalars['Boolean'];
  Version: Scalars['String'];
};


export type MutationMobileVersionDeletingArgs = {
  MobileVersionID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateStatusArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateDeleteArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyInsertArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencySetupExcelUploadArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationMultiCurrencyExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationMultiCurrencyInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationPopulateAttendanceRecordArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type MutationOvertimeInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: OvertimeInput;
};


export type MutationOvertimeDraftArgs = {
  input: OvertimeInput;
};


export type MutationOvertimeBatchArgs = {
  Input: Array<OvertimeInput>;
};


export type MutationOvertimeUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<OvertimeInput>;
};


export type MutationOvertimeDeleteArgs = {
  OvertimeID: Scalars['String'];
};


export type MutationOvertimeExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationOvertimeExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationOvertimeExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationOvertimeRoundingInsertArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingUpdateArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingDeleteArgs = {
  OvertimeRoundingSettingID: Scalars['String'];
};


export type MutationOvertimeTemplateInsertArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateUpdateArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateDeleteArgs = {
  OvertimeTemplateID: Scalars['String'];
};


export type MutationSubmitAdvancePolicyArgs = {
  input: AdvancePolicyInput;
};


export type MutationDeleteAdvancePolicyArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type MutationAssignAdvancePolicyArgs = {
  AdvancePolicyIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationGenerateAdvanceProcessingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationPostUnpostAdvanceProcessingArgs = {
  IsPosting: Scalars['Boolean'];
  AdvanceProcessingIDs: Array<Scalars['String']>;
};


export type MutationSubmitPayGroupArgs = {
  input: PayGroupInput;
};


export type MutationPayGroupDeleteArgs = {
  PayGroupID: Scalars['String'];
};


export type MutationAssignCompanyPayGroupArgs = {
  input: PayGroupAssignmentInput;
};


export type MutationUpdateStatutoryAdjustmentArgs = {
  PayrollDetailInputs?: Maybe<Array<Scalars['JSON']>>;
  PayrollHeaderInput: Scalars['JSON'];
  StatutoryInput: StatutoryAdjustmentInput;
};


export type MutationDeleteStatutoryAdjustmentArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollHistoryArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollProcessArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdatePayrollCalendarArgs = {
  DeferredPayCycles?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  companyID: Scalars['String'];
};


export type MutationGenerateDefaultOrderforAllPayPeriodsArgs = {
  PayPeriodIDs: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUpdatePayrollProcessStatusArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  PayrollCycleStatus: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateMonthlyTransactionByFixedAllowanceArgs = {
  PayrollCycleID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationInsertPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationInsertSubsequentPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationPayItemInsertArgs = {
  condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
};


export type MutationPayItemUpdateArgs = {
  Condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
  PayItemID: Scalars['String'];
};


export type MutationPayItemConditionTemplateLibraryUpdateArgs = {
  Condition?: Maybe<PayItemConditionInput>;
};


export type MutationSalaryBatchArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<CareerLogInput>;
};


export type MutationPayItemDeleteArgs = {
  PayItemID: Scalars['String'];
};


export type MutationRecurringPayInsertArgs = {
  ExcelUpload: Scalars['Boolean'];
  DocumentsInput?: Maybe<DocumentsInput>;
  RecurringPayInput: Array<RecurringPayInput>;
};


export type MutationRecurringPayUpdateArgs = {
  input: RecurringPayInput;
  RecurringPayID: Scalars['String'];
};


export type MutationRecurringPayDeleteArgs = {
  RecurringPayID: Scalars['String'];
};


export type MutationCp159UpdateArgs = {
  CP159Input: Cp159Input;
};


export type MutationOneTimePayDeleteArgs = {
  OneTimePayID: Scalars['String'];
};


export type MutationOneTimePayInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationOneTimePayUpdateArgs = {
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationPublishPaySlipArgs = {
  PublishPaySlipID: Scalars['String'];
};


export type MutationPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationUnPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationDeleteMonthlyTrxArgs = {
  OneTimePayIDs: Array<Scalars['String']>;
};


export type MutationFreezePayrollInsertArgs = {
  input: FreezePayrollInput;
};


export type MutationEmployeeFreezePayrollDeleteArgs = {
  FreezePayrollID: Scalars['String'];
};


export type MutationSubmitEmployeeKpiArgs = {
  Comment?: Maybe<Scalars['String']>;
  IsSubmitByApprover?: Maybe<Scalars['Boolean']>;
  input: EmployeeKpiInput;
};


export type MutationSaveEmployeeKpiInRepoArgs = {
  input: EmployeeKpiRepositoryInput;
};


export type MutationEmployeeKpiRepoDeleteArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type MutationKpiExerciseChangeSuperiorArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseChangeStatusArgs = {
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSubmitArgs = {
  input: KpiExerciseDetailsEditInput;
};


export type MutationKpiExerciseDeleteArgs = {
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseInitiateDraftArgs = {
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type MutationKpiExerciseCopyArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSetupSubmitArgs = {
  input: KpiExerciseSetupInput;
};


export type MutationKpiExerciseSetupCopyArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  KpiExerciseID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupDeleteArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationOrganizationalGoalSubmitArgs = {
  input: OrganizationalGoalInput;
};


export type MutationOrganizationalGoalDeleteArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type MutationOrgKpiPlanningSubmitArgs = {
  input: OrgKpiPlanningInput;
};


export type MutationOrgKpiPlanningDetailSubmitArgs = {
  input: OrgKpiPlanningDetailsInput;
};


export type MutationOrgKpiPlanningDetailsDeleteArgs = {
  OrgKpiPlanningDetailsID: Scalars['String'];
};


export type MutationPerformanceGroupMatrixSubmitArgs = {
  input: PerformanceGroupMatrixInput;
};


export type MutationPerformanceGroupMatrixDeleteArgs = {
  PerformanceGroupMatrixID: Scalars['String'];
};


export type MutationEmployeeReviewUpdateSetupArgs = {
  input: EmployeeReviewSetupInput;
};


export type MutationCopyEmployeeReviewExerciseArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewerID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
};


export type MutationEmployeeReviewFormStatusUpdateArgs = {
  IsSendBack?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  EmployeeReviewIDs: Array<Scalars['String']>;
};


export type MutationSubmitEmployeeReviewFormArgs = {
  Comment?: Maybe<Scalars['String']>;
  input: ReviewFormSubmitInput;
};


export type MutationAddConversationLogArgs = {
  input: ReviewConversationLogInput;
};


export type MutationPeerRegistrationSubmitArgs = {
  input?: Maybe<Array<ReviewPeerRegistrationInput>>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupSubmitArgs = {
  input: ReviewExerciseSetupInput;
};


export type MutationReviewExerciseSetupCopyArgs = {
  KpiAsAtDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewExerciseID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupDeleteArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationPeriodicAllowanceTypeInputArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeUpdateArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeDeleteArgs = {
  PeriodicAllowanceTypeID: Scalars['String'];
};


export type MutationPersonnelCertificateInsertArgs = {
  Input: PersonnelCertificateInput;
};


export type MutationPersonnelCertificateUpdateArgs = {
  Input: PersonnelCertificateInput;
  PersonnelCertificateID: Scalars['String'];
};


export type MutationPersonnelCertificateDeleteArgs = {
  PersonnelCertificateID: Scalars['String'];
};


export type MutationContractInfoInsertArgs = {
  ContractInfoInput: ContractInfoInput;
};


export type MutationContractInfoUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  ContractInfoInput: ContractInfoInput;
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoEndArgs = {
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoRenewalArgs = {
  ContractInfoInput: ContractInfoInput;
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationReportDocDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationContractInfoDeleteArgs = {
  ContractInfoID: Scalars['String'];
};


export type MutationPersonnelEducationInsertArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
};


export type MutationPersonnelEducationUpdateArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelEducationDeleteArgs = {
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelHealthInfoInsertArgs = {
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
};


export type MutationPersonnelHealthInfoUpdateArgs = {
  AttachmentToRemove?: Maybe<Array<DocumentsInput>>;
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
  PersonnelHealthInfoID: Scalars['String'];
};


export type MutationMedicalRecordDeleteArgs = {
  MedicalRecordID: Scalars['String'];
};


export type MutationPersonnelJobHistoryInsertArgs = {
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryInsertByEmployeeArgs = {
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateByEmployeeArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryDeleteArgs = {
  PersonnelJobHistoryID: Scalars['String'];
};


export type MutationPersonnelReferenceInsertArgs = {
  Input: PersonnelReferenceInput;
};


export type MutationPersonnelReferenceUpdateArgs = {
  Input: PersonnelReferenceInput;
  PersonnelReferenceID: Scalars['String'];
};


export type MutationPersonnelReferenceDeleteArgs = {
  PersonnelReferenceID: Scalars['String'];
};


export type MutationUserDefineDetailBulkInsertArgs = {
  UserDefineDetailInput: Array<UserDefineDetailInput>;
};


export type MutationPersonnelInsuranceSchemeBulkInsertArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  PersonnelIDs: Array<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationPersonnelInsuranceSchemeUpdateArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationStaffConfirmationInsertArgs = {
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationUpdateArgs = {
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationCancelConfirmedArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationPersonnelStaffExitCompanyInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationPersonnelStaffExitCompanyUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffExitCompanyCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationProcessStaffMovementArgs = {
  DateToProcess: Scalars['DateTime'];
};


export type MutationStaffPromotionDemotionInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationStaffTransferInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationDeleteProjectExpenseClaimRecordsArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitProjectExpenseClaimForApprovalArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyProjectClaimArgs = {
  input: SubmitProjectClaimRecordsInput;
};


export type MutationApproveProjectExpenseClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationDeleteApprovedProjectExpenseClaimRecordsArgs = {
  Remark: Scalars['String'];
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationPostPeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostPeClaimRecordsInput;
};


export type MutationReversePeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationVerifyApprovedProjectExpenseClaimDetailArgs = {
  input: VerifyApprovedProjectExpenseClaimDetailInput;
};


export type MutationReverseApprovedProjectExpenseClaimRecordsArgs = {
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationProjectExpenseTypeSubmitArgs = {
  input: ProjectExpenseTypeInput;
};


export type MutationProjectExpenseTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectGlAccountMappingSubmitArgs = {
  DetailInput: Array<ProjectGlAccountMappingDetailInput>;
  HeaderInput: ProjectGlAccountMappingInput;
};


export type MutationProjectGlAccountMappingDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectAccountAssignmentInsertArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationProjectAccountAssignmentUpdateArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationDeleteClaimProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PeriodID: Scalars['String'];
};


export type MutationProjectMasterSubmitArgs = {
  input: ProjectMasterInput;
};


export type MutationProjectMasterDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectCostAllocationSubmitArgs = {
  ProjectCostAllocationDetailInput: Array<ProjectCostAllocationDetailInput>;
  ProjectCostAllocationInput: ProjectCostAllocationInput;
};


export type MutationProjectCostAllocationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitApproverMasterArgs = {
  accountName: Scalars['String'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverMasterInput;
};


export type MutationResendConfirmationEmailArgs = {
  accountName: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationApproverConfirmedEmailActionArgs = {
  token: Scalars['String'];
};


export type MutationDeleteApproverMasterArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationDeleteApproverEmployeeListingArgs = {
  ProjectTimesheetPeriodID: Scalars['String'];
};


export type MutationSubmitApproverAssignmentArgs = {
  selectAllEmployee: Scalars['Boolean'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverAssignmentInput;
};


export type MutationSubmitDailyEntryArgs = {
  input: ProjectTimesheetDailyEntryInput;
  ContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  mode: Scalars['String'];
};


export type MutationDeleteDailyEntryArgs = {
  ProjectTimesheetDailyEntryID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetArgs = {
  input: Array<ProjectTimesheetDailyEntryInput>;
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetApprovalArgs = {
  Remarks?: Maybe<Scalars['String']>;
  StatusToChange: WorkflowLogStatus;
  token: Scalars['String'];
};


export type MutationProjectTimesheetReportForApproverSendEmailArgs = {
  input: ProjectTimesheetReportForApproverInput;
  ReportURL?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};


export type MutationDeleteApprovedRequisitionArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: EmpRequisitionInput;
};


export type MutationRequisitionManagementApprovalUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<RequisitionUpdateInput>;
};


export type MutationRequisitionInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: RequisitionInput;
};


export type MutationRequisitionUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  RequisitionInput: RequisitionInput;
};


export type MutationRequisitionDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
};


export type MutationRequisitionSubmitArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type MutationSubmitRequisitionTypeArgs = {
  input: RequisitionTypeInput;
};


export type MutationInvoiceRpaArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationClaimParameterInsertArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsInsertArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
};


export type MutationMileageTableInsertArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
};


export type MutationVehicleRateInsertArgs = {
  ClaimVehicleRateInput: Array<ClaimVehicleRateInput>;
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsUpdateArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimUserDefineFieldsDuplicateArgs = {
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableUpdateArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimParameterUpdateArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimParameterDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationPersonnelParameterInsertArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterUpdateArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterDeleteArgs = {
  PersonnelParameterID: Scalars['String'];
};


export type MutationReportTemplateInsertArgs = {
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationReportTemplateAssignmentArgs = {
  DocumentsInput: DocumentsInput;
  CompanyID: Scalars['String'];
  input: ReportTemplateInput;
};


export type MutationReportTemplateInactiveArgs = {
  ReportTemplateID: Scalars['String'];
};


export type MutationReportTemplateUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationSubmitStaffMovementRequestArgs = {
  input: StaffMovementRequestInput;
};


export type MutationEaPartFInsertArgs = {
  input: EaPartFInput;
};


export type MutationEaPartFUpdateArgs = {
  EAPartFID: Scalars['String'];
  input: EaPartFInput;
};


export type MutationEaPartFDeleteArgs = {
  EAPartFID: Scalars['String'];
};


export type MutationEaPartFCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationEisPolicyUpdateArgs = {
  EISPolicyID: Scalars['String'];
  input: EisPolicyInput;
};


export type MutationEpfPolicyUpdateArgs = {
  EPFPolicyDetailInput: Array<EpfPolicyDetailInput>;
  EPFPolicyInput: EpfPolicyInput;
  EPFPolicyID: Scalars['String'];
};


export type MutationFormItemRatingSubmitArgs = {
  input: FormItemRatingInput;
};


export type MutationFormItemRatingDeleteArgs = {
  FormItemRatingID: Scalars['String'];
};


export type MutationKpiCategorySubmitArgs = {
  input: KpiCategoryInput;
};


export type MutationKpiCategoryDeleteArgs = {
  KpiCategoryID: Scalars['String'];
};


export type MutationKpiPredefinedItemSubmitArgs = {
  input: KpiPredefinedItemInput;
};


export type MutationKpiPredefinedItemUpdateStatusArgs = {
  input: KpiActiveInactiveInput;
};


export type MutationKpiPredefinedItemDeleteArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type MutationKpiPredefinedTemplateSubmitArgs = {
  input: KpiPredefinedTemplateInput;
};


export type MutationKpiPredefinedTemplateDeleteArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type MutationReviewFormTemplateSubmitArgs = {
  input: ReviewFormTemplateInput;
};


export type MutationReviewFormTemplateDeleteArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type MutationReviewGradingTableSubmitArgs = {
  input: ReviewGradingTableInput;
};


export type MutationReviewGradingTableDeleteArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type MutationReviewGroupSubmitArgs = {
  input: ReviewGroupInput;
};


export type MutationReviewGroupDeleteArgs = {
  ReviewGroupID: Scalars['String'];
};


export type MutationReviewItemSubmitArgs = {
  input: ReviewItemInput;
};


export type MutationReviewItemDeleteArgs = {
  ReviewItemID: Scalars['String'];
};


export type MutationReviewPolicySubmitArgs = {
  input: ReviewPolicyInput;
};


export type MutationReviewPolicyDeleteArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type MutationReviewProcessPolicySubmitArgs = {
  input: ReviewProcessPolicyInput;
};


export type MutationReviewProcessPolicyDeleteArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type MutationReviewTemplateSubmitArgs = {
  input: ReviewTemplateInput;
};


export type MutationReviewTemplateDeleteArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type MutationRewardGroupSubmitArgs = {
  input: RewardGroupInput;
};


export type MutationRewardGroupDeleteArgs = {
  RewardGroupID: Scalars['String'];
};


export type MutationPersonnelSecurityPolicySubmitArgs = {
  input: PersonnelSecurityPolicyInput;
};


export type MutationHolidayBulkInsertArgs = {
  HolidayInput: Array<HolidayInput>;
};


export type MutationHolidayBulkDeleteArgs = {
  HolidayID: Array<Scalars['String']>;
};


export type MutationHolidayBulkUpdateArgs = {
  HolidayID: Array<Scalars['String']>;
  HolidayInput: Array<HolidayInput>;
};


export type MutationSocsoPolicyUpdateArgs = {
  SocsoPolicyID: Scalars['String'];
  input: SocsoPolicyInput;
};


export type MutationTaxReliefInsertArgs = {
  input: TaxReliefInput;
};


export type MutationTaxReliefUpdateArgs = {
  TaxReliefID: Scalars['String'];
  input: TaxReliefInput;
};


export type MutationTaxReliefDeleteArgs = {
  TaxReliefID: Scalars['String'];
};


export type MutationTaxReliefCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationMonthlyAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationAttendanceProcessingRedirectArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationUpdateAdjustedValueArgs = {
  input: AttendanceCalculationInput;
};


export type MutationDeleteAutomatedClockDataImportArgs = {
  AutomatedClockDataImportID: Scalars['String'];
};


export type MutationCreateUpdateAutomatedClockDataImportArgs = {
  input: AutomatedClockDataImportInput;
};


export type MutationCreateUpdateBeaconDeviceArgs = {
  input: BluetoothBeaconDevicesInput;
};


export type MutationCreateChangeShiftArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ChangeShiftInput: ChangeShiftInput;
};


export type MutationUpdateChangeShiftArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  RejectReason?: Maybe<Scalars['String']>;
  CSStatus: Scalars['String'];
  Input: Array<ChangeShiftInput>;
};


export type MutationDeleteChangeShiftArgs = {
  ChangeShiftID: Scalars['String'];
};


export type MutationUpdateChangeShiftSubmitArgs = {
  input: ChangeShiftInput;
};


export type MutationCreateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
};


export type MutationUpdateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
  DailyAllowanceID: Scalars['String'];
};


export type MutationDeleteDailyAllowanceArgs = {
  DailyAllowanceID: Scalars['String'];
};


export type MutationCreateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
};


export type MutationUpdateDeductionPolicyStatusArgs = {
  Status: Status;
  DeductionPolicyID: Scalars['String'];
};


export type MutationUpdateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
  DeductionPolicyID: Scalars['String'];
};


export type MutationPostEarnedLeaveArgs = {
  Remarks?: Maybe<Scalars['String']>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationReverseEarnedLeaveArgs = {
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationCreateJobGradePolicyMatrixArgs = {
  input: JobGradePolicyMatrixInput;
};


export type MutationCreateMobileCheckInArgs = {
  input: MobileCheckInInput;
};


export type MutationUpdateMobileCheckInArgs = {
  input: MobileCheckInInput;
  MobileCheckInID: Scalars['String'];
};


export type MutationDeleteMobileCheckInArgs = {
  MobileCheckInID: Scalars['String'];
};


export type MutationInsertUpdateLocationRadiusSettingArgs = {
  MobileCheckInID?: Maybe<Scalars['String']>;
  Radius: Scalars['Float'];
};


export type MutationCreateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
};


export type MutationUpdateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationDeleteMonthlyAllowanceArgs = {
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationCreateOvertimeClaimTableArgs = {
  input: OvertimeClaimTableInput;
};


export type MutationUpdateOvertimeClaimTableStatusArgs = {
  Status: Status;
  OvertimeClaimTableID: Scalars['String'];
};


export type MutationOtReasonUpdateArgs = {
  OvertimeReasonID: Scalars['String'];
  Input: OvertimeReasonInput;
};


export type MutationOtReasonDeleteArgs = {
  OvertimeReasonID: Scalars['String'];
};


export type MutationOtReasonStatusArgs = {
  OvertimeReasonID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationOtReasonInsertArgs = {
  Input: OvertimeReasonInput;
};


export type MutationOvertimePolicyInsertArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyUpdateArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyDeleteArgs = {
  OvertimePolicyID: Scalars['String'];
};


export type MutationCreatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
};


export type MutationUpdatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
  PeriodicDeductionID: Scalars['String'];
};


export type MutationDeletePeriodicDeductionArgs = {
  PeriodicDeductionID: Scalars['String'];
};


export type MutationCreateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
};


export type MutationUpdateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
  AllowanceDeductionID: Scalars['String'];
};


export type MutationDeleteAllowanceDeductionArgs = {
  AllowanceDeductionID: Scalars['String'];
};


export type MutationCreateOtRoundingArgs = {
  input: OtRoundingInput;
};


export type MutationUpdateOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
  input: OtRoundingInput;
};


export type MutationDeleteOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
};


export type MutationShiftSettingInsertArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
};


export type MutationShiftSetupUpdateArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
  ShiftID: Scalars['String'];
};


export type MutationShiftSetupDeleteArgs = {
  ShiftID: Scalars['String'];
};


export type MutationCreateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
};


export type MutationUpdateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupID: Scalars['String'];
};


export type MutationUpdateShiftGroupStatusArgs = {
  Status: Status;
  ShiftGroupID: Scalars['String'];
};


export type MutationSubmitShiftGroupPatternArgs = {
  input: ShiftGroupPatternInput;
};


export type MutationDeleteShiftGroupPatternArgs = {
  ShiftGroupPatternID: Scalars['String'];
};


export type MutationLogShiftGroupEmployeeAssignmentHistoryArgs = {
  inputs: Array<ShiftGroupEmployeeAssignmentLogInput>;
};


export type MutationUpdateShiftGroupEmployeeAssignmentHistoryArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  AssignmentLogID: Scalars['String'];
};


export type MutationDeleteShiftGroupEmployeeAssignmentHistoryArgs = {
  AssignmentLogID: Scalars['String'];
};


export type MutationCreateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
};


export type MutationUpdateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternID: Scalars['String'];
};


export type MutationDeleteShiftPatternArgs = {
  ShiftPatternID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyUpdateArgs = {
  UserDefineEPFPolicyDetailInput: Array<UserDefineEpfPolicyDetailInput>;
  UserDefineEPFPolicyInput: UserDefineEpfPolicyInput;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
};


export type MutationUserDefineEpfPolicyDeleteArgs = {
  UserDefineEPFPolicyID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyConditionInsertOrUpdateArgs = {
  UserDefineEPFPolicyMatrixInput: UserDefineEpfPolicyMatrixInput;
};


export type MutationUserDefineEpfPolicyConditionDeleteArgs = {
  UserDefineEPFPolicyMatrixID: Scalars['String'];
};


export type MutationUpdateTemplateLibraryAssignmentArgs = {
  input: TemplateLibraryAssignmentInput;
};


export type MutationCreateTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationOvertimeSettingUpdateArgs = {
  CompanyInput?: Maybe<CompanyInput>;
  CompanyID?: Maybe<Scalars['String']>;
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentForTmsShiftGroupArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentv2Args = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationDeleteTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationShiftGroupAssignmentArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
};


export type MutationDailyTypeConditionUpdateArgs = {
  DailyTemplateInput: DailyTemplateInput;
};


export type MutationDailyTypeConditionDeleteArgs = {
  DailyTemplateID: Scalars['String'];
};


export type MutationDeductionTypeConditionUpdateArgs = {
  DeductionTemplateInput: DeductionTemplateInput;
};


export type MutationDeductionTypeConditionDeleteArgs = {
  DeductionTemplateID: Scalars['String'];
};


export type MutationTmsTypeConditionUpdateArgs = {
  TimeAttendanceTemplateInput: TimeAttendanceTemplateInput;
};


export type MutationTmsTypeConditionDeleteArgs = {
  TMSTemplateID: Scalars['String'];
};


export type MutationTmsotConditionTemplateLibraryUpdateArgs = {
  CompanyID: Scalars['String'];
  TMSOTConditionInput: Array<TmsotConditionInput>;
};


export type MutationMonthlyTypeConditionUpdateArgs = {
  MonthlyTemplateInput: MonthlyTemplateInput;
};


export type MutationMonthlyTypeConditionDeleteArgs = {
  MonthlyTemplateID: Scalars['String'];
};


export type MutationTimeOffInsertArgs = {
  input: TimeOffInput;
};


export type MutationTimeOffUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<TimeOffInput>;
};


export type MutationTimeOffDeleteArgs = {
  TimeOffID: Scalars['String'];
};


export type MutationCreateTimeOffArgs = {
  input: TimeOffPolicyInput;
};


export type MutationTimeOffReasonInputArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonUpdateArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonDeleteArgs = {
  TimeOffReasonID: Scalars['String'];
};


export type MutationTimeOffSettingInsertArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingUpdateArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingDeleteArgs = {
  TimeOffSettingID: Scalars['String'];
};


export type MutationTsAccountTypeInsertArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeUpdateArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTsAccountCodeInsertArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeUpdateArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationStaffTimeSheetInsertArgs = {
  StaffTimesheetInput: StaffTimesheetInput;
};


export type MutationStaffTimesheetDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationToDoStatusUpdateArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  DataChangesIDs?: Maybe<Array<TrxIDs>>;
  ProjectExpenseClaimHeaderIDs: Array<TrxIDs>;
  ChangeShiftIDs: Array<TrxIDs>;
  TimeOffIDs: Array<TrxIDs>;
  OvertimeIDs: Array<TrxIDs>;
  ClaimHeaderIDs: Array<TrxIDs>;
  RequisitionIDs: Array<TrxIDs>;
  LeaveHeaderIDs: Array<TrxIDs>;
  EmployeeID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationCreateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationUpdateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationCompulsaryCourseByJobGradeSubmitArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type MutationCourseClassificationBulkInsertArgs = {
  input: Array<CourseClassificationInput>;
};


export type MutationCourseClassificationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTrainingCourseSubmitArgs = {
  input: TrainingCourseInput;
};


export type MutationTrainingCourseDeleteArgs = {
  TrainingCourseID: Scalars['String'];
};


export type MutationAuthorisedTrainingProviderSubmitArgs = {
  input: AuthorisedTrainingProviderInput;
};


export type MutationTrainingCostItemBulkInsertArgs = {
  input: Array<TrainingCostItemInput>;
};


export type MutationTrainingCostItemDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTraineeEvalSubmitArgs = {
  input: TraineeEvalInput;
};


export type MutationSupervisorEvalSubmitArgs = {
  input: SupervisorEvalInput;
};


export type MutationCourseEvalSubmitArgs = {
  input: CourseEvalInput;
};


export type MutationTrainerEvalSubmitArgs = {
  input: TrainerEvalInput;
};


export type MutationScheduleTrainingSubmitArgs = {
  input: ScheduleTrainingInput;
};


export type MutationScheduleTableSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingScheduleTableInput>;
};


export type MutationScheduleTrainingDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationScheduleTrainingCancelArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationRegisteredTraineeInsertArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationRegisteredTraineeDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationTrainingBudgetInputBulkInsertArgs = {
  input: Array<TrainingBudgetInput>;
};


export type MutationTrainingCostSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingCostInput>;
};


export type MutationCostAllocationSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<CostAllocationInput>;
};


export type MutationHrdfClaimSubmitArgs = {
  input: HrdfClaimInput;
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationAllowancePolicyInsertArgs = {
  input: AllowanceBenefitInput;
};


export type MutationAllowancePolicyUpdateArgs = {
  AllowanceBenefitID: Scalars['String'];
  input: AllowanceBenefitInput;
};


export type MutationAllowanceEntitlementInsertArgs = {
  input: AllowanceItemInput;
};


export type MutationAllowanceEntitlementUpdateArgs = {
  AllowanceItemID: Scalars['String'];
  input: AllowanceItemInput;
};


export type MutationUpdateLastProcessArgs = {
  IsLastProcess: Scalars['Boolean'];
  PayrollCyclePeriodID: Scalars['String'];
};


export type MutationPayProcessInsertArgs = {
  input: PayrollDetailInput;
};


export type MutationEmployeeAppLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  accountName: Scalars['String'];
  input: UserInput;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  accountName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  companyID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationRedirectDomainTokenArgs = {
  UserID: Scalars['String'];
};


export type MutationLoginArgs = {
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationChangeUserPasswordArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationForgotEmployeePasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetEmployeePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationUnblockUserArgs = {
  accountID: Scalars['String'];
  input: PasswordInfo;
};


export type MutationUserActionStatusArgs = {
  accountID: Scalars['String'];
  input: ActionStatus;
};


export type MutationUpdateHelpdeskAssigneeArgs = {
  input: UpdateHelpdeskAssigneeInput;
};


export type MutationChangeEmployeePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationUpdateLastLoginCompanyArgs = {
  lastestAccessedEntity: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUserProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  UserID: Scalars['String'];
};


export type MutationResendInvitationByIdArgs = {
  data: Array<Scalars['JSON']>;
};

export type MyApplicationOutput = {
  __typename?: 'MyApplicationOutput';
  leave?: Maybe<LeaveAppOutput>;
  claim?: Maybe<ClaimAppOutput>;
  requisition?: Maybe<RequisitionAppOutput>;
};

export type MyClaimDetailOutput = {
  __typename?: 'MyClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<VerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
};

export type MyClaimHeaderDetails = {
  __typename?: 'MyClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
};

export type MyClaimListingOutput = {
  __typename?: 'MyClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  CountApprovedClaims?: Maybe<Scalars['Float']>;
  TotalApprovedClaimsAmount?: Maybe<Scalars['Float']>;
  ClaimRecords?: Maybe<Array<ClaimRecordsListing>>;
};

export type MyJobAccountEntity = InterfaceBase & {
  __typename?: 'MyJobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MyJobAccountID: Scalars['String'];
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  JobAccount: JobAccountEntity;
  Department: DepartmentEntity;
};

export type MyJobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveDetailOutput = InterfaceBase & {
  __typename?: 'MyLeaveDetailOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  DocumentFile?: Maybe<Scalars['String']>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovedByEmployeeID?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementList = {
  __typename?: 'MyLeaveEntitlementList';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementOuput = {
  __typename?: 'MyLeaveEntitlementOuput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Entitlement?: Maybe<Scalars['Float']>;
  AsToDateEnt?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type MyLeaveInsertInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveListingInput = {
  EmployeeID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypes?: Maybe<Array<Scalars['String']>>;
};

export type MyLeaveSummaryInput = {
  EmployeeID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type MyLeaveSummaryOutput = {
  __typename?: 'MyLeaveSummaryOutput';
  LeaveType: Scalars['String'];
  EntitledDay: Scalars['Float'];
  TakenDay: Scalars['Float'];
  BalanceDay: Scalars['Float'];
};

export type MyLeaveUpdateInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyPeClaimHeaderDetails = {
  __typename?: 'MyPEClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
};

export type MyProjectExpenseClaimDetailOutput = {
  __typename?: 'MyProjectExpenseClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<PeVerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PePostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type MyReviewActionOutput = {
  __typename?: 'MyReviewActionOutput';
  ID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseName: Scalars['String'];
};

export type MySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bruneian = 'Bruneian',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum NationalityType {
  Malaysian = 'Malaysian',
  NonMalaysian = 'Non_Malaysian'
}

export type NewApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type NewDocumentNumberingInsertInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type NewInterfaceBase = {
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
};

export type NewInterfaceBaseInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
};

export type NewReportingStructureEntity = NewInterfaceBase & {
  __typename?: 'NewReportingStructureEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_structure_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group?: Maybe<ReportingGroupEntity>;
  effective_date?: Maybe<Scalars['DateTime']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  subordinate_id?: Maybe<Scalars['String']>;
  subordinate?: Maybe<EmployeeEntity>;
  superior_id?: Maybe<Scalars['String']>;
  superior?: Maybe<EmployeeEntity>;
  reporting_type: ReportingType;
  is_data_import?: Maybe<Scalars['Boolean']>;
};

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom?: Maybe<Scalars['DateTime']>;
  ValidTo?: Maybe<Scalars['DateTime']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<PostStatus>;
  NewsRead: Array<NewsReadEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Contact?: Maybe<ContactEntity>;
  ContentUrl?: Maybe<Scalars['String']>;
  PublishedBy: Scalars['String'];
};

export type NewsInput = {
  NewsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom: Scalars['DateTime'];
  ValidTo?: Maybe<Scalars['DateTime']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  PostStatus?: Maybe<PostStatus>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentUrl?: Maybe<Scalars['String']>;
};

export type NewsReadEntity = InterfaceBase & {
  __typename?: 'NewsReadEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsReadID: Scalars['String'];
  NewsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  News: NewsEntity;
};

export type NewsReadInput = {
  NewsID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationEntity = InterfaceBase & {
  __typename?: 'NotificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  ShowNotification?: Maybe<Scalars['Boolean']>;
  Company: CompanyEntity;
  FromEmployee?: Maybe<EmployeeEntity>;
  ToEmployee?: Maybe<EmployeeEntity>;
};

export type NotificationInput = {
  NotificationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShowNotification?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyClaimEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
};

export type NotificationPolicyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyConditionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyConditionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyConditionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyID: Scalars['String'];
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType: NotificationPolicyType;
  PolicyType: PolicyType;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimEntity>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveEntity>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionEntity>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionEntity>>;
};

export type NotificationPolicyInput = {
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType?: Maybe<NotificationPolicyType>;
  PolicyType?: Maybe<PolicyType>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimInput>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveInput>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionInput>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionInput>>;
};

export type NotificationPolicyLastUpdatedOutput = {
  __typename?: 'NotificationPolicyLastUpdatedOutput';
  NotificationEmploymentLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLeaveLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationClaimLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationRequisitionLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationEmploymentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationLeaveIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationClaimIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationRequisitionIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyLeaveEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
};

export type NotificationPolicyLeaveInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyRequisitionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyRequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyRequisitionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType: RequisitionType;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyRequisitionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum NotificationPolicyType {
  EmploymentStatus = 'EmploymentStatus',
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition'
}

export enum NotificationType {
  News = 'News',
  Approval = 'Approval',
  SelfNotification = 'Self_Notification'
}

export type OtClaimEntitlement = {
  __typename?: 'OTClaimEntitlement';
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementList>>;
};

export type OtClaimEntitlementInput = {
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementListInput>>;
};

export type OtClaimEntitlementList = {
  __typename?: 'OTClaimEntitlementList';
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export type OtClaimEntitlementListInput = {
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export enum OtClaimTableType {
  OtClaims = 'OT_CLAIMS',
  ReplacementLeave = 'REPLACEMENT_LEAVE'
}

export enum OtEntitlementType {
  ActualHour = 'ACTUAL_HOUR',
  MaxHours = 'MAX_HOURS'
}

export type OtProcessingOutput = {
  __typename?: 'OTProcessingOutput';
  WorkDate?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type OtRoundingEntity = InterfaceBase & {
  __typename?: 'OTRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OTRoundingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type OtRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OTRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
};

export type OTconditionList = {
  Condition?: Maybe<Scalars['String']>;
  totalOTMinutes?: Maybe<Scalars['String']>;
  DeductOTMinutes?: Maybe<Scalars['String']>;
  MinimumPay?: Maybe<Scalars['String']>;
};

export enum Occurrence {
  CalendarYear = 'Calendar_Year',
  EmploymentPeriod = 'Employment_Period'
}

export type OneTimePayEntity = InterfaceBase & {
  __typename?: 'OneTimePayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OneTimePayID: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  RecordSource?: Maybe<RecordSource>;
  ExcelFileName?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Employee?: Maybe<EmployeeEntity>;
  User?: Maybe<UserEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type OneTimePayInput = {
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type OneTimePayItemCalculatioOutput = {
  __typename?: 'OneTimePayItemCalculatioOutput';
  TotalOneTimeTax: Scalars['Float'];
  TotalOneTimeAddRemuneration: Scalars['Float'];
  TotalOneTimeEPF: Scalars['Float'];
  TotalOneTimeEPFVE: Scalars['Float'];
  EPFEmployeeToBeMinus: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPF: Scalars['Float'];
  TotalForcastedNormalRemunerationEPF: Scalars['Float'];
  TotalOneTimeMonthlyWageNumeration: Scalars['Float'];
  TotalOneTimeAddRemunerationEPF: Scalars['Float'];
  TotalOneTimeEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFVEEmployee: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployee: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFEmployer: Scalars['Float'];
  TotalOneTimeEPFVEEmployer: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployer: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeSocso: Scalars['Float'];
  TotalOneTimeZakat: Scalars['Float'];
  TotalOneTimeEIS: Scalars['Float'];
  TotalOneTimeBonus: Scalars['Float'];
  TotalOneTimeHRDF: Scalars['Float'];
  TotalOneTimeBonusEPF: Scalars['Float'];
  TotalOneTimeBonusEPFEmployee: Scalars['Float'];
  TotalOneTimeBonusEPFEmployer: Scalars['Float'];
  TotalCountOneTimeAddRemuneration: Scalars['Float'];
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
};

export type OneTimePayItemOutput = {
  __typename?: 'OneTimePayItemOutput';
  PayItemID: Scalars['String'];
  PayItemType?: Maybe<PayItemType>;
  PayName: Scalars['String'];
  PayCode: Scalars['String'];
  Calculation: Scalars['String'];
  Formula: Scalars['String'];
  Quantity: Scalars['Float'];
  Amount: Scalars['Float'];
  ActualAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TotalAddRemuneration: Scalars['Float'];
  ExemptNormalRemunerationAmount: Scalars['Float'];
  ExemptAdditionalRemunerationAmount: Scalars['Float'];
  AddRemunerationAmount: Scalars['Float'];
  EPFAmount: Scalars['Float'];
  EPFVEAmount: Scalars['Float'];
  SocsoAmount: Scalars['Float'];
  EISAmount: Scalars['Float'];
  BonusAmount: Scalars['Float'];
  HRDFAmount: Scalars['Float'];
  ZakatAmount: Scalars['Float'];
  EPFEmployee: Scalars['Boolean'];
  EPFEmployer: Scalars['Boolean'];
  EPFAmountEmployee: Scalars['Float'];
  EPFAmountEmployer: Scalars['Float'];
  EPFVEAmountEmployee: Scalars['Float'];
  EPFVEAmountEmployer: Scalars['Float'];
};

export type OrgKpiPlanningDetailsEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  OrgGoal?: Maybe<OrganizationalGoalEntity>;
};

export type OrgKpiPlanningDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
  Owner?: Maybe<EmployeeEntity>;
  TotalGoals: Scalars['Float'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
};

export type OrganizationalGoalEntity = InterfaceBase & {
  __typename?: 'OrganizationalGoalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  InUse: Scalars['Boolean'];
};

export type OrganizationalGoalInput = {
  ID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OvertimeCalculationItemOutput = {
  __typename?: 'OvertimeCalculationItemOutput';
  OTData?: Maybe<Scalars['JSON']>;
};

export type OvertimeClaimTableEntity = InterfaceBase & {
  __typename?: 'OvertimeClaimTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeClaimTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  OTClaimTableType?: Maybe<OtClaimTableType>;
  OTClaimData?: Maybe<Scalars['JSON']>;
  ReplacementLeaveData?: Maybe<Scalars['JSON']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeClaimTableInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTClaimData?: Maybe<Array<OtClaimEntitlementInput>>;
  ReplacementLeaveData?: Maybe<Array<ReplacementLeaveClaimTypeInput>>;
  OTClaimTableType: OtClaimTableType;
};

export type OvertimeDetails = {
  __typename?: 'OvertimeDetails';
  ApprovalStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type OvertimeEntity = InterfaceBase & {
  __typename?: 'OvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus: OvertimeStatus;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  Duration?: Maybe<Scalars['Float']>;
  otReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  OvertimeHistory?: Maybe<Array<OvertimeEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
};

export type OvertimeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type OvertimeOutput = {
  __typename?: 'OvertimeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Record?: Maybe<Scalars['String']>;
};

export type OvertimePolicyEntity = InterfaceBase & {
  __typename?: 'OvertimePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  OvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  OTLimitDayType?: Maybe<Scalars['JSON']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  otRounding?: Maybe<OtRoundingEntity>;
};

export type OvertimePolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  OTLimitDayType?: Maybe<Array<Scalars['JSON']>>;
};

export type OvertimeReasonEntity = InterfaceBase & {
  __typename?: 'OvertimeReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeReasonInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingEntity = InterfaceBase & {
  __typename?: 'OvertimeRoundingSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeRoundingSettingID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingInput = {
  SubscriptionAccountID: Scalars['String'];
  OvertimeRoundingSettingID: Scalars['String'];
  Name: Scalars['String'];
};

export enum OvertimeStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type OvertimeTemplateEntity = InterfaceBase & {
  __typename?: 'OvertimeTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeTemplateID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type OvertimeTemplateInput = {
  OvertimeTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type PcbCalculationInput = {
  EmployeeID: Scalars['String'];
  AccumulatedGrossTaxable: Scalars['Float'];
  AccumulatedEPF: Scalars['Float'];
  AccumulatedPCB: Scalars['Float'];
  AccumulatedZakat: Scalars['Float'];
};

export type PeApprovedClaimDetail = {
  __typename?: 'PEApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type PeClaimDuplicateList = {
  __typename?: 'PEClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export type PeClaimPostedListingOutput = {
  __typename?: 'PEClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PeClaimPostingListingOutput = {
  __typename?: 'PEClaimPostingListingOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<PeClaimPostedListingOutput>;
};

export type PeClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type PeClaimPostingPeriodListingOutput = {
  __typename?: 'PEClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<PeClaimPostingPeriodStatus>>;
};

export type PeClaimPostingPeriodStatus = {
  __typename?: 'PEClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type PeClaimRecordsDetailHeaderOutput = {
  __typename?: 'PEClaimRecordsDetailHeaderOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Project?: Maybe<Array<ProjectMasterEntity>>;
};

export type PeglClaimRecordsDetail = {
  __typename?: 'PEGLClaimRecordsDetail';
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type PePostedClaimDetail = {
  __typename?: 'PEPostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export type PeVerifiedClaimDetail = {
  __typename?: 'PEVerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export enum PayCategoryType {
  General = 'General',
  PayItem = 'Pay_Item'
}

export type PayGroupAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayGroupID: Scalars['String'];
  PayGroup?: Maybe<PayGroupEntity>;
};

export type PayGroupAssignmentInput = {
  CompanyID: Scalars['String'];
  PayGroupIDs: Array<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayGroupEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  PayGroupAssignment?: Maybe<Array<PayGroupAssignmentEntity>>;
  InUse: Scalars['Boolean'];
};

export type PayGroupInput = {
  PayGroupID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayItemConditionEntity = InterfaceBase & {
  __typename?: 'PayItemConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
};

export type PayItemConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsList = {
  __typename?: 'PayItemEmployeeDetailsList';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsOutput = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEntity = {
  __typename?: 'PayItemEntity';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
};

export type PayItemFilterInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayItemGlEntity = InterfaceBase & {
  __typename?: 'PayItemGLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type PayItemInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
};

export type PayItemListingByTlaOutput = {
  __typename?: 'PayItemListingByTLAOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
  EmployeeIds?: Maybe<Array<Scalars['String']>>;
};

export type PayItemMapping = {
  __typename?: 'PayItemMapping';
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemMappingInput = {
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemOutput = {
  __typename?: 'PayItemOutput';
  TransactionID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type PayItemTransactionOutput = {
  __typename?: 'PayItemTransactionOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Employees?: Maybe<Array<EmployeeTransactionOutput>>;
  IsAllProcessed: Scalars['Boolean'];
};

export enum PayItemType {
  Earning = 'Earning',
  Deduction = 'Deduction',
  NonPayable = 'Non_Payable',
  Overtime = 'Overtime'
}

export type PayItemsWithAmountList = {
  __typename?: 'PayItemsWithAmountList';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsList>>;
};

export type PayItemsWithAmountOutput = {
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsOutput>>;
};

export type PayPeriodEntity = InterfaceBase & {
  __typename?: 'PayPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ActualWorkDays?: Maybe<Scalars['Float']>;
};

export type PayPeriodInput = {
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
};

export type PayPeriodSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PaymentMethod {
  Payroll = 'Payroll',
  BankTransfer = 'Bank_Transfer',
  Cash = 'Cash',
  Cheque = 'Cheque'
}

export type PayrollCalculationInput = {
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  IsLastProceed: Scalars['Boolean'];
  IsForecasted?: Maybe<Scalars['Boolean']>;
  SequenceNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  IsIncludedSocso?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SalaryInfo?: Maybe<Scalars['JSON']>;
  PenaltyBreakdownInfo?: Maybe<Scalars['JSON']>;
  ParcelSupplementaryInfo?: Maybe<Scalars['JSON']>;
};

export type PayrollCalculationItemOutput = {
  __typename?: 'PayrollCalculationItemOutput';
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
  accPreviousMonthOutput?: Maybe<AccumulatedPreviousMonthOutput>;
  AccCurrentMonthPreviousPayrollCycle?: Maybe<AccCurrentMonthPreviousPayrollCycleOutput>;
  EmployeeID?: Maybe<Scalars['String']>;
  NonResidentNonPayable?: Maybe<Scalars['Float']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  totalDu?: Maybe<Scalars['Float']>;
  totalSu?: Maybe<Scalars['Float']>;
  total100UnderAge?: Maybe<Scalars['Float']>;
  total50UnderAge?: Maybe<Scalars['Float']>;
  total100Certificate?: Maybe<Scalars['Float']>;
  total50Certificate?: Maybe<Scalars['Float']>;
  total100Diploma?: Maybe<Scalars['Float']>;
  total50Diploma?: Maybe<Scalars['Float']>;
  total100Disabled?: Maybe<Scalars['Float']>;
  total50Disabled?: Maybe<Scalars['Float']>;
  total100DisabledDiploma?: Maybe<Scalars['Float']>;
  total50DisabledDiploma?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ActualWorkday?: Maybe<Scalars['Float']>;
  RequiredWorkDay?: Maybe<Scalars['Float']>;
  EPFRoundingAmount?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFRate?: Maybe<Scalars['Float']>;
  EmployerEPFRate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  TotalChargableEPFEmployer?: Maybe<Scalars['Float']>;
  EmployeeEPF2?: Maybe<Scalars['Float']>;
  TaxableEPF?: Maybe<Scalars['Float']>;
  TaxableEPF2?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  TotalEmployerEPFRate?: Maybe<Scalars['Float']>;
  TotalEmployeeEPFRate?: Maybe<Scalars['Float']>;
  SocsoRequirement?: Maybe<Scalars['String']>;
  EISRequirement?: Maybe<Scalars['String']>;
  SocsoRule?: Maybe<Scalars['String']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax1?: Maybe<Scalars['Float']>;
  Tax2?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxZakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
  ContributeHRDF?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  SalaryType?: Maybe<Scalars['String']>;
  TaxBasicSalary?: Maybe<Scalars['Float']>;
  GrossSalary?: Maybe<Scalars['Float']>;
  TotalChargableTax?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  NetSalary?: Maybe<Scalars['Float']>;
  TotalEarningPayItem?: Maybe<Scalars['Float']>;
  TotalDeductionPayItem?: Maybe<Scalars['Float']>;
  TotalNonPayablePayItem?: Maybe<Scalars['Float']>;
  TotalBIKAmount?: Maybe<Scalars['Float']>;
  TotalVOLAAmount?: Maybe<Scalars['Float']>;
  TotalBIKVOLA?: Maybe<Scalars['Float']>;
  TotalChargableTax1?: Maybe<Scalars['Float']>;
  TotalChargableTax2?: Maybe<Scalars['Float']>;
  TotalChargableAddRemuneration?: Maybe<Scalars['Float']>;
  TotalChargableEPF?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployee?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployer?: Maybe<Scalars['Float']>;
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  CurrentMonthAdditionalRemunerationEPFEmployer?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPFEmployee?: Maybe<Scalars['Float']>;
  TotalChargableEPFVE?: Maybe<Scalars['Float']>;
  TotalChargableSocso?: Maybe<Scalars['Float']>;
  TotalChargableEIS?: Maybe<Scalars['Float']>;
  TotalChargableHRDF?: Maybe<Scalars['Float']>;
  TotalChargableBonus?: Maybe<Scalars['Float']>;
  TaxRelief?: Maybe<Scalars['Float']>;
  MTDCalc?: Maybe<MtdOutput>;
  CurrentMonthNormalRemunerationEPF?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPF?: Maybe<Scalars['Float']>;
  ProcessLog?: Maybe<Scalars['String']>;
  ContributeTax?: Maybe<Scalars['Boolean']>;
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['Float']>;
  NormalD2DLarge?: Maybe<Scalars['Float']>;
  NormalD2DBulky?: Maybe<Scalars['Float']>;
  PublicD2DSmall?: Maybe<Scalars['Float']>;
  PublicD2DLarge?: Maybe<Scalars['Float']>;
  PublicD2DBulky?: Maybe<Scalars['Float']>;
  CampaignD2DSmall?: Maybe<Scalars['Float']>;
  CampaignD2DLarge?: Maybe<Scalars['Float']>;
  CampaignD2DBulky?: Maybe<Scalars['Float']>;
  NormalBSCSmall?: Maybe<Scalars['Float']>;
  NormalBSCLarge?: Maybe<Scalars['Float']>;
  NormalBSCBulky?: Maybe<Scalars['Float']>;
  PublicBSCSmall?: Maybe<Scalars['Float']>;
  PublicBSCLarge?: Maybe<Scalars['Float']>;
  PublicBSCBulky?: Maybe<Scalars['Float']>;
  CampaignBSCSmall?: Maybe<Scalars['Float']>;
  CampaignBSCLarge?: Maybe<Scalars['Float']>;
  CampaignBSCBulky?: Maybe<Scalars['Float']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayGroupID?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<Scalars['String']>;
  StartPayPeriod: Scalars['DateTime'];
  EndPayPeriod: Scalars['DateTime'];
};

export type PayrollCalculationOutput = {
  __typename?: 'PayrollCalculationOutput';
  Result?: Maybe<Scalars['Boolean']>;
  TotalRecord?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Listing?: Maybe<Array<PayrollCalculationItemOutput>>;
  Entity?: Maybe<Array<PayrollHeaderEntity>>;
};

export type PayrollConditionListInput = {
  Formula: Scalars['JSON'];
  Criteria: Array<PayrollCriteriaListInput>;
  Description: Scalars['String'];
  UserLogs?: Maybe<Array<UserLogInput>>;
};

export type PayrollCriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['JSON']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['JSON']>;
  Description: Scalars['String'];
};

export type PayrollCycleEntity = InterfaceBase & {
  __typename?: 'PayrollCycleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  PayrollCyclePeriod?: Maybe<Array<PayrollCyclePeriodEntity>>;
};

export type PayrollCycleInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodEntity = InterfaceBase & {
  __typename?: 'PayrollCyclePeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCyclePeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsDeferred?: Maybe<Scalars['Boolean']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  IsProccess?: Maybe<Scalars['Boolean']>;
};

export type PayrollCyclePeriodInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsDeferred?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodListingOutput = {
  __typename?: 'PayrollCyclePeriodListingOutput';
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  RowNumber?: Maybe<Scalars['Float']>;
};

export type PayrollCycleSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PayrollCycleStatus {
  Calculated = 'Calculated',
  Locked = 'Locked',
  Unlock = 'Unlock',
  NoRecord = 'NoRecord'
}

export type PayrollDetailEntity = InterfaceBase & {
  __typename?: 'PayrollDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDetailID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage: Scalars['Float'];
  Amount: Scalars['String'];
  Quantity: Scalars['String'];
  ExemptNormalRemunerationAmount: Scalars['String'];
  ExemptAdditionalRemunerationAmount: Scalars['String'];
  TotalAmount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayItem: PayItemEntity;
};

export type PayrollDetailInput = {
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollFixedAllowanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayrollGlExportEntity = InterfaceBase & {
  __typename?: 'PayrollGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  GLCode?: Maybe<Scalars['String']>;
  FinancialYear?: Maybe<Scalars['String']>;
  DR?: Maybe<Scalars['String']>;
  CR?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  PayCycle?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
};

export type PayrollHeaderEntity = InterfaceBase & {
  __typename?: 'PayrollHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  TaxRelief: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  TotalChargableAddRemuneration: Scalars['String'];
  TotalChargableNormalRemunerationEPF: Scalars['String'];
  TotalChargableAdditionalRemunerationEPF: Scalars['String'];
  TotalChargableEPF: Scalars['String'];
  TotalChargableEPFVE: Scalars['String'];
  TotalChargableSocso: Scalars['String'];
  TotalChargableEIS: Scalars['String'];
  TotalChargableHRDF: Scalars['String'];
  TotalChargableBonus: Scalars['String'];
  EPFPaid: Scalars['String'];
  EPFPaidByAdditionalRemuneration: Scalars['String'];
  EPFPaidByNormalRemunerationPaid: Scalars['String'];
  EPFVEPaid: Scalars['String'];
  SOCSOPaid: Scalars['String'];
  EISPaid: Scalars['String'];
  ZAKATPaid: Scalars['String'];
  TAXPaid: Scalars['String'];
  TotalBIK: Scalars['String'];
  TotalVOLA: Scalars['String'];
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['String']>;
  NormalD2DLarge?: Maybe<Scalars['String']>;
  NormalD2DBulky?: Maybe<Scalars['String']>;
  PublicD2DSmall?: Maybe<Scalars['String']>;
  PublicD2DLarge?: Maybe<Scalars['String']>;
  PublicD2DBulky?: Maybe<Scalars['String']>;
  CampaignD2DSmall?: Maybe<Scalars['String']>;
  CampaignD2DLarge?: Maybe<Scalars['String']>;
  CampaignD2DBulky?: Maybe<Scalars['String']>;
  NormalBSCSmall?: Maybe<Scalars['String']>;
  NormalBSCLarge?: Maybe<Scalars['String']>;
  NormalBSCBulky?: Maybe<Scalars['String']>;
  PublicBSCSmall?: Maybe<Scalars['String']>;
  PublicBSCLarge?: Maybe<Scalars['String']>;
  PublicBSCBulky?: Maybe<Scalars['String']>;
  CampaignBSCSmall?: Maybe<Scalars['String']>;
  CampaignBSCLarge?: Maybe<Scalars['String']>;
  CampaignBSCBulky?: Maybe<Scalars['String']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayrollDetails: Array<PayrollDetailEntity>;
  PayGroupID?: Maybe<Scalars['String']>;
  StartPayPeriod?: Maybe<Scalars['DateTime']>;
  EndPayPeriod?: Maybe<Scalars['DateTime']>;
  Islocked?: Maybe<Scalars['Boolean']>;
};

export type PayrollHeaderInput = {
  EmployeeID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay: Scalars['String'];
  TotalGross: Scalars['String'];
  TotalDeduction: Scalars['String'];
  NormalRemn: Scalars['String'];
  AdditionalRemn: Scalars['String'];
  ChargeSOCSO: Scalars['String'];
  ChargeEPF: Scalars['String'];
  ChargeEIS: Scalars['String'];
  ChargeZAKAT: Scalars['String'];
  TaxRelief: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollHomeInfoLinesOutput = {
  __typename?: 'PayrollHomeInfoLinesOutput';
  OutstandingRecord?: Maybe<Scalars['Float']>;
  NewEmpPending?: Maybe<Scalars['Float']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  NoOfRecords?: Maybe<Scalars['Float']>;
  NoOfRecords1?: Maybe<Scalars['Float']>;
  TotalRecordsFixed?: Maybe<Scalars['Float']>;
  DeductionRecords?: Maybe<Scalars['Float']>;
  DeductionRecordsMonthly?: Maybe<Scalars['Float']>;
  EarningRecords?: Maybe<Scalars['Float']>;
  AllowanceRecordsMonthly?: Maybe<Scalars['Float']>;
  NonpayableRecords?: Maybe<Scalars['Float']>;
  NonpayableRecordsMonthly?: Maybe<Scalars['Float']>;
  OvertimeRecords?: Maybe<Scalars['Float']>;
  OvertimeRecordsMonthly?: Maybe<Scalars['Float']>;
  PayrollInitiateDate?: Maybe<Scalars['String']>;
  MthTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  PayrollFreeze?: Maybe<Scalars['Float']>;
  ProcessDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDate?: Maybe<Scalars['DateTime']>;
  PayrollGross?: Maybe<Scalars['Float']>;
  PayrollNetPay?: Maybe<Scalars['Float']>;
  ClosingDate?: Maybe<Scalars['DateTime']>;
  ClosingModifiedBy?: Maybe<Scalars['String']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  PublishedModifiedBy?: Maybe<Scalars['String']>;
  PublishedEADate?: Maybe<Scalars['DateTime']>;
  PublishedEAModifiedBy?: Maybe<Scalars['String']>;
  PCBDate?: Maybe<Scalars['DateTime']>;
  GLDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessEmployeeListingOutput = {
  __typename?: 'PayrollProcessEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  Status?: Maybe<Status>;
  IsChecked?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
};

export type PayrollProcessPayItemListingOutput = {
  __typename?: 'PayrollProcessPayItemListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  EarningPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  DeductionPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  NonPayablePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  OvertimePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
};

export type PayrollProcessPayItemOutput = {
  __typename?: 'PayrollProcessPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type PayrollProcessPayrollCycleListingOutput = {
  __typename?: 'PayrollProcessPayrollCycleListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  ShowDelete?: Maybe<Scalars['Boolean']>;
  TotalRecords?: Maybe<Scalars['Float']>;
  PayrollCyclePeriodID?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsProjectGLProcessed?: Maybe<Scalars['Boolean']>;
  LastProjectGLProcessedDate?: Maybe<Scalars['DateTime']>;
  IsPayrollGLProcessed?: Maybe<Scalars['Boolean']>;
  LastPayrollGLProcessedDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessPeriodListingOutput = {
  __typename?: 'PayrollProcessPeriodListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  TotalNetPay?: Maybe<Scalars['Float']>;
  TotalEmployee?: Maybe<Scalars['Float']>;
  TotalPayrollCyclePeriodSetting?: Maybe<Scalars['Float']>;
  LastPayrollGLProcess?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessPeriodListingStatusOutput = {
  __typename?: 'PayrollProcessPeriodListingStatusOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TotalLastProcess?: Maybe<Scalars['Float']>;
};

export type PayrollReportEntity = InterfaceBase & {
  __typename?: 'PayrollReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollReportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollData?: Maybe<Scalars['String']>;
};

export type PayrollReportOutput = {
  __typename?: 'PayrollReportOutput';
  PayrollReportID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
};

export type PayrollSnapshotEntity = InterfaceBase & {
  __typename?: 'PayrollSnapshotEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
};

export type PayrollSnapshotOutput = {
  __typename?: 'PayrollSnapshotOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
};

export type PayrollSummmaryListingOutput = {
  __typename?: 'PayrollSummmaryListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
};

export type PenaltyBreakdownInfo = {
  __typename?: 'PenaltyBreakdownInfo';
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PenaltyBreakdownInfoInput = {
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PerformanceGroupMatrixEntity = InterfaceBase & {
  __typename?: 'PerformanceGroupMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList: Array<Scalars['JSON']>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  RewardGroup?: Maybe<RewardGroupEntity>;
  TotalCriteria: Scalars['Float'];
  CriteriaDesc: Scalars['String'];
};

export type PerformanceGroupMatrixInput = {
  ID?: Maybe<Scalars['String']>;
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList?: Maybe<Array<ConditionListInput>>;
};

export enum PerformanceGroupType {
  Review = 'Review',
  Reward = 'Reward'
}

export type PeriodicAllowancePolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowancePolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  MonthlyAllowanceID: Scalars['String'];
  MonthlyAllowance?: Maybe<MonthlyAllowancePolicyEntity>;
};

export type PeriodicAllowancePolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  MonthlyAllowanceID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicAllowanceTypeEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowanceTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicAllowanceTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type PeriodicAllowanceTypeInput = {
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PeriodicClaimType {
  Allowance = 'Allowance',
  Deduction = 'Deduction'
}

export type PeriodicDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type PeriodicDeductionPolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PeriodicDeductionID: Scalars['String'];
  PeriodicDeduction?: Maybe<PeriodicDeductionPolicyEntity>;
};

export type PeriodicDeductionPolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodicDeductionID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicDeductionPolicyEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicDeductionPolicyCondition?: Maybe<Array<PeriodicDeductionPolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type PersonnelCertificateEntity = InterfaceBase & {
  __typename?: 'PersonnelCertificateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelCertificateID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelCertificateInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type PersonnelEFileListingOutput = {
  __typename?: 'PersonnelEFileListingOutput';
  Employee?: Maybe<EmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelEducationEntity = InterfaceBase & {
  __typename?: 'PersonnelEducationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelEducationID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelEducationInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelHealthInfoEntity = InterfaceBase & {
  __typename?: 'PersonnelHealthInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecords?: Maybe<Array<MedicalRecordEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  VaccinationListSummary?: Maybe<Array<VaccinationListItemOutput>>;
};

export type PersonnelHealthInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
};

export type PersonnelHomeInfoLinesOutput = {
  __typename?: 'PersonnelHomeInfoLinesOutput';
  EmpBySubsTotal?: Maybe<Scalars['Float']>;
  EmpTotal?: Maybe<Scalars['Float']>;
  MthNewEmpTotal?: Maybe<Scalars['Float']>;
  OverdueConfTotal?: Maybe<Scalars['Float']>;
  UpcomingConfTotal?: Maybe<Scalars['Float']>;
  MthProbExtTotal?: Maybe<Scalars['Float']>;
  MthContractDocExpTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  MthDemoTotal?: Maybe<Scalars['Float']>;
  MthSecondTotal?: Maybe<Scalars['Float']>;
  MthRedesTotal?: Maybe<Scalars['Float']>;
  MthIntTransTotal?: Maybe<Scalars['Float']>;
  MthIntCompTransTotal?: Maybe<Scalars['Float']>;
  MthTerminationTotal?: Maybe<Scalars['Float']>;
  MthRetireTotal?: Maybe<Scalars['Float']>;
  MthOffBoardTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  DataChangesApplicationPendingTotal?: Maybe<Scalars['Float']>;
};

export type PersonnelJobHistoryEntity = InterfaceBase & {
  __typename?: 'PersonnelJobHistoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelJobHistoryID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<Address2>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelJobHistoryInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<AddressInput2>;
};

export type PersonnelParameterEntity = InterfaceBase & {
  __typename?: 'PersonnelParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
};

export type PersonnelParameterInput = {
  PersonnelParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PersonnelParameterType {
  ExitCompanyReason = 'Exit_Company_Reason',
  Salutation = 'Salutation',
  EducationLevel = 'Education_Level',
  Major = 'Major',
  Grade = 'Grade',
  StaffCategory = 'Staff_Category',
  Specialization = 'Specialization',
  Industry = 'Industry',
  DocumentType = 'Document_Type',
  VaccinationType = 'Vaccination_Type'
}

export type PersonnelReferenceEntity = InterfaceBase & {
  __typename?: 'PersonnelReferenceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelReferenceID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
};

export type PersonnelReferenceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};

export type PersonnelSecurityPolicyEntity = InterfaceBase & {
  __typename?: 'PersonnelSecurityPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelSecurityPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export enum PersonnelSecurityPolicyOption {
  Default = 'Default',
  EnforceByCompany = 'Enforce_By_Company',
  EnforceByAccessPermission = 'Enforce_By_Access_Permission',
  HideNotInUseRecords = 'Hide_Not_In_Use_Records'
}

export type PersonnelTemplateLibraryAssignmentOutput = {
  __typename?: 'PersonnelTemplateLibraryAssignmentOutput';
  JobGrade?: Maybe<Array<JobGradeEntity>>;
  SkillSet?: Maybe<Array<SkillSetEntity>>;
  JobPosition?: Maybe<Array<JobDesignationEntity>>;
  InsuranceScheme?: Maybe<Array<InsuranceSchemeEntity>>;
  RecruitmentSource?: Maybe<Array<RecruitmentSourceEntity>>;
  UserDefine?: Maybe<Array<UserDefineEntity>>;
};

export type PlatformLeaveItemEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveItemID: Scalars['String'];
  PlatformLeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveFrequency: LeaveFrequency;
  CutOffDate: Scalars['DateTime'];
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay: Scalars['Float'];
  ResignCutOffDay: Scalars['Float'];
  MonthlyCutOffDay: Scalars['Float'];
  ShortNoticeType: ShortNoticeType;
  ShortNoticePeriod: Scalars['Float'];
  CFMethod: CfMethod;
  CFValue: Scalars['Float'];
  CFMonth: Scalars['Float'];
  IsOffDay: Scalars['Boolean'];
  IsRestDay: Scalars['Boolean'];
  IsPublicHoliday: Scalars['Boolean'];
  IsHalfDay: Scalars['Boolean'];
  IsAttachment: Scalars['Boolean'];
  IsRemarks: Scalars['Boolean'];
  IsRounding: Scalars['Boolean'];
  IsConfirmedStaff: Scalars['Boolean'];
};

export type PlatformLeaveItemInput = {
  PlatformLeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLeaveTypeEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveTypeID: Scalars['String'];
  IntegratedLeaveTypeID: Scalars['String'];
  LeaveTypeCode: Scalars['String'];
  Description: Scalars['String'];
};

export type PlatformLeaveTypeInput = {
  IntegratedLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type PolicyOutput = {
  __typename?: 'PolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export enum PolicyType {
  EmploymentConfirmation = 'EmploymentConfirmation',
  Transfer = 'Transfer',
  Exit = 'Exit',
  ShortNotice = 'ShortNotice',
  NoOfDayPerApplication = 'NoOfDayPerApplication',
  LeavePendingApproval = 'LeavePendingApproval',
  ClaimSubmissionAmount = 'ClaimSubmissionAmount',
  ClaimPendingApproval = 'ClaimPendingApproval',
  RequisitionAmount = 'RequisitionAmount',
  RequisitionPendingApproval = 'RequisitionPendingApproval'
}

export type PostClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PostPeClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export enum PostStatus {
  Draft = 'Draft',
  Posted = 'Posted'
}

export type PostedClaimDetail = {
  __typename?: 'PostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export type ProbationExtendedEmployeeOutput = {
  __typename?: 'ProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<CareerLogEntity>;
};

export type ProcessAttendanceEntity = {
  __typename?: 'ProcessAttendanceEntity';
  ProcessAttendanceID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IntergrationClockType?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  AttendanceRecord?: Maybe<AttendanceRecordEntity>;
};

export type ProjectAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
  ProjectAccountHeader?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimCcMappingListEntity = InterfaceBase & {
  __typename?: 'ProjectClaimCCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  PeriodID?: Maybe<Scalars['String']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  ClaimItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailList = {
  __typename?: 'ProjectClaimGLAccountDetailList';
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'ProjectClaimGLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderList = {
  __typename?: 'ProjectClaimGLAccountHeaderList';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimProcessingPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectClaimProcessingPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  CCMappingDetails: Array<ProjectClaimCcMappingListEntity>;
  AccountDetails: Array<ProjectClaimGlAccountDetailOutputEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationDetailEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectCostAllocationID: Scalars['String'];
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  ProjectCostAllocation?: Maybe<ProjectCostAllocationEntity>;
};

export type ProjectCostAllocationDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectCostAllocationID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
};

export type ProjectCostAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetailEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseClaimHeaderID: Scalars['String'];
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectExpenseClaimHeaderEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  ProjectExpenseType?: Maybe<ProjectExpenseTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseClaimDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetails = {
  __typename?: 'ProjectExpenseClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimDetailsListing?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseClaimGlExportEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  CostCenterName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  AccountDescription?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  FinancialYear?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ClaimDetails: Array<ProjectExpenseClaimDetailEntity>;
  Approver?: Maybe<EmployeeEntity>;
};

export type ProjectExpenseClaimHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ProjectExpenseClaimRecordsItemInput = {
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
};

export type ProjectExpenseClaimRecordsListing = {
  __typename?: 'ProjectExpenseClaimRecordsListing';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ProjectExpenseHistoryListingOutput = {
  __typename?: 'ProjectExpenseHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseToApproveListingOutput = {
  __typename?: 'ProjectExpenseToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  ClaimHistory?: Maybe<Array<ProjectExpenseHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseTypeEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseType: Scalars['String'];
  PaymentType: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil: Scalars['DateTime'];
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectExpenseClaimDetail?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
  PayItem?: Maybe<PayItemEntity>;
};

export type ProjectExpenseTypeInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingDetailEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectGLAccountMappingID: Scalars['String'];
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID: Scalars['String'];
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectGlAccountMappingDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectGLAccountMappingID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Title: Scalars['String'];
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectGLAccountMappingDetail?: Maybe<Array<ProjectGlAccountMappingDetailEntity>>;
  ProjectAccountAssignment?: Maybe<Array<ProjectAccountAssignmentEntity>>;
};

export type ProjectGlAccountMappingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutput = {
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutputList = {
  __typename?: 'ProjectGLDetailsOutputList';
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderList = {
  __typename?: 'ProjectGLHeaderList';
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderOutput = {
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectMasterEntity = InterfaceBase & {
  __typename?: 'ProjectMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectName: Scalars['String'];
  CostCenter: Scalars['String'];
  CostCenterDescription: Scalars['String'];
  ValidUntil: Scalars['DateTime'];
  ProjectOwnerID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectOwner?: Maybe<EmployeeEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
  ProjectExpenseClaimHeader?: Maybe<Array<ProjectExpenseClaimHeaderEntity>>;
};

export type ProjectMasterInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  CostCenterDescription?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  ProjectOwnerID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetApproverAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetPeriod?: Maybe<ProjectTimesheetPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type ProjectTimesheetApproverAssignmentInput = {
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverAssignmentID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodFrom?: Maybe<Scalars['String']>;
  PeriodTo?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['JSON']>>;
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectTimesheetApproverMasterEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Name: Scalars['String'];
  EmailAddress: Scalars['String'];
  ClientName: Scalars['String'];
  Department: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  LastUpdatedDate?: Maybe<Scalars['DateTime']>;
  ConfirmationDT?: Maybe<Scalars['DateTime']>;
  ProjectTimesheetPeriod?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
};

export type ProjectTimesheetApproverMasterInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetCheckOverlapOutput = {
  __typename?: 'ProjectTimesheetCheckOverlapOutput';
  ApproverPeriodRangeOverlap?: Maybe<Scalars['String']>;
  EmployeeListingBySubscriptionAccount?: Maybe<Array<EmployeeEntity>>;
};

export type ProjectTimesheetDailyEntryEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetDailyEntryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
  EntryDate: Scalars['DateTime'];
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetMonthlyRecord?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
};

export type ProjectTimesheetDailyEntryInput = {
  ProjectTimesheetDailyEntryID?: Maybe<Scalars['String']>;
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  EntryDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetMonthlyRecordEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetMonthlyRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  CompanyID: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYearMonth: Scalars['DateTime'];
  ApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetDailyEntry?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
};

export type ProjectTimesheetMonthlyRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ApprovalLog?: Maybe<Array<ApprovalLog>>;
};

export type ProjectTimesheetPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  PeriodFrom: Scalars['String'];
  PeriodTo: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetApproverAssignment?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
  InUse: Scalars['Boolean'];
};

export type ProjectTimesheetPeriodListingOutput = {
  __typename?: 'ProjectTimesheetPeriodListingOutput';
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  PeriodInfoline?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  isAssignedToApprover?: Maybe<Scalars['Boolean']>;
};

export type ProjectTimesheetReportForApproverInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<DocumentsInput>;
  EmployeeList?: Maybe<Array<Scalars['JSON']>>;
};

export type PublishEaEntity = InterfaceBase & {
  __typename?: 'PublishEAEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type PublishEaInput = {
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishEaSingleEmployeeListingOutput = {
  __typename?: 'PublishEASingleEmployeeListingOutput';
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  CompanyName?: Maybe<Scalars['String']>;
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishPayslipEntity = InterfaceBase & {
  __typename?: 'PublishPayslipEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishPaySlipID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayPeriod: PayPeriodEntity;
  PayrollCycle: PayrollCycleEntity;
};

export type PublishPayslipInput = {
  CompanyID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum QuantitativeType {
  Value = 'Value',
  Unit = 'Unit',
  Date = 'Date',
  Percentage = 'Percentage'
}

export type Query = {
  __typename?: 'Query';
  TestLoader: Array<DutyRosterEntity>;
  getDutyRoster: Array<DutyRosterEntity>;
  DutyRosterInfoLine: Scalars['JSON'];
  DutyRosterEmployeeSum: Scalars['JSON'];
  DutyRosterDates: Scalars['JSON'];
  DutyRosterSummaryByEmployee: Scalars['JSON'];
  DutyRosterSummary: Scalars['JSON'];
  DutyRosterByExcel: Scalars['JSON'];
  getDutyRosterByEmployee: Scalars['JSON'];
  getDutyRosterByEmployeeList: Scalars['JSON'];
  NotificationListing: Array<NotificationEntity>;
  NewsReadListing: Array<NewsReadEntity>;
  EmployeeCalendar?: Maybe<EmployeeCalendarOutput>;
  GetAttendanceRecordPairedData: Scalars['JSON'];
  GetAttendanceRecordPairedDataCount: Scalars['JSON'];
  GetFinalizeAnalysisDataByEmployee: Scalars['JSON'];
  GetFinalizeAnalysisData: Scalars['JSON'];
  GetFinalizeAnalysisDataCount: Scalars['JSON'];
  GetClockPairing: Scalars['String'];
  GetClockPairingRedirect: Scalars['String'];
  getAttenRecords: Array<AttendanceRecordEntity>;
  AttendanceRecordByHikvision: Scalars['JSON'];
  AttendanceRecordByHikvisionByCompany: Scalars['JSON'];
  getAttendanceRecord: Array<AttendanceRecordEntity>;
  getAttendanceRecordwithHikvision: Array<Scalars['JSON']>;
  AttendanceRecordSummary?: Maybe<Scalars['JSON']>;
  AttendanceRecordByExcel: Scalars['JSON'];
  AttendanceRecordListingByClockType: AttendanceRecordEntity;
  AttendanceRecordListing: Array<AttendanceRecordEntity>;
  AttendanceRecordByPeriod: Array<AttendanceRecordEntity>;
  getAttendanceRecordLastModified: Array<Scalars['DateTime']>;
  getContactTracing: Scalars['JSON'];
  GetAttendanceRecordManualEntryPageTotalCount?: Maybe<Scalars['Float']>;
  GetServerDateTime: Scalars['JSON'];
  GetPrivateDocumentByFileName: Array<Scalars['JSON']>;
  AppraisalCategoryListing: Array<AppraisalCategoryEntity>;
  AppraisalCategoryListingAll: Array<AppraisalCategoryEntity>;
  AppraisalCategoryItemListingAll: Array<AppraisalCategoryItemEntity>;
  AppraisalPolicyListing: Array<AppraisalPolicyEntity>;
  AppraisalTemplateByJobPositionListing: Array<AppraisalTemplateHeaderEntity>;
  GetAppraisalTemplateByJobPosition: AppraisalTempleteByJobPositionEntity;
  AppraisalScheduleListing: Array<AppraisalScheduleHeaderEntity>;
  GetAppraisalCompanySetting: AppraisalCompanySettingEntity;
  GetCompanyAppraisalTemplete: Array<AppraisalTempleteByJobPositionEntity>;
  GetCompanyAppraisalPolicy: AppraisalCompanySettingEntity;
  FinalRatingListing: Array<FinalRatingHeaderEntity>;
  ScaleRatingListing: Array<ScaleRatingHeaderEntity>;
  BonusSimulationListing: Array<BonusSimulationEntity>;
  IncrementSimulationListing: Array<IncrementSimulationEntity>;
  GetBonusRecordByCompanyOrgUnitList: Scalars['JSON'];
  GetIncrementRecordByCompanyOrgUnitList: Scalars['JSON'];
  AppraisalTempleteListing: Array<AppraisalTempleteEntity>;
  AppraisalTempleteListingAll: Array<AppraisalTempleteEntity>;
  GetEmployeeListingByscheduledAndQuater: Array<AppraisalEmployeeLisitngOutput>;
  GetEmployeeAppraisalHeader: Array<EmployeeAppraisalHeaderEntity>;
  GetEmployeeAppraisalDetails: Array<EmployeeAppraisalDetailEntity>;
  ModerationListing: Array<ModerationRatingEntity>;
  GetAppraisalDefaultSettingByJobPosition: AppraisalTempleteByJobPositionEntity;
  GetEmployeeKPITempleteLatestVersion: EmployeeKpiHeaderEntity;
  GetEmployeeKPIPlanningRecord: EmployeePlanningOutput;
  GetSubordinateKPIPlanning: Array<Scalars['JSON']>;
  GetEmployeeKPIPlanningByYear: Array<EmployeePlanningOutput>;
  GetKPIPlanningDetails: Array<EmployeeKpiCategoryEntity>;
  StaffPerformanceAnalysisSummaryListing: Array<StaffPerformanceAnalysisSummaryOutput>;
  ApprovalPolicySummary: Scalars['JSON'];
  ApprovalPolicyAssignmentSummary: Scalars['JSON'];
  ApprovalPolicyList: Array<ApprovalPolicyEntity>;
  ApprovalPolicyDetails: ApprovalPolicyEntity;
  IsApprovalPolicyTitleExist: Scalars['Boolean'];
  ApprovalPolicyAssignmentList?: Maybe<ApprovalPolicyAssignmentEntity>;
  EmployeeListingByRoleID: Array<EmployeeEntity>;
  ApproverListByCompany: Scalars['JSON'];
  AttendancePostingListing: Array<AttendancePostingOutput>;
  getLastPostedAttendanceProccess?: Maybe<Scalars['JSON']>;
  AttendancePeriodSummary?: Maybe<Scalars['JSON']>;
  GetLatestAttandanceCalculationDateByCompany?: Maybe<Scalars['JSON']>;
  GetPersonalInfo: Scalars['JSON'];
  CompanyBusinessInsight: Array<BusinessInsightCompanyOutput>;
  EmployeeBusinessInsightSummary: BusinessInsightSummaryOutput;
  EmployeeBusinessInsightRawListing: Array<BusinessInsightListingOutput>;
  EmployeeBusinessInsightListing?: Maybe<Array<BusinessInsightOutput>>;
  PayrollBusinessInsightStatutoryListing: Array<BusinessInsightPayrollStatutoryOutput>;
  PayrollBusinessInsightSalaryOverheadListing: Array<BusinessInsightPayrollSalaryOverheadOutput>;
  PayrollBusinessInsightPayItemListing: Array<BusinessInsightPayrollPayItemOutput>;
  PayrollBusinessInsightSummary: BusinessInsightSummaryPayrollOutput;
  TopLeaveTakerByType: Array<LeaveEntitlementByLeaveTypeListing>;
  LeaveBILeaveTakenListing: Array<BusinessInsightLeaveTakenOutput>;
  TopClaimTaker: Array<TopClaimTakerOutput>;
  ClaimBIClaimTaken: Array<BusinessInsightClaimTakenOuput>;
  TopRequisitionTaker: Array<TopRequisitionTakerOutput>;
  RequisitionTakenSummaryByType: Array<RequisitionTakenSummaryByTypeOutput>;
  BIManagementListing: Scalars['JSON'];
  HeadCountSummary: Scalars['JSON'];
  OverHeadSummary: Scalars['JSON'];
  TurnOverSummary: Scalars['JSON'];
  OvertimeSummary: Scalars['JSON'];
  LeaveSummary: Scalars['JSON'];
  ClaimSummary: Scalars['JSON'];
  ClaimByMonth: Scalars['JSON'];
  HeadCountAndBasicSalaryByMonth: Scalars['JSON'];
  EmployeeYOS: Scalars['JSON'];
  EmployeeSalary: Scalars['JSON'];
  EmployeeAge: Scalars['JSON'];
  EmpGender: Scalars['JSON'];
  EmpRace: Scalars['JSON'];
  EmpEmploymentType: Scalars['JSON'];
  LeaveByMonth: Scalars['JSON'];
  OverheadByMonth: Scalars['JSON'];
  ContributionByMonth: Scalars['JSON'];
  OvertimeHrsAndCost: Scalars['JSON'];
  StaffTurnOverByMonth: Scalars['JSON'];
  GetClaimManagementListing?: Maybe<Array<ClaimManagementListing>>;
  GetClaimRecordsListing?: Maybe<Array<ClaimRecordsListing>>;
  GetClaimRecordsTotalCount: Scalars['Float'];
  ClaimMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimRecordsDetails?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimRecordsHistoryListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimVerificationListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimCount: Scalars['JSON'];
  GetApprovedClaimVerificationDetails?: Maybe<ApprovedClaimVerificationDetailsOutput>;
  GetClaimPostingListing?: Maybe<Array<ClaimPostingListingOutput>>;
  GetClaimPostingCount?: Maybe<Scalars['JSON']>;
  GetClaimPostingDetail?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimPostingPeriodListing?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimPostingPeriodListingV2?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimEntitlementByClaimTypeListing?: Maybe<Array<EmpClaimEntitlementByClaimTypeOutput>>;
  GetClaimEntitlementByClaimTypeListingCount?: Maybe<Scalars['JSON']>;
  GetClaimEntitlementByEmployeeListing?: Maybe<Array<EmpClaimEntitlementByEmployeeOutput>>;
  GetClaimToApprove?: Maybe<Array<ClaimToApproveListingOutput>>;
  GetClaimApprovalHistory?: Maybe<Array<ClaimToApproveListingOutput>>;
  ClaimHomeMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimReportDetails?: Maybe<Scalars['JSON']>;
  ClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetEmpListingbyCompany: Array<EmployeeEntity>;
  GetEmpsClaimEntitlement: Array<EmpClaimEntitlementOutput>;
  GetMyClaimEntitlement: EmpClaimEntitlementOutput;
  GetEmpsClaimBalance: Array<EmpClaimBalanceOutput>;
  GetMyClaimBalance: EmpClaimBalanceOutput;
  GetMyClaimPoolBalance: EmpClaimPoolBalanceOutput;
  GetMyClaimListing: MyClaimListingOutput;
  GetMyClaimDetails: MyClaimHeaderDetails;
  VerifyMyClaim: Scalars['Boolean'];
  GetMainClaimListing: Array<GetMainClaimListingOutput>;
  GetEmpClaimListing: Array<GetEmpClaimListingOutput>;
  GetApprovedDetail: Array<GetApprovedDetailOutput>;
  GetClaimRecordListing: Array<ClaimRecordOutput>;
  GetApproveClaimVerification: Array<ApproveClaimVerificationOutput>;
  GetClaimPostingManagement: Array<ClaimPostingManagementOutput>;
  GetClaimRecordHistory: Array<ClaimRecordHistoryOutput>;
  GetCompletedDetail: Array<GetCompletedDetailOutput>;
  MyClaimDetail: ClaimDetailsEntity;
  ClaimListing: Array<ClaimHeaderEntity>;
  GetAllExpenseAccount: Array<JobAccountEntity>;
  ClaimValidityDetails: ClaimSettingEntity;
  ClaimTypeListing: Array<ClaimTypeEntity>;
  ClaimTypeListingAll: Array<ClaimTypeEntity>;
  ClaimTypeTemplateListing: Array<ClaimBenefitEntity>;
  ClaimPolicyListing: Array<ClaimBenefitEntity>;
  ClaimPolicyListingAll: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateDetail: ClaimBenefitEntity;
  GetClaimSettingsListing: Array<ClaimSettingsListing>;
  ClaimTypeConditionList: Array<ClaimTemplateEntity>;
  ClaimTemplateListing: Array<ClaimTemplateEntity>;
  IsClaimSubjectExists: Scalars['Boolean'];
  ClaimPoolListing: Array<ClaimPoolEntity>;
  GetEmployeePolicies?: Maybe<Scalars['JSON']>;
  GetEmployeeClaimPolicies?: Maybe<Scalars['JSON']>;
  BankDetail: Array<BankInfoEntity>;
  BankListing: Array<BankInfoEntity>;
  IsAccountNoExists: Scalars['Boolean'];
  IsAccountNameExists: Scalars['Boolean'];
  HolidayListing: Array<HolidayEntity>;
  CompanyOffDaysListing: Array<CompanyOffDayEntity>;
  CompanyOffDaysListingWithFilters: Scalars['JSON'];
  OffRestDayListing: Array<RecurringRestOffDayEntity>;
  CalendarStateListing: Array<CalendarOffDayOutput>;
  EmployeeStateAssignListing: Array<CalendarOffDayEmployeeOutput>;
  CalendarPolicyListing: Array<CalendarPolicyOutput>;
  EmployeeHomeCalendar?: Maybe<Array<HomeCalendarOutput>>;
  CompanyDeclaredHolidayListing: Array<CompanyDelaredHolidayEntity>;
  CompanyDeclaredHolidayListingByCalendarPolicyID: Array<CompanyDelaredHolidayEntity>;
  DocumentListing: Array<DocumentsEntity>;
  PersonnelDocumentListing: Array<DocumentsEntity>;
  CompanyListingByEmail: Scalars['JSON'];
  EmployeeCompanyListing: Scalars['JSON'];
  CompanyListWithAccessLevel?: Maybe<Array<Scalars['JSON']>>;
  CompanyListing: Array<CompanyEntity>;
  CompanySummary: CompanyEntity;
  CompanySummarySimplified: CompanyEntity;
  JobPositionListing: Array<JobDesignationEntity>;
  JobDesignationDetail: JobDesignationEntity;
  JobDesignationGradeListing: Array<JobDesignationGradeEntity>;
  JobGradeByJobDesignationListingV2: Scalars['JSON'];
  JobGradeByJobDesignationListing: Array<JobGradeByJobDesignationOutput>;
  JobDesignationListing: Array<JobDesignationEntity>;
  RecruitmentSourceListing: Array<RecruitmentSourceEntity>;
  GetCompanyCyclePeriodList?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  GetCompanyCyclePeriodListV2?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  CompanyAltAddressListing: Scalars['JSON'];
  getCompanyStatutoryAccountListing?: Maybe<Array<CompanyStatutoryAccountOutput>>;
  CompanyStatutoryDetail?: Maybe<CompanyStatutoryEntity>;
  IsCompanyHierarchyExists: Scalars['Boolean'];
  CompanyHierarchyList: Scalars['JSON'];
  CompanyOrgUnitListV2: Scalars['JSON'];
  CompanyOrgUnitList: Scalars['JSON'];
  CompanyOrgUnitListWithFilter: Scalars['JSON'];
  CompanyOrgUnitListWithEmpCheck: Scalars['JSON'];
  JobPositionByOrgUnitListing: Scalars['JSON'];
  JobPositionAssignmentList: Scalars['JSON'];
  CompanyAccessPermList: Scalars['JSON'];
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentDivisionListing: Array<DepartmentEntity>;
  DepartmentDetail: Array<DepartmentEntity>;
  DivisionListing: Array<DepartmentEntity>;
  DivisionDetail: Array<DepartmentEntity>;
  GetInsuranceScheme: Array<InsuranceSchemeEntity>;
  InsuranceSchemeListing: Array<InsuranceSchemeEntity>;
  InterbankDetail: Array<InterbankGiroEntity>;
  InterbankListingBulk: Array<InterbankGiroEntity>;
  InterbankListing: Array<InterbankGiroEntity>;
  GetBankInfoListing: Array<BankInfoOutput>;
  GetBankInfoListingV2: Array<BankInfoOutput>;
  MyJobAccountListing: Array<MyJobAccountEntity>;
  JobAccountListing: Array<JobAccountEntity>;
  JobAccountDetail: Array<JobAccountEntity>;
  JobGradeDetail: JobGradeEntity;
  JobGradeListing: Array<JobGradeEntity>;
  JobGradeListingByCompany: Array<JobGradeEntity>;
  JobGradePolicyListing: Array<JobGradePolicyEntity>;
  JobGradePolicyListingAll: Array<JobGradePolicyEntity>;
  LeaveRoundingListing: Array<LeaveRoundingEntity>;
  LeaveCompulsoryPolicyListing: Array<LeaveCompulsoryPolicyEntity>;
  LeaveTemplateListing: Array<LeaveTemplateEntity>;
  LHDNReportListing: Array<PayItemEntity>;
  LHDNReportDropdownListing: Array<LhdnReportEntity>;
  LHDNReportDetail: PayItemEntity;
  GetLHDNReportListing?: Maybe<Array<DisplayReportTypeSequenceOutput>>;
  NotificationPolicyDetail: Array<NotificationPolicyEntity>;
  NotificationPolicyListing: Array<NotificationPolicyEntity>;
  NotificationPolicyConditionListing: Array<NotificationPolicyConditionEntity>;
  NotificationPolicyConditionDetail: NotificationPolicyConditionEntity;
  NotificationPolicySummaryDates: NotificationPolicyLastUpdatedOutput;
  PayrollCycleDetail: PayrollCycleEntity;
  PayrollProcessPayrollCycleListingByTLA: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollCycleByPayPeriod: Scalars['JSON'];
  PayPeriodsByCompany: Scalars['JSON'];
  PayrollCycleListingByTLA: Array<PayrollCycleEntity>;
  PayItemListingByTLA: Array<PayItemListingByTlaOutput>;
  PayrollCycleListing: Array<PayrollCycleEntity>;
  PayrollCycleListingV2: Array<PayrollCycleEntity>;
  PayrollCycleListingV3: Array<PayrollCycleEntity>;
  PayrollCycleListingWithAudit: Scalars['JSON'];
  IsPayrollCycleLocked: Scalars['Boolean'];
  ReportingGroupListing: Array<ReportingGroupEntity>;
  ReportingStructureGroupOptionList: Scalars['JSON'];
  ReportingCompanyGroupListing: Scalars['JSON'];
  ReportingGroupTitleExist: Scalars['Boolean'];
  GetReportingStructureListing: ReportingStructureOutput;
  GetSuperior?: Maybe<Scalars['JSON']>;
  GetSkillSet: Array<SkillSetEntity>;
  GetSkillSetDetail: Array<SkillSetDetailEntity>;
  UserDefineListing: Array<UserDefineEntity>;
  GetLeaveOffDay: Scalars['JSON'];
  GetLeaveOffDayByAllStateWithCompany: Scalars['JSON'];
  GetLeavePolicyByEmployee?: Maybe<Scalars['JSON']>;
  GetPayItemPolicy?: Maybe<Scalars['JSON']>;
  GetLeavePolicyByJobGradeID?: Maybe<Array<LeaveItemEntity>>;
  GetOffboardEncashPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetPlatformNewLeaveTypePolicy?: Maybe<LeaveEntitlementTemplate>;
  GetMyLeaveEntitlement?: Maybe<EmpLeaveEntitlementOuput>;
  GetEmpLeaveBalance?: Maybe<Array<EmpLeaveBalanceOuput>>;
  GetMyLeaveBalance?: Maybe<EmpLeaveBalanceOuput>;
  EmployeeCalendarLeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  EmployeeLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetEmpLeaveDetail?: Maybe<EmpLeaveDetailOuput>;
  GetLeaveRoundedValue?: Maybe<Scalars['Float']>;
  LeaveTypeListing: Array<LeaveTypeEntity>;
  LeaveReasonListing: Array<LeaveReasonEntity>;
  LeaveDiseaseListing: Array<LeaveDiseaseEntity>;
  LeaveCompanySettingSummary?: Maybe<Scalars['JSON']>;
  LeaveTypeConditionList: Array<LeaveTemplateEntity>;
  LeavePolicyListing?: Maybe<Array<LeaveItemEntity>>;
  LeavePolicyByID?: Maybe<LeaveItemEntity>;
  LeaveTypeTemplateListing: Array<LeaveBenefitEntity>;
  LeaveTypeTemplateDetail: LeaveBenefitEntity;
  GetIntegratedLeaveBalance: Scalars['String'];
  GetEmployeesLeaveSummaryReport?: Maybe<Array<EmployeeLeavesSummary>>;
  GetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  EnhanceGetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  GetLeaveEntitlementByLeaveTypeListingTotalCount: Scalars['Float'];
  GetLeaveEntitlementByLeaveTypeListing?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  GetALBalanceForResignedEmployees?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  getEmployeeCalendar: Scalars['JSON'];
  GetTakenLeaveByEmployeeID?: Maybe<Array<LeaveDetailsEntity>>;
  GetEmployeeEntitlementByYear?: Maybe<Scalars['JSON']>;
  GetLeaveAdjustmentEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  GetLeaveAdjustmentEmployeeListingCount?: Maybe<Scalars['JSON']>;
  GetEmployeeLeaveCriteriaByEmpID?: Maybe<Scalars['JSON']>;
  GetUserDashboardSetting?: Maybe<UserDashboardControlEntity>;
  GetOffboardingList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveSummary?: Maybe<Array<Scalars['JSON']>>;
  GetBirthdayList: Array<BasicDashboardCardOutput>;
  GetConfirmationDueList: Array<BasicDashboardCardOutput>;
  GetAnnouncementList: Array<AnnouncementDashboardCardOutput>;
  GetAttendanceList: Array<AttendanceDashboardCardOutput>;
  getDataChangesListing?: Maybe<Array<DataChangesEntity>>;
  getDocument: Array<DocumentsEntity>;
  EmployeeDependentsSummary: EmployeeDependentsEntity;
  SelectedDataChangesApprovalPolicy: Scalars['JSON'];
  GetDataChangesToDo?: Maybe<Array<DataChangesEntity>>;
  ViewDataChangesApprovalHistory?: Maybe<Array<DataChangesEntity>>;
  GetDataImportTemplate: Scalars['JSONObject'];
  ShopeeImport: Scalars['Boolean'];
  ShopeeImportRedirect: Scalars['Boolean'];
  EmployeePendingActivateListing: Array<ContactEntity>;
  BulletinListing: Scalars['JSON'];
  NewsListing: Array<NewsEntity>;
  NewsCount?: Maybe<Scalars['Float']>;
  ClaimDetailReportListing: Array<Scalars['JSON']>;
  TTDA_ClaimDetailReportListing: Array<Scalars['JSON']>;
  GetPayrollReportListing?: Maybe<Array<PayrollReportOutput>>;
  GetPayrollReportListingRedirect?: Maybe<Array<PayrollReportOutput>>;
  GetCompanyStatutoryListing?: Maybe<Array<CompanyStatutoryOutput>>;
  GetCompanyReportYearListing?: Maybe<Array<ReportYearOutput>>;
  GetPayItemListing?: Maybe<Array<ReportPayItemOutput>>;
  EmployeeMasterExcelReport: Array<Scalars['JSON']>;
  SimpleShiftGroupList: Array<ShiftGroupEntity>;
  SimpleShiftList: Array<ShiftEntity>;
  SimpleJobPositionListing: Array<JobDesignationEntity>;
  AttendanceListingReport: Array<Scalars['JSON']>;
  EmployeeClockingListingReport: Array<Scalars['JSON']>;
  AbsenteeismListingReport: Array<Scalars['JSON']>;
  IncompleteClockingListingReport: Array<Scalars['JSON']>;
  IrregularAttendanceListingReport: Array<Scalars['JSON']>;
  LateInEarlyOutListingReport: Array<Scalars['JSON']>;
  GetNextDocNumber: Scalars['JSON'];
  DocumentNumberingList: Array<DocumentNumberHeaderEntity>;
  DocumentNumberingAssignmentList: Scalars['JSON'];
  HasEmployeeDocNumber: Scalars['Boolean'];
  getBatchEntryByShift: Array<BatchEntryByShiftEntity>;
  getBatchEntryByPattern: Array<BatchEntryByShiftPatternEntity>;
  GetEmployeeAppraisal: Array<EmployeeAppraisalEntity>;
  CareerLogListing: Array<CareerLogEntity>;
  GetEmployeeDiary: Array<EmployeeDiaryEntity>;
  EmployeeDocumentListing: Array<DocumentsEntity>;
  TestNotification: Scalars['Boolean'];
  EmployeeListForSpecificSection: Scalars['JSON'];
  EmployeeListForSpecificSectionCount: Scalars['JSON'];
  EmployeeListForReporting: Scalars['JSON'];
  SimpleEmployeeListing: Array<EmployeeEntity>;
  EmployeeListing: Array<EmployeeEntity>;
  EmployeeListingCount?: Maybe<Scalars['JSON']>;
  SubordinateMenuSummary: Scalars['JSON'];
  GetOrgChart: Scalars['JSON'];
  SubordinateTree: Scalars['JSON'];
  SubordinateTreeTimeAttendance: Scalars['JSON'];
  TMSEmployeeListing: Array<EmployeeEntity>;
  TMSEmployeeListingCount?: Maybe<Scalars['JSON']>;
  EmployeeListingBySubscriptionAccount: Array<EmployeeEntity>;
  EmployeeListingBySubscriptionAccountCount?: Maybe<Scalars['JSON']>;
  PersonnelEmployementInfoListing: Scalars['JSON'];
  PersonnelEmployementInfoListingCount: Scalars['JSON'];
  EmployeeListForJobInfo: Scalars['JSON'];
  EmployeeAppSummary: Scalars['JSON'];
  EmployeeAppDocSummary: Scalars['JSON'];
  EmployeeSummary: EmployeeEntity;
  EmployeeContactID: EmployeeEntity;
  EmployeeInfoKpi: EmployeeEntity;
  ZakatSummary?: Maybe<Scalars['String']>;
  EmployeeSummaryBySubscription: EmployeeEntity;
  GetEmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  IsSuperUserEmployee: Scalars['Boolean'];
  FilterRequisitionListing: Array<RequisitionEntity>;
  RequisitionListing: Array<RequisitionEntity>;
  FixedAllowanceListing: Array<RecurringPayEntity>;
  FilterEmployeeLeaveRecords: Array<LeaveHeaderEntity>;
  TaxReliefListingDefaultExcluded: Array<TaxReliefEntity>;
  EmployeeTaxReliefListing: Array<EmployeeTaxReliefEntity>;
  EmployeeTaxReliefDetails: Array<EmployeeTaxReliefEntity>;
  EmployeeAssetListing: Array<EmployeeAssetEntity>;
  EmployeeAssetDetail: EmployeeAssetEntity;
  EmployeeBenefitInKindListing: Array<EmployeeBenefitInKindEntity>;
  EmployeeBenefitInKindDetailForYear: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindDetail: EmployeeBenefitInKindEntity;
  EmployeeDependentsListing: Array<EmployeeDependentsEntity>;
  hasEmergencyContact: Scalars['Boolean'];
  MyEmployeeInfo?: Maybe<EmployeeEntity>;
  MyNewsList: Scalars['Float'];
  MyApplicationDetail: MyApplicationOutput;
  EmployeeCP22Listing: Array<EmployeeCp22Output>;
  GetEmployeeListing?: Maybe<Array<EmployeeListingOutput>>;
  GetSignatoryListing?: Maybe<Array<EmployeeListingOutput>>;
  GetEmployeeListingWithPayrollInput?: Maybe<Array<EmployeeListingOutput>>;
  GetEmployeeInsuranceScheme: Array<EmployeeInsuranceSchemeEntity>;
  EmployeeNotificationListing: Array<EmployeeNotificationOutput>;
  SubordinateListing: Array<EmployeeEntity>;
  GetEmployeeTraining: Array<EmployeeTrainingEntity>;
  EmployeeUserDefineFieldsListing: Array<EmployeeUserDefineFieldsEntity>;
  EmployeeUserDefineFieldsDetailListing: Array<EmployeeUserDefineFieldsDetailEntity>;
  EmployeeUserDefineFieldsDetailSummary?: Maybe<Scalars['String']>;
  ZakatContributionListing: Array<ZakatContributionEntity>;
  ZakatContributionDetails: Array<ZakatContributionEntity>;
  IsZakatPeriodOverlap: Scalars['Boolean'];
  GetEmployeeAppSecurityListing?: Maybe<Array<EmployeeAppSecurityOutput>>;
  GetEmployeeAppSecurityAssignmentListing?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeAppSecurityPermissionsByContactID?: Maybe<Scalars['JSON']>;
  EaDocumentsByEmployeeID: Array<Scalars['JSON']>;
  PayslipDocumentsByEmployeeID: Array<Scalars['JSON']>;
  EonDocumentsByBatch: Array<Scalars['JSON']>;
  SystemEonDocumentsCount: Scalars['Float'];
  HelpdeskListingReport?: Maybe<Array<Scalars['JSON']>>;
  HelpdeskSubjectListing: Array<HelpdeskSubjectEntity>;
  HelpdeskAssigneeListing: Array<EmployeeEntity>;
  HelpdeskSubjectDetail: HelpdeskSubjectEntity;
  IsHelpdeskSubjectExist: Scalars['Boolean'];
  getHelpdeskTicketSummary?: Maybe<Scalars['JSON']>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  HelpdeskTicketListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskToDoListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskAssigneeApprovalHistory?: Maybe<Array<HelpdeskTicketEntity>>;
  BlockLeaveListing: Array<BlockLeaveEntity>;
  BlockLeaveEmpListing?: Maybe<Array<BlockLeaveEntity>>;
  GetCarryForwardEncashmentPostedListing?: Maybe<Array<EncashCarryForwardLeaveOutput>>;
  GetCarryForwardEncashmentPendingListing?: Maybe<Array<YecfListingOutput>>;
  ViewLeaveApprovalHistory?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveRecordsListing?: Maybe<Array<LeaveRecordsOutput>>;
  GetLeaveRecordsTotalCount?: Maybe<Scalars['Float']>;
  GetLeaveReplacementListingByEmployee?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetLeaveAdjustmentListing?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetCompulsoryLeaveListing?: Maybe<Array<CompulsoryLeaveOutput>>;
  GetCompulsoryLeaveDetail?: Maybe<CompulsoryLeaveOutput>;
  GetLeavePostingListing?: Maybe<Array<LeavePostingOutput>>;
  GetLeaveApproveToDo?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveToAprove?: Maybe<Array<LeaveHeaderEntity>>;
  LeaveAdjustmentDetail: LeaveHeaderEntity;
  GetLeavePostingPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetYECFPendingListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPendingListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  FilterGetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  LeaveMenuSummary?: Maybe<Scalars['JSON']>;
  LeaveHomeSummary: LeaveHomeSummaryOutput;
  LeaveTakenListing?: Maybe<Array<LeaveTakenOutput>>;
  GetLeaveByMonthCalendar?: Maybe<Scalars['JSON']>;
  GetLeaveByMonthCalendarToDo?: Maybe<Scalars['JSON']>;
  LeaveOutOfStateListing: Array<LeaveOutOfStateEntity>;
  GLAccountMappingList: Scalars['JSON'];
  GLAccountHeaderList: Array<GlAccountHeaderEntity>;
  GetAccountMappingAssignment: GlAccountAssignmentEntity;
  GLAccountHeaderListWithDetails: Scalars['JSON'];
  PayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ClaimAccountMappingList: Scalars['JSON'];
  ClaimAccountHeaderList: Array<ClaimAccountHeaderOutput>;
  GetClaimAccountMappingAssignment: Array<ClaimAccountHeaderEntity>;
  ClaimAccountHeaderListWithDetails: Scalars['JSON'];
  PEClaimAccountHeaderListWithDetails: Scalars['JSON'];
  GetClaimAccountAssignment?: Maybe<ClaimAccountAssignmentEntity>;
  GetGLClaimRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  ClaimGLCycleProcess: Scalars['Boolean'];
  CostCenterMappingList: Scalars['JSON'];
  IsGLProcessed: Scalars['Boolean'];
  GetGLData: Scalars['JSON'];
  ProjectCostCenterMappingListForGLExport: Scalars['JSON'];
  CostCenterMappingListForGLExport: Scalars['JSON'];
  CurrentMobileVersion?: Maybe<Scalars['JSON']>;
  MobileVersionListing?: Maybe<Array<Scalars['JSON']>>;
  getMultiCurrencySetup: Array<MultiCurrencySetupEntity>;
  CheckMultiCurrencyAlreadyExist: Scalars['String'];
  GetEmployeeRoleName: Array<EmployeeEntity>;
  MultiCurrencyDetailsListing: Array<MultiCurrencyDetailsEntity>;
  MultiCurrencyListingByExcel: Scalars['JSON'];
  MultiCurrencyInfoline: Scalars['JSON'];
  CheckMultiCurrencyInUse: Scalars['Boolean'];
  OvertimeForecastReport?: Maybe<Scalars['JSON']>;
  getOvertime: Array<OvertimeEntity>;
  GetTotalOverTime: Scalars['JSON'];
  OvertimeListing: Array<OvertimeEntity>;
  OvertimeWithdrawalChecking: Scalars['Boolean'];
  OvertimeListingByExcel: Scalars['JSON'];
  OvertimeListingByYear: Array<OvertimeEntity>;
  OvertimeListingByDateTimeV2: Array<OvertimeEntity>;
  OvertimeListingByDateTime: Array<OvertimeEntity>;
  OvertimeListingByPeriod: Array<OvertimeEntity>;
  ViewOvertimeApprovalHistory?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeApproveToDo?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeToApprove?: Maybe<Array<OvertimeEntity>>;
  OvertimePeriodSummary?: Maybe<Scalars['JSON']>;
  OvertimeMenuSummary?: Maybe<Scalars['JSON']>;
  OvertimeRoundingListing: Array<OvertimeRoundingSettingEntity>;
  OvertimeTemplateListing: Array<OvertimeTemplateEntity>;
  AdvancePolicyListing: Array<AdvancePolicyEntity>;
  GetSingleAdvancePolicyDetail: AdvancePolicyEntity;
  DuplicateAdvancePolicyName: Scalars['Boolean'];
  AdvancePolicyByCompanyAssignment?: Maybe<Scalars['JSON']>;
  PostedAdvanceProcessingListing: Array<AdvanceProcessingEntity>;
  PayGroupListing: Array<PayGroupEntity>;
  CompanyPayGroupListing: Array<CompanyPayGroupListingOutput>;
  StatutoryAdjustmentListing?: Maybe<Array<Scalars['JSON']>>;
  StatutoryAdjFormEmpListing: Array<Scalars['JSON']>;
  GetFormulaTest: Scalars['String'];
  PayrollCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetSalaryTest: Scalars['JSON'];
  SalaryPayoutAnalysisSummaryExportExcel: Scalars['JSON'];
  PayrollDetailListingExportExcel: Scalars['JSON'];
  EmployeeOutstandingSalaryListing: Array<EmployeeEntity>;
  EmployeeOutstandingSalaryListingAndCount?: Maybe<Scalars['JSON']>;
  CP22Count: Array<EmployeeCp22Entity>;
  GetPayrollCyclePeriodSetting: Array<PayrollCyclePeriodEntity>;
  ViewPayrollHistorySummary: ViewPayrollHistorySummaryOutput;
  TestPayrollCalc: Array<Scalars['JSON']>;
  ViewPayrollHistory: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryCount?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryTotal?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryMyPayrollInfo: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryByEmployee: Scalars['JSON'];
  PayrollProcessPeriodListing: Array<PayrollProcessPeriodListingOutput>;
  PayrollProcessPayrollCycleListing: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollProcessPayItemListing: PayrollProcessPayItemListingOutput;
  PayrollProcessEmployeeListing: Array<PayrollProcessEmployeeListingOutput>;
  CalculatePayroll: PayrollCalculationOutput;
  CalculateEPFRate?: Maybe<EpfRateCalculationOutput>;
  Rounding: Scalars['String'];
  CalculateHRDF: HrdfCalculationOutput;
  CalculateEPFV2: EpfCalculationOutput;
  CalculateEIS: EisCalculationOutput;
  CalculateSocso: SocsoCalculationOutput;
  PayPeriodDetails: PayPeriodEntity;
  PayPeriodListing: Array<PayPeriodEntity>;
  StartPayPeriodListingByYear: Array<PayPeriodEntity>;
  EndPayPeriodListingByYear: Array<PayPeriodEntity>;
  PayItemByPayType: Array<PayItemEntity>;
  PayItemListing: Array<PayItemEntity>;
  PayItemConditionListTemplateLibrary: PayItemConditionEntity;
  PayItemConditionList: PayItemConditionEntity;
  PayrollProcessingPayItemListing: Array<PayItemEntity>;
  PayrollProcessingPayItemListing2: Array<PayItemEntity>;
  LeaveTypePayItemListing: Array<PayItemEntity>;
  SalaryListingByExcel: Scalars['JSON'];
  SalaryListingByPeriod: Array<CareerLogEntity>;
  ClaimTypePayItemListing: Array<PayItemEntity>;
  PayItemDetail?: Maybe<PayItemEntity>;
  PayrollFixedAllowanceListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListingCount?: Maybe<Scalars['JSON']>;
  PayrollFixedAllowanceDetails: Array<RecurringPayEntity>;
  CP159Listing: Array<Cp159Entity>;
  OneTimePayListing: Array<OneTimePayEntity>;
  OneTimePayListingByPayPeriodAndPayCycle: Array<OneTimePayEntity>;
  PublishPaySlipListing: Array<PublishPayslipEntity>;
  PublishPaySlipListingByEmployee: Array<PublishPayslipEntity>;
  GetSingleUser: Array<UserEntity>;
  PublishEAListing: Array<PublishEaEntity>;
  PublishEAListingByEmployee: Scalars['JSON'];
  IsMonthlyTrxInUse: Scalars['Boolean'];
  MonthlyTransactionByEmployee: Array<EmployeeTransactionOutput>;
  MonthlyTransactionByEmployeeCount?: Maybe<Scalars['JSON']>;
  MonthlyTransactionByPayItem: Array<PayItemTransactionOutput>;
  MonthlyTransactionByExcel: Array<ExcelPayItemTransactionOutput>;
  FreezepayrollListing: Array<FreezePayrollEntity>;
  PayrollFixedAllowanceByExcel: Array<ExcelPayItemTransactionOutput>;
  PublishedEACompanyListing: Array<PublishEaSingleEmployeeListingOutput>;
  CheckOldDataForSalaryBatch: Array<Scalars['JSON']>;
  CheckOldDataForOneTimePayInsert: Array<Scalars['JSON']>;
  CheckOldDataForRecurringPayInsert?: Maybe<Array<Scalars['JSON']>>;
  EmployeeSalaryLogs: Array<EmployeeEntity>;
  EmployeeSalaryLogsCount?: Maybe<Scalars['JSON']>;
  EmployeeSalaryListing: EmployeeEntity;
  PayrollHomeInfoLinesOutput?: Maybe<Scalars['JSON']>;
  PayrollCommonSettingsInfoLine?: Maybe<Scalars['JSON']>;
  OtherPayrollRelatedInfoLine: Scalars['JSON'];
  EmployeeKpiListing: Array<EmployeeKpiEntity>;
  EmployeeKpiDetails: EmployeeKpiEntity;
  EmployeeKpiRepoListing: Array<EmployeeKpiRepositoryEntity>;
  RetrieveEmployeeKpiDetailFromRepo: EmployeeKpiRepositoryEntity;
  IsKpiExistInRepo: Scalars['Boolean'];
  KpiExerciseListing: Array<KpiExerciseDetailsEntity>;
  KpiExerciseSubmenuSummary: Scalars['JSON'];
  KpiExerciseSetupListing: Array<KpiExerciseSetupEntity>;
  KpiExerciseSetupInfo: KpiExerciseSetupEntity;
  IsKpiExerciseIDExistByCompany: Scalars['Boolean'];
  IsKpiExerciseDateRangeOverlappingByCompany: Scalars['Boolean'];
  KpiCategoryWithOrgGoalListing: Array<KpiCategoryEntity>;
  OrganizationalGoalByCategoryListing: Array<OrganizationalGoalEntity>;
  OrganizationalGoalDetails: OrganizationalGoalEntity;
  IsOrgGoalExistByCategory: Scalars['Boolean'];
  OrgKpiPlanningTreeViewListing: Array<OrgKpiPlanningEntity>;
  KpiCategoryWithOrgKpiPlanningListing: Array<KpiCategoryEntity>;
  OrgKpiPlanningDetailsByKpiCategoryListing: Array<OrgKpiPlanningDetailsEntity>;
  OrgKpiPlanningInfo: OrgKpiPlanningDetailsEntity;
  IsOrgKpiExistByCategory: Scalars['Boolean'];
  PerformanceGroupMatrixListing: Array<PerformanceGroupMatrixEntity>;
  PerformanceMainMenuSummary: Scalars['JSON'];
  MyPerformanceSummary: Scalars['JSON'];
  GetPerformanceToDo: ToDoPerformanceOutput;
  GetPerformanceApprovalHistory: ToDoPerformanceOutput;
  PerformanceEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  ReviewExerciseListing: Array<ReviewExerciseListingOutput>;
  GetEmployeeReviewListing: Array<ReviewExerciseEntity>;
  GetEmployeeReviewActionListing: Array<MyReviewActionOutput>;
  GetEmployeeReviewForm: EmployeeReviewEntity;
  GetConversationLogByReviewFormItem: Array<ReviewConversationLogEntity>;
  ReviewExerciseSubmenuSummary: Scalars['JSON'];
  ReviewExerciseSetupListing: Array<ReviewExerciseSetupEntity>;
  ReviewExerciseSetupInfo: ReviewExerciseSetupEntity;
  IsReviewExerciseIDExist: Scalars['Boolean'];
  PeerRegistrationListing: Array<ReviewPeerRegistrationEntity>;
  IsSuperiorAlreadyExist: Scalars['Boolean'];
  MySubordinatePerformanceSummary: Scalars['JSON'];
  SubordinateKpiEmpListing: Array<SubordinateKpiEmpOutput>;
  PeriodicAllowanceTypeListing: Array<PeriodicAllowanceTypeEntity>;
  PersonnelCertificateListing: Array<PersonnelCertificateEntity>;
  ContractInfoListing: Array<ContractInfoEntity>;
  ContractInfoDueListing: Array<ContractEmployeeOutput>;
  ContractInfoDueListingCount?: Maybe<Scalars['JSON']>;
  ContractInfoExistingListing: Array<ContractEmployeeOutput>;
  ContractInfoExistingListingCount?: Maybe<Scalars['JSON']>;
  PersonnelEducationListing: Array<PersonnelEducationEntity>;
  IsEmailExists: Scalars['Boolean'];
  IsMobileNoExists: Scalars['Boolean'];
  IsEmployeeNoExists: Scalars['Boolean'];
  DocumentProfilePicture?: Maybe<Array<DocumentProfilePictureOutput>>;
  PersonnelEFileListing?: Maybe<Scalars['JSON']>;
  PersonnelEFileListingCount?: Maybe<Scalars['Float']>;
  PersonnelHealthInfoSummary: PersonnelHealthInfoEntity;
  PersonnelHomeInfoLines: PersonnelHomeInfoLinesOutput;
  ELetterTempleteInfolines: Array<ReportTemplateEntity>;
  CommonSettingsInfoLines: CommonSettingsInfoLinesOutput;
  PersonnelJobHistoryListing: Array<PersonnelJobHistoryEntity>;
  PersonnelReferenceListing: Array<PersonnelReferenceEntity>;
  UserDefineDetailListing: Array<UserDefineDetailEntity>;
  InsuranceSchemeInUse: Scalars['JSON'];
  PersonnelInsuranceSchemePersonnelListing: Array<EmployeeInsuranceSchemeEntity>;
  ProbationExtensionListing: Array<ProbationExtendedEmployeeOutput>;
  StaffConfirmationProbationListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedCareerLogListing: Array<CareerLogEntity>;
  StaffTerminationListing: Array<CareerLogEntity>;
  StaffTerminationListingBySubscription: Array<CareerLogEntity>;
  StaffRetirementRetiredListing: Array<CareerLogEntity>;
  StaffRetirementDueListing: Array<StaffDueForRetirementOutput>;
  StaffExitCompanyListing: Array<CareerLogEntity>;
  StaffPromotionDemotionListing: Array<CareerLogEntity>;
  StaffSecondmentListing: Array<CareerLogEntity>;
  StaffRedesignationListing: Array<CareerLogEntity>;
  StaffInternalTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferCopyTransferData: Scalars['JSON'];
  StaffInterCompanyTransferDetails: CareerLogEntity;
  InterCoTransferLeavePolicyData: Scalars['JSON'];
  GetProjectExpenseClaimRecordsListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEClaimRecordsDetails?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetPEClaimRecordsTotalCount: Scalars['Float'];
  ProjecExpenseSummary: Scalars['JSON'];
  VerifyMyPEClaim: Scalars['Boolean'];
  IsProjectExpenseSubjectExists: Scalars['Boolean'];
  GetPEClaimPostingPeriodListingV2?: Maybe<Array<PeClaimPostingPeriodListingOutput>>;
  GetPEClaimPostingListing?: Maybe<Array<PeClaimPostingListingOutput>>;
  GetPEClaimPostingDetail?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetApprovedProjectExpenseClaimVerificationListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEApprovedClaimCount: Scalars['JSON'];
  GetApprovedProjectExpenseClaimVerificationDetails?: Maybe<ApprovedProjectExpenseClaimVerificationDetailsOutput>;
  PEClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetProjectToApprove?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  GetProjectExpenseApprovalHistory?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  ProjectExpenseTypeListing: Array<ProjectExpenseTypeEntity>;
  ProjectGLAccountMappingListing: Array<ProjectGlAccountMappingEntity>;
  GetProjectAccountAssignment: ProjectAccountAssignmentEntity;
  ProjectAccountMappingList: Scalars['JSON'];
  ProjectGLAccountHeaderListWithDetails: Scalars['JSON'];
  ProjectPayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ProjectPayrollGLExport: Scalars['Boolean'];
  ProjectGLClaimCycleProcess: Scalars['Boolean'];
  GetGLClaimCycleList: Scalars['JSON'];
  ProjectMasterListing: Array<ProjectMasterEntity>;
  ProjecCommonSettingSummary: Scalars['JSON'];
  ProjectCostAllocationListing: Array<ProjectCostAllocationEntity>;
  ProjectCostAllocationEmployeeListing: Array<EmployeeEntity>;
  ProjectCostAllocationDetailListing: Array<ProjectCostAllocationDetailEntity>;
  getApproverMasterListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  getApproverAssignmentListing?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  IsApproverEmailAlreadyExist: Scalars['Boolean'];
  ProjectTimesheetPeriodListing: Array<ProjectTimesheetPeriodListingOutput>;
  DailyEntryListing: Array<ProjectTimesheetDailyEntryEntity>;
  ProjectTimesheetSubmissionListing?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApprovalListing: Scalars['JSON'];
  ProjectTimesheetCheckPeriodOverlapping: ProjectTimesheetCheckOverlapOutput;
  ProjectTimesheetCheckPeriodOverlappingCount: Scalars['JSON'];
  ProjectTimesheetPeriod: Array<ProjectTimesheetMonthlyRecordEntity>;
  ProjectTimesheetApprover: Array<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetEmployeeListing?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  AdminDailyEntryListing?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
  ProjectTimesheeTApproverReportListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  RequisitionMenuSummary: Scalars['JSON'];
  RequisitionManagementListing?: Maybe<Array<RequisitionOutput>>;
  RequisitionManagementListingTotalCount?: Maybe<Scalars['Float']>;
  EmployeeRequisitionListing: Array<RequisitionEntity>;
  RequisitionDetails: Scalars['JSON'];
  FilterEmployeeRequisitionListing: Array<RequisitionEntity>;
  TravelRequsitionListing: Array<RequisitionEntity>;
  RequisitionDetail: Array<RequisitionEntity>;
  CheckVerifyEndIf: Scalars['Boolean'];
  GetRequisitionApproveToDo?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionToAprove?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionApprovalHistory?: Maybe<Array<RequisitionOutput>>;
  RequisitionTypeList: RequisitionTypeEntity;
  ClaimParameterListing: Array<ClaimParameterEntity>;
  ClaimParameterListingAll: Array<ClaimParameterEntity>;
  ClaimUserDefineFieldsListing: Array<ClaimUserDefineFieldsEntity>;
  VehicleRateListing: Array<ClaimVehicleRateEntity>;
  PersonnelParameterListingByType: Array<Scalars['JSON']>;
  PersonnelParameterListing: Array<PersonnelParameterEntity>;
  ReportTemplateListing: Array<ReportTemplateEntity>;
  ReportTemplateAssignmentListing: Scalars['JSON'];
  StatesListing: Array<StatesEntity>;
  ViewGeneralSettingSummary: GeneralSettingsLastUpdatedOutput;
  StaffMovementRequestList: StaffMovementRequestEntity;
  HomeSummary: Scalars['JSON'];
  EmployeeMainMenuSummary: Scalars['JSON'];
  EmploymentInfoSummary: Scalars['JSON'];
  LoginUserPerms: Scalars['String'];
  PersonnelMenuSummary: Scalars['JSON'];
  EAPartFListing: Array<EaPartFEntity>;
  EAPartFListingByYear: Array<EaPartFEntity>;
  EISPolicyListing: Array<EisPolicyEntity>;
  EISPolicyDetail: EisPolicyEntity;
  EPFPolicyListing: Array<EpfPolicyEntity>;
  EPFPolicyDetail: EpfPolicyEntity;
  FormItemRatingListing: Array<FormItemRatingEntity>;
  FormItemRatingDetails: FormItemRatingEntity;
  IsFormItemRatingTitleExist: Scalars['Boolean'];
  KpiCategoryListing: Array<KpiCategoryEntity>;
  KpiCategoryDetails: KpiCategoryEntity;
  IsKpiCategoryTitleExist: Scalars['Boolean'];
  KpiPredefinedItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedTemplateItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedItemDetails: KpiPredefinedItemEntity;
  IsKpiPredefinedItemTitleExist: Scalars['Boolean'];
  KpiPredefinedTemplateListing: Array<KpiPredefinedTemplateEntity>;
  KpiPredefinedTemplateDetails: KpiPredefinedTemplateEntity;
  IsKpiPredefinedTemplateTitleExist: Scalars['Boolean'];
  PerformanceMenuSummary: Scalars['JSON'];
  ReviewFormTemplateListing: Array<ReviewFormTemplateEntity>;
  ReviewFormTemplateDetails: ReviewFormTemplateEntity;
  IsReviewFormTemplateTitleExist: Scalars['Boolean'];
  ReviewGradingTableListing: Array<ReviewGradingTableEntity>;
  ReviewGradingTableDetails: ReviewGradingTableEntity;
  IsReviewGradingTitleExist: Scalars['Boolean'];
  ReviewGroupListing: Array<ReviewGroupEntity>;
  ReviewGroupDetails: ReviewGroupEntity;
  IsReviewGroupTitleExist: Scalars['Boolean'];
  ReviewItemListing: Array<ReviewItemEntity>;
  ReviewItemDetails: ReviewItemEntity;
  IsReviewItemCodeExist: Scalars['Boolean'];
  ReviewPolicyListing: Array<ReviewPolicyEntity>;
  ReviewPolicyDetails: ReviewPolicyEntity;
  IsReviewPolicyTitleExist: Scalars['Boolean'];
  ReviewProcessPolicyListing: Array<ReviewProcessPolicyEntity>;
  ReviewProcessPolicyDetails: ReviewProcessPolicyEntity;
  IsReviewProcessPolicyTitleExist: Scalars['Boolean'];
  ReviewTemplateListing: Array<ReviewTemplateEntity>;
  ReviewTemplateDetails: ReviewTemplateEntity;
  IsReviewTemplateTitleExist: Scalars['Boolean'];
  RewardGroupListing: Array<RewardGroupEntity>;
  RewardGroupDetails: RewardGroupEntity;
  IsRewardGroupTitleExist: Scalars['Boolean'];
  PersonnelSecurityPolicyInfoByRole?: Maybe<PersonnelSecurityPolicyEntity>;
  PublicHolidayCalendarListing: Array<HolidayEntity>;
  PublicHolidayListing: Array<HolidayEntity>;
  PublicHolidayForStatesListing: Array<HolidayEntity>;
  SocsoPolicyListing: Array<SocsoPolicyEntity>;
  SocsoPolicyDetail: SocsoPolicyEntity;
  SystemAdminSummaryListing: SystemAdminSummaryOutput;
  SystemAdminMenuSummary: Scalars['JSON'];
  TaxReliefListing: Array<TaxReliefEntity>;
  TaxReliefListingByYear: Array<TaxReliefEntity>;
  AttendanceAnalysis: Array<AttendanceDetailOutput>;
  AttendanceProcessingList: Array<Scalars['JSON']>;
  MonthlyAttendanceProcessingList: Array<Scalars['JSON']>;
  ProcessingListing: Array<AttendanceProcessingBatchEntity>;
  GetAllAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetBeaconDevicesListing?: Maybe<Array<BluetoothBeaconDevicesEntity>>;
  IsDeviceExists: Scalars['Boolean'];
  getChangeShift: Array<ChangeShiftEntity>;
  getShiftByEmployee: Scalars['JSON'];
  getShiftByTLA: Array<ShiftEntity>;
  getShiftGroupByTLA: Array<ShiftGroupEntity>;
  getShiftGroupByShiftPattern: Array<ShiftGroupEntity>;
  getShiftPatternByTLA: Array<ShiftPatternEntity>;
  getEmpByDuty: Array<Scalars['JSON']>;
  getChangeShiftListing: Array<ChangeShiftEntity>;
  ViewChangeShiftApprovalHistory?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftApproveToDo?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftToApprove?: Maybe<Array<ChangeShiftEntity>>;
  getChangeShiftByYear: Array<ChangeShiftEntity>;
  ChangeShiftMenuSummary?: Maybe<Scalars['JSON']>;
  getDailyAllowance: Array<DailyAllowancePolicyEntity>;
  getDailyAllowanceByTLA: Array<DailyAllowancePolicyEntity>;
  DailyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getDeductionPolicy: Array<DeductionPolicyEntity>;
  getDeductionPolicyByTLA: Array<DeductionPolicyEntity>;
  DeductionPolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  EarnLeavePosting: Scalars['JSON'];
  EarnLeavePeriodSummary?: Maybe<Scalars['JSON']>;
  EmployeeTMSSummary?: Maybe<EmployeeSummary>;
  getJobGradePolicyMatrix: Array<JobGradePolicyMatrixEntity>;
  getMobileCheckIn?: Maybe<Array<MobileCheckInEntity>>;
  QRListing: DocumentsEntity;
  EmpAttendanceHistory: Scalars['JSON'];
  AttendanceHistory: Array<Scalars['JSON']>;
  VerifyScan: Scalars['Boolean'];
  GetLocationRadiusSetting: MobileCheckInEntity;
  getMonthlyAllowanceByTLA: Array<MonthlyAllowancePolicyEntity>;
  MonthlyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getMonthlyAllowance: Array<MonthlyAllowancePolicyEntity>;
  getOvertimeClaimTable: Array<OvertimeClaimTableEntity>;
  OvertimeClaimTableInUseListing?: Maybe<Array<Scalars['String']>>;
  getOvertimeReason: Array<OvertimeReasonEntity>;
  OTReasonListing: Array<OvertimeReasonEntity>;
  OTReasonByTLA: Array<OvertimeReasonEntity>;
  getOvertimePolicy?: Maybe<Array<OvertimePolicyEntity>>;
  OvertimePolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  PeriodicDeductionInUseListing?: Maybe<Array<Scalars['String']>>;
  getPeriodicDeduction: Array<PeriodicDeductionPolicyEntity>;
  getAllowanceDeduction: Array<AllowanceDeductionRoundingEntity>;
  getOTRounding: Array<OtRoundingEntity>;
  getShift: Array<ShiftEntity>;
  getShiftDay: Array<ShiftDayEntity>;
  getShiftList: Array<ShiftEntity>;
  getShiftGroup: Array<ShiftGroupEntity>;
  EmployeeShiftGroup?: Maybe<Scalars['JSON']>;
  EmployeeShiftGroupAssignmentLog: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  getShiftPattern: Array<ShiftPatternEntity>;
  ShiftPatternListing: Array<ShiftPatternEntity>;
  ValidShiftPatternListing: Array<ShiftPatternEntity>;
  TemplateLibrarySummary?: Maybe<TemplateLibrarySummaryOutput>;
  TMSSummary?: Maybe<TmsSummary>;
  UserDefineEPFPolicyListing: Array<UserDefineEpfPolicyEntity>;
  UserDefineEPFPolicyConditionList: Array<UserDefineEpfPolicyMatrixEntity>;
  getTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getTemplateLibraryAssignment: Array<TemplateLibraryAssignmentEntity>;
  getJobGradeByTLA: Array<Scalars['JSON']>;
  getPayrollTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getPayCycleForPayCalendar: Scalars['JSON'];
  IsPayrollCycleInUse: Scalars['Boolean'];
  GetTemplateLibraryAssignmentInfoline: TemplateLibraryAssignmentInfolineOutput;
  PersonnelTemplateLibraryAssignmentListing: PersonnelTemplateLibraryAssignmentOutput;
  DailyTypeConditionList: Array<DailyTemplateEntity>;
  GetEmployeeDailyPolicies?: Maybe<Scalars['JSON']>;
  DeductionTypeConditionList: Array<DeductionTemplateEntity>;
  GetEmployeeDeductionPolicies?: Maybe<Scalars['JSON']>;
  TMSTypeConditionList: Array<ClaimTemplateEntity>;
  OTPayItemConditionList: Array<TmsotConditionEntity>;
  GetEmployeeOtPolicies?: Maybe<Scalars['JSON']>;
  MonthlyTypeConditionList: Array<MonthlyTemplateEntity>;
  TmsPolicyMenuInfoLinesOutput?: Maybe<Scalars['JSON']>;
  TimeOffListing: Array<TimeOffEntity>;
  TimeOffListingByYear: Array<TimeOffEntity>;
  ViewTimeOffApprovalHistory?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffAproveToDo?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffToApprove?: Maybe<Array<TimeOffEntity>>;
  TimeOffApplicationPeriodSummary?: Maybe<Scalars['JSON']>;
  TimeOffMenuSummary?: Maybe<Scalars['JSON']>;
  TimeOffListingByPeriod: Array<TimeOffEntity>;
  getTimeOff: Array<TimeOffPolicyEntity>;
  TimeOffReasonByTLA: Array<TimeOffReasonEntity>;
  TimeOffReasonListing: Array<TimeOffReasonEntity>;
  TimeOffSettingListing: Array<TimeOffSettingEntity>;
  TsAccountTypeList: Array<TsAccountTypeEntity>;
  TsAccountCodeList: Array<TsAccountCodeEntity>;
  StaffTimesheetList: Array<StaffTimesheetEntity>;
  TimesheetHours?: Maybe<Scalars['JSON']>;
  TimesheetDates: Scalars['JSON'];
  TimesheetMandayAnalysisByAccCode: Array<TimesheetMandayAnalysisByAccCodeDto>;
  TimesheetMandayAnalysisByActivity: Array<TimesheetMandayAnalysisDto>;
  TimesheetMandayAnalysisByAccType: Array<TimesheetMandayAnalysisByAccTypeDto>;
  TimesheetMandayAnalysisByAccount: Array<TimesheetMandayAnalysisDto>;
  TimesheetCompanyDate: Scalars['JSON'];
  TimesheetSummary: Scalars['JSON'];
  IsActvitiyInUse: Scalars['JSON'];
  ToDoSummary: Scalars['JSON'];
  TrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  GetEmployeeTrainingRecord: Array<EmployeeAttendanceOutput>;
  EmployeeTrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  CompulsaryCourseByJobGradeListing: Array<CompulsaryCourseByJobGradeEntity>;
  GetCompulsaryCourseByJobGradeListingWithCompany: Array<CompulsaryCourseByJobGradeEntity>;
  CourseClassificationListing: Array<CourseClassificationEntity>;
  TrainingCourseListing: Array<TrainingCourseEntity>;
  TrainingCourseListingAll: Array<TrainingCourseEntity>;
  TrainingCourseListingByJobGrade: Array<TrainingCourseEntity>;
  AuthorisedTrainingProviderListing: Array<AuthorisedTrainingProviderEntity>;
  GetProviderListByCourse: Array<CourseProviderOutput>;
  TrainingCostItemListing: Array<TrainingCostItemEntity>;
  GetLatestUpdateForMainMenuModule: TraningModuleLatestUpdateOutput;
  TraineeEvalListing: Array<TraineeEvalEntity>;
  TraineeEvalListingAll: Array<TraineeEvalEntity>;
  SupervisorEvalListing: Array<SupervisorEvalEntity>;
  CourseEvalListing: Array<CourseEvalEntity>;
  TrainerEvalListing: Array<TrainerEvalEntity>;
  GetTrainerEvalLuation: Array<TrainerEvaluationListingOutput>;
  GetSupervisorEvalLuation: Array<SupervisorEvaluationListingOutput>;
  GetFulfilmentByJobGrade: Array<EmployeeFulfilmentOutput>;
  GetFulfilmentByCourse: Array<CourseFulfilmentOutput>;
  GetOSFulfilmentByJobGrade: Array<GetOsFulfilmentByJobGradeOutput>;
  ScheduleTrainingListing: Array<ScheduleTrainingEntity>;
  RegisteredTraineeListing: Array<EmployeeEntity>;
  EmployeeScheduledTrainingListing: Array<ScheduleTrainingEntity>;
  AvailableCoursesListing: Array<ScheduleTrainingEntity>;
  CompulsoryCourseListing: Array<ScheduleTrainingEntity>;
  EmpTrainingAttendanceHistory: Scalars['JSON'];
  TrainingCostBudgetListing: Array<TrainingBudgetEntity>;
  TrainingCostListing: Array<TrainingCostEntity>;
  CostAllocationListing: Array<CostAllocationEntity>;
  HRDFClaimListing: Array<HrdfClaimOutput>;
  GetPersonalTTDAInfo: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRoleList?: Maybe<Array<Scalars['JSON']>>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  userRoleIDbyUser: Array<RoleUserEntAssignmentEntity>;
  getCompanyPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getRolePermissionByUser: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetUserRolePermissionByRole: Scalars['JSON'];
  AllowancePolicy: Array<AllowanceBenfitEntity>;
  AllowanceEntitlementListing: Array<AllowanceItemEntity>;
  AllowanceEntitlementDetail: Array<AllowanceItemEntity>;
  CompanySummaryDates: CompanyViewLastUpdatedOutput;
  CurrentCountry?: Maybe<Scalars['JSON']>;
  CountryListing?: Maybe<Array<CountryEntity>>;
  PayProcessSelectPayPeriod: Array<PayPeriodEntity>;
  PayProcessSelectPayrollCycle: Array<PayrollCycleEntity>;
  PayProcessEmployeeList: Array<EmployeeEntity>;
  PayProcessDetail: Array<PayrollDetailEntity>;
  loggedInEmployeeProfile: EmployeeResponse;
  loggedInUserProfile: UserEntity;
  TestFCM: Scalars['Boolean'];
  TestEmail: Scalars['Boolean'];
  MobileNotification: Scalars['Boolean'];
  loginSSO: Scalars['JSON'];
  VerifyRedirectDomainToken: Scalars['String'];
  activeUser: Scalars['Float'];
  getUserListing?: Maybe<Array<Scalars['JSON']>>;
  getEmployeeUserListing?: Maybe<Array<Scalars['JSON']>>;
  getUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getActiveUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  GetExceededUserWarning?: Maybe<Scalars['Boolean']>;
  UserProfilePic?: Maybe<Scalars['JSON']>;
  GetNonAccessUsers?: Maybe<Array<Scalars['JSON']>>;
  CheckOnPremServer?: Maybe<Scalars['String']>;
  testWorkFlowServices: Scalars['JSON'];
};


export type QueryGetDutyRosterArgs = {
  IsExcelFile?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  DutyRosterID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DutyDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterEmployeeSumArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterDatesArgs = {
  date: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryByEmployeeArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeArgs = {
  GetLeaveTimeOffOvertimeData?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeListArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
};


export type QueryNotificationListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryNewsReadListingArgs = {
  NewsID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCalendarArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataCountArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataCountArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetClockPairingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
};


export type QueryGetClockPairingRedirectArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
  payload?: Maybe<Scalars['JSON']>;
};


export type QueryGetAttenRecordsArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryAttendanceRecordByHikvisionArgs = {
  keyword?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByHikvisionByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetAttendanceRecordwithHikvisionArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryAttendanceRecordSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByExcelArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingByClockTypeArgs = {
  ClockType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordLastModifiedArgs = {
  companyID: Scalars['String'];
  timezoneOffset: Scalars['Float'];
  year: Scalars['DateTime'];
};


export type QueryGetContactTracingArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordManualEntryPageTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetPrivateDocumentByFileNameArgs = {
  Documents: Array<Scalars['JSON']>;
};


export type QueryAppraisalCategoryListingArgs = {
  AppraisalType: Scalars['String'];
};


export type QueryGetAppraisalTemplateByJobPositionArgs = {
  JobPositionID: Scalars['String'];
};


export type QueryGetAppraisalCompanySettingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalTempleteArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBonusSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIncrementSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBonusRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetIncrementRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAppraisalTempleteListingArgs = {
  TempleteType: Scalars['String'];
};


export type QueryAppraisalTempleteListingAllArgs = {
  TempleteType: Scalars['String'];
};


export type QueryGetEmployeeListingByscheduledAndQuaterArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  ScheduledID: Scalars['String'];
  QuaterID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalHeaderArgs = {
  PeriodYear: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalDetailsArgs = {
  EmployeeAppraisalHeaderID: Scalars['String'];
};


export type QueryModerationListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetAppraisalDefaultSettingByJobPositionArgs = {
  JobPositionID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeKpiTempleteLatestVersionArgs = {
  KPIPlanningID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeKpiPlanningRecordArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetSubordinateKpiPlanningArgs = {
  EmployeeID: Array<Scalars['String']>;
};


export type QueryGetEmployeeKpiPlanningByYearArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetKpiPlanningDetailsArgs = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
};


export type QueryStaffPerformanceAnalysisSummaryListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
};


export type QueryApprovalPolicySummaryArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyAssignmentSummaryArgs = {
  Category: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryApprovalPolicyListArgs = {
  Category: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyDetailsArgs = {
  Category: Scalars['String'];
  ApprovalPolicyID: Scalars['String'];
};


export type QueryIsApprovalPolicyTitleExistArgs = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  Title: Scalars['String'];
};


export type QueryApprovalPolicyAssignmentListArgs = {
  Category: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListingByRoleIdArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryApproverListByCompanyArgs = {
  Category?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryAttendancePostingListingArgs = {
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLastPostedAttendanceProccessArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAttendancePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryGetLatestAttandanceCalculationDateByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPersonalInfoArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
};


export type QueryCompanyBusinessInsightArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightRawListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightStatutoryListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSalaryOverheadListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightPayItemListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopLeaveTakerByTypeArgs = {
  LeaveTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveBiLeaveTakenListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopClaimTakerArgs = {
  ClaimTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryClaimBiClaimTakenArgs = {
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs: Array<Scalars['String']>;
  Year: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTopRequisitionTakerArgs = {
  RequisitionType: Array<RequisitionType>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryRequisitionTakenSummaryByTypeArgs = {
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryHeadCountSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverHeadSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryTurnOverSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryHeadCountAndBasicSalaryByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeYosArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeSalaryArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeAgeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpGenderArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpRaceArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpEmploymentTypeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverheadByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryContributionByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeHrsAndCostArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryStaffTurnOverByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryGetClaimManagementListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ClaimRecordsInput;
};


export type QueryGetClaimRecordsTotalCountArgs = {
  input: ClaimRecordsInput;
};


export type QueryClaimMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordsHistoryListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetApprovedClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimCountArgs = {
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingCountArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingPeriodListingArgs = {
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimEntitlementByClaimTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByClaimTypeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByEmployeeListingArgs = {
  IsV2?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetClaimToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetClaimApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimHomeMenuSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimReportDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpListingbyCompanyArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmpsClaimEntitlementArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyClaimEntitlementArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpsClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  input: EmpClaimEntitlementInput;
};


export type QueryGetMyClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimPoolBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetMyClaimDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryVerifyMyClaimArgs = {
  VerfiyClaimInput: VerfiyClaimInput;
};


export type QueryGetMainClaimListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpClaimListingArgs = {
  Input: Array<GetEmpClaimListingInput>;
};


export type QueryGetApprovedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetApproveClaimVerificationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimPostingManagementArgs = {
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordHistoryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetCompletedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryMyClaimDetailArgs = {
  ClaimDetailID: Scalars['String'];
};


export type QueryClaimListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetAllExpenseAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimValidityDetailsArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateDetailArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type QueryGetClaimSettingsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryIsClaimSubjectExistsArgs = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryClaimPoolListingArgs = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeePoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeClaimPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryBankDetailArgs = {
  BankInfoIDs: Array<Scalars['String']>;
};


export type QueryBankListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsAccountNoExistsArgs = {
  CompanyID: Scalars['String'];
  AccountNo: Scalars['String'];
};


export type QueryIsAccountNameExistsArgs = {
  CompanyID: Scalars['String'];
  AccountName: Scalars['String'];
};


export type QueryHolidayListingArgs = {
  States: Scalars['String'];
  PeriodYear: Scalars['Float'];
};


export type QueryCompanyOffDaysListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyOffDaysListingWithFiltersArgs = {
  StateID: Scalars['String'];
  Title: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryOffRestDayListingArgs = {
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeStateAssignListingArgs = {
  IsDefault: Scalars['Boolean'];
  EmployeeFormStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeHomeCalendarArgs = {
  CompanyID: Scalars['String'];
  LeaveDate: Scalars['DateTime'];
};


export type QueryCompanyDeclaredHolidayListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCompanyDeclaredHolidayListingByCalendarPolicyIdArgs = {
  CalendarPolicyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  ParentIDs?: Maybe<Array<Scalars['String']>>;
  ParentID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryPersonnelDocumentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryCompanyListingByEmailArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCompanyListingArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryCompanyListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCompanySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanySummarySimplifiedArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionListingArgs = {
  JobDesignationID?: Maybe<Scalars['String']>;
};


export type QueryJobDesignationDetailArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationGradeListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingV2Args = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryRecruitmentSourceListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetCompanyCyclePeriodListV2Args = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAltAddressListingArgs = {
  IsStatutoryInfo?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyStatutoryDetailArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsCompanyHierarchyExistsArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyHierarchyListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListV2Args = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithFilterArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithEmpCheckArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJobPositionByOrgUnitListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionAssignmentListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  JobDesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAccessPermListArgs = {
  usePayload?: Maybe<Scalars['Boolean']>;
  RoleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDivisionListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionListingArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryGetInsuranceSchemeArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeListingArgs = {
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInterbankDetailArgs = {
  InterbankGiroIDs: Array<Scalars['String']>;
};


export type QueryInterbankListingBulkArgs = {
  BankInfoID: Array<Scalars['String']>;
};


export type QueryInterbankListingArgs = {
  BankInfoID: Scalars['String'];
};


export type QueryGetBankInfoListingV2Args = {
  BankName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryMyJobAccountListingArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobAccountDetailArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobGradeDetailArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryJobGradeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradeListingByCompanyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradePolicyListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryJobGradePolicyListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveRoundingListingArgs = {
  LeaveRoundingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveCompulsoryPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLhdnReportDropdownListingArgs = {
  ReportYear: Scalars['Float'];
};


export type QueryLhdnReportDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryGetLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  periodYear: Scalars['Float'];
};


export type QueryNotificationPolicyDetailArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionListingArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionDetailArgs = {
  NotificationPolicyConditionID: Scalars['String'];
};


export type QueryNotificationPolicySummaryDatesArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleDetailArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingByTlaArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleByPayPeriodArgs = {
  ToPayPeriod: Scalars['DateTime'];
  FromPayPeriod: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodsByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayItemListingByTlaArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV2Args = {
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV3Args = {
  CompanyID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
};


export type QueryIsPayrollCycleLockedArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryReportingStructureGroupOptionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryReportingCompanyGroupListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReportingGroupTitleExistArgs = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryGetReportingStructureListingArgs = {
  CompanyID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
};


export type QueryGetSuperiorArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetSkillSetArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetSkillSetDetailArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};


export type QueryGetLeaveOffDayArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveOffDayByAllStateWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePolicyByEmployeeArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetPayItemPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetLeavePolicyByJobGradeIdArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
};


export type QueryGetOffboardEncashPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetPlatformNewLeaveTypePolicyArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetMyLeaveEntitlementArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeCalendarLeaveDetailsArgs = {
  input: EmpCalendarLeaveListing;
};


export type QueryEmployeeLeaveListingArgs = {
  input: EmpLeaveListingInput;
};


export type QueryGetEmpLeaveDetailArgs = {
  input: EmpLeaveDetailInput;
};


export type QueryGetLeaveRoundedValueArgs = {
  LeaveValue: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveReasonListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveDiseaseListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeavePolicyListingArgs = {
  ShortNoticeOptioIDs?: Maybe<Array<Scalars['String']>>;
  AvailableBasisIDs?: Maybe<Array<Scalars['String']>>;
  EntitlementMethodIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeavePolicyByIdArgs = {
  LeavePolicyID: Scalars['String'];
};


export type QueryLeaveTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypeTemplateDetailArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type QueryGetIntegratedLeaveBalanceArgs = {
  sTaken: Scalars['Float'];
  hTaken: Scalars['Float'];
  sEntilement: Scalars['Float'];
  hEntitlement: Scalars['Float'];
};


export type QueryGetEmployeesLeaveSummaryReportArgs = {
  IsReport?: Maybe<Scalars['Boolean']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  Sort?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeesLeaveSummaryArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsV2?: Maybe<Scalars['Boolean']>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEnhanceGetEmployeesLeaveSummaryArgs = {
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAlBalanceForResignedEmployeesArgs = {
  toDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeCalendarArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTakenLeaveByEmployeeIdArgs = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeEntitlementByYearArgs = {
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetLeaveAdjustmentEmployeeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentEmployeeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeLeaveCriteriaByEmpIdArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetUserDashboardSettingArgs = {
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryGetOffboardingListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  OffboardingCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  LeaveCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetConfirmationDueListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ConfirmationCardType: DashboardCardType;
};


export type QueryGetAttendanceListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  AttendanceCardType: DashboardCardType;
};


export type QueryGetDataChangesListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentArgs = {
  DocumentID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeDependentsSummaryArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type QuerySelectedDataChangesApprovalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDataChangesToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewDataChangesApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetDataImportTemplateArgs = {
  Type: Scalars['String'];
};


export type QueryShopeeImportArgs = {
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryShopeeImportRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryEmployeePendingActivateListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryBulletinListingArgs = {
  NewsID: Scalars['String'];
};


export type QueryNewsListingArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryNewsCountArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryTtda_ClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryGetPayrollReportListingArgs = {
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollReportListingRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryListingArgs = {
  Types?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeMasterExcelReportArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryAttendanceListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryEmployeeClockingListingReportArgs = {
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryAbsenteeismListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIncompleteClockingListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIrregularAttendanceListingReportArgs = {
  IsExcludeAbsent?: Maybe<Scalars['Boolean']>;
  IsExcludeIncomplete?: Maybe<Scalars['Boolean']>;
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryLateInEarlyOutListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetNextDocNumberArgs = {
  RefTableName: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryDocumentNumberingListArgs = {
  IsStatusActive?: Maybe<Scalars['Boolean']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
};


export type QueryDocumentNumberingAssignmentListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryHasEmployeeDocNumberArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBatchEntryByShiftArgs = {
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBatchEntryByPatternArgs = {
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppraisalArgs = {
  EmployeeAppraisalID?: Maybe<Scalars['String']>;
};


export type QueryCareerLogListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeDiaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeDocumentListingArgs = {
  ParentID: Scalars['String'];
};


export type QueryTestNotificationArgs = {
  msg: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeListForSpecificSectionArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListForSpecificSectionCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QuerySimpleEmployeeListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingArgs = {
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingCountArgs = {
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QuerySubordinateMenuSummaryArgs = {
  ApproverID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryGetOrgChartArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeArgs = {
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeTimeAttendanceArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTmsEmployeeListingArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryTmsEmployeeListingCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingBySubscriptionAccountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListingBySubscriptionAccountCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeAppSummaryArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAppDocSummaryArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeContactIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeInfoKpiArgs = {
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
};


export type QueryZakatSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryBySubscriptionArgs = {
  SubscriptionUserID: Scalars['String'];
};


export type QueryGetEmployeePrevContributionArgs = {
  PeriodYear?: Maybe<Scalars['Float']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryIsSuperUserEmployeeArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterRequisitionListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryFixedAllowanceListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterEmployeeLeaveRecordsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  LeaveTypeIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTaxReliefListingDefaultExcludedArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEmployeeTaxReliefListingArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTaxReliefDetailsArgs = {
  EmployeeTaxReliefID: Scalars['String'];
};


export type QueryEmployeeAssetListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAssetDetailArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailForYearArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type QueryEmployeeDependentsListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryHasEmergencyContactArgs = {
  DependentID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryMyEmployeeInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyNewsListArgs = {
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyApplicationDetailArgs = {
  RequestID: Scalars['String'];
  RequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeCp22ListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeListingWithPayrollInputArgs = {
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeNotificationListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeTrainingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeUserDefineFieldsListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryZakatContributionListingArgs = {
  input: ZakatContributionInput;
};


export type QueryZakatContributionDetailsArgs = {
  ID: Scalars['String'];
};


export type QueryIsZakatPeriodOverlapArgs = {
  PayrollCycleID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ZakatID?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppSecurityListingArgs = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityAssignmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityPermissionsByContactIdArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Permissions?: Maybe<Array<HrPermission>>;
  ContactID: Scalars['String'];
};


export type QueryEaDocumentsByEmployeeIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPayslipDocumentsByEmployeeIdArgs = {
  Year?: Maybe<Scalars['Float']>;
  EmployeeID: Scalars['String'];
};


export type QueryEonDocumentsByBatchArgs = {
  Type: Scalars['String'];
};


export type QuerySystemEonDocumentsCountArgs = {
  Type: Scalars['String'];
};


export type QueryHelpdeskListingReportArgs = {
  input: HelpdeskListingReportInput;
};


export type QueryHelpdeskSubjectDetailArgs = {
  SubjectID: Scalars['String'];
};


export type QueryIsHelpdeskSubjectExistArgs = {
  SubjectID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
};


export type QueryGetHelpdeskTicketSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryHelpdeskTicketDetailArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type QueryHelpdeskTicketListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Assignee?: Maybe<Array<Scalars['String']>>;
  HelpdeskSubjectType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RequestType: Scalars['String'];
};


export type QueryHelpdeskToDoListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryBlockLeaveListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBlockLeaveEmpListingArgs = {
  UntilDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCarryForwardEncashmentPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetCarryForwardEncashmentPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryViewLeaveApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetLeaveRecordsListingArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  effectiveMonth?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveRecordsTotalCountArgs = {
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveReplacementListingByEmployeeArgs = {
  AsToDate?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentListingArgs = {
  input: LeaveAdjustmentListingInput;
};


export type QueryGetCompulsoryLeaveListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveDetailArgs = {
  LeaveCompulsoryID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePostingListingArgs = {
  input: LeavePostingListingInput;
};


export type QueryGetLeaveApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveListingArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryLeaveAdjustmentDetailArgs = {
  LeaveHeaderID: Scalars['String'];
};


export type QueryGetLeavePostingPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYearEndCarryForwardListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryFilterGetYearEndCarryForwardListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveHomeSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveTakenListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarArgs = {
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarToDoArgs = {
  CalendarDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryLeaveOutOfStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountMappingListArgs = {
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetAccountMappingAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryClaimAccountMappingListArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountMappingAssignmentArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryClaimAccountHeaderListWithDetailsArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPeClaimAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimRecordsListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryClaimGlCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
};


export type QueryCostCenterMappingListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsGlProcessedArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type QueryProjectCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMultiCurrencySetupArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCheckMultiCurrencyAlreadyExistArgs = {
  CurrencyName: Scalars['String'];
  CurrencyCode: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetEmployeeRoleNameArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyDetailsListingArgs = {
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckMultiCurrencyInUseArgs = {
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryOvertimeForecastReportArgs = {
  FormulaFormat: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetOvertimeArgs = {
  WorkDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
};


export type QueryGetTotalOverTimeArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CutOffDay: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingArgs = {
  IsExcelFileOnly?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeWithdrawalCheckingArgs = {
  WorkDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryOvertimeListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeV2Args = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryViewOvertimeApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetOvertimeApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetOvertimeToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryOvertimePeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSingleAdvancePolicyDetailArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type QueryDuplicateAdvancePolicyNameArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  AdvancePolicyName: Scalars['String'];
};


export type QueryAdvancePolicyByCompanyAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPostedAdvanceProcessingListingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompanyPayGroupListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjustmentListingArgs = {
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjFormEmpListingArgs = {
  ExcludeEmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFormulaTestArgs = {
  formula: Scalars['String'];
};


export type QueryPayrollCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSalaryTestArgs = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySalaryPayoutAnalysisSummaryExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollDetailListingExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingAndCountArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCp22CountArgs = {
  ID: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollCyclePeriodSettingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryViewPayrollHistorySummaryArgs = {
  EmployeeID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTestPayrollCalcArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryCountArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryTotalArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryMyPayrollInfoArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryByEmployeeArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryPayrollProcessPeriodListingArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  IsViewHistory: Scalars['Boolean'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayItemListingArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessEmployeeListingArgs = {
  input: EmployeeFilterInput;
};


export type QueryCalculatePayrollArgs = {
  PayrollCalculationInput: PayrollCalculationInput;
};


export type QueryCalculateEpfRateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRoundingArgs = {
  input: Scalars['Float'];
};


export type QueryCalculateHrdfArgs = {
  HRDFCalculationInput: HrdfCalculationInput;
};


export type QueryCalculateEpfv2Args = {
  EPFCalculationInput: EpfCalculationInputV2;
};


export type QueryCalculateEisArgs = {
  EISCalculationInput: EisCalculationInput;
};


export type QueryCalculateSocsoArgs = {
  SocsoCalculationInput: SocsoCalculationInput;
};


export type QueryPayPeriodDetailsArgs = {
  PeriodYearMonth: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryStartPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryEndPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryPayItemByPayTypeArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  PayType: Array<Scalars['String']>;
};


export type QueryPayItemListingArgs = {
  OnlySubscription?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListArgs = {
  PayItemID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListing2Args = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QuerySalaryListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySalaryListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryClaimTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryPayrollFixedAllowanceListingArgs = {
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceDetailsArgs = {
  RecurringPayID: Scalars['String'];
};


export type QueryCp159ListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingByPayPeriodAndPayCycleArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPublishPaySlipListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  Year: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPublishPaySlipListingByEmployeeArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetSingleUserArgs = {
  ID: Array<Scalars['String']>;
};


export type QueryPublishEaListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPublishEaListingByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIsMonthlyTrxInUseArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMonthlyTransactionByEmployeeArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByEmployeeCountArgs = {
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByPayItemArgs = {
  input: PayItemFilterInput;
};


export type QueryMonthlyTransactionByExcelArgs = {
  input: MonthlyTransactionInput;
};


export type QueryFreezepayrollListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollFixedAllowanceByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPublishedEaCompanyListingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryCheckOldDataForSalaryBatchArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForOneTimePayInsertArgs = {
  PayPeriodID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForRecurringPayInsertArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryEmployeeSalaryLogsArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryLogsCountArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryPayrollHomeInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryOtherPayrollRelatedInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeKpiListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeKpiDetailsArgs = {
  EmployeeKpiID: Scalars['String'];
};


export type QueryEmployeeKpiRepoListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRetrieveEmployeeKpiDetailFromRepoArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type QueryIsKpiExistInRepoArgs = {
  KpiRepoID?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryKpiExerciseListingArgs = {
  KpiFormStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type QueryKpiExerciseSetupInfoArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type QueryIsKpiExerciseIdExistByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
};


export type QueryIsKpiExerciseDateRangeOverlappingByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
};


export type QueryKpiCategoryWithOrgGoalListingArgs = {
  Year: Scalars['Float'];
};


export type QueryOrganizationalGoalByCategoryListingArgs = {
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
};


export type QueryOrganizationalGoalDetailsArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type QueryIsOrgGoalExistByCategoryArgs = {
  OrgGoalID?: Maybe<Scalars['String']>;
  KpiCategoryID: Scalars['String'];
  Year: Scalars['Float'];
  Goal: Scalars['String'];
};


export type QueryOrgKpiPlanningTreeViewListingArgs = {
  Year: Scalars['Float'];
};


export type QueryKpiCategoryWithOrgKpiPlanningListingArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningDetailsByKpiCategoryListingArgs = {
  KpiCategoryID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningInfoArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryIsOrgKpiExistByCategoryArgs = {
  OrgKpiPlanningDetailslID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryPerformanceGroupMatrixListingArgs = {
  GroupType: Scalars['String'];
};


export type QueryMyPerformanceSummaryArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetPerformanceToDoArgs = {
  PerformanceRequestType: Scalars['String'];
  ContactID: Scalars['String'];
};


export type QueryGetPerformanceApprovalHistoryArgs = {
  PerformanceRequestType: Scalars['String'];
  ApprovalContactID: Scalars['String'];
};


export type QueryPerformanceEmployeeListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequestType: Scalars['String'];
};


export type QueryReviewExerciseListingArgs = {
  ReviewFormStatus?: Maybe<Array<Scalars['String']>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ReviewExerciseIDs: Array<Scalars['String']>;
};


export type QueryGetEmployeeReviewListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewActionListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewFormArgs = {
  EmployeeReviewID: Scalars['String'];
};


export type QueryGetConversationLogByReviewFormItemArgs = {
  EmployeeReviewSectionItemID: Scalars['String'];
};


export type QueryReviewExerciseSetupInfoArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsReviewExerciseIdExistArgs = {
  ReviewExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
};


export type QueryPeerRegistrationListingArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsSuperiorAlreadyExistArgs = {
  NewSuperiorID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
};


export type QueryMySubordinatePerformanceSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySubordinateKpiEmpListingArgs = {
  ContactID: Scalars['String'];
};


export type QueryPeriodicAllowanceTypeListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryPersonnelCertificateListingArgs = {
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoListingArgs = {
  ContractInfoID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoDueListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoDueListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelEducationListingArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryIsEmailExistsArgs = {
  Email: Scalars['String'];
};


export type QueryIsMobileNoExistsArgs = {
  MobileNo: Scalars['String'];
};


export type QueryIsEmployeeNoExistsArgs = {
  EmployeeNo: Scalars['String'];
};


export type QueryDocumentProfilePictureArgs = {
  ContactID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEFileListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelEFileListingCountArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelHealthInfoSummaryArgs = {
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelHomeInfoLinesArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelJobHistoryListingArgs = {
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelReferenceListingArgs = {
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryUserDefineDetailListingArgs = {
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeInUseArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  InsuranceSchemeID: Scalars['String'];
};


export type QueryPersonnelInsuranceSchemePersonnelListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type QueryProbationExtensionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationProbationListingArgs = {
  IsCompleted?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedCareerLogListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingBySubscriptionArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryStaffRetirementRetiredListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRetirementDueListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffExitCompanyListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStaffPromotionDemotionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PromotionOrDemotion: MovementType;
  CompanyID: Scalars['String'];
};


export type QueryStaffSecondmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRedesignationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInternalTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferCopyTransferDataArgs = {
  EffectiveDate: Scalars['DateTime'];
  CurrentCompanyID: Scalars['String'];
  CurrentEmployeeID: Scalars['String'];
  NewCompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferDetailsArgs = {
  CareerLogID: Scalars['String'];
};


export type QueryInterCoTransferLeavePolicyDataArgs = {
  EmployeeDetailsInput: EmployeeDetailsInput;
  EmployeeID: Scalars['String'];
  CurrentCompanyID: Scalars['String'];
};


export type QueryGetProjectExpenseClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryGetPeClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetPeClaimRecordsTotalCountArgs = {
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryProjecExpenseSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryVerifyMyPeClaimArgs = {
  VerfiyPEClaimInput: VerfiyPeClaimInput;
};


export type QueryIsProjectExpenseSubjectExistsArgs = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  input: ProjectExpenseClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryGetPeClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: PeClaimPostingPeriodListingInput;
};


export type QueryGetPeClaimPostingListingArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetApprovedProjectExpenseClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeApprovedClaimCountArgs = {
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetApprovedProjectExpenseClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryPeClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetProjectToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetProjectExpenseApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetProjectAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectAccountMappingListArgs = {
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectPayrollGlExportArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectGlClaimCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimCycleListArgs = {
  Type?: Maybe<Scalars['String']>;
  AllClaimCycles?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryProjectCostAllocationListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationEmployeeListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationDetailListingArgs = {
  ProjectCostAllocationID: Scalars['String'];
};


export type QueryGetApproverAssignmentListingArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type QueryIsApproverEmailAlreadyExistArgs = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  EmailAddress: Scalars['String'];
};


export type QueryProjectTimesheetPeriodListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryDailyEntryListingArgs = {
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetSubmissionListingArgs = {
  EmployeeCalendar: Scalars['Boolean'];
  DutyRoster: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetApprovalListingArgs = {
  token: Scalars['String'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingCountArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheetEmployeeListingArgs = {
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryAdminDailyEntryListingArgs = {
  PeriodYearMonth: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheeTApproverReportListingArgs = {
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryRequisitionMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  filterText: Scalars['String'];
  ApStatus: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingTotalCountArgs = {
  ApStatus: Array<Scalars['String']>;
  DepartmentIDs: Array<Scalars['String']>;
  EmployeeStatus: Array<Scalars['String']>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeRequisitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailsArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};


export type QueryFilterEmployeeRequisitionListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTravelRequsitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type QueryCheckVerifyEndIfArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRequisitionApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetRequisitionToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetRequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimParameterListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimParameterListingAllArgs = {
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimUserDefineFieldsListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryVehicleRateListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryPersonnelParameterListingByTypeArgs = {
  TypeList?: Maybe<Array<Scalars['String']>>;
};


export type QueryReportTemplateListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
};


export type QueryReportTemplateAssignmentListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStatesListingArgs = {
  Country?: Maybe<Scalars['String']>;
};


export type QueryViewGeneralSettingSummaryArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryHomeSummaryArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeMainMenuSummaryArgs = {
  JobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmploymentInfoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPersonnelMenuSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEaPartFListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEisPolicyDetailArgs = {
  EISPolicyID: Scalars['String'];
};


export type QueryEpfPolicyDetailArgs = {
  EPFPolicyID: Scalars['String'];
};


export type QueryFormItemRatingDetailsArgs = {
  FormItemRatingID: Scalars['String'];
};


export type QueryIsFormItemRatingTitleExistArgs = {
  FormItemRatingID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiCategoryDetailsArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryIsKpiCategoryTitleExistArgs = {
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedItemListingArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryKpiPredefinedItemDetailsArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type QueryIsKpiPredefinedItemTitleExistArgs = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedTemplateDetailsArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type QueryIsKpiPredefinedTemplateTitleExistArgs = {
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewFormTemplateDetailsArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type QueryIsReviewFormTemplateTitleExistArgs = {
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGradingTableDetailsArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type QueryIsReviewGradingTitleExistArgs = {
  ReviewGradingTableID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGroupDetailsArgs = {
  ReviewGroupID: Scalars['String'];
};


export type QueryIsReviewGroupTitleExistArgs = {
  ReviewGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewItemDetailsArgs = {
  ReviewItemID: Scalars['String'];
};


export type QueryIsReviewItemCodeExistArgs = {
  ReviewItemID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
};


export type QueryReviewPolicyDetailsArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type QueryIsReviewPolicyTitleExistArgs = {
  ReviewPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewProcessPolicyDetailsArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type QueryIsReviewProcessPolicyTitleExistArgs = {
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewTemplateDetailsArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type QueryIsReviewTemplateTitleExistArgs = {
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryRewardGroupDetailsArgs = {
  RewardGroupID: Scalars['String'];
};


export type QueryIsRewardGroupTitleExistArgs = {
  RewardGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryPublicHolidayCalendarListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayForStatesListingArgs = {
  Description: Scalars['String'];
  HolidayDate: Scalars['DateTime'];
};


export type QuerySocsoPolicyDetailArgs = {
  SocsoPolicyID: Scalars['String'];
};


export type QuerySystemAdminMenuSummaryArgs = {
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryTaxReliefListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryAttendanceAnalysisArgs = {
  ToDate?: Maybe<Scalars['String']>;
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryMonthlyAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryProcessingListingArgs = {
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAutomatedClockDataImportListingArgs = {
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
};


export type QueryGetBeaconDevicesListingArgs = {
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
};


export type QueryGetChangeShiftArgs = {
  CompanyID: Scalars['String'];
  ChangeShiftID?: Maybe<Scalars['String']>;
};


export type QueryGetShiftByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByTlaArgs = {
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByShiftPatternArgs = {
  CompanyID: Scalars['String'];
  ShiftPatternID: Scalars['String'];
};


export type QueryGetShiftPatternByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpByDutyArgs = {
  DutyDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetChangeShiftListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryViewChangeShiftApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetChangeShiftToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Date: Scalars['DateTime'];
};


export type QueryChangeShiftMenuSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDailyAllowanceArgs = {
  DailyAllowanceID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};


export type QueryGetDailyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDeductionPolicyArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetDeductionPolicyByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePostingArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryEmployeeTmsSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradePolicyMatrixArgs = {
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  JobGradeID?: Maybe<Scalars['String']>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetMobileCheckInArgs = {
  Status?: Maybe<Status>;
  MobileCheckInID?: Maybe<Scalars['String']>;
};


export type QueryQrListingArgs = {
  DocumentID: Scalars['String'];
};


export type QueryEmpAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryVerifyScanArgs = {
  Location: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOvertimeClaimTableArgs = {
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetOvertimeReasonArgs = {
  OTReason?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryOtReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryOtReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPeriodicDeductionArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllowanceDeductionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AllowanceDeductionID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOtRoundingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftDayArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftGroupArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeShiftGroupArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeShiftGroupAssignmentLogArgs = {
  Today?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetShiftPatternArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryShiftPatternListingArgs = {
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryValidShiftPatternListingArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryTemplateLibrarySummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTmsSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryUserDefineEpfPolicyConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetTemplateLibraryArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetTemplateLibraryAssignmentArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradeByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPayCycleForPayCalendarArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIsPayrollCycleInUseArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryGetTemplateLibraryAssignmentInfolineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDailyTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDailyPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryDeductionTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDeductionPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTmsTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOtPayItemConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeOtPoliciesArgs = {
  WorkDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMonthlyTypeConditionListArgs = {
  ClaimType: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTmsPolicyMenuInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryViewTimeOffApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetTimeOffAproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetTimeOffToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryTimeOffApplicationPeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetTimeOffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTimeOffSettingListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTsAccountTypeListArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTsAccountCodeListArgs = {
  AccountTypeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTimesheetListArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetHoursArgs = {
  SheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetDatesArgs = {
  sheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccCodeArgs = {
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByActivityArgs = {
  Activity: Scalars['String'];
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccTypeArgs = {
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccountArgs = {
  Account: Scalars['String'];
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsActvitiyInUseArgs = {
  TsAccountTypeID: Scalars['String'];
  Activities: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryToDoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainingAttendanceListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeTrainingRecordArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeTrainingAttendanceListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsaryCourseByJobGradeListingArgs = {
  CourseID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsaryCourseByJobGradeListingWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTrainingCourseListingArgs = {
  CourseClassificationID: Scalars['String'];
};


export type QueryTrainingCourseListingByJobGradeArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryGetLatestUpdateForMainMenuModuleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySupervisorEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCourseEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainerEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTrainerEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSupervisorEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByJobGradeArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByCourseArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOsFulfilmentByJobGradeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryScheduleTrainingListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRegisteredTraineeListingArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type QueryEmployeeScheduledTrainingListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAvailableCoursesListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsoryCourseListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmpTrainingAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCostBudgetListingArgs = {
  Year: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainingCostListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryCostAllocationListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryHrdfClaimListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPersonalTtdaInfoArgs = {
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  IsApprovalPolicy?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryUserRoleIDbyUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
};


export type QueryGetCompanyPermissionArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetRolePermissionByUserArgs = {
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetUsersRoleContractArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetUserRolePermissionByRoleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowancePolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowanceEntitlementListingArgs = {
  AllowanceBenefitID: Scalars['String'];
};


export type QueryAllowanceEntitlementDetailArgs = {
  AllowanceItemIDs: Array<Scalars['String']>;
};


export type QueryCompanySummaryDatesArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayProcessSelectPayPeriodArgs = {
  input: PayPeriodSelectionInput;
};


export type QueryPayProcessSelectPayrollCycleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessEmployeeListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessDetailArgs = {
  PayrollDetailID: Scalars['String'];
};


export type QueryMobileNotificationArgs = {
  Body: Scalars['String'];
  Title: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryLoginSsoArgs = {
  DeviceToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryVerifyRedirectDomainTokenArgs = {
  Token: Scalars['String'];
};


export type QueryGetUserListingArgs = {
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  UserStatus?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeUserListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
  accountID: Scalars['String'];
};


export type QueryGetActiveUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryUserProfilePicArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  UserID: Scalars['String'];
};


export type QueryTestWorkFlowServicesArgs = {
  input?: Maybe<Scalars['Float']>;
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Iban = 'Iban',
  Bidayuh = 'Bidayuh',
  Melanau = 'Melanau',
  OrangUlu = 'Orang_Ulu',
  Bajau = 'Bajau',
  Murut = 'Murut',
  KadazanDusun = 'Kadazan_Dusun',
  OthersSarawakNative = 'Others_Sarawak_Native',
  OthersSabahNative = 'Others_Sabah_Native',
  Others = 'Others'
}

export enum RatingInputMethod {
  Fixed = 'Fixed',
  Range = 'Range'
}

export type RatingScaleTable = {
  __typename?: 'RatingScaleTable';
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type RatingScaleTableInput = {
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export enum ReadStatus {
  Read = 'Read',
  Unread = 'Unread'
}

export type Reason = InterfaceBase & {
  __typename?: 'Reason';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type ReasonInput = {
  Name: Scalars['String'];
};

export enum RecommendOption {
  AnnualIncrement = 'Annual_Increment',
  SalaryAdjustment = 'Salary_Adjustment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  WitholdIncrement = 'Withold_Increment',
  Termination = 'Termination'
}

export type RecommendSectionInput = {
  RecommendOption?: Maybe<RecommendOption>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  EffectiveUntilDate?: Maybe<Scalars['DateTime']>;
  CurrentSalary?: Maybe<Scalars['Float']>;
  ProposedIncrement?: Maybe<Scalars['Float']>;
  CurrentJobPosition?: Maybe<Scalars['String']>;
  NewJobPosition?: Maybe<Scalars['String']>;
  CurrentJobGrade?: Maybe<Scalars['String']>;
  NewJobGrade?: Maybe<Scalars['String']>;
};

export enum RecordSource {
  ExcelUpload = 'ExcelUpload',
  ManualByPayItem = 'ManualByPayItem',
  ManualByEmployee = 'ManualByEmployee',
  RecurringPay = 'RecurringPay',
  RecurringZakat = 'RecurringZakat',
  AdvancePolicy = 'AdvancePolicy'
}

export type RecruitmentSourceBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type RecruitmentSourceEntity = InterfaceBase & {
  __typename?: 'RecruitmentSourceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecruitmentSourceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeInfo?: Maybe<EmployeeEntity>;
};

export type RecruitmentSourceInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RecurringPayEntity = InterfaceBase & {
  __typename?: 'RecurringPayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringPayID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type RecurringPayInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OldRecurringPayID?: Maybe<Array<Scalars['String']>>;
};

export type RecurringRestOffDayEntity = InterfaceBase & {
  __typename?: 'RecurringRestOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringRestOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType: RestOffType;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  States?: Maybe<StatesEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type RecurringRestOffDayInput = {
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType?: Maybe<RestOffType>;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayIDs?: Maybe<Array<Scalars['String']>>;
};

export enum Relationship {
  Aunt = 'Aunt',
  Brother = 'Brother',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  Father = 'Father',
  Husband = 'Husband',
  Mother = 'Mother',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Sister = 'Sister',
  Son = 'Son',
  Uncle = 'Uncle',
  Wife = 'Wife',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Sikh = 'Sikh',
  Others = 'Others'
}

export type ReplacementLeaveClaimType = {
  __typename?: 'ReplacementLeaveClaimType';
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeList>>;
};

export type ReplacementLeaveClaimTypeInput = {
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeListInput>>;
};

export type ReplacementLeaveClaimTypeList = {
  __typename?: 'ReplacementLeaveClaimTypeList';
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReplacementLeaveClaimTypeListInput = {
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReportPayItemOutput = {
  __typename?: 'ReportPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  PayItemTypeName?: Maybe<Scalars['String']>;
};

export type ReportTemplateAssignmentEntity = InterfaceBase & {
  __typename?: 'ReportTemplateAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateAssignmentID: Scalars['String'];
  ReportTemplateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
};

export type ReportTemplateAssignmentInput = {
  ReportTemplateAssignmentID?: Maybe<Scalars['String']>;
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ReportTemplateEntity = InterfaceBase & {
  __typename?: 'ReportTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Documents?: Maybe<DocumentsEntity>;
};

export type ReportTemplateInput = {
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ReportTemplateType {
  Confirmation = 'Confirmation',
  ProbationExtension = 'Probation_Extension',
  Demotion = 'Demotion',
  Secondment = 'Secondment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer',
  Termination = 'Termination',
  Retirement = 'Retirement',
  ExitCompany = 'Exit_Company'
}

export enum ReportType {
  EaForm = 'EAForm',
  Cp21 = 'CP21',
  Cp22A = 'CP22A',
  Cp22B = 'CP22B',
  Cp22 = 'CP22'
}

export type ReportYearOutput = {
  __typename?: 'ReportYearOutput';
  Year?: Maybe<Scalars['Float']>;
};

export type ReportingGroupEntity = NewInterfaceBase & {
  __typename?: 'ReportingGroupEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_group_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  GroupInUse: Scalars['Boolean'];
  CompanyInUse: Scalars['Boolean'];
};

export type ReportingGroupInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export enum ReportingRecordType {
  Active = 'Active',
  Future = 'Future',
  Expired = 'Expired'
}

export type ReportingStructureEntity = InterfaceBase & {
  __typename?: 'ReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  FromJobPosition?: Maybe<JobDesignationEntity>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToJobPosition?: Maybe<JobDesignationEntity>;
  SubordinateID?: Maybe<Scalars['String']>;
  Subordinate?: Maybe<EmployeeEntity>;
  SuperiorID?: Maybe<Scalars['String']>;
  Superior?: Maybe<EmployeeEntity>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ReportingStructureGroupInput = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
  CompanyID: Scalars['String'];
  JobPositionReportingInput?: Maybe<Array<JobPositionReportingInput>>;
  EmployeeReportingInput?: Maybe<Array<EmployeeReportingInput>>;
};

export type ReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export type ReportingStructureOutput = {
  __typename?: 'ReportingStructureOutput';
  ReportingGroupID: Scalars['String'];
  ReportingGroup?: Maybe<ReportingGroupEntity>;
  ItemActionType: ItemActionType;
  Title: Scalars['String'];
  JobPositionReporting?: Maybe<Array<JobPositionReportingOutput>>;
  EmployeeReporting?: Maybe<Array<EmployeeReportingOutput>>;
};

export enum ReportingType {
  ByEmployee = 'By_Employee',
  ByJobPosition = 'By_JobPosition'
}

export enum RequestType {
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition',
  Overtime = 'Overtime',
  ChangeShift = 'Change_Shift',
  TimeOff = 'Time_Off',
  Request = 'Request',
  ProjectExpenseClaim = 'Project_Expense_Claim',
  DataChanges = 'Data_Changes',
  KpiSetting = 'KPI_Setting',
  KpiApproval = 'KPI_Approval',
  ReviewSetting = 'Review_Setting',
  ReviewApproval = 'Review_Approval',
  HelpdeskTicket = 'Helpdesk_Ticket',
  ProjectTimesheet = 'Project_Timesheet'
}

export type RequisitionAppOutput = {
  __typename?: 'RequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type RequisitionDetails = {
  __typename?: 'RequisitionDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TotalDays?: Maybe<Scalars['Float']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type RequisitionEntity = InterfaceBase & {
  __typename?: 'RequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  TravelRequestID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  MovementType?: Maybe<MovementType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SubordinateDetail?: Maybe<EmployeeEntity>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  RequisitionHistory?: Maybe<Array<RequisitionEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type RequisitionInput = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  RequisitionType: RequisitionType;
  TravelRequestID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
};

export type RequisitionOutput = {
  __typename?: 'RequisitionOutput';
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelRequestID?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobPositionName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  OrgUnitName?: Maybe<Scalars['String']>;
  ReqOrgUnitID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  EmpSubordinateName?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  RequestCompanyName?: Maybe<Scalars['String']>;
};

export type RequisitionTakenSummaryByTypeOutput = {
  __typename?: 'RequisitionTakenSummaryByTypeOutput';
  RequisitionType?: Maybe<RequisitionType>;
  TotalRequisitionCount?: Maybe<Scalars['Float']>;
  TotalRequisitionAmount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
};

export enum RequisitionType {
  AssetRequisition = 'Asset_Requisition',
  TravelRequest = 'Travel_Request',
  CashAdvance = 'Cash_Advance',
  Others = 'Others',
  StaffMovementRequest = 'Staff_Movement_Request'
}

export type RequisitionTypeEntity = InterfaceBase & {
  __typename?: 'RequisitionTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionTypeInput = {
  ID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionUpdateDocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RequisitionUpdateInput = {
  RequisitionHeaderID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type ResBiyearly = {
  __typename?: 'ResBiyearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResLifetimely = {
  __typename?: 'ResLifetimely';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResMonthly = {
  __typename?: 'ResMonthly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResYearly = {
  __typename?: 'ResYearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum ResidentStatus {
  Resident = 'Resident',
  NonResident = 'Non_Resident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker',
  CSuite = 'C_Suite'
}

export type RestOffDayOutput = {
  __typename?: 'RestOffDayOutput';
  DayOfWeek?: Maybe<Scalars['Float']>;
  Label?: Maybe<Scalars['String']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Disabled?: Maybe<Scalars['Boolean']>;
};

export enum RestOffType {
  RestDay = 'Rest_Day',
  OffDay = 'Off_Day',
  PublicHoliday = 'Public_Holiday'
}

export type ReversePostedLcfeInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReversePostedYecfInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReviewConversationLogEntity = InterfaceBase & {
  __typename?: 'ReviewConversationLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  DocumentIDs?: Maybe<Scalars['JSON']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItem?: Maybe<EmployeeReviewSectionItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  CommentBy: Scalars['JSON'];
  Documents?: Maybe<Array<DocumentsEntity>>;
  VoiceDocument?: Maybe<DocumentsEntity>;
};

export type ReviewConversationLogInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  VoiceInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
};

export type ReviewExerciseEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type ReviewExerciseInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewInput>;
};

export type ReviewExerciseListingOutput = {
  __typename?: 'ReviewExerciseListingOutput';
  ReviewExerciseSetupID: Scalars['String'];
  ReviewExerciseName: Scalars['String'];
  ReviewExercise: Array<ReviewExerciseEntity>;
};

export type ReviewExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  ReviewGroupName: Scalars['String'];
  ReviewFormTemplateName: Scalars['JSON'];
};

export type ReviewExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export type ReviewExerciseSetupEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  GenerateForCurrentReporting?: Maybe<Scalars['Boolean']>;
  UseRecentKpi?: Maybe<Scalars['Boolean']>;
  ExcludeResigning?: Maybe<Scalars['Boolean']>;
  AdhocSetupDetails?: Maybe<Scalars['JSON']>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsEntity>;
  ReviewExercise: Array<ReviewExerciseEntity>;
  InUse: Scalars['Boolean'];
};

export type ReviewExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  GenerateForCurrentReporting: Scalars['Boolean'];
  UseRecentKpi: Scalars['Boolean'];
  ExcludeResigning: Scalars['Boolean'];
  AdhocSetupDetails?: Maybe<AdhocSetupDetails>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsInput>;
};

export type ReviewFormItemSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type ReviewFormSectionSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  Recommendation?: Maybe<RecommendSectionInput>;
  ReviewFormItem?: Maybe<Array<ReviewFormItemSubmitInput>>;
};

export type ReviewFormSubmitInput = {
  ID: Scalars['String'];
  ReviewDate: Scalars['DateTime'];
  Status: Status;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog: Array<ReviewProcessLog>;
  ReviewFormSection: Array<ReviewFormSectionSubmitInput>;
};

export type ReviewFormTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewFormTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  WeightageType: QuantitativeType;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID: Scalars['String'];
  ReviewType: ReviewFormTemplateType;
  ApplicableTo: Array<AppraiseeType>;
  Description: Scalars['String'];
  ReviewProcessPolicy?: Maybe<ApprovalPolicyEntity>;
  ReviewGradingTable: ReviewGradingTableEntity;
  ReviewSection: Array<ReviewSectionEntity>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewFormTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewType?: Maybe<ReviewFormTemplateType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewSections?: Maybe<Array<ReviewSectionInput>>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewFormTemplateType {
  Periodic = 'Periodic',
  Confirmation = 'Confirmation',
  Adhoc = 'Adhoc'
}

export type ReviewGradingTableEntity = InterfaceBase & {
  __typename?: 'ReviewGradingTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  GradingTable: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewGradingTableInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  GradingTable?: Maybe<Array<GradingTableInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewGroupEntity = InterfaceBase & {
  __typename?: 'ReviewGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  KpiExercises?: Maybe<Array<KpiExerciseDetailsEntity>>;
  InUse: Scalars['Boolean'];
};

export type ReviewGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemDetail = {
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  KpiItemWeightage?: Maybe<Scalars['Float']>;
  WeightageType: Scalars['String'];
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<Scalars['String']>;
  ratingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  TemplateID: Scalars['String'];
  ReviewItemID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  FormItemRatingSetup?: Maybe<FormItemRatingSetup>;
};

export type ReviewItemEntity = InterfaceBase & {
  __typename?: 'ReviewItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Code: Scalars['String'];
  Description: Scalars['String'];
  ItemRatingMethod: ItemRatingMethod;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo: Array<AppraiseeType>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<ItemRatingMethod>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemList = {
  __typename?: 'ReviewItemList';
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewItemListInput = {
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewPeerRegistrationEntity = InterfaceBase & {
  __typename?: 'ReviewPeerRegistrationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
  Employee: EmployeeEntity;
  PeerInfoListing: Array<EmployeeEntity>;
};

export type ReviewPeerRegistrationInput = {
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
};

export type ReviewPolicyDetail = {
  __typename?: 'ReviewPolicyDetail';
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyDetailInput = {
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ReviewPolicyDetails: Array<Scalars['JSON']>;
  ReviewGradingID: Scalars['String'];
  ReviewGrading: ReviewGradingTableEntity;
  InUse: Scalars['Boolean'];
};

export type ReviewPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewPolicyDetails?: Maybe<Array<ReviewPolicyDetailInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewProcessLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeRoleID?: Maybe<Scalars['String']>;
  EmployeeRole?: Maybe<Scalars['String']>;
  Status: WorkflowLogStatus;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
};

export type ReviewProcessPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewProcessPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ApprovalList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewProcessPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewSectionEntity = InterfaceBase & {
  __typename?: 'ReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  Title: Scalars['String'];
  Type: ReviewSectionType;
  Weightage: Scalars['Float'];
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  AccessControl: Array<Scalars['JSON']>;
  Sequence: Scalars['Float'];
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  ReviewTemplate?: Maybe<ReviewTemplateEntity>;
  ReviewFormTemplate: ReviewFormTemplateEntity;
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
};

export type ReviewSectionInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Type?: Maybe<ReviewSectionType>;
  Weightage?: Maybe<Scalars['Float']>;
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
  AccessControl?: Maybe<Array<AccessControlInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewSectionType {
  Kpi = 'Kpi',
  Review = 'Review',
  Recommendation = 'Recommendation'
}

export type ReviewTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  ApplicableTo: Array<AppraiseeType>;
  WeightageType: QuantitativeType;
  ReviewItemList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  ItemCount: Scalars['Float'];
};

export type ReviewTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewItemList?: Maybe<Array<ReviewItemListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RewardGroupEntity = InterfaceBase & {
  __typename?: 'RewardGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  InUse: Scalars['Boolean'];
};

export type RewardGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
  roleUserAssignments?: Maybe<Array<RoleUserEntAssignmentEntity>>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  role: RoleEntity;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingCriteria = {
  __typename?: 'RoundingCriteria';
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingCriteriaInput = {
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingMethodEntity = InterfaceBase & {
  __typename?: 'RoundingMethodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoundingMethodID: Scalars['String'];
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export type RoundingMethodInput = {
  RoundingMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export enum RoundingType {
  Time = 'TIME',
  Unit = 'UNIT'
}

export type SdTrainingCoursesEntity = InterfaceBase & {
  __typename?: 'SDTrainingCoursesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SDTrainingCoursesID: Scalars['String'];
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  StaffDevelopmentProgram?: Maybe<StaffDevelopmentProgramEntity>;
};

export type SdTrainingCoursesInput = {
  SDTrainingCoursesID?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SalaryType {
  Monthly = 'Monthly',
  Daily = 'Daily'
}

export type ScaleRatingDetailEntity = InterfaceBase & {
  __typename?: 'ScaleRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingDetailID: Scalars['String'];
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
};

export type ScaleRatingDetailInput = {
  ScaleRatingDetailID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScaleRatingHeaderEntity = InterfaceBase & {
  __typename?: 'ScaleRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  ScaleRatingDetails?: Maybe<Array<ScaleRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type ScaleRatingHeaderInput = {
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ScaleRatingDetailInput>>;
};

export type ScheduleTrainingEntity = InterfaceBase & {
  __typename?: 'ScheduleTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScheduledTrainingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Registered?: Maybe<Scalars['Boolean']>;
  Document?: Maybe<DocumentsEntity>;
  QRCodeDocument?: Maybe<DocumentsEntity>;
  GetTrainingCostItemTotal?: Maybe<Scalars['Int']>;
};

export type ScheduleTrainingInput = {
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Sequence {
  FirstDeduction = 'FIRST_DEDUCTION',
  SecondDeduction = 'SECOND_DEDUCTION',
  ThirdDeduction = 'THIRD_DEDUCTION'
}

export enum SequenceOrderType {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum SessionType {
  Full = 'Full',
  FirstHalf = 'First_Half',
  SecondHalf = 'Second_Half'
}

export type ShiftDayEntity = InterfaceBase & {
  __typename?: 'ShiftDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  DayType: DayType;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay: CrossDayType;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak: Scalars['Boolean'];
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  IsPreOvertime: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime: Scalars['Boolean'];
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
};

export type ShiftDayInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay?: Maybe<CrossDayType>;
  DayType?: Maybe<DayType>;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak?: Maybe<Scalars['Boolean']>;
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  BreakTimeList?: Maybe<Array<BreakTimeListInput>>;
  IsPreOvertime?: Maybe<Scalars['Boolean']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime?: Maybe<Scalars['Boolean']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime?: Maybe<Scalars['Boolean']>;
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
};

export type ShiftEntity = InterfaceBase & {
  __typename?: 'ShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  DutyRosterDisplayColour: Scalars['String'];
  IsPreOvertimeMin: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertimeMin: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertimeMin: Scalars['Boolean'];
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  fromShiftList?: Maybe<Array<ChangeShiftEntity>>;
  toShiftList?: Maybe<Array<ChangeShiftEntity>>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
  shiftDayList?: Maybe<Array<ShiftDayEntity>>;
  shiftPatternDetailList?: Maybe<Array<ShiftPatternDetailEntity>>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupEmployeeAssignmentLogEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEmployeeAssignmentLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupEmployeeAssignmentLogID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupOBJ: ShiftGroupEntity;
  hasDutyRoster: Scalars['Boolean'];
};

export type ShiftGroupEmployeeAssignmentLogInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ShiftGroupEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  ShiftGroupShift: Array<ShiftGroupShiftEntity>;
  ShiftGroupEmployeeAssignmentLog?: Maybe<Array<ShiftGroupEmployeeAssignmentLogEntity>>;
  ShiftGroupPattern?: Maybe<Array<ShiftGroupPatternEntity>>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
  Shift?: Maybe<Array<ShiftEntity>>;
  ShiftCount: Scalars['Float'];
  Employee?: Maybe<Array<EmployeeEntity>>;
  EmployeeCount: Scalars['Float'];
};

export type ShiftGroupInput = {
  ShiftGroupID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupPatternEntity = InterfaceBase & {
  __typename?: 'ShiftGroupPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};

export type ShiftGroupPatternInput = {
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type ShiftGroupShiftEntity = InterfaceBase & {
  __typename?: 'ShiftGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  ShiftID: Scalars['String'];
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroup: ShiftGroupEntity;
};

export type ShiftGroupShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  DutyRosterDisplayColour: Scalars['String'];
  ShiftID?: Maybe<Scalars['String']>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftPatternDetail = {
  __typename?: 'ShiftPatternDetail';
  days?: Maybe<Scalars['String']>;
  ShiftDay?: Maybe<ShiftDayEntity>;
};

export type ShiftPatternDetailEntity = {
  __typename?: 'ShiftPatternDetailEntity';
  ShiftPatternDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  shiftList?: Maybe<Array<ShiftPatternEntity>>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  shiftType?: Maybe<Array<ShiftEntity>>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type ShiftPatternDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
};

export type ShiftPatternEntity = InterfaceBase & {
  __typename?: 'ShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ShiftPatternDetailEntity>>;
};

export type ShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
};

export type ShopeePayItemEntity = {
  __typename?: 'ShopeePayItemEntity';
  ShopeePayItemID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ShortNoticeType {
  CalendarDay = 'Calendar_Day',
  WorkingDay = 'Working_Day'
}

export type SkillSetDetailEntity = InterfaceBase & {
  __typename?: 'SkillSetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetDetailID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetID?: Maybe<Scalars['String']>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetDetailInput = {
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SkillSetEntity = InterfaceBase & {
  __typename?: 'SkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SkillSetDetails?: Maybe<Array<SkillSetDetailEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetInput = {
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  NationalityType?: Maybe<NationalityType>;
  SocsoChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type SocsoCalculationOutput = {
  __typename?: 'SocsoCalculationOutput';
  EmployeeID: Scalars['String'];
  SocsoAmount: Scalars['Float'];
  FirstCatCmpAmount?: Maybe<Scalars['Float']>;
  FirstCatEmpAmount?: Maybe<Scalars['Float']>;
  SecondCatCmpAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  SocsoRule?: Maybe<SocsoRule>;
  SocsoRequirement?: Maybe<Scalars['String']>;
};

export type SocsoPolicyEntity = InterfaceBase & {
  __typename?: 'SocsoPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  SocsoRule: Scalars['String'];
  Formula: Scalars['String'];
};

export type SocsoPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<Scalars['String']>;
  Age: Scalars['Float'];
  SocsoRule?: Maybe<SocsoRule>;
  Formula?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoRefEntity = InterfaceBase & {
  __typename?: 'SocsoRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoReflD: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
};

export type SocsoRefInput = {
  SocsoRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SocsoRule {
  FirstCategory = 'FirstCategory',
  SecondCategory = 'SecondCategory'
}

export enum SoftDelete {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION'
}

export type StaffDevelopmentProgramEntity = InterfaceBase & {
  __typename?: 'StaffDevelopmentProgramEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type StaffDevelopmentProgramInput = {
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffDueForRetirementOutput = {
  __typename?: 'StaffDueForRetirementOutput';
  Employee?: Maybe<EmployeeEntity>;
  RetirementDate?: Maybe<Scalars['DateTime']>;
  IsRetirementOverdue?: Maybe<Scalars['Boolean']>;
};

export type StaffMovementRequestEntity = InterfaceBase & {
  __typename?: 'StaffMovementRequestEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffMovementRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffPerformanceAnalysisSummaryEntity = InterfaceBase & {
  __typename?: 'StaffPerformanceAnalysisSummaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffPerformanceAnalysisSummaryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type StaffPerformanceAnalysisSummaryInput = {
  StaffPerformanceAnalysisSummaryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffPerformanceAnalysisSummaryOutput = {
  __typename?: 'StaffPerformanceAnalysisSummaryOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeSalary?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  FinalScore?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<EmployeeEntity>;
  RatingAdjustment?: Maybe<StaffPerformanceAnalysisSummaryEntity>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
};

export type StaffTimesheetDetailEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StaffTimesheetID?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Activity?: Maybe<Scalars['String']>;
  Other?: Maybe<Scalars['String']>;
  StaffTimesheet: StaffTimesheetEntity;
};

export type StaffTimesheetDetailInput = {
  Description?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Other?: Maybe<Scalars['String']>;
};

export type StaffTimesheetEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
  TsAccountCode: TsAccountCodeEntity;
  StaffTimesheetDetail: Array<StaffTimesheetDetailEntity>;
};

export type StaffTimesheetInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffTimesheetDetail?: Maybe<Array<StaffTimesheetDetailInput>>;
};

export type StatesEntity = InterfaceBase & {
  __typename?: 'StatesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatesID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CalendarOffDay?: Maybe<Array<CalendarOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Confirmed = 'Confirmed',
  Probation = 'Probation',
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Illness = 'Illness',
  Absconded = 'Absconded',
  Draft = 'Draft',
  SoftDelete = 'SoftDelete',
  Hidden = 'Hidden',
  InUse = 'InUse',
  NotInUse = 'NotInUse',
  Initiated = 'Initiated',
  Submitted = 'Submitted',
  Finalised = 'Finalised',
  Open = 'Open',
  Closed = 'Closed',
  Withdraw = 'Withdraw',
  Overdue = 'Overdue'
}

export type StatutoryAccount = {
  __typename?: 'StatutoryAccount';
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAccountInput = {
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAdjustmentEntity = InterfaceBase & {
  __typename?: 'StatutoryAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatutoryAdjustmentID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  EPFPaidEE: Scalars['String'];
  EPFVEPaidEE: Scalars['String'];
  SOCSOPaidEE: Scalars['String'];
  EISPaidEE: Scalars['String'];
  TAXPaidEE: Scalars['String'];
  EPFPaidER: Scalars['String'];
  EPFVEPaidER: Scalars['String'];
  SOCSOPaidER: Scalars['String'];
  EISPaidER: Scalars['String'];
  HRDFPaidER: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayrollCycle: PayrollCycleEntity;
  Employee: EmployeeEntity;
};

export type StatutoryAdjustmentInput = {
  PayrollHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  TotalDeduction?: Maybe<Scalars['String']>;
  EPFPaidEE?: Maybe<Scalars['String']>;
  EPFVEPaidEE?: Maybe<Scalars['String']>;
  SOCSOPaidEE?: Maybe<Scalars['String']>;
  EISPaidEE?: Maybe<Scalars['String']>;
  TAXPaidEE?: Maybe<Scalars['String']>;
  EPFPaidER?: Maybe<Scalars['String']>;
  EPFVEPaidER?: Maybe<Scalars['String']>;
  SOCSOPaidER?: Maybe<Scalars['String']>;
  EISPaidER?: Maybe<Scalars['String']>;
  HRDFPaidER?: Maybe<Scalars['String']>;
  TotalChargableTax?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type SubmitClaimRecordsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ExpenseAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimRecordsItems?: Maybe<Array<ClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
};

export type SubmitProjectClaimRecordsInput = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ProjectClaimRecordsItems?: Maybe<Array<ProjectExpenseClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
};

export type SubordinateKpiEmpOutput = InterfaceBase & {
  __typename?: 'SubordinateKpiEmpOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  KpiPendingActionCount: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotifications: NotificationEntity;
};


export type SubscriptionNewNotificationsArgs = {
  ToEmployeeID: Scalars['String'];
};

export type SuccessionPlanningEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type SuccessionPlanningInput = {
  SuccessionPlanningID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SuccessionPlanningPositionEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningPositionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type SuccessionPlanningPositionInput = {
  SuccessionPlanningPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvalEntity = InterfaceBase & {
  __typename?: 'SupervisorEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SupervisorEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type SupervisorEvalInput = {
  SupervisorEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvaluationListingOutput = {
  __typename?: 'SupervisorEvaluationListingOutput';
  ScheduleTraining?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type SystemAdminSummaryOutput = {
  __typename?: 'SystemAdminSummaryOutput';
  PublicHolidayCalendarLastPostedDate?: Maybe<Scalars['DateTime']>;
  TaxReliefLastPostedDate?: Maybe<Scalars['DateTime']>;
  EAPartFLastPostedDate?: Maybe<Scalars['DateTime']>;
  EPFPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  SocsoPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EISPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LatestMobileVersion?: Maybe<Scalars['String']>;
};

export type TaxzakatCalculationInput = {
  PayrollCycleIsZakat: Scalars['Boolean'];
  IsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  IsLastProcess: Scalars['Boolean'];
  CurrentMTD: Scalars['Float'];
  AccumulatedTAXPaid: Scalars['Float'];
  AccumulatedZAKATPaid: Scalars['Float'];
  AccumulatedTax: Scalars['Float'];
};

export type TmsotConditionEntity = InterfaceBase & {
  __typename?: 'TMSOTConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type TmsotConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TmsSummary = {
  __typename?: 'TMSSummary';
  ShiftGroup?: Maybe<Scalars['Float']>;
  AttendanceExclusion?: Maybe<Scalars['Float']>;
  EarnLeave?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  AttendanceRecord?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  Absent?: Maybe<Scalars['Float']>;
  IncompleteClocking?: Maybe<Scalars['Float']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  AttendanceProcessing?: Maybe<Scalars['DateTime']>;
  AttendancePosting?: Maybe<Scalars['DateTime']>;
  IncompleteClockingEmployees?: Maybe<Array<Scalars['JSON']>>;
  LateInEmployees?: Maybe<Array<Scalars['JSON']>>;
  AbsentEmployee?: Maybe<Array<Scalars['JSON']>>;
  TodayData?: Maybe<Array<Scalars['JSON']>>;
};

export enum TargetType {
  Company = 'COMPANY',
  Designation = 'DESIGNATION',
  Department = 'DEPARTMENT',
  JobGrade = 'JOB_GRADE',
  DailyAllowancePolicy = 'DAILY_ALLOWANCE_POLICY',
  MonthlyAllowancePolicy = 'MONTHLY_ALLOWANCE_POLICY',
  DeductionPolicy = 'DEDUCTION_POLICY',
  ShiftSetup = 'SHIFT_SETUP',
  ShiftPattern = 'SHIFT_PATTERN',
  ShiftGroup = 'SHIFT_GROUP',
  TimeOff = 'TIME_OFF',
  OvertimeRoundingPolicy = 'OVERTIME_ROUNDING_POLICY',
  AllowanceDeductionRoundingPolicy = 'ALLOWANCE_DEDUCTION_ROUNDING_POLICY',
  OvertimeReasonCodes = 'OVERTIME_REASON_CODES',
  TimeOffReasonCodes = 'TIME_OFF_REASON_CODES',
  MobileCheckInLocation = 'MOBILE_CHECK_IN_LOCATION',
  OvertimeClaimTable = 'OVERTIME_CLAIM_TABLE',
  OvertimePolicy = 'OVERTIME_POLICY',
  Employee = 'EMPLOYEE',
  AttendanceRecord = 'ATTENDANCE_RECORD',
  SkillSet = 'SKILL_SET',
  InsuranceScheme = 'INSURANCE_SCHEME',
  RecruitmentSource = 'RECRUITMENT_SOURCE',
  UserDefineField = 'USER_DEFINE_FIELD',
  OvertimePayitem = 'OVERTIME_PAYITEM',
  EarningPayitem = 'EARNING_PAYITEM',
  DeductionPayitem = 'DEDUCTION_PAYITEM',
  NonPayablePayitem = 'NON_PAYABLE_PAYITEM',
  PayrollCycle = 'PAYROLL_CYCLE',
  OvertimePolicyMatrix = 'OVERTIME_POLICY_MATRIX',
  OvertimeClaimMatrix = 'OVERTIME_CLAIM_MATRIX',
  BluetoothBeaconDevices = 'BLUETOOTH_BEACON_DEVICES'
}

export type TargetUnion = DepartmentEntity | JobDesignationEntity | JobGradeEntity | DailyAllowancePolicyEntity | MonthlyAllowancePolicyEntity | DeductionPolicyEntity | ShiftEntity | ShiftPatternEntity | ShiftGroupEntity | TimeOffPolicyEntity | OvertimeRoundingSettingEntity | AllowanceDeductionRoundingEntity | OvertimeReasonEntity | TimeOffReasonEntity | MobileCheckInEntity | OvertimeClaimTableEntity | OvertimePolicyEntity | AttendanceRecordEntity | SkillSetEntity | InsuranceSchemeEntity | RecruitmentSourceEntity | UserDefineEntity | EmployeeEntity | CompanyEntity;

export enum TaxMaritialStatus {
  SingleSpouseNotClaimedChildren = 'Single_Spouse_Not_Claimed_Children',
  MarriedAndSpouseNotWorking = 'Married_and_Spouse_Not_Working',
  MarriedAndSpouseWorking = 'Married_and_Spouse_Working',
  DivorceWidowWidower = 'Divorce_Widow_Widower'
}

export type TaxOutput = {
  __typename?: 'TaxOutput';
  FinalTax1: Scalars['Float'];
  FinalTax2: Scalars['Float'];
  NetMTD: Scalars['Float'];
  MTD_A: Scalars['Float'];
  MTD_B: Scalars['Float'];
  YearlyTax_CS: Scalars['Float'];
  MTD_C: Scalars['Float'];
  FinalZakat: Scalars['Float'];
  TaxZakat: Scalars['Float'];
  Tax1: Scalars['Float'];
  Tax2: Scalars['Float'];
};

export type TaxReisscosInput = {
  __typename?: 'TaxREISSCOSInput';
  currentEISSCOS?: Maybe<Scalars['Float']>;
  TaxReliefSocsoEISLimit?: Maybe<Scalars['Float']>;
  PreviousMonthTaxRelief?: Maybe<Array<EmployeeTaxReliefEntity>>;
  CurrentMonthReliefAmount?: Maybe<Scalars['Float']>;
};

export type TaxRefEntity = InterfaceBase & {
  __typename?: 'TaxRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReflD: Scalars['String'];
  TaxRefType: TaxRefType;
  PeriodYear: Scalars['Float'];
  PFromAmount: Scalars['Float'];
  PToAmount: Scalars['Float'];
  MAmount: Scalars['Float'];
  RTaxRate: Scalars['Float'];
  BIN3Amount: Scalars['Float'];
  B2Amount: Scalars['Float'];
  NonResidentRate: Scalars['Float'];
};

export enum TaxRefType {
  ResidentNonResident = 'ResidentNonResident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker'
}

export type TaxReliefEntity = InterfaceBase & {
  __typename?: 'TaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReliefID: Scalars['String'];
  ReliefCode: Scalars['String'];
  Description: Scalars['String'];
  PeriodYear: Scalars['Float'];
  MaxAmount: Scalars['Float'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type TaxReliefInput = {
  ReliefCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  MaxAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxZakatCalculationOutput = {
  __typename?: 'TaxZakatCalculationOutput';
  NetMTD: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  PayrollCycleIsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  IsZakat: Scalars['Boolean'];
  FinalTotalTAXPaid: Scalars['Float'];
  FinalTotalZAKATPaid: Scalars['Float'];
  FinalTotalMTD: Scalars['Float'];
};

export type TempAttendanceEntity = {
  __typename?: 'TempAttendanceEntity';
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  integration_clock_type?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempCareerMovementEntity = {
  __typename?: 'TempCareerMovementEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  MovementType: Scalars['String'];
  Sequence: Scalars['Float'];
  ToOrgUnit?: Maybe<Scalars['String']>;
  ToPosition: Scalars['String'];
  ToJobGrade: Scalars['String'];
  ToEmploymentType: Scalars['String'];
  StaffCategory: Scalars['String'];
  ExitReasonID?: Maybe<Scalars['String']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsCareerLog: Scalars['Boolean'];
  IsSalaryLog: Scalars['Boolean'];
  FromCompany?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  NewEmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentContactEntity = {
  __typename?: 'TempDependentContactEntity';
  TempDependentContactID: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  Nationality: Scalars['String'];
  BirthDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentEntity = {
  __typename?: 'TempDependentEntity';
  TempDependentID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  Relationship: Scalars['String'];
  IsEmergencyContact: Scalars['Boolean'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  IsWorking: Scalars['Boolean'];
  Education: Scalars['String'];
  ChildEligibleRate: Scalars['Float'];
  IsOKU: Scalars['Boolean'];
  PCBNo?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmployeeSalaryRecordsEntity = {
  __typename?: 'TempEmployeeSalaryRecordsEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromSalary: Scalars['String'];
  ToSalary: Scalars['String'];
  Reason: Scalars['String'];
  SalaryType: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  IsSalaryLog: Scalars['Boolean'];
  IsCareerLog: Scalars['Boolean'];
  MovementType?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmploymentInfoEntity = {
  __typename?: 'TempEmploymentInfoEntity';
  TempEmploymentInfoID: Scalars['String'];
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  DesignationID: Scalars['String'];
  JobGradeID: Scalars['String'];
  StaffCategoryID: Scalars['String'];
  RoleID: Scalars['String'];
  RoleName: Scalars['String'];
  EmployeeStatus: Status;
  Status: Status;
  EmploymentType: EmploymentType;
  JoinedDate: Scalars['DateTime'];
  JoinedReferenceDate: Scalars['DateTime'];
  Probation?: Maybe<Scalars['Float']>;
  ConfirmedDate: Scalars['DateTime'];
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyID: Scalars['String'];
  CalendarTitle: Scalars['String'];
  RecuritmentSourceID: Scalars['String'];
  IsOKU?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  RetirementAge: Scalars['Float'];
};

export type TempLeaveAdjustmentEntity = {
  __typename?: 'TempLeaveAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  PeriodYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempManualAdjustmentEntity = {
  __typename?: 'TempManualAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  FromDate: Scalars['DateTime'];
  UntilDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempPayrollHistoryEntity = {
  __typename?: 'TempPayrollHistoryEntity';
  Id: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type TempPersonnelProfileEntity = {
  __typename?: 'TempPersonnelProfileEntity';
  TempPersonnelProfileID: Scalars['String'];
  FullName: Scalars['String'];
  Salutation: Scalars['String'];
  NickName?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Gender: Scalars['String'];
  Marital: Scalars['String'];
  Race: Scalars['String'];
  Religion: Scalars['String'];
  PermanentAddress: Address2;
  SameAddress?: Maybe<Scalars['Boolean']>;
  Address?: Maybe<Address2>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempReportingStructureEntity = {
  __typename?: 'TempReportingStructureEntity';
  TempReportingStructureID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubordinateID: Scalars['String'];
  SuperiorID: Scalars['Float'];
  ReportingType: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate: Scalars['DateTime'];
};

export type TempStatutoryInfoEntity = {
  __typename?: 'TempStatutoryInfoEntity';
  TempStatutoryInfoID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PaymentMethod: PaymentMethod;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  IsEPF: Scalars['Boolean'];
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['String']>;
  EPFVERateCmp?: Maybe<Scalars['String']>;
  IsSocso: Scalars['Boolean'];
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB: Scalars['Boolean'];
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS: Scalars['Boolean'];
  CompanyEISAccount?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsASB: Scalars['Boolean'];
  ASBNo?: Maybe<Scalars['String']>;
  IsAngkasa: Scalars['Boolean'];
  AngkasaNo?: Maybe<Scalars['String']>;
  IsKWAP: Scalars['Boolean'];
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH: Scalars['Boolean'];
  TBHNo?: Maybe<Scalars['String']>;
  IsHRDF: Scalars['Boolean'];
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempTp3Entity = {
  __typename?: 'TempTP3Entity';
  TempTP3ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  TaxExemptionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempUserAccessEntity = InterfaceBase & {
  __typename?: 'TempUserAccessEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyAccess: Scalars['JSON'];
  EmployeeIDs: Scalars['JSON'];
  SubordinateIDs: Scalars['JSON'];
};

export type TempZakatContributionEntity = {
  __typename?: 'TempZakatContributionEntity';
  TempZakatContributionID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  StartPeriodID: Scalars['String'];
  EndPeroidID?: Maybe<Scalars['String']>;
  ZakatStateID: Scalars['String'];
  AccountNo: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TemplateLibraryAssignmentEntity = InterfaceBase & {
  __typename?: 'TemplateLibraryAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Target?: Maybe<TargetUnion>;
};

export type TemplateLibraryAssignmentInfolineOutput = {
  __typename?: 'TemplateLibraryAssignmentInfolineOutput';
  ShiftAssignment?: Maybe<Scalars['DateTime']>;
  ShiftPatternAssignment?: Maybe<Scalars['DateTime']>;
  ShiftGroupAssignment?: Maybe<Scalars['DateTime']>;
  DailyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  MonthlyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  DeductionAssignment?: Maybe<Scalars['DateTime']>;
  TimeOffAssignment?: Maybe<Scalars['DateTime']>;
  OvertimeReason?: Maybe<Scalars['DateTime']>;
  TimeOffReason?: Maybe<Scalars['DateTime']>;
  MobileCheckInSite?: Maybe<Scalars['Float']>;
  MobileCheckInRadius?: Maybe<Scalars['Float']>;
  BluetoothBeaconDevices?: Maybe<Scalars['Float']>;
  PeriodicAllowanceType?: Maybe<Scalars['DateTime']>;
};

export type TemplateLibraryAssignmentInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type TemplateLibrarySummaryOutput = {
  __typename?: 'TemplateLibrarySummaryOutput';
  ShiftSetup?: Maybe<Scalars['Float']>;
  ShiftPattern?: Maybe<Scalars['Float']>;
  ShiftGroup?: Maybe<Scalars['Float']>;
  OvertimePolicy?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['Float']>;
  OvertimeReasonCode?: Maybe<Scalars['Float']>;
  OvertimeRoundingPolicy?: Maybe<Scalars['Float']>;
  DailyAllowancePolicy?: Maybe<Scalars['Float']>;
  MonthlyAllowancePolicy?: Maybe<Scalars['Float']>;
  PeriodicDeductionPolicy?: Maybe<Scalars['Float']>;
  DeudctionPolicy?: Maybe<Scalars['Float']>;
  DeductionRoundingPolicy?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  JobGrade?: Maybe<Scalars['Float']>;
  assignedJob?: Maybe<Scalars['Float']>;
  TimeOffReasonCode?: Maybe<Scalars['Float']>;
  MobileCheckInLocation?: Maybe<Scalars['Float']>;
  templateDate?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['JSON']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  OTRoundingPolicy?: Maybe<Scalars['String']>;
  AutomatedClockDataImport?: Maybe<Scalars['Float']>;
  PeriodicDate?: Maybe<Scalars['DateTime']>;
};

export type TestingBusinessInsightCompanyOutput = {
  __typename?: 'TestingBusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDepartmentOutput>>;
};

export type TestingBusinessInsightDepartmentOutput = {
  __typename?: 'TestingBusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDivisionOutput>>;
};

export type TestingBusinessInsightDivisionOutput = {
  __typename?: 'TestingBusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TestingBusinessInsightGroupingOutput = {
  __typename?: 'TestingBusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTakenOutput = {
  __typename?: 'TestingBusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTypeOutput = {
  __typename?: 'TestingBusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightListingOutput = {
  __typename?: 'TestingBusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightOutput = {
  __typename?: 'TestingBusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollPayItemOutput = {
  __typename?: 'TestingBusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'TestingBusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollStatutoryOutput = {
  __typename?: 'TestingBusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightSummaryOutput = {
  __typename?: 'TestingBusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
};

export type TestingBusinessInsightSummaryPayrollOutput = {
  __typename?: 'TestingBusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<TestingBusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<TestingBusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<TestingBusinessInsightPayrollPayItemOutput>>;
};

export type TestingCareerLogEntity = InterfaceBase & {
  __typename?: 'TestingCareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
};

export type TestingCareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
};

export type TestingClaimAppDetailsOutput = {
  __typename?: 'TestingClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingClaimAppOutput = {
  __typename?: 'TestingClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<TestingClaimAppDetailsOutput>>;
};

export type TestingConditionObject = {
  __typename?: 'TestingConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type TestingContactEntity = InterfaceBase & {
  __typename?: 'TestingContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type TestingEmployeeConditionsDetails = {
  __typename?: 'TestingEmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<TestingConditionObject>;
  JobPosition?: Maybe<TestingConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<TestingConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<TestingConditionObject>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<TestingPolicyOutput>>;
};

export type TestingEmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Contact?: Maybe<TestingContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<TestingContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<TestingEmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  Subordinate?: Maybe<Array<TestingEmployeeEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<TestingEmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
};

export type TestingEmployeeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeListOutput = {
  __typename?: 'TestingEmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type TestingEmployeeListingOutput = {
  __typename?: 'TestingEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
};

export type TestingEmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'TestingEmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeStatutoryEntity = {
  __typename?: 'TestingEmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingGroupCountEmployeeRoleListingOutput = {
  __typename?: 'TestingGroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type TestingLastUpdatedDatesOutput = {
  __typename?: 'TestingLastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type TestingLeaveAppOutput = {
  __typename?: 'TestingLeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingLeaveHeaderEntity = InterfaceBase & {
  __typename?: 'TestingLeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<TestingEmployeeEntity>;
  LeaveHistory?: Maybe<Array<TestingLeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingMyApplicationOutput = {
  __typename?: 'TestingMyApplicationOutput';
  leave?: Maybe<TestingLeaveAppOutput>;
  claim?: Maybe<TestingClaimAppOutput>;
  requisition?: Maybe<TestingRequisitionAppOutput>;
};

export type TestingMySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export type TestingPersonnelEFileListingOutput = {
  __typename?: 'TestingPersonnelEFileListingOutput';
  Employee?: Maybe<TestingEmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type TestingPolicyOutput = {
  __typename?: 'TestingPolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export type TestingProbationExtendedEmployeeOutput = {
  __typename?: 'TestingProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<TestingCareerLogEntity>;
};

export type TestingReportingStructureEntity = InterfaceBase & {
  __typename?: 'TestingReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingRequisitionAppOutput = {
  __typename?: 'TestingRequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type TestingZakatContributionEntity = InterfaceBase & {
  __typename?: 'TestingZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TimeAttendanceTemplateEntity = InterfaceBase & {
  __typename?: 'TimeAttendanceTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TMSTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TMSConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type TimeAttendanceTemplateInput = {
  TMSTemplateID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TimeOffEntity = InterfaceBase & {
  __typename?: 'TimeOffEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOffStatus: TimeOffStatus;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToReasonID?: Maybe<Scalars['String']>;
  WorkDate: Scalars['DateTime'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ToReason?: Maybe<TimeOffReasonEntity>;
  TimeOffHistory?: Maybe<Array<TimeOffEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type TimeOffInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  EmployeeID?: Maybe<Scalars['String']>;
  TimeOffID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  ToReasonID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TimeOffStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
};

export type TimeOffPolicyEntity = InterfaceBase & {
  __typename?: 'TimeOffPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  RequireTimeOffReason: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type TimeOffPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
};

export type TimeOffReasonEntity = InterfaceBase & {
  __typename?: 'TimeOffReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffReasonInput = {
  TimeOffReasonID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffSettingEntity = InterfaceBase & {
  __typename?: 'TimeOffSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffSettingID: Scalars['String'];
  Name: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  TimeOffReasonID?: Maybe<Scalars['String']>;
  TimeOffReasonEntity?: Maybe<TimeOffReasonEntity>;
};

export type TimeOffSettingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffSettingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  Reasons?: Maybe<Scalars['String']>;
  ReasonIDs?: Maybe<Scalars['String']>;
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
};

export enum TimeOffStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type TimeSheetAgingDto = {
  __typename?: 'TimeSheetAgingDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccCodeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccCodeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Activity?: Maybe<Scalars['String']>;
  AccountCodeID?: Maybe<Scalars['String']>;
  AccountCodeName?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccTypeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccTypeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Account?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisDto = {
  __typename?: 'TimesheetMandayAnalysisDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type TmsPolicyMenuInfoLinesOutput = {
  __typename?: 'TmsPolicyMenuInfoLinesOutput';
  OvertimeDateCreate?: Maybe<Scalars['DateTime']>;
  OvertimeDateModified?: Maybe<Scalars['DateTime']>;
  DailyDateCreate?: Maybe<Scalars['DateTime']>;
  DailyDateModified?: Maybe<Scalars['DateTime']>;
  MonthlyDateCreate?: Maybe<Scalars['DateTime']>;
  MonthlyDateModified?: Maybe<Scalars['DateTime']>;
  DeductionDateCreate?: Maybe<Scalars['DateTime']>;
  DeductionDateModified?: Maybe<Scalars['DateTime']>;
};

export type ToDoKpiExerciseOutput = InterfaceBase & {
  __typename?: 'ToDoKpiExerciseOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
  EmployeeName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  SubmitDate?: Maybe<Scalars['DateTime']>;
  ApproverName: Scalars['String'];
  ApprovalHistory: Array<KpiExerciseEntity>;
};

export type ToDoPerformanceOutput = {
  __typename?: 'ToDoPerformanceOutput';
  KpiExercise?: Maybe<Array<ToDoKpiExerciseOutput>>;
  EmployeeReview?: Maybe<Array<EmployeeReviewEntity>>;
};

export type TopClaimTakerOutput = {
  __typename?: 'TopClaimTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type TopRequisitionTakerOutput = {
  __typename?: 'TopRequisitionTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  RequisitionTaken?: Maybe<Scalars['Float']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TraineeEvalEntity = InterfaceBase & {
  __typename?: 'TraineeEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TraineeEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TraineeEvalInput = {
  TraineeEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvalEntity = InterfaceBase & {
  __typename?: 'TrainerEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainerEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainerEvalInput = {
  TrainerEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvaluationListingOutput = {
  __typename?: 'TrainerEvaluationListingOutput';
  Provider?: Maybe<AuthorisedTrainingProviderEntity>;
  TrainingCourse?: Maybe<Array<TrainingCourseOutput>>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainingAttendanceEntity = InterfaceBase & {
  __typename?: 'TrainingAttendanceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingAttendanceID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type TrainingAttendanceInput = {
  TrainingAttendanceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingBudgetEntity = InterfaceBase & {
  __typename?: 'TrainingBudgetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingBudgetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TrainingBudgetInput = {
  TrainingBudgetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCostEntity = InterfaceBase & {
  __typename?: 'TrainingCostEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingCostID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TrainingCostItem?: Maybe<TrainingCostItemEntity>;
};

export type TrainingCostInput = {
  TrainingCostID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCostItemEntity = InterfaceBase & {
  __typename?: 'TrainingCostItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type TrainingCostItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseEntity = InterfaceBase & {
  __typename?: 'TrainingCourseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CourseClassificationID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CourseClassification?: Maybe<CourseClassificationEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainingCourseInput = {
  ID?: Maybe<Scalars['String']>;
  CourseClassificationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseOutput = {
  __typename?: 'TrainingCourseOutput';
  Course?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainingScheduleTableInput = {
  Date?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Hour?: Maybe<Scalars['Float']>;
};

export type TraningModuleLatestUpdateOutput = {
  __typename?: 'TraningModuleLatestUpdateOutput';
  CostBudget?: Maybe<Scalars['DateTime']>;
  ScheduledTrainig?: Maybe<Scalars['DateTime']>;
  ScheduledTrainingCount?: Maybe<Scalars['Float']>;
  TraineeEval?: Maybe<Scalars['DateTime']>;
  CourseEval?: Maybe<Scalars['DateTime']>;
  TrainerEval?: Maybe<Scalars['DateTime']>;
  SupervisorEval?: Maybe<Scalars['DateTime']>;
  TrainingCost?: Maybe<Scalars['DateTime']>;
  CostAllocation?: Maybe<Scalars['DateTime']>;
  HRDFClaim?: Maybe<Scalars['DateTime']>;
};

export enum TransportMode {
  Flight = 'Flight',
  Car = 'Car',
  Bus = 'Bus',
  Train = 'Train',
  Motorcycle = 'Motorcycle'
}

export type TrxIDs = {
  ID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  Type?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
};

export type TsAccountCodeEntity = InterfaceBase & {
  __typename?: 'TsAccountCodeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
};

export type TsAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TsAccountTypeEntity = InterfaceBase & {
  __typename?: 'TsAccountTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ActivityList: Array<ActivityListJson>;
  TsAccountCode: Array<TsAccountCodeEntity>;
};

export type TsAccountTypeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ActivityList?: Maybe<Array<ActivityListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserDashboardControlEntity = InterfaceBase & {
  __typename?: 'UserDashboardControlEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Scalars['JSON'];
};

export type UserDashboardControlInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Array<DashboardCardSetting>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type UserDefineBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type UserDefineDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineDetailID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  UserDefine?: Maybe<UserDefineEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type UserDefineDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineDetailID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type UserDefineEpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetailID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyDetailInput = {
  UserDefineEPFPolicyDetaiID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID: Scalars['String'];
  Title: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetail?: Maybe<Array<UserDefineEpfPolicyDetailEntity>>;
  UserDefineEPFPolicyMatrix?: Maybe<Array<UserDefineEpfPolicyMatrixEntity>>;
};

export type UserDefineEpfPolicyInput = {
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyMatrixEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyMatrixID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyMatrixInput = {
  UserDefineEPFPolicyMatrixID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEntity = InterfaceBase & {
  __typename?: 'UserDefineEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  employeeID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  lastAccessDate?: Maybe<Scalars['DateTime']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
  NavToken?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  StaffID: Scalars['String'];
  UserName: Scalars['String'];
  StaffName: Scalars['String'];
  Dept: Scalars['String'];
  CostCenter: Scalars['String'];
  EmailID: Scalars['String'];
  Status: Scalars['String'];
  Shop: Scalars['String'];
  StaffCode: Scalars['String'];
  StaffType: Scalars['String'];
  UserRank: Scalars['String'];
  UserJoindDate: Scalars['DateTime'];
  UserStatus: Scalars['String'];
  TranStatus: Scalars['String'];
  LastUpdatedDate: Scalars['DateTime'];
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type UserLogInput = {
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserLogs = {
  __typename?: 'UserLogs';
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
  roleID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type VaccinationInput = {
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type VaccinationListItemOutput = {
  __typename?: 'VaccinationListItemOutput';
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsEntity>;
};

export type VaccinationRecordInput = {
  DocumentID?: Maybe<Scalars['String']>;
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsInput>;
};

export type ValidateResult = {
  __typename?: 'ValidateResult';
  subscriptionaccountid?: Maybe<Scalars['String']>;
  datamodule?: Maybe<Scalars['String']>;
  datarow?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rowno?: Maybe<Scalars['String']>;
  totalrecord?: Maybe<Scalars['String']>;
  exceltotalrecord?: Maybe<Scalars['String']>;
};

export type VerfiyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type VerfiyPeClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ProjectTypeID?: Maybe<Scalars['String']>;
};

export type VerifiedClaimDetail = {
  __typename?: 'VerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type VerifyApprovedClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyApprovedProjectExpenseClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyCompany = {
  __typename?: 'VerifyCompany';
  Company?: Maybe<Array<ValidateResult>>;
  Department?: Maybe<Array<ValidateResult>>;
  JobGrade?: Maybe<Array<ValidateResult>>;
  RecruitmentSource?: Maybe<Array<ValidateResult>>;
};

export type VerifyEmployee = {
  __typename?: 'VerifyEmployee';
  Employee?: Maybe<Array<ValidateResult>>;
  JobInfo?: Maybe<Array<ValidateResult>>;
  Statutory?: Maybe<Array<ValidateResult>>;
  Dependent?: Maybe<Array<ValidateResult>>;
};

export type VerifyPayroll = {
  __typename?: 'VerifyPayroll';
  CutOff?: Maybe<Array<ValidateResult>>;
  Cycle?: Maybe<Array<ValidateResult>>;
  Bank?: Maybe<Array<ValidateResult>>;
  PayItem?: Maybe<Array<ValidateResult>>;
  EmpSalary?: Maybe<Array<ValidateResult>>;
  FixedAllowDec?: Maybe<Array<ValidateResult>>;
  History?: Maybe<Array<ValidateResult>>;
};

export type ViewPayrollHistoryDetailListingOutput = {
  __typename?: 'ViewPayrollHistoryDetailListingOutput';
  Description?: Maybe<Scalars['String']>;
  PayrollCycleId?: Maybe<Scalars['String']>;
  TotalEmployee?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  AdditionalEarnings?: Maybe<Scalars['Float']>;
  GrossPay?: Maybe<Scalars['Float']>;
  Deduction?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
};

export type ViewPayrollHistoryInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export type ViewPayrollHistorySummaryOutput = {
  __typename?: 'ViewPayrollHistorySummaryOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TotalPayrollCycle?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<ViewPayrollHistoryDetailListingOutput>>;
};

export type WorkFlowApprovedStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowApprovedStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: WorkFlowStepEntity;
  ApproverName?: Maybe<Scalars['String']>;
};

export type WorkFlowEntity = InterfaceBase & {
  __typename?: 'WorkFlowEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  WorkFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApproverPendingList?: Maybe<Array<Scalars['JSON']>>;
  GroupWorkFlowStep?: Maybe<Scalars['JSON']>;
};

export type WorkFlowInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: WorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<WorkFlowApprovedStepEntity>>;
  RoleName?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  ApproverID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
};

export type WorkGroupShiftEntity = InterfaceBase & {
  __typename?: 'WorkGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkGroupShiftID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
};

export type WorkdayAdjustmentEntity = InterfaceBase & {
  __typename?: 'WorkdayAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkdayAdjustmentID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays: Scalars['Float'];
  AdjustedWorkDays: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type WorkdayAdjustmentInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays?: Maybe<Scalars['Float']>;
  AdjustedWorkDays?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum WorkflowLogStatus {
  Initiated = 'Initiated',
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  SendBack = 'Send_Back',
  ReInitiated = 'ReInitiated',
  RevertDraft = 'RevertDraft',
  Rejected = 'Rejected'
}

export type YecfListingOutput = {
  __typename?: 'YECFListingOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  NoOfPendingApprovalApplication?: Maybe<Scalars['Float']>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  Employee?: Maybe<EmployeeEntity>;
  InUse?: Maybe<Scalars['Boolean']>;
};

export type YearEndCarryForwardOutput = {
  __typename?: 'YearEndCarryForwardOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypes?: Maybe<Array<LeaveIndentity>>;
};

export type YearlyError = {
  __typename?: 'YearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type YearlyHolidaysGroup = {
  __typename?: 'YearlyHolidaysGroup';
  PeriodYear?: Maybe<Scalars['Float']>;
  RestDay?: Maybe<Array<RestOffDayOutput>>;
  OffDay?: Maybe<Array<RestOffDayOutput>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  CompanyOffDay?: Maybe<Array<CompanyOffDayEntity>>;
};

export type ZakatContributionEntity = InterfaceBase & {
  __typename?: 'ZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ZakatContributionInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['String']>;
  EndMonth?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ActionStatus = {
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type ArrMesg = {
  __typename?: 'arrMesg';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  JoinedDateError?: Maybe<Array<JoinedDateError>>;
  ClaimTrxError?: Maybe<Array<ClaimTrxError>>;
  BiYearlyError?: Maybe<Array<BiYearlyError>>;
  YearlyError?: Maybe<Array<YearlyError>>;
  MonthlyError?: Maybe<Array<MonthlyError>>;
  LifeTimeError?: Maybe<Array<LifeTimeError>>;
};

export type DropDownItem = {
  __typename?: 'dropDownItem';
  Value?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmedPassword?: Maybe<Scalars['String']>;
};

export type Process_LeaveDetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveDetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveDetailsID: Scalars['String'];
  ProcessLeaveHeaderID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProcessLeaveHeader?: Maybe<Process_LeaveHeader_ImportEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type Process_LeaveHeader_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveHeader_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  BlockLeaveID?: Maybe<Scalars['String']>;
  BlockLeave?: Maybe<BlockLeaveEntity>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type Process_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Process_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Status>;
  Index?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Process_Multicurrencydetails_ImportEntity>>;
};

export type Process_Overtime_ImportEntity = {
  __typename?: 'process_overtime_importEntity';
  ProcessOvertimeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Duration?: Maybe<Scalars['Float']>;
  HasDutyRoster?: Maybe<Scalars['Boolean']>;
  OvertimeReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Temp_Leave_TakenEntity = InterfaceBase & {
  __typename?: 'temp_leave_takenEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  leave_type?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  session?: Maybe<Scalars['String']>;
  total_leave?: Maybe<Scalars['Float']>;
  leave_reason?: Maybe<Scalars['String']>;
  disease_code?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  is_short_period_notice?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Float']>;
};

export type Temp_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  details_id: Scalars['String'];
  subscriptionaccountID?: Maybe<Scalars['String']>;
  setup_id?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Temp_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  currencyid: Scalars['String'];
  subscriptionaccountid?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  currency_name?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  soft_delete?: Maybe<Status>;
  index?: Maybe<Scalars['Float']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Temp_Multicurrencydetails_ImportEntity>>;
};

export type Temp_OvertimeEntity = {
  __typename?: 'temp_overtimeEntity';
  id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  overtime_reason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type UpdateHelpdeskAssigneeInput = {
  ID?: Maybe<Scalars['String']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type EmployeeProfileInsertUpdateMutationVariables = Exact<{
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type EmployeeProfileInsertUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfileInsertUpdate'>
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'superUser' | 'lastestAccessedEntity' | 'roleID' | 'isEmployee' | 'isEmployeeAuth' | 'employeeID' | 'helpdeskAssignee'>
  ) }
);

export type IsSuperUserEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type IsSuperUserEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsSuperUserEmployee'>
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
  softwareCode: SoftwareCode;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'isEmployeeSuperUser'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'accountID' | 'ID' | 'name' | 'superUser' | 'employeeID'>
    ) }
  ) }
);

export type EmployeeAppLoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type EmployeeAppLoginMutation = (
  { __typename?: 'Mutation' }
  & { employeeAppLogin: (
    { __typename?: 'EmployeeAppLoginResponse' }
    & Pick<EmployeeAppLoginResponse, 'accessToken'>
    & { employee?: Maybe<(
      { __typename?: 'EmployeeResponse' }
      & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'name' | 'email' | 'mobileNo'>
    )> }
  ) }
);

export type LoggedInEmployeeProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInEmployeeProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInEmployeeProfile: (
    { __typename?: 'EmployeeResponse' }
    & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'companyID' | 'name' | 'email' | 'mobileNo' | 'LastestAccessedEntity' | 'SuperUserID' | 'Salutation'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ChangeEmployeePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangeEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeEmployeePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ForgotEmployeePasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotEmployeePassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResetEmployeePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetEmployeePassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type EmployeeLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type EmployeeLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'employeeLogout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type UserProfilePicQueryVariables = Exact<{
  UserID: Scalars['String'];
  IsEmployee?: Maybe<Scalars['Boolean']>;
}>;


export type UserProfilePicQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserProfilePic'>
);

export type GetEmployeeAppSecurityPermissionsByContactIdQueryVariables = Exact<{
  ContactID: Scalars['String'];
  Permissions?: Maybe<Array<HrPermission> | HrPermission>;
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityPermissionsByContactIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeAppSecurityPermissionsByContactID'>
);

export type GetRoleByUserIdQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
}>;


export type GetRoleByUserIdQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type GetRolePermissionByUserQueryVariables = Exact<{
  hrPermissions?: Maybe<Array<HrPermission> | HrPermission>;
}>;


export type GetRolePermissionByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermissionByUser'>
);

export type JobDesignationListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobDesignationListingQuery = (
  { __typename?: 'Query' }
  & { JobDesignationListing: Array<(
    { __typename?: 'JobDesignationEntity' }
    & Pick<JobDesignationEntity, 'JobDesignationID' | 'SubscriptionAccountID' | 'Name' | 'IsAdmin' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'Status' | 'DesignationID' | 'EmployeeID'>
    )>>, JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobGradeID'>
    )>> }
  )> }
);

export type JobGradeByJobDesignationListingQueryVariables = Exact<{
  JobDesignationID: Scalars['String'];
}>;


export type JobGradeByJobDesignationListingQuery = (
  { __typename?: 'Query' }
  & { JobGradeByJobDesignationListing: Array<(
    { __typename?: 'JobGradeByJobDesignationOutput' }
    & Pick<JobGradeByJobDesignationOutput, 'JobDesignationID'>
    & { JobGrade?: Maybe<(
      { __typename?: 'JobGradeOutput' }
      & Pick<JobGradeOutput, 'JobGradeID' | 'Description'>
    )> }
  )> }
);

export type ApprovalPolicyDeleteMutationVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type ApprovalPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyDelete'>
);

export type ApprovalPolicyListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  Category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type ApprovalPolicyListQuery = (
  { __typename?: 'Query' }
  & { ApprovalPolicyList: Array<(
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ID' | 'InUse' | 'SubscriptionAccountID' | 'Title' | 'Category'>
    & { ApprovalList: Array<(
      { __typename?: 'ApprovalListJson' }
      & Pick<ApprovalListJson, 'ID' | 'StepNo' | 'RoleID' | 'Predicate' | 'Amount' | 'Category' | 'RoleName' | 'EndIf' | 'Skip'>
    )> }
  )> }
);

export type ApprovalPolicySubmitMutationVariables = Exact<{
  input: NewApprovalPolicyInput;
}>;


export type ApprovalPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicySubmit'>
);

export type ApprovalPolicyDetailsQueryVariables = Exact<{
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
}>;


export type ApprovalPolicyDetailsQuery = (
  { __typename?: 'Query' }
  & { ApprovalPolicyDetails: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID' | 'Title'>
    & { ApprovalList: Array<(
      { __typename?: 'ApprovalListJson' }
      & Pick<ApprovalListJson, 'ID' | 'StepNo' | 'RoleID' | 'Predicate' | 'Amount' | 'Category' | 'RoleName' | 'EndIf' | 'Skip' | 'reporting_group_id'>
    )> }
  ) }
);

export type IsApprovalPolicyTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  Category: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
}>;


export type IsApprovalPolicyTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsApprovalPolicyTitleExist'>
);

export type EmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'SubscriptionUserID' | 'EmployeeNo' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'Probation' | 'Status' | 'FormStatus' | 'OrgUnitID' | 'DesignationID' | 'ResignedDate' | 'JobGradeID'>
    & { EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'MovementType' | 'FromSalary' | 'ToSalary' | 'excelfilename' | 'EffectiveDate' | 'SalaryAdjustmentAmount' | 'Remark'>
    )>>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type GetRoleQueryVariables = Exact<{
  IsApprovalPolicy?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )>, roleUserAssignments?: Maybe<Array<(
      { __typename?: 'RoleUserEntAssignmentEntity' }
      & Pick<RoleUserEntAssignmentEntity, 'roleID' | 'userID' | 'entityID' | 'ID'>
    )>> }
  )> }
);

export type JobGradeListingByCompanyQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobGradeListingByCompanyQuery = (
  { __typename?: 'Query' }
  & { JobGradeListingByCompany: Array<(
    { __typename?: 'JobGradeEntity' }
    & Pick<JobGradeEntity, 'JobGradeID' | 'SubscriptionAccountID' | 'Description' | 'LeaveBenefitID' | 'ClaimBenefitID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobDesignationGradeID' | 'JobDesignationID' | 'JobGradeID'>
    )>> }
  )> }
);

export type CompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type CompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CreatedDT' | 'ModifiedDT' | 'CompanyID' | 'CompanyName' | 'BizRegNo'>
    & { Departments?: Maybe<Array<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description' | 'DepartmentType'>
    )>>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>> }
  )> }
);

export type GetCompanyPermissionQueryVariables = Exact<{
  hrPermissions?: Maybe<Array<HrPermission> | HrPermission>;
  CompanyID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyPermission'>
);

export type CompanyOrgUnitByCompanyIdArrayQueryVariables = Exact<{
  CompanyIDArray?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CompanyOrgUnitByCompanyIdArrayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOrgUnitList'>
);

export type SimpleCompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SimpleCompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
  )> }
);

export type EmployeeListForJobInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeListForJobInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListForJobInfo'>
);

export type EmployeeSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummary: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'RetirementAge' | 'EmployeeID' | 'CalendarPolicyID' | 'CalendarTitle' | 'IsOKU' | 'IsHOD' | 'IsSuperUser' | 'IsInterCompanyTransfer' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'EmployeeStatus' | 'FormStatus' | 'Probation' | 'CalendarStateID' | 'JoinedReferenceDate' | 'StaffCategory' | 'StaffCategoryID' | 'OrgUnitID' | 'ActualConfirmedDate' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocCount' | 'ContractInfoDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize' | 'SubscriptionAccountID' | 'ReportTo' | 'JoinedDate' | 'ConfirmedDate' | 'ResignedDate' | 'TenderResignDate' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'TotalEducation' | 'TotalJobHistory' | 'TotalPersonalDocument' | 'TotalCertificate' | 'RoleID' | 'RoleName'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'NickName' | 'Email' | 'Nationality' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'PassportNoLHDN' | 'BirthDate' | 'Gender' | 'Marital' | 'Salutation' | 'Race' | 'Religion' | 'SameAddress' | 'ProfilePicture'>
      & { Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )>, PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )> }
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CalendarTitle' | 'CompanyName' | 'CalendarStateID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID'>
        & { Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )> }
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, EmployeeDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )>, RecruitmentSource?: Maybe<(
      { __typename?: 'RecruitmentSourceEntity' }
      & Pick<RecruitmentSourceEntity, 'RecruitmentSourceID' | 'Name'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'SalaryType' | 'FromSalary' | 'ToSalary' | 'TenderResignDate' | 'MovementType' | 'ExitReasonID' | 'Status' | 'LastEmploymentDate' | 'LastWorkingDate' | 'ModifiedDT'>
      & { ExitCompanyReason?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name'>
      )> }
    )>>, EmployeeBankInfo?: Maybe<(
      { __typename?: 'EmployeeBankInfoEntity' }
      & Pick<EmployeeBankInfoEntity, 'EmployeeBankInfoID' | 'Bank' | 'AcctNo' | 'PaymentMethod' | 'CompanyBankAccount' | 'PayeeName' | 'PayeeID'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID' | 'IsEPF' | 'EPFNo' | 'EPFVERate' | 'EPFVERateCmp' | 'IsSocso' | 'SocsoNo' | 'IsPCB' | 'PCBNo' | 'IsEIS' | 'EISNo' | 'IsAngkasa' | 'AngkasaNo' | 'IsASB' | 'ASBNo' | 'IsKWAP' | 'KWAPNo' | 'IsTBH' | 'TBHNo' | 'IsZakat' | 'ZakatNo' | 'ZakatAmount' | 'ZakatRate' | 'ResidentStatus' | 'CountryCode' | 'TaxMaritialStatus' | 'ZakatState' | 'IsHRDF' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'CompanyEPFAccount' | 'CompanySocsoAccount' | 'CompanyPCBAccount' | 'CompanyEISAccount'>
      & { UserLogs?: Maybe<Array<(
        { __typename?: 'UserLogs' }
        & Pick<UserLogs, 'columnName' | 'newColumnValue' | 'oldColumnValue' | 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy'>
      )>> }
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID' | 'CompanyID' | 'EmployeeID' | 'PeriodYear' | 'GrossTaxable' | 'EPFPaid' | 'PCBPaid' | 'ZAKATPaid' | 'ModifiedDT' | 'CreatedDT'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'ChildEligibleRate' | 'Occupation' | 'IsWorking' | 'IsOKU' | 'IsEmergencyContact' | 'PCBNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'BirthDate' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality'>
      )> }
    )>>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, EmployeeAppraisals?: Maybe<Array<(
      { __typename?: 'EmployeeAppraisalEntity' }
      & Pick<EmployeeAppraisalEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeAppraisalID' | 'EmployeeID' | 'AppraisalRecord' | 'Date' | 'Rating' | 'Score' | 'AppraiseeRemark' | 'AppraiserRemark' | 'AppraiserName' | 'DocumentFile' | 'BucketFileName'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, EmployeeDiaries?: Maybe<Array<(
      { __typename?: 'EmployeeDiaryEntity' }
      & Pick<EmployeeDiaryEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeDiaryID' | 'EmployeeID' | 'Date' | 'CaseNo' | 'Description' | 'Countermeasure' | 'Private' | 'DocumentID'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description' | 'BucketFileName'>
      )> }
    )>>, EmployeeTrainings?: Maybe<Array<(
      { __typename?: 'EmployeeTrainingEntity' }
      & Pick<EmployeeTrainingEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeTrainingID' | 'EmployeeID' | 'CourseName' | 'StartDate' | 'EndDate' | 'TrainingHour' | 'TrainingProvider' | 'Trainer' | 'TrainerRating' | 'TrainerScore' | 'Superior' | 'SuperiorRating' | 'SuperiorScore'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, EmployeeInsuranceSchemes?: Maybe<Array<(
      { __typename?: 'EmployeeInsuranceSchemeEntity' }
      & Pick<EmployeeInsuranceSchemeEntity, 'InsuranceSchemeID' | 'EmployeeInsuranceSchemeID' | 'StartDate' | 'EndDate' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { InsuranceScheme?: Maybe<(
        { __typename?: 'InsuranceSchemeEntity' }
        & Pick<InsuranceSchemeEntity, 'Name' | 'Status'>
      )> }
    )>>, EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )>, EmployeeAssets?: Maybe<Array<(
      { __typename?: 'EmployeeAssetEntity' }
      & Pick<EmployeeAssetEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeAssetID' | 'EmployeeID' | 'DateAssign' | 'Item' | 'Remark'>
    )>>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'EmployeeLastDate' | 'AssetsLastDate' | 'BIKLastDate' | 'CareerLastDate' | 'SubordinateLastDate' | 'TaxLastDate' | 'HealthInfoLastDate' | 'UserDefineFieldsLastDate'>
    ) }
  ) }
);

export type PersonnelParameterListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonnelParameterListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelParameterListing: Array<(
    { __typename?: 'PersonnelParameterEntity' }
    & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'SubscriptionAccountID' | 'Name' | 'IsDefault' | 'Voluntary' | 'PersonnelParameterType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type EmployeeListingBySubscriptionAccountQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingBySubscriptionAccountQuery = (
  { __typename?: 'Query' }
  & { EmployeeListingBySubscriptionAccount: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'RetirementAge' | 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'JoinedDate' | 'ResignedDate' | 'Status' | 'CompanyID' | 'CalendarTitle' | 'CalendarStateID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT' | 'RoleID' | 'RoleName'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'Description'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
    )>>, EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'FromSalary' | 'ToSalary' | 'SalaryAdjustmentAmount' | 'SalaryType' | 'Reason' | 'Remark'>
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'TaxLastDate' | 'Cp22LastDate' | 'BIKLastDate'>
    ) }
  )> }
);

export type EmployeeNameListingBySubscriptionAccountQueryVariables = Exact<{
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeNameListingBySubscriptionAccountQuery = (
  { __typename?: 'Query' }
  & { EmployeeListingBySubscriptionAccount: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'Status'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )> }
  )> }
);

export type SubordinateTreeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ModuleName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
}>;


export type SubordinateTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SubordinateTree'>
);

export type UpdateEmployeeProfilePicMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type UpdateEmployeeProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfilePicUpdate'>
);

export type CompanyListingByEmailQueryVariables = Exact<{
  EmployeeEmail: Scalars['String'];
}>;


export type CompanyListingByEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyListingByEmail'>
);

export type HomeSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type HomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HomeSummary'>
);

export type MyEmployeeInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type MyEmployeeInfoQuery = (
  { __typename?: 'Query' }
  & { MyEmployeeInfo?: Maybe<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DesignationID' | 'CalendarPolicyID' | 'OrgUnitID' | 'ContactID' | 'JobGradeID' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalUnpaidLeave' | 'TotalClaimApproved' | 'TotalClaimAmountApproved' | 'TotalTravelRequest' | 'TotalPurchaseRequisition' | 'TotalCashAdvanceRequisition' | 'TotalOtherRequisition' | 'RoleID' | 'RoleName' | 'Status' | 'ReportTo' | 'MyTodo' | 'MyNotification' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Salutation'>
      & { EmployeeSalutation?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'Name'>
      )> }
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Description'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'SubscriptionAccountID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'CareerLastDate'>
    ), EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CompanyID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'ParentID' | 'DocumentType' | 'FileSize' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type NewNotificationSubscriptionVariables = Exact<{
  ToEmployeeID: Scalars['String'];
}>;


export type NewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newNotifications: (
    { __typename?: 'NotificationEntity' }
    & Pick<NotificationEntity, 'ToEmployeeID' | 'Message'>
  ) }
);

export type UpdateEmployeeLastLoginCompanyMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  LastestAccessedEntity: Scalars['String'];
}>;


export type UpdateEmployeeLastLoginCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeLastLoginCompany'>
);

export type EmployeeShiftGroupAssignmentLogQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Today?: Maybe<Scalars['Boolean']>;
}>;


export type EmployeeShiftGroupAssignmentLogQuery = (
  { __typename?: 'Query' }
  & { EmployeeShiftGroupAssignmentLog: Array<(
    { __typename?: 'ShiftGroupEmployeeAssignmentLogEntity' }
    & Pick<ShiftGroupEmployeeAssignmentLogEntity, 'ShiftGroupEmployeeAssignmentLogID' | 'ShiftGroupID' | 'EmployeeID' | 'Start' | 'End' | 'Status' | 'hasDutyRoster'>
    & { ShiftGroup?: Maybe<(
      { __typename?: 'ShiftGroupEntity' }
      & Pick<ShiftGroupEntity, 'Name' | 'ByQR' | 'QRSelfie' | 'ByLocation' | 'LocationSelfie' | 'ByBluetooth' | 'BluetoothSelfie'>
    )> }
  )> }
);

export type GetEmployeeAppSecurityAssignmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityAssignmentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeAppSecurityAssignmentListing'>
);

export type GetPerformanceApprovalHistoryQueryVariables = Exact<{
  ApprovalContactID: Scalars['String'];
  PerformanceRequestType: Scalars['String'];
}>;


export type GetPerformanceApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { GetPerformanceApprovalHistory: (
    { __typename?: 'ToDoPerformanceOutput' }
    & { KpiExercise?: Maybe<Array<(
      { __typename?: 'ToDoKpiExerciseOutput' }
      & Pick<ToDoKpiExerciseOutput, 'ID' | 'Type' | 'KpiExerciseID' | 'ReviewGroupName' | 'EmployeeName' | 'EmployeeNo' | 'Status' | 'FromDate' | 'ToDate' | 'CreatedDT' | 'SubmitDate' | 'ApprovalStatus' | 'ApproverName'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>>, EmployeeReview?: Maybe<Array<(
      { __typename?: 'EmployeeReviewEntity' }
      & Pick<EmployeeReviewEntity, 'ID' | 'ReviewExerciseName' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'ReviewFormType' | 'ReviewFormTemplateName' | 'Status' | 'CreatedDT' | 'ReviewProcessLog'>
      & { Reviewer?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, ApprovalHistory: Array<(
        { __typename?: 'EmployeeReviewEntity' }
        & Pick<EmployeeReviewEntity, 'ReviewExerciseName' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'ReviewFormType' | 'ReviewFormTemplateName' | 'Status' | 'CreatedDT'>
        & { Reviewer?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type NewsReadListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  NewsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type NewsReadListingQuery = (
  { __typename?: 'Query' }
  & { NewsReadListing: Array<(
    { __typename?: 'NewsReadEntity' }
    & Pick<NewsReadEntity, 'NewsReadID' | 'NewsID' | 'EmployeeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type NewsReadInsertMutationVariables = Exact<{
  NewsReadInput: NewsReadInput;
}>;


export type NewsReadInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsReadInsert'>
);

export type BulletinListingQueryVariables = Exact<{
  NewsID: Scalars['String'];
}>;


export type BulletinListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BulletinListing'>
);

export type NewsListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  IsPublishedOnly: Scalars['Boolean'];
  StartMonth: Scalars['String'];
  EndMonth: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  isValid?: Maybe<Scalars['Boolean']>;
}>;


export type NewsListingQuery = (
  { __typename?: 'Query' }
  & { NewsListing: Array<(
    { __typename?: 'NewsEntity' }
    & Pick<NewsEntity, 'NewsID' | 'SubscriptionAccountID' | 'Title' | 'Content' | 'PublishedBy' | 'ValidFrom' | 'ValidTo' | 'Acknowledgement' | 'AcknowledgementStatement' | 'PostStatus' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ContentUrl'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description' | 'BucketFileName'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )> }
  )> }
);

export type NewsCountQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  IsPublishedOnly: Scalars['Boolean'];
  StartMonth: Scalars['String'];
  EndMonth: Scalars['String'];
  isValid?: Maybe<Scalars['Boolean']>;
}>;


export type NewsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'NewsCount'>
);

export type CompanyDeclaredHolidayListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StateID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
}>;


export type CompanyDeclaredHolidayListingQuery = (
  { __typename?: 'Query' }
  & { CompanyDeclaredHolidayListing: Array<(
    { __typename?: 'CompanyDelaredHolidayEntity' }
    & Pick<CompanyDelaredHolidayEntity, 'CompanyDelaredHolidayID' | 'CompanyID' | 'StateID' | 'HolidayDate' | 'Description' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type CompanySummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanySummaryQuery = (
  { __typename?: 'Query' }
  & { CompanySummary: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'BizRegNo' | 'Sector' | 'ExcludeTaxRelief' | 'ProjectExpenseTrxDayLimit' | 'ProjectExpenseCutOff' | 'CalendarStateID' | 'PayrollCutOffDay' | 'LeaveCutOffDay' | 'ClaimCutOffDay' | 'EnableExpenseAccount' | 'ClaimTrxDayLimit' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'CustomPayslipFormat' | 'CustomPayrollGlExportFormat' | 'CustomClaimGlExportFormat' | 'CustomProjectExpenseGlExportFormat'>
    & { Departments?: Maybe<Array<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID'>
    )>>, Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'Email' | 'WebUrl' | 'OfficeNo' | 'MobileNo' | 'FaxNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'PostalCode' | 'City' | 'State' | 'Country'>
      )> }
    )>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>>, LeaveRounding?: Maybe<(
      { __typename?: 'LeaveRoundingEntity' }
      & Pick<LeaveRoundingEntity, 'LeaveRoundingID' | 'Title'>
    )> }
  ) }
);

export type EmployeeCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
}>;


export type EmployeeCalendarQuery = (
  { __typename?: 'Query' }
  & { EmployeeCalendar?: Maybe<(
    { __typename?: 'EmployeeCalendarOutput' }
    & { companyOffDays?: Maybe<Array<(
      { __typename?: 'CompanyOffDayOutput' }
      & Pick<CompanyOffDayOutput, 'DayOffDate' | 'Description'>
    )>>, employeeLeaves?: Maybe<Array<(
      { __typename?: 'EmployeeLeavesOutput' }
      & Pick<EmployeeLeavesOutput, 'EmployeeID' | 'EmployeeNo' | 'FullName' | 'LeaveType' | 'LeaveHeaderID' | 'Remarks' | 'LeaveDate' | 'FromDate' | 'UntilDate' | 'ApproverID' | 'SessionType' | 'IsOffDay' | 'IsRestDay' | 'IsPublicHoliday' | 'LeaveApprovalStatus'>
    )>>, employeeStaffLeaves?: Maybe<Array<(
      { __typename?: 'EmployeeLeavesOutput' }
      & Pick<EmployeeLeavesOutput, 'EmployeeID' | 'EmployeeNo' | 'LeaveHeaderID' | 'FullName' | 'LeaveType' | 'Remarks' | 'LeaveDate' | 'FromDate' | 'UntilDate' | 'SessionType' | 'IsOffDay' | 'IsRestDay' | 'IsPublicHoliday' | 'LeaveApprovalStatus' | 'OwnSubordinates'>
    )>>, employeeBirthdays?: Maybe<Array<(
      { __typename?: 'EmployeeBirthdaysOutput' }
      & Pick<EmployeeBirthdaysOutput, 'EmployeeID' | 'EmployeeNo' | 'FullName' | 'BirthDate' | 'CompanyID'>
    )>> }
  )> }
);

export type GetLeaveOffDayQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLeaveOffDayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveOffDay'>
);

export type EmpAttendanceHistoryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type EmpAttendanceHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmpAttendanceHistory'>
);

export type SubordinateListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SubordinateListingQuery = (
  { __typename?: 'Query' }
  & { SubordinateListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName' | 'CompanyID'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type EmployeeCompanyListingQueryVariables = Exact<{
  EmployeeEmail: Scalars['String'];
}>;


export type EmployeeCompanyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeCompanyListing'>
);

export type EmployeeNotificationListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeNotificationListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeNotificationListing: Array<(
    { __typename?: 'EmployeeNotificationOutput' }
    & Pick<EmployeeNotificationOutput, 'Message' | 'CreatedDT' | 'RequestType' | 'Topic' | 'Status' | 'NotificationType' | 'ReadStatus' | 'NotificationID'>
    & { LeaveDetails?: Maybe<(
      { __typename?: 'LeaveDetails' }
      & Pick<LeaveDetails, 'ApprovalStatus' | 'TotalDay' | 'LeaveType' | 'ApproverName' | 'ApprovalRemark' | 'Remarks' | 'FromDate' | 'UntilDate'>
    )>, ClaimDetails?: Maybe<(
      { __typename?: 'ClaimDetails' }
      & Pick<ClaimDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'ClaimSubject' | 'CreatedDT' | 'TotalClaim' | 'TotalClaimAmount' | 'TotalVerifiedAmount'>
      & { ClaimDetailsListing?: Maybe<Array<(
        { __typename?: 'ClaimDetailsEntity' }
        & Pick<ClaimDetailsEntity, 'ClaimAmountByFinance' | 'ClaimAmount' | 'ClaimRemarks' | 'ClaimDetailsID' | 'ReceiptDate' | 'ReceiptNo' | 'FinanceApproveStatus'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID'>
        )>, ClaimType?: Maybe<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name'>
        )> }
      )>> }
    )>, RequisitionDetails?: Maybe<(
      { __typename?: 'RequisitionDetails' }
      & Pick<RequisitionDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'RequisitionType' | 'DocumentFile' | 'Subject' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'Remark' | 'StartDate' | 'EndDate' | 'TotalDays' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'CreatedDT'>
    )>, DataChangesDetails?: Maybe<(
      { __typename?: 'DataChangesDetails' }
      & Pick<DataChangesDetails, 'DataChangesID' | 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'CreatedDT' | 'Action' | 'Type'>
      & { ChangesData?: Maybe<(
        { __typename?: 'ChangesData' }
        & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )>, Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )> }
      )>, OldData?: Maybe<(
        { __typename?: 'ChangesData' }
        & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )>, Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )> }
      )> }
    )>, ProjectExpenseClaimDetails?: Maybe<(
      { __typename?: 'ProjectExpenseClaimDetails' }
      & Pick<ProjectExpenseClaimDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'ClaimSubject' | 'CreatedDT' | 'TotalClaim' | 'TotalClaimAmount' | 'TotalVerifiedAmount'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>>, ClaimDetailsListing?: Maybe<Array<(
        { __typename?: 'ProjectExpenseClaimDetailEntity' }
        & Pick<ProjectExpenseClaimDetailEntity, 'ClaimAmountByFinance' | 'ClaimAmount' | 'ClaimRemark' | 'ID' | 'ReceiptDate' | 'ReceiptNo' | 'FinanceApproveStatus'>
        & { ProjectExpenseType?: Maybe<(
          { __typename?: 'ProjectExpenseTypeEntity' }
          & Pick<ProjectExpenseTypeEntity, 'ProjectExpenseType'>
        )> }
      )>> }
    )>, PerformanceKpiDetail?: Maybe<(
      { __typename?: 'KpiExerciseDetailsEntity' }
      & { ReviewGroup?: Maybe<(
        { __typename?: 'ReviewGroupEntity' }
        & Pick<ReviewGroupEntity, 'Title'>
      )>, KpiExercise?: Maybe<(
        { __typename?: 'KpiExerciseEntity' }
        & Pick<KpiExerciseEntity, 'ID' | 'KpiExerciseID' | 'EmployeeID' | 'FromDate' | 'ToDate'>
      )> }
    )>, PerformanceReviewDetail?: Maybe<(
      { __typename?: 'EmployeeReviewEntity' }
      & Pick<EmployeeReviewEntity, 'ID' | 'ReviewGroupName' | 'ReviewExerciseName' | 'FromDate' | 'ToDate'>
    )> }
  )> }
);

export type UpdateNotificationReadStatusMutationVariables = Exact<{
  NotificationID: Scalars['String'];
}>;


export type UpdateNotificationReadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateNotificationReadStatus'>
);

export type NotificationReadStatusDeleteMutationVariables = Exact<{
  ID: Array<Scalars['String']> | Scalars['String'];
}>;


export type NotificationReadStatusDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationReadStatusDelete'>
);

export type UpdateBulkNotificationReadStatusMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateBulkNotificationReadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateBulkNotificationReadStatus'>
);

export type ToDoSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type ToDoSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ToDoSummary'>
);

export type GetDocumentQueryVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type GetDocumentQuery = (
  { __typename?: 'Query' }
  & { getDocument: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
  )> }
);

export type EmployeeDependentsSummaryQueryVariables = Exact<{
  EmployeeDependentsID: Scalars['String'];
}>;


export type EmployeeDependentsSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeDependentsSummary: (
    { __typename?: 'EmployeeDependentsEntity' }
    & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'IsOKU' | 'ChildEligibleRate' | 'Occupation' | 'IsEmergencyContact' | 'PCBNo' | 'DocumentID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality' | 'BirthDate'>
    )> }
  ) }
);

export type ReportingGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportingGroupListingQuery = (
  { __typename?: 'Query' }
  & { ReportingGroupListing: Array<(
    { __typename?: 'ReportingGroupEntity' }
    & Pick<ReportingGroupEntity, 'reporting_group_id' | 'name' | 'created_dt' | 'modified_dt' | 'GroupInUse' | 'CompanyInUse'>
  )> }
);

export type PerformanceMenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PerformanceMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PerformanceMenuSummary'>
);

export type FormItemRatingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type FormItemRatingListingQuery = (
  { __typename?: 'Query' }
  & { FormItemRatingListing: Array<(
    { __typename?: 'FormItemRatingEntity' }
    & Pick<FormItemRatingEntity, 'ID' | 'Title' | 'InUse' | 'RatingScaleTable' | 'InputMethod' | 'AllowFraction' | 'AllowIncrement' | 'IncrementNumber'>
  )> }
);

export type FormItemRatingDetailsQueryVariables = Exact<{
  FormItemRatingID: Scalars['String'];
}>;


export type FormItemRatingDetailsQuery = (
  { __typename?: 'Query' }
  & { FormItemRatingDetails: (
    { __typename?: 'FormItemRatingEntity' }
    & Pick<FormItemRatingEntity, 'ID' | 'Title' | 'To' | 'From' | 'OrderType' | 'RatingScaleTable' | 'InputMethod' | 'AllowFraction' | 'AllowIncrement' | 'IncrementNumber'>
  ) }
);

export type IsFormItemRatingTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  FormItemRatingID?: Maybe<Scalars['String']>;
}>;


export type IsFormItemRatingTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsFormItemRatingTitleExist'>
);

export type FormItemRatingSubmitMutationVariables = Exact<{
  input: FormItemRatingInput;
}>;


export type FormItemRatingSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FormItemRatingSubmit'>
);

export type FormItemRatingDeleteMutationVariables = Exact<{
  FormItemRatingID: Scalars['String'];
}>;


export type FormItemRatingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FormItemRatingDelete'>
);

export type ReviewGradingTableListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewGradingTableListingQuery = (
  { __typename?: 'Query' }
  & { ReviewGradingTableListing: Array<(
    { __typename?: 'ReviewGradingTableEntity' }
    & Pick<ReviewGradingTableEntity, 'ID' | 'Title' | 'InUse' | 'GradingTable'>
  )> }
);

export type ReviewGradingTableDetailsQueryVariables = Exact<{
  ReviewGradingTableID: Scalars['String'];
}>;


export type ReviewGradingTableDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewGradingTableDetails: (
    { __typename?: 'ReviewGradingTableEntity' }
    & Pick<ReviewGradingTableEntity, 'ID' | 'Title' | 'GradingTable'>
  ) }
);

export type IsReviewGradingTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewGradingTableID?: Maybe<Scalars['String']>;
}>;


export type IsReviewGradingTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewGradingTitleExist'>
);

export type ReviewGradingTableSubmitMutationVariables = Exact<{
  input: ReviewGradingTableInput;
}>;


export type ReviewGradingTableSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGradingTableSubmit'>
);

export type ReviewGradingTableDeleteMutationVariables = Exact<{
  ReviewGradingTableID: Scalars['String'];
}>;


export type ReviewGradingTableDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGradingTableDelete'>
);

export type ReviewPolicyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewPolicyListingQuery = (
  { __typename?: 'Query' }
  & { ReviewPolicyListing: Array<(
    { __typename?: 'ReviewPolicyEntity' }
    & Pick<ReviewPolicyEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type ReviewPolicyDetailsQueryVariables = Exact<{
  ReviewPolicyID: Scalars['String'];
}>;


export type ReviewPolicyDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewPolicyDetails: (
    { __typename?: 'ReviewPolicyEntity' }
    & Pick<ReviewPolicyEntity, 'ID' | 'Title' | 'ReviewPolicyDetails'>
    & { ReviewGrading: (
      { __typename?: 'ReviewGradingTableEntity' }
      & Pick<ReviewGradingTableEntity, 'ID' | 'Title'>
    ) }
  ) }
);

export type IsReviewPolicyTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewPolicyID?: Maybe<Scalars['String']>;
}>;


export type IsReviewPolicyTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewPolicyTitleExist'>
);

export type ReviewPolicySubmitMutationVariables = Exact<{
  input: ReviewPolicyInput;
}>;


export type ReviewPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewPolicySubmit'>
);

export type ReviewPolicyDeleteMutationVariables = Exact<{
  ReviewPolicyID: Scalars['String'];
}>;


export type ReviewPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewPolicyDelete'>
);

export type KpiCategoryListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiCategoryListingQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT' | 'PredefinedItemCount'>
  )> }
);

export type KpiCategoryDetailsQueryVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiCategoryDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryDetails: (
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'PredefinedItemCount'>
  ) }
);

export type IsKpiCategoryTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
}>;


export type IsKpiCategoryTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiCategoryTitleExist'>
);

export type KpiCategorySubmitMutationVariables = Exact<{
  input: KpiCategoryInput;
}>;


export type KpiCategorySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiCategorySubmit'>
);

export type KpiCategoryDeleteMutationVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiCategoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiCategoryDelete'>
);

export type KpiCategoryListingWithItemCountQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiCategoryListingWithItemCountQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'PredefinedItemCount'>
  )> }
);

export type KpiPredefinedItemListingQueryVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiPredefinedItemListingQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedItemListing: Array<(
    { __typename?: 'KpiPredefinedItemEntity' }
    & Pick<KpiPredefinedItemEntity, 'ID' | 'Title' | 'Targets' | 'Initiatives' | 'InUse' | 'Status'>
  )> }
);

export type KpiPredefinedItemDetailsQueryVariables = Exact<{
  KpiPredefinedItemID: Scalars['String'];
}>;


export type KpiPredefinedItemDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedItemDetails: (
    { __typename?: 'KpiPredefinedItemEntity' }
    & Pick<KpiPredefinedItemEntity, 'ID' | 'Title' | 'Targets' | 'Initiatives'>
    & { FormItemRating: (
      { __typename?: 'FormItemRatingEntity' }
      & Pick<FormItemRatingEntity, 'ID' | 'Title'>
    ) }
  ) }
);

export type IsKpiPredefinedItemTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
}>;


export type IsKpiPredefinedItemTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiPredefinedItemTitleExist'>
);

export type KpiPredefinedItemSubmitMutationVariables = Exact<{
  input: KpiPredefinedItemInput;
}>;


export type KpiPredefinedItemSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemSubmit'>
);

export type KpiPredefinedItemUpdateStatusMutationVariables = Exact<{
  input: KpiActiveInactiveInput;
}>;


export type KpiPredefinedItemUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemUpdateStatus'>
);

export type KpiPredefinedItemDeleteMutationVariables = Exact<{
  KpiPredefinedItemID: Scalars['String'];
}>;


export type KpiPredefinedItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemDelete'>
);

export type KpiPredefinedTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiPredefinedTemplateListingQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedTemplateListing: Array<(
    { __typename?: 'KpiPredefinedTemplateEntity' }
    & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title' | 'InUse' | 'CategoryCount' | 'ItemCount' | 'WeightageType' | 'ModifiedDT'>
  )> }
);

export type KpiPredefinedTemplateItemListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiPredefinedTemplateItemListingQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedTemplateItemListing: Array<(
    { __typename?: 'KpiPredefinedItemEntity' }
    & Pick<KpiPredefinedItemEntity, 'ID' | 'Title' | 'Targets' | 'Initiatives' | 'InUse' | 'Status' | 'KpiCategoryID'>
  )> }
);

export type KpiPredefinedTemplateDetailsQueryVariables = Exact<{
  KpiPredefinedTemplateID: Scalars['String'];
}>;


export type KpiPredefinedTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedTemplateDetails: (
    { __typename?: 'KpiPredefinedTemplateEntity' }
    & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title' | 'Description' | 'WeightageType' | 'KpiWeightage'>
  ) }
);

export type IsKpiPredefinedTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsKpiPredefinedTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiPredefinedTemplateTitleExist'>
);

export type KpiPredefinedTemplateSubmitMutationVariables = Exact<{
  input: KpiPredefinedTemplateInput;
}>;


export type KpiPredefinedTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedTemplateSubmit'>
);

export type KpiPredefinedTemplateDeleteMutationVariables = Exact<{
  KpiPredefinedTemplateID: Scalars['String'];
}>;


export type KpiPredefinedTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedTemplateDelete'>
);

export type ReviewItemListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewItemListingQuery = (
  { __typename?: 'Query' }
  & { ReviewItemListing: Array<(
    { __typename?: 'ReviewItemEntity' }
    & Pick<ReviewItemEntity, 'ID' | 'Description' | 'Title' | 'InUse' | 'ApplicableTo' | 'Code' | 'ItemRatingMethod'>
  )> }
);

export type ReviewItemDetailsQueryVariables = Exact<{
  ReviewItemID: Scalars['String'];
}>;


export type ReviewItemDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewItemDetails: (
    { __typename?: 'ReviewItemEntity' }
    & Pick<ReviewItemEntity, 'ID' | 'Title' | 'Code' | 'Description' | 'ItemRatingMethod' | 'StaffComment' | 'SuperiorComment' | 'ApplicableTo'>
    & { FormItemRating?: Maybe<(
      { __typename?: 'FormItemRatingEntity' }
      & Pick<FormItemRatingEntity, 'ID' | 'Title'>
    )> }
  ) }
);

export type IsReviewItemCodeExistQueryVariables = Exact<{
  Code: Scalars['String'];
  ReviewItemID?: Maybe<Scalars['String']>;
}>;


export type IsReviewItemCodeExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewItemCodeExist'>
);

export type ReviewItemSubmitMutationVariables = Exact<{
  input: ReviewItemInput;
}>;


export type ReviewItemSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewItemSubmit'>
);

export type ReviewItemDeleteMutationVariables = Exact<{
  ReviewItemID: Scalars['String'];
}>;


export type ReviewItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewItemDelete'>
);

export type ReviewTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ReviewTemplateListing: Array<(
    { __typename?: 'ReviewTemplateEntity' }
    & Pick<ReviewTemplateEntity, 'ID' | 'Title' | 'InUse' | 'ItemCount' | 'ModifiedDT'>
  )> }
);

export type ReviewTemplateDetailsQueryVariables = Exact<{
  ReviewTemplateID: Scalars['String'];
}>;


export type ReviewTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewTemplateDetails: (
    { __typename?: 'ReviewTemplateEntity' }
    & Pick<ReviewTemplateEntity, 'ID' | 'Title' | 'Description' | 'ApplicableTo' | 'WeightageType' | 'ReviewItemList'>
  ) }
);

export type IsReviewTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsReviewTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewTemplateTitleExist'>
);

export type ReviewTemplateSubmitMutationVariables = Exact<{
  input: ReviewTemplateInput;
}>;


export type ReviewTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewTemplateSubmit'>
);

export type ReviewTemplateDeleteMutationVariables = Exact<{
  ReviewTemplateID: Scalars['String'];
}>;


export type ReviewTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewTemplateDelete'>
);

export type ReviewFormTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewFormTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ReviewFormTemplateListing: Array<(
    { __typename?: 'ReviewFormTemplateEntity' }
    & Pick<ReviewFormTemplateEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT' | 'ReviewType' | 'ApplicableTo'>
  )> }
);

export type ReviewFormTemplateDetailsQueryVariables = Exact<{
  ReviewFormTemplateID: Scalars['String'];
}>;


export type ReviewFormTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewFormTemplateDetails: (
    { __typename?: 'ReviewFormTemplateEntity' }
    & Pick<ReviewFormTemplateEntity, 'ID' | 'Title' | 'Description' | 'WeightageType' | 'ReviewType' | 'ApplicableTo' | 'AllowSectionEdited' | 'SectionEditedByEmployee' | 'SectionEditedBySuperior' | 'AllowSectionZero'>
    & { ReviewProcessPolicy?: Maybe<(
      { __typename?: 'ApprovalPolicyEntity' }
      & Pick<ApprovalPolicyEntity, 'Title' | 'ID'>
    )>, ReviewGradingTable: (
      { __typename?: 'ReviewGradingTableEntity' }
      & Pick<ReviewGradingTableEntity, 'ID' | 'Title'>
    ), ReviewSection: Array<(
      { __typename?: 'ReviewSectionEntity' }
      & Pick<ReviewSectionEntity, 'ID' | 'Title' | 'Type' | 'Weightage' | 'KpiSource' | 'Sequence' | 'AllowItemEdited' | 'ItemEditedByEmployee' | 'ItemEditedBySuperior' | 'AllowItemZero' | 'Description'>
      & { KpiPredefinedTemplate?: Maybe<(
        { __typename?: 'KpiPredefinedTemplateEntity' }
        & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title'>
      )>, FormItemRating?: Maybe<(
        { __typename?: 'FormItemRatingEntity' }
        & Pick<FormItemRatingEntity, 'ID' | 'Title'>
      )>, ReviewTemplate?: Maybe<(
        { __typename?: 'ReviewTemplateEntity' }
        & Pick<ReviewTemplateEntity, 'ID' | 'Title'>
      )> }
    )> }
  ) }
);

export type IsReviewFormTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsReviewFormTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewFormTemplateTitleExist'>
);

export type ReviewFormTemplateSubmitMutationVariables = Exact<{
  input: ReviewFormTemplateInput;
}>;


export type ReviewFormTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewFormTemplateSubmit'>
);

export type ReviewFormTemplateDeleteMutationVariables = Exact<{
  ReviewFormTemplateID: Scalars['String'];
}>;


export type ReviewFormTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewFormTemplateDelete'>
);

export type ReviewGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewGroupListingQuery = (
  { __typename?: 'Query' }
  & { ReviewGroupListing: Array<(
    { __typename?: 'ReviewGroupEntity' }
    & Pick<ReviewGroupEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type IsReviewGroupTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
}>;


export type IsReviewGroupTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewGroupTitleExist'>
);

export type ReviewGroupSubmitMutationVariables = Exact<{
  input: ReviewGroupInput;
}>;


export type ReviewGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGroupSubmit'>
);

export type ReviewGroupDeleteMutationVariables = Exact<{
  ReviewGroupID: Scalars['String'];
}>;


export type ReviewGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGroupDelete'>
);

export type RewardGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardGroupListingQuery = (
  { __typename?: 'Query' }
  & { RewardGroupListing: Array<(
    { __typename?: 'RewardGroupEntity' }
    & Pick<RewardGroupEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type IsRewardGroupTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  RewardGroupID?: Maybe<Scalars['String']>;
}>;


export type IsRewardGroupTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRewardGroupTitleExist'>
);

export type RewardGroupSubmitMutationVariables = Exact<{
  input: RewardGroupInput;
}>;


export type RewardGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RewardGroupSubmit'>
);

export type RewardGroupDeleteMutationVariables = Exact<{
  RewardGroupID: Scalars['String'];
}>;


export type RewardGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RewardGroupDelete'>
);

export type PerformanceMainMenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PerformanceMainMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PerformanceMainMenuSummary'>
);

export type PerformanceGroupMatrixListingQueryVariables = Exact<{
  GroupType: Scalars['String'];
}>;


export type PerformanceGroupMatrixListingQuery = (
  { __typename?: 'Query' }
  & { PerformanceGroupMatrixListing: Array<(
    { __typename?: 'PerformanceGroupMatrixEntity' }
    & Pick<PerformanceGroupMatrixEntity, 'ID' | 'GroupID' | 'ConditionList' | 'TotalCriteria' | 'CriteriaDesc'>
    & { ReviewGroup?: Maybe<(
      { __typename?: 'ReviewGroupEntity' }
      & Pick<ReviewGroupEntity, 'ID' | 'Title'>
    )>, RewardGroup?: Maybe<(
      { __typename?: 'RewardGroupEntity' }
      & Pick<RewardGroupEntity, 'ID' | 'Title'>
    )> }
  )> }
);

export type PerformanceGroupMatrixSubmitMutationVariables = Exact<{
  input: PerformanceGroupMatrixInput;
}>;


export type PerformanceGroupMatrixSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PerformanceGroupMatrixSubmit'>
);

export type PerformanceGroupMatrixDeleteMutationVariables = Exact<{
  PerformanceGroupMatrixID: Scalars['String'];
}>;


export type PerformanceGroupMatrixDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PerformanceGroupMatrixDelete'>
);

export type KpiCategoryWithOrgGoalListingQueryVariables = Exact<{
  Year: Scalars['Float'];
}>;


export type KpiCategoryWithOrgGoalListingQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryWithOrgGoalListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title'>
    & { OrgGoals?: Maybe<Array<(
      { __typename?: 'OrganizationalGoalEntity' }
      & Pick<OrganizationalGoalEntity, 'ID' | 'Goal' | 'PercentageComplete'>
    )>> }
  )> }
);

export type OrganizationalGoalByCategoryListingQueryVariables = Exact<{
  KpiCategoryID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type OrganizationalGoalByCategoryListingQuery = (
  { __typename?: 'Query' }
  & { OrganizationalGoalByCategoryListing: Array<(
    { __typename?: 'OrganizationalGoalEntity' }
    & Pick<OrganizationalGoalEntity, 'ID' | 'Goal' | 'Objective' | 'PercentageComplete' | 'InUse'>
  )> }
);

export type OrganizationalGoalDetailsQueryVariables = Exact<{
  OrganizationalGoalID: Scalars['String'];
}>;


export type OrganizationalGoalDetailsQuery = (
  { __typename?: 'Query' }
  & { OrganizationalGoalDetails: (
    { __typename?: 'OrganizationalGoalEntity' }
    & Pick<OrganizationalGoalEntity, 'ID' | 'Goal' | 'Objective' | 'PercentageComplete'>
  ) }
);

export type IsOrgGoalExistByCategoryQueryVariables = Exact<{
  Goal: Scalars['String'];
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  OrgGoalID?: Maybe<Scalars['String']>;
}>;


export type IsOrgGoalExistByCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsOrgGoalExistByCategory'>
);

export type OrganizationalGoalSubmitMutationVariables = Exact<{
  input: OrganizationalGoalInput;
}>;


export type OrganizationalGoalSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OrganizationalGoalSubmit'>
);

export type OrganizationalGoalDeleteMutationVariables = Exact<{
  OrganizationalGoalID: Scalars['String'];
}>;


export type OrganizationalGoalDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OrganizationalGoalDelete'>
);

export type OrgKpiPlanningTreeViewListingQueryVariables = Exact<{
  Year: Scalars['Float'];
}>;


export type OrgKpiPlanningTreeViewListingQuery = (
  { __typename?: 'Query' }
  & { OrgKpiPlanningTreeViewListing: Array<(
    { __typename?: 'OrgKpiPlanningEntity' }
    & Pick<OrgKpiPlanningEntity, 'ID' | 'OrgUnitID' | 'TotalGoals' | 'Year' | 'OwnerID' | 'CompanyID' | 'PercentageComplete'>
    & { Owner?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type KpiCategoryWithOrgKpiPlanningListingQueryVariables = Exact<{
  OrgKpiPlanningID: Scalars['String'];
}>;


export type KpiCategoryWithOrgKpiPlanningListingQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryWithOrgKpiPlanningListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title'>
    & { OrgKpiPlannings?: Maybe<Array<(
      { __typename?: 'OrgKpiPlanningDetailsEntity' }
      & Pick<OrgKpiPlanningDetailsEntity, 'ID' | 'KPI' | 'Target' | 'Initiatives' | 'PercentageComplete'>
    )>> }
  )> }
);

export type OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables = Exact<{
  OrgKpiPlanningID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
}>;


export type OrgKpiPlanningDetailsByKpiCategoryListingQuery = (
  { __typename?: 'Query' }
  & { OrgKpiPlanningDetailsByKpiCategoryListing: Array<(
    { __typename?: 'OrgKpiPlanningDetailsEntity' }
    & Pick<OrgKpiPlanningDetailsEntity, 'ID' | 'KPI' | 'Target' | 'Initiatives' | 'PercentageComplete'>
    & { OrgGoal?: Maybe<(
      { __typename?: 'OrganizationalGoalEntity' }
      & Pick<OrganizationalGoalEntity, 'ID' | 'Goal'>
    )>, KpiCategory?: Maybe<(
      { __typename?: 'KpiCategoryEntity' }
      & Pick<KpiCategoryEntity, 'Title'>
    )> }
  )> }
);

export type IsOrgKpiExistByCategoryQueryVariables = Exact<{
  KPI: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
  OrgKpiPlanningDetailslID?: Maybe<Scalars['String']>;
}>;


export type IsOrgKpiExistByCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsOrgKpiExistByCategory'>
);

export type OrgKpiPlanningSubmitMutationVariables = Exact<{
  input: OrgKpiPlanningInput;
}>;


export type OrgKpiPlanningSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OrgKpiPlanningSubmit'>
);

export type OrgKpiPlanningDetailSubmitMutationVariables = Exact<{
  input: OrgKpiPlanningDetailsInput;
}>;


export type OrgKpiPlanningDetailSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OrgKpiPlanningDetailSubmit'>
);

export type OrgKpiPlanningDetailsDeleteMutationVariables = Exact<{
  OrgKpiPlanningDetailsID: Scalars['String'];
}>;


export type OrgKpiPlanningDetailsDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OrgKpiPlanningDetailsDelete'>
);

export type KpiExerciseSubmenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiExerciseSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'KpiExerciseSubmenuSummary'>
);

export type KpiExerciseSetupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiExerciseSetupListingQuery = (
  { __typename?: 'Query' }
  & { KpiExerciseSetupListing: Array<(
    { __typename?: 'KpiExerciseSetupEntity' }
    & Pick<KpiExerciseSetupEntity, 'ID' | 'KpiExerciseID' | 'FromDate' | 'ToDate' | 'InUse'>
  )> }
);

export type KpiExerciseSetupInfoQueryVariables = Exact<{
  KpiExerciseSetupID: Scalars['String'];
}>;


export type KpiExerciseSetupInfoQuery = (
  { __typename?: 'Query' }
  & { KpiExerciseSetupInfo: (
    { __typename?: 'KpiExerciseSetupEntity' }
    & Pick<KpiExerciseSetupEntity, 'ID' | 'KpiExerciseID' | 'FromDate' | 'ToDate' | 'AllowToReInitiate'>
    & { KpiExerciseSetupDetails?: Maybe<Array<(
      { __typename?: 'KpiExerciseSetupDetailsEntity' }
      & Pick<KpiExerciseSetupDetailsEntity, 'ID' | 'ReviewGroupID' | 'KpiRule' | 'KpiPredefinedTemplateID' | 'EditableWeightage' | 'FormItemRatingID' | 'WeightageType' | 'CompositionRules' | 'KpiWeightage'>
    )>> }
  ) }
);

export type IsKpiExerciseIdExistByCompanyQueryVariables = Exact<{
  KpiExerciseID: Scalars['String'];
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
}>;


export type IsKpiExerciseIdExistByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiExerciseIDExistByCompany'>
);

export type IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables = Exact<{
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
}>;


export type IsKpiExerciseDateRangeOverlappingByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiExerciseDateRangeOverlappingByCompany'>
);

export type KpiExerciseSetupSubmitMutationVariables = Exact<{
  input: KpiExerciseSetupInput;
}>;


export type KpiExerciseSetupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseSetupSubmit'>
);

export type KpiExerciseSetupCopyMutationVariables = Exact<{
  KpiExerciseSetupID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
}>;


export type KpiExerciseSetupCopyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseSetupCopy'>
);

export type KpiExerciseSetupGenerateMutationVariables = Exact<{
  KpiExerciseSetupID: Scalars['String'];
  Overwrite?: Maybe<Scalars['Boolean']>;
}>;


export type KpiExerciseSetupGenerateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseSetupGenerate'>
);

export type KpiExerciseSetupDeleteMutationVariables = Exact<{
  KpiExerciseSetupID: Scalars['String'];
}>;


export type KpiExerciseSetupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseSetupDelete'>
);

export type KpiExerciseListingQueryVariables = Exact<{
  KpiExerciseIDs: Array<Scalars['String']> | Scalars['String'];
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  KpiFormStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type KpiExerciseListingQuery = (
  { __typename?: 'Query' }
  & { KpiExerciseListing: Array<(
    { __typename?: 'KpiExerciseDetailsEntity' }
    & Pick<KpiExerciseDetailsEntity, 'EmployeeKpiExerciseID' | 'EmployeeSubmitDT' | 'ID' | 'KpiRule' | 'WeightageType' | 'EditableWeightage' | 'CompositionRules' | 'KpiWeightage'>
    & { KpiExercise?: Maybe<(
      { __typename?: 'KpiExerciseEntity' }
      & Pick<KpiExerciseEntity, 'ID' | 'KpiExerciseSetupID' | 'KpiExerciseID' | 'AllowToReInitiate' | 'Status' | 'CreatedDT' | 'FromDate' | 'ToDate' | 'ApprovalDT' | 'ApprovalStatus' | 'ApprovalRemark'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )> }
      )>, Superior?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, ReviewGroup?: Maybe<(
      { __typename?: 'ReviewGroupEntity' }
      & Pick<ReviewGroupEntity, 'ID' | 'Title'>
    )>, FormItemRating?: Maybe<(
      { __typename?: 'FormItemRatingEntity' }
      & Pick<FormItemRatingEntity, 'Title' | 'ID'>
    )>, KpiPredefinedTemplate?: Maybe<(
      { __typename?: 'KpiPredefinedTemplateEntity' }
      & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Status' | 'KpiWeightage' | 'Title'>
    )> }
  )> }
);

export type ViewEmployeeKpiDetailsQueryVariables = Exact<{
  EmployeeKpiExerciseID: Scalars['String'];
}>;


export type ViewEmployeeKpiDetailsQuery = (
  { __typename?: 'Query' }
  & { EmployeeKpiDetails: (
    { __typename?: 'EmployeeKpiEntity' }
    & Pick<EmployeeKpiEntity, 'ID' | 'EmployeeKpiDetails'>
    & { KpiExerciseDetail: (
      { __typename?: 'KpiExerciseDetailsEntity' }
      & Pick<KpiExerciseDetailsEntity, 'WeightageType' | 'EmployeeSubmitDT' | 'ID' | 'KpiRule' | 'EditableWeightage' | 'CompositionRules' | 'KpiWeightage'>
      & { KpiExercise?: Maybe<(
        { __typename?: 'KpiExerciseEntity' }
        & Pick<KpiExerciseEntity, 'ID' | 'KpiExerciseID' | 'AllowToReInitiate' | 'Status' | 'CreatedDT' | 'FromDate' | 'ToDate' | 'ApprovalDT' | 'ApprovalStatus' | 'ApprovalRemark'>
        & { Employee?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )>, Documents?: Maybe<(
            { __typename?: 'DocumentsEntity' }
            & Pick<DocumentsEntity, 'DocumentFile'>
          )> }
        )>, Superior?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>, ReviewGroup?: Maybe<(
        { __typename?: 'ReviewGroupEntity' }
        & Pick<ReviewGroupEntity, 'ID' | 'Title'>
      )>, FormItemRating?: Maybe<(
        { __typename?: 'FormItemRatingEntity' }
        & Pick<FormItemRatingEntity, 'Title' | 'ID'>
      )>, KpiPredefinedTemplate?: Maybe<(
        { __typename?: 'KpiPredefinedTemplateEntity' }
        & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Status' | 'KpiWeightage' | 'Title'>
      )> }
    ) }
  ) }
);

export type KpiExerciseChangeSuperiorMutationVariables = Exact<{
  KpiExerciseID: Scalars['String'];
  SuperiorID: Scalars['String'];
}>;


export type KpiExerciseChangeSuperiorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseChangeSuperior'>
);

export type KpiExerciseSubmitMutationVariables = Exact<{
  input: KpiExerciseDetailsEditInput;
}>;


export type KpiExerciseSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseSubmit'>
);

export type KpiExerciseDeleteMutationVariables = Exact<{
  KpiExerciseID: Scalars['String'];
}>;


export type KpiExerciseDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseDelete'>
);

export type KpiExerciseInitiateDraftMutationVariables = Exact<{
  KpiExerciseIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type KpiExerciseInitiateDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseInitiateDraft'>
);

export type KpiExerciseChangeStatusMutationVariables = Exact<{
  KpiExerciseID: Scalars['String'];
  StatusToChange: Status;
  Comment?: Maybe<Scalars['String']>;
}>;


export type KpiExerciseChangeStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseChangeStatus'>
);

export type KpiExerciseCopyMutationVariables = Exact<{
  KpiExerciseID: Scalars['String'];
  SuperiorID: Scalars['String'];
}>;


export type KpiExerciseCopyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseCopy'>
);

export type PerformanceEmployeeListingQueryVariables = Exact<{
  RequestType: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type PerformanceEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { PerformanceEmployeeListing?: Maybe<Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'ContactID' | 'EmployeeNo' | 'TotalKpiRecord' | 'TotalReviewRecord'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )>> }
);

export type ReviewExerciseSubmenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewExerciseSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReviewExerciseSubmenuSummary'>
);

export type ReviewExerciseSetupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewExerciseSetupListingQuery = (
  { __typename?: 'Query' }
  & { ReviewExerciseSetupListing: Array<(
    { __typename?: 'ReviewExerciseSetupEntity' }
    & Pick<ReviewExerciseSetupEntity, 'ID' | 'ReviewExerciseID' | 'FromDate' | 'ToDate' | 'InUse'>
  )> }
);

export type ReviewExerciseSetupInfoQueryVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
}>;


export type ReviewExerciseSetupInfoQuery = (
  { __typename?: 'Query' }
  & { ReviewExerciseSetupInfo: (
    { __typename?: 'ReviewExerciseSetupEntity' }
    & Pick<ReviewExerciseSetupEntity, 'ID' | 'ReviewExerciseID' | 'FromDate' | 'ToDate' | 'ReviewType' | 'KpiAsAtDate' | 'ExcludeResigning' | 'AllowToReInitiate' | 'GenerateForCurrentReporting' | 'UseRecentKpi' | 'AdhocSetupDetails'>
    & { ReviewExerciseSetupDetails: Array<(
      { __typename?: 'ReviewExerciseSetupDetailsEntity' }
      & Pick<ReviewExerciseSetupDetailsEntity, 'ID' | 'ReviewGroupID' | 'ReviewGroupName' | 'ConfirmDueFrom' | 'ConfirmDueTo' | 'SuperiorSelfReviewFormTemplateID' | 'PeerReviewFormTemplateID' | 'SubordinateReviewFormTemplateID' | 'ReviewFormTemplateName'>
    )> }
  ) }
);

export type IsReviewExerciseIdExistQueryVariables = Exact<{
  ReviewExerciseID: Scalars['String'];
  ReviewExerciseSetupID?: Maybe<Scalars['String']>;
}>;


export type IsReviewExerciseIdExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewExerciseIDExist'>
);

export type PeerRegistrationListingQueryVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
}>;


export type PeerRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { PeerRegistrationListing: Array<(
    { __typename?: 'ReviewPeerRegistrationEntity' }
    & Pick<ReviewPeerRegistrationEntity, 'ID' | 'ReviewExerciseSetupID' | 'EmployeeID' | 'PeerIDs'>
    & { Employee: (
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo' | 'EmploymentType' | 'OrgUnitID' | 'Status'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )> }
    ), PeerInfoListing: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'EmploymentType' | 'OrgUnitID' | 'Status'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )> }
    )> }
  )> }
);

export type PeerRegistrationSubmitMutationVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
  input?: Maybe<Array<ReviewPeerRegistrationInput> | ReviewPeerRegistrationInput>;
}>;


export type PeerRegistrationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PeerRegistrationSubmit'>
);

export type ReviewExerciseSetupSubmitMutationVariables = Exact<{
  input: ReviewExerciseSetupInput;
}>;


export type ReviewExerciseSetupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewExerciseSetupSubmit'>
);

export type ReviewExerciseSetupCopyMutationVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  KpiAsAtDate: Scalars['DateTime'];
}>;


export type ReviewExerciseSetupCopyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewExerciseSetupCopy'>
);

export type ReviewExerciseSetupGenerateMutationVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
  Overwrite?: Maybe<Scalars['Boolean']>;
}>;


export type ReviewExerciseSetupGenerateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewExerciseSetupGenerate'>
);

export type ReviewExerciseSetupDeleteMutationVariables = Exact<{
  ReviewExerciseSetupID: Scalars['String'];
}>;


export type ReviewExerciseSetupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewExerciseSetupDelete'>
);

export type ReviewExerciseListingQueryVariables = Exact<{
  ReviewExerciseIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ReviewFormType?: Maybe<Array<AppraiseeType> | AppraiseeType>;
  ReviewFormStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ReviewExerciseListingQuery = (
  { __typename?: 'Query' }
  & { ReviewExerciseListing: Array<(
    { __typename?: 'ReviewExerciseListingOutput' }
    & Pick<ReviewExerciseListingOutput, 'ReviewExerciseSetupID' | 'ReviewExerciseName'>
    & { ReviewExercise: Array<(
      { __typename?: 'ReviewExerciseEntity' }
      & Pick<ReviewExerciseEntity, 'ID' | 'EmployeeID' | 'ReviewGroupID' | 'ReviewGroupName'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, ReviewExerciseSetup?: Maybe<(
        { __typename?: 'ReviewExerciseSetupEntity' }
        & Pick<ReviewExerciseSetupEntity, 'AllowToReInitiate' | 'ReviewType'>
      )>, EmployeeReview: Array<(
        { __typename?: 'EmployeeReviewEntity' }
        & Pick<EmployeeReviewEntity, 'ID' | 'EmployeeRating' | 'ReviewerRating' | 'OverallGrade' | 'ReviewType' | 'ReviewFormType' | 'ReviewerID' | 'FromDate' | 'ToDate' | 'ReviewFormTemplateID' | 'ReviewFormTemplateName' | 'Status' | 'ReviewProcessLog'>
        & { Reviewer?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo'>
          & { Documents?: Maybe<(
            { __typename?: 'DocumentsEntity' }
            & Pick<DocumentsEntity, 'DocumentFile'>
          )>, Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetEmployeeReviewFormQueryVariables = Exact<{
  EmployeeReviewID: Scalars['String'];
}>;


export type GetEmployeeReviewFormQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeReviewForm: (
    { __typename?: 'EmployeeReviewEntity' }
    & Pick<EmployeeReviewEntity, 'ID' | 'Status' | 'ReviewExerciseName' | 'HasNextLevelApproval' | 'IsSendBackToInitaite' | 'ReviewGroupName' | 'ReviewFormType' | 'WeightageType' | 'ReviewFormTemplateName' | 'FromDate' | 'ToDate' | 'EmployeeRating' | 'ReviewerRating' | 'OverallGrade' | 'ReviewGradingTable' | 'ReviewProcessLog' | 'EmployeeID' | 'ReviewDate'>
    & { Reviewer?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'ContactID' | 'RoleID' | 'RoleName' | 'JobGradeID' | 'EmployeeNo' | 'EmployeeCurrentSalary'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )> }
    )>, EmployeeReviewSection: Array<(
      { __typename?: 'EmployeeReviewSectionEntity' }
      & Pick<EmployeeReviewSectionEntity, 'ID' | 'EmployeeViewable' | 'Recommendation' | 'Weightage'>
      & { ReviewSection?: Maybe<(
        { __typename?: 'ReviewSectionEntity' }
        & Pick<ReviewSectionEntity, 'Title' | 'Description' | 'Type' | 'Sequence' | 'AllowItemEdited' | 'ItemEditedByEmployee' | 'ItemEditedBySuperior' | 'AllowItemZero'>
      )>, EmployeeReviewSectionItem: Array<(
        { __typename?: 'EmployeeReviewSectionItemEntity' }
        & Pick<EmployeeReviewSectionItemEntity, 'ID' | 'KpiCategoryID' | 'KPI' | 'Weightage' | 'ItemWeightage' | 'ItemDetails' | 'EmployeeReview' | 'ReviewerReview' | 'Sequence'>
        & { ReviewConversationLog?: Maybe<Array<(
          { __typename?: 'ReviewConversationLogEntity' }
          & Pick<ReviewConversationLogEntity, 'ID' | 'EmployeeID' | 'CreatedDT' | 'CommentBy' | 'Comment'>
          & { Employee?: Maybe<(
            { __typename?: 'EmployeeEntity' }
            & Pick<EmployeeEntity, 'EmployeeNo'>
            & { Contact?: Maybe<(
              { __typename?: 'ContactEntity' }
              & Pick<ContactEntity, 'FullName'>
            )>, Documents?: Maybe<(
              { __typename?: 'DocumentsEntity' }
              & Pick<DocumentsEntity, 'DocumentFile'>
            )> }
          )>, Documents?: Maybe<Array<(
            { __typename?: 'DocumentsEntity' }
            & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
          )>>, VoiceDocument?: Maybe<(
            { __typename?: 'DocumentsEntity' }
            & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
          )> }
        )>> }
      )> }
    )>, ReviewFormTemplate?: Maybe<(
      { __typename?: 'ReviewFormTemplateEntity' }
      & Pick<ReviewFormTemplateEntity, 'AllowSectionEdited' | 'SectionEditedByEmployee' | 'SectionEditedBySuperior' | 'AllowSectionZero'>
    )> }
  ) }
);

export type IsSuperiorAlreadyExistQueryVariables = Exact<{
  ReviewExerciseID?: Maybe<Scalars['String']>;
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  NewSuperiorID: Scalars['String'];
}>;


export type IsSuperiorAlreadyExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsSuperiorAlreadyExist'>
);

export type EmployeeReviewUpdateSetupMutationVariables = Exact<{
  input: EmployeeReviewSetupInput;
}>;


export type EmployeeReviewUpdateSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeReviewUpdateSetup'>
);

export type CopyEmployeeReviewExerciseMutationVariables = Exact<{
  EmployeeReviewID: Scalars['String'];
  ReviewerID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
}>;


export type CopyEmployeeReviewExerciseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CopyEmployeeReviewExercise'>
);

export type EmployeeReviewFormStatusUpdateMutationVariables = Exact<{
  EmployeeReviewIDs: Array<Scalars['String']> | Scalars['String'];
  StatusToChange: Status;
  Comment?: Maybe<Scalars['String']>;
  IsSendBack?: Maybe<Scalars['Boolean']>;
}>;


export type EmployeeReviewFormStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeReviewFormStatusUpdate'>
);

export type SubmitEmployeeReviewFormMutationVariables = Exact<{
  input: ReviewFormSubmitInput;
  Comment?: Maybe<Scalars['String']>;
}>;


export type SubmitEmployeeReviewFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitEmployeeReviewForm'>
);

export type GetConversationLogByReviewFormItemQueryVariables = Exact<{
  EmployeeReviewSectionItemID: Scalars['String'];
}>;


export type GetConversationLogByReviewFormItemQuery = (
  { __typename?: 'Query' }
  & { GetConversationLogByReviewFormItem: Array<(
    { __typename?: 'ReviewConversationLogEntity' }
    & Pick<ReviewConversationLogEntity, 'ID' | 'EmployeeID' | 'CreatedDT' | 'CommentBy' | 'Comment'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile'>
      )> }
    )>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>>, VoiceDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )> }
  )> }
);

export type AddConversationLogMutationVariables = Exact<{
  input: ReviewConversationLogInput;
}>;


export type AddConversationLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddConversationLog'>
);

export type GetPerformanceToDoQueryVariables = Exact<{
  ContactID: Scalars['String'];
  PerformanceRequestType: Scalars['String'];
}>;


export type GetPerformanceToDoQuery = (
  { __typename?: 'Query' }
  & { GetPerformanceToDo: (
    { __typename?: 'ToDoPerformanceOutput' }
    & { KpiExercise?: Maybe<Array<(
      { __typename?: 'ToDoKpiExerciseOutput' }
      & Pick<ToDoKpiExerciseOutput, 'ID' | 'Type' | 'KpiExerciseID' | 'ReviewGroupName' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'SuperiorID' | 'Status' | 'FromDate' | 'ToDate' | 'CreatedDT' | 'SubmitDate' | 'ApprovalStatus' | 'ApproverName'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'ContactID' | 'EmployeeNo'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Approver?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'ContactID'>
        )> }
      )>, ApprovalHistory: Array<(
        { __typename?: 'KpiExerciseEntity' }
        & Pick<KpiExerciseEntity, 'EmployeeID' | 'SuperiorID' | 'KpiExerciseID' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'CreatedDT' | 'ApprovalStatus'>
      )> }
    )>>, EmployeeReview?: Maybe<Array<(
      { __typename?: 'EmployeeReviewEntity' }
      & Pick<EmployeeReviewEntity, 'ID' | 'ReviewExerciseName' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'ReviewFormType' | 'ReviewFormTemplateName' | 'Status' | 'CreatedDT' | 'ReviewProcessLog'>
      & { Reviewer?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'ContactID' | 'EmployeeNo'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, ApprovalHistory: Array<(
        { __typename?: 'EmployeeReviewEntity' }
        & Pick<EmployeeReviewEntity, 'ReviewExerciseName' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'ReviewFormType' | 'ReviewFormTemplateName' | 'Status' | 'CreatedDT'>
        & { Reviewer?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type MyPerformanceSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
}>;


export type MyPerformanceSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyPerformanceSummary'>
);

export type EmployeeKpiListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ContactID?: Maybe<Scalars['String']>;
}>;


export type EmployeeKpiListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeKpiListing: Array<(
    { __typename?: 'EmployeeKpiEntity' }
    & Pick<EmployeeKpiEntity, 'ID' | 'EmployeeKpiDetails' | 'CreatedDT' | 'ModifiedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, KpiExerciseDetail: (
      { __typename?: 'KpiExerciseDetailsEntity' }
      & Pick<KpiExerciseDetailsEntity, 'KpiExerciseID' | 'KpiRule' | 'CompositionRules' | 'WeightageType' | 'EditableWeightage' | 'FormItemRatingID'>
      & { ReviewGroup?: Maybe<(
        { __typename?: 'ReviewGroupEntity' }
        & Pick<ReviewGroupEntity, 'ID' | 'Title'>
      )>, KpiPredefinedTemplate?: Maybe<(
        { __typename?: 'KpiPredefinedTemplateEntity' }
        & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title'>
      )>, FormItemRating?: Maybe<(
        { __typename?: 'FormItemRatingEntity' }
        & Pick<FormItemRatingEntity, 'ID' | 'Title'>
      )>, KpiExercise?: Maybe<(
        { __typename?: 'KpiExerciseEntity' }
        & Pick<KpiExerciseEntity, 'ApprovalID' | 'KpiExerciseID' | 'FromDate' | 'ToDate' | 'Status' | 'ApprovalStatus' | 'ApprovalDT' | 'ApprovalRemark'>
        & { Superior?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type SubmitEmployeeKpiMutationVariables = Exact<{
  input: EmployeeKpiInput;
}>;


export type SubmitEmployeeKpiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitEmployeeKpi'>
);

export type EmployeeKpiRepoListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
}>;


export type EmployeeKpiRepoListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeKpiRepoListing: Array<(
    { __typename?: 'EmployeeKpiRepositoryEntity' }
    & Pick<EmployeeKpiRepositoryEntity, 'ID' | 'EmployeeKpiDetails'>
  )> }
);

export type RetrieveEmployeeKpiDetailFromRepoQueryVariables = Exact<{
  EmployeeKpiRepositoryID: Scalars['String'];
}>;


export type RetrieveEmployeeKpiDetailFromRepoQuery = (
  { __typename?: 'Query' }
  & { RetrieveEmployeeKpiDetailFromRepo: (
    { __typename?: 'EmployeeKpiRepositoryEntity' }
    & Pick<EmployeeKpiRepositoryEntity, 'ID' | 'EmployeeKpiDetails'>
  ) }
);

export type IsKpiExistInRepoQueryVariables = Exact<{
  KPI: Scalars['String'];
  ContactID: Scalars['String'];
  KpiRepoID?: Maybe<Scalars['String']>;
}>;


export type IsKpiExistInRepoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiExistInRepo'>
);

export type SaveEmployeeKpiInRepoMutationVariables = Exact<{
  input: EmployeeKpiRepositoryInput;
}>;


export type SaveEmployeeKpiInRepoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SaveEmployeeKpiInRepo'>
);

export type EmployeeKpiRepoDeleteMutationVariables = Exact<{
  EmployeeKpiRepositoryID: Scalars['String'];
}>;


export type EmployeeKpiRepoDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeKpiRepoDelete'>
);

export type GetEmployeeReviewListingQueryVariables = Exact<{
  ContactID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewFormType?: Maybe<Array<AppraiseeType> | AppraiseeType>;
  ReviewFormStatus?: Maybe<Array<Status> | Status>;
}>;


export type GetEmployeeReviewListingQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeReviewListing: Array<(
    { __typename?: 'ReviewExerciseEntity' }
    & Pick<ReviewExerciseEntity, 'ID' | 'ReviewExerciseName' | 'ReviewGroupID' | 'ReviewGroupName'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, EmployeeReview: Array<(
      { __typename?: 'EmployeeReviewEntity' }
      & Pick<EmployeeReviewEntity, 'ID' | 'EmployeeRating' | 'ReviewerRating' | 'OverallGrade' | 'ReviewFormType' | 'ReviewerID' | 'FromDate' | 'ToDate' | 'ReviewFormTemplateID' | 'ReviewFormTemplateName' | 'Status' | 'ReviewProcessLog'>
      & { Reviewer?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )> }
      )> }
    )> }
  )> }
);

export type GetEmployeeReviewActionListingQueryVariables = Exact<{
  ContactID: Scalars['String'];
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ReviewFormType?: Maybe<Array<AppraiseeType> | AppraiseeType>;
  ReviewFormStatus?: Maybe<Array<Status> | Status>;
}>;


export type GetEmployeeReviewActionListingQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeReviewActionListing: Array<(
    { __typename?: 'MyReviewActionOutput' }
    & Pick<MyReviewActionOutput, 'ID' | 'ReviewExerciseName'>
    & { EmployeeReview: Array<(
      { __typename?: 'EmployeeReviewEntity' }
      & Pick<EmployeeReviewEntity, 'ID' | 'ReviewerID' | 'HasNextLevelApproval' | 'IsSendBackToInitaite' | 'EmployeeRating' | 'ReviewerRating' | 'OverallGrade' | 'ReviewFormType' | 'ReviewGroupName' | 'FromDate' | 'ToDate' | 'ReviewFormTemplateName' | 'Status' | 'ReviewProcessLog'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo' | 'ContactID'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Reviewer?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile'>
        )> }
      )> }
    )> }
  )> }
);

export type MySubordinatePerformanceSummaryQueryVariables = Exact<{
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  SuperiorContactID: Scalars['String'];
}>;


export type MySubordinatePerformanceSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MySubordinatePerformanceSummary'>
);

export type SubordinateKpiEmpListingQueryVariables = Exact<{
  SuperiorContactID: Scalars['String'];
}>;


export type SubordinateKpiEmpListingQuery = (
  { __typename?: 'Query' }
  & { SubordinateKpiEmpListing: Array<(
    { __typename?: 'SubordinateKpiEmpOutput' }
    & Pick<SubordinateKpiEmpOutput, 'EmployeeID' | 'ContactID' | 'EmployeeNo' | 'TotalKpiRecord' | 'KpiPendingActionCount'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )> }
);

export type KpiListingBySubordinateQueryVariables = Exact<{
  SubordinateEmployeeID: Scalars['String'];
  SubordinateContactID?: Maybe<Scalars['String']>;
}>;


export type KpiListingBySubordinateQuery = (
  { __typename?: 'Query' }
  & { EmployeeKpiListing: Array<(
    { __typename?: 'EmployeeKpiEntity' }
    & Pick<EmployeeKpiEntity, 'ID' | 'EmployeeKpiDetails' | 'CreatedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )> }
    )>, KpiExerciseDetail: (
      { __typename?: 'KpiExerciseDetailsEntity' }
      & Pick<KpiExerciseDetailsEntity, 'KpiExerciseID' | 'KpiRule' | 'CompositionRules' | 'WeightageType' | 'EditableWeightage' | 'FormItemRatingID'>
      & { KpiExercise?: Maybe<(
        { __typename?: 'KpiExerciseEntity' }
        & Pick<KpiExerciseEntity, 'ApprovalID' | 'KpiExerciseID' | 'AllowToReInitiate' | 'FromDate' | 'ToDate' | 'Status' | 'ApprovalStatus' | 'ApprovalDT' | 'ApprovalRemark'>
        & { Superior?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>, ReviewGroup?: Maybe<(
        { __typename?: 'ReviewGroupEntity' }
        & Pick<ReviewGroupEntity, 'Title'>
      )>, KpiPredefinedTemplate?: Maybe<(
        { __typename?: 'KpiPredefinedTemplateEntity' }
        & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title'>
      )>, FormItemRating?: Maybe<(
        { __typename?: 'FormItemRatingEntity' }
        & Pick<FormItemRatingEntity, 'ID' | 'Title'>
      )> }
    ) }
  )> }
);

export type SubmitSubordinateKpiMutationVariables = Exact<{
  input: EmployeeKpiInput;
  IsSubmitByApprover?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['String']>;
}>;


export type SubmitSubordinateKpiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitEmployeeKpi'>
);

export type SubordinateKpiChangeStatusMutationVariables = Exact<{
  KpiExerciseID: Scalars['String'];
  StatusToChange: Status;
  Comment?: Maybe<Scalars['String']>;
}>;


export type SubordinateKpiChangeStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiExerciseChangeStatus'>
);


export const EmployeeProfileInsertUpdateDocument = gql`
    mutation EmployeeProfileInsertUpdate($DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfileInsertUpdate(DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type EmployeeProfileInsertUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;

/**
 * __useEmployeeProfileInsertUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeProfileInsertUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeProfileInsertUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeProfileInsertUpdateMutation, { data, loading, error }] = useEmployeeProfileInsertUpdateMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useEmployeeProfileInsertUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>(EmployeeProfileInsertUpdateDocument, baseOptions);
      }
export type EmployeeProfileInsertUpdateMutationHookResult = ReturnType<typeof useEmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    userName
    email
    contactNo
    department
    accountID
    jobTitle
    superUser
    lastestAccessedEntity
    roleID
    isEmployee
    isEmployeeAuth
    employeeID
    helpdeskAssignee
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const IsSuperUserEmployeeDocument = gql`
    query IsSuperUserEmployee($EmployeeID: String!) {
  IsSuperUserEmployee(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useIsSuperUserEmployeeQuery__
 *
 * To run a query within a React component, call `useIsSuperUserEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSuperUserEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSuperUserEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useIsSuperUserEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
      }
export function useIsSuperUserEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
        }
export type IsSuperUserEmployeeQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeQuery>;
export type IsSuperUserEmployeeLazyQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeLazyQuery>;
export type IsSuperUserEmployeeQueryResult = ApolloReactCommon.QueryResult<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!, $softwareCode: SoftwareCode!) {
  login(password: $password, loginId: $loginId, softwareCode: $softwareCode) {
    accessToken
    isEmployeeSuperUser
    user {
      accountID
      ID
      name
      superUser
      employeeID
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const EmployeeAppLoginDocument = gql`
    mutation employeeAppLogin($loginId: String!, $password: String!) {
  employeeAppLogin(loginId: $loginId, password: $password) {
    accessToken
    employee {
      subscriptionAccountID
      employeeID
      employeeNo
      name
      email
      mobileNo
    }
  }
}
    `;
export type EmployeeAppLoginMutationFn = ApolloReactCommon.MutationFunction<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;

/**
 * __useEmployeeAppLoginMutation__
 *
 * To run a mutation, you first call `useEmployeeAppLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAppLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAppLoginMutation, { data, loading, error }] = useEmployeeAppLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEmployeeAppLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>(EmployeeAppLoginDocument, baseOptions);
      }
export type EmployeeAppLoginMutationHookResult = ReturnType<typeof useEmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationResult = ApolloReactCommon.MutationResult<EmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;
export const LoggedInEmployeeProfileDocument = gql`
    query loggedInEmployeeProfile {
  loggedInEmployeeProfile {
    subscriptionAccountID
    employeeID
    employeeNo
    companyID
    name
    email
    mobileNo
    LastestAccessedEntity
    SuperUserID
    Salutation
  }
}
    `;

/**
 * __useLoggedInEmployeeProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInEmployeeProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInEmployeeProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInEmployeeProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInEmployeeProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
      }
export function useLoggedInEmployeeProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
        }
export type LoggedInEmployeeProfileQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileQuery>;
export type LoggedInEmployeeProfileLazyQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileLazyQuery>;
export type LoggedInEmployeeProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeEmployeePasswordDocument = gql`
    mutation changeEmployeePassword($newPassword: String!, $currentPassword: String!) {
  changeEmployeePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangeEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;

/**
 * __useChangeEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useChangeEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmployeePasswordMutation, { data, loading, error }] = useChangeEmployeePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangeEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>(ChangeEmployeePasswordDocument, baseOptions);
      }
export type ChangeEmployeePasswordMutationHookResult = ReturnType<typeof useChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ForgotEmployeePasswordDocument = gql`
    mutation forgotEmployeePassword($email: String!) {
  forgotEmployeePassword(email: $email)
}
    `;
export type ForgotEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;

/**
 * __useForgotEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useForgotEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotEmployeePasswordMutation, { data, loading, error }] = useForgotEmployeePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>(ForgotEmployeePasswordDocument, baseOptions);
      }
export type ForgotEmployeePasswordMutationHookResult = ReturnType<typeof useForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetEmployeePasswordDocument = gql`
    mutation resetEmployeePassword($password: String!, $token: String!) {
  resetEmployeePassword(password: $password, token: $token)
}
    `;
export type ResetEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;

/**
 * __useResetEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useResetEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEmployeePasswordMutation, { data, loading, error }] = useResetEmployeePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>(ResetEmployeePasswordDocument, baseOptions);
      }
export type ResetEmployeePasswordMutationHookResult = ReturnType<typeof useResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmployeeLogoutDocument = gql`
    mutation employeeLogout {
  employeeLogout
}
    `;
export type EmployeeLogoutMutationFn = ApolloReactCommon.MutationFunction<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;

/**
 * __useEmployeeLogoutMutation__
 *
 * To run a mutation, you first call `useEmployeeLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeLogoutMutation, { data, loading, error }] = useEmployeeLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>(EmployeeLogoutDocument, baseOptions);
      }
export type EmployeeLogoutMutationHookResult = ReturnType<typeof useEmployeeLogoutMutation>;
export type EmployeeLogoutMutationResult = ApolloReactCommon.MutationResult<EmployeeLogoutMutation>;
export type EmployeeLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const UserProfilePicDocument = gql`
    query UserProfilePic($UserID: String!, $IsEmployee: Boolean) {
  UserProfilePic(UserID: $UserID, IsEmployee: $IsEmployee)
}
    `;

/**
 * __useUserProfilePicQuery__
 *
 * To run a query within a React component, call `useUserProfilePicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfilePicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfilePicQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      IsEmployee: // value for 'IsEmployee'
 *   },
 * });
 */
export function useUserProfilePicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
      }
export function useUserProfilePicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
        }
export type UserProfilePicQueryHookResult = ReturnType<typeof useUserProfilePicQuery>;
export type UserProfilePicLazyQueryHookResult = ReturnType<typeof useUserProfilePicLazyQuery>;
export type UserProfilePicQueryResult = ApolloReactCommon.QueryResult<UserProfilePicQuery, UserProfilePicQueryVariables>;
export const GetEmployeeAppSecurityPermissionsByContactIdDocument = gql`
    query GetEmployeeAppSecurityPermissionsByContactID($ContactID: String!, $Permissions: [HrPermission!], $EmployeeID: String) {
  GetEmployeeAppSecurityPermissionsByContactID(ContactID: $ContactID, Permissions: $Permissions, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetEmployeeAppSecurityPermissionsByContactIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityPermissionsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityPermissionsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      Permissions: // value for 'Permissions'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityPermissionsByContactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
        }
export type GetEmployeeAppSecurityPermissionsByContactIdQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>;
export const GetRoleByUserIdDocument = gql`
    query getRoleByUserID($UserID: String) {
  getRole(UserID: $UserID) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleByUserIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByUserIdQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useGetRoleByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, baseOptions);
      }
export function useGetRoleByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, baseOptions);
        }
export type GetRoleByUserIdQueryHookResult = ReturnType<typeof useGetRoleByUserIdQuery>;
export type GetRoleByUserIdLazyQueryHookResult = ReturnType<typeof useGetRoleByUserIdLazyQuery>;
export type GetRoleByUserIdQueryResult = ApolloReactCommon.QueryResult<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>;
export const GetRolePermissionByUserDocument = gql`
    query getRolePermissionByUser($hrPermissions: [HrPermission!]) {
  getRolePermissionByUser(hrPermissions: $hrPermissions)
}
    `;

/**
 * __useGetRolePermissionByUserQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionByUserQuery({
 *   variables: {
 *      hrPermissions: // value for 'hrPermissions'
 *   },
 * });
 */
export function useGetRolePermissionByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
      }
export function useGetRolePermissionByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
        }
export type GetRolePermissionByUserQueryHookResult = ReturnType<typeof useGetRolePermissionByUserQuery>;
export type GetRolePermissionByUserLazyQueryHookResult = ReturnType<typeof useGetRolePermissionByUserLazyQuery>;
export type GetRolePermissionByUserQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>;
export const JobDesignationListingDocument = gql`
    query JobDesignationListing($SubscriptionAccountID: String!) {
  JobDesignationListing(SubscriptionAccountID: $SubscriptionAccountID) {
    JobDesignationID
    SubscriptionAccountID
    Name
    IsAdmin
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Employees {
      Status
      DesignationID
      EmployeeID
    }
    JobDesignationGrades {
      JobGradeID
    }
  }
}
    `;

/**
 * __useJobDesignationListingQuery__
 *
 * To run a query within a React component, call `useJobDesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDesignationListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobDesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
      }
export function useJobDesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
        }
export type JobDesignationListingQueryHookResult = ReturnType<typeof useJobDesignationListingQuery>;
export type JobDesignationListingLazyQueryHookResult = ReturnType<typeof useJobDesignationListingLazyQuery>;
export type JobDesignationListingQueryResult = ApolloReactCommon.QueryResult<JobDesignationListingQuery, JobDesignationListingQueryVariables>;
export const JobGradeByJobDesignationListingDocument = gql`
    query JobGradeByJobDesignationListing($JobDesignationID: String!) {
  JobGradeByJobDesignationListing(JobDesignationID: $JobDesignationID) {
    JobDesignationID
    JobGrade {
      JobGradeID
      Description
    }
  }
}
    `;

/**
 * __useJobGradeByJobDesignationListingQuery__
 *
 * To run a query within a React component, call `useJobGradeByJobDesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeByJobDesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeByJobDesignationListingQuery({
 *   variables: {
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobGradeByJobDesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>(JobGradeByJobDesignationListingDocument, baseOptions);
      }
export function useJobGradeByJobDesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>(JobGradeByJobDesignationListingDocument, baseOptions);
        }
export type JobGradeByJobDesignationListingQueryHookResult = ReturnType<typeof useJobGradeByJobDesignationListingQuery>;
export type JobGradeByJobDesignationListingLazyQueryHookResult = ReturnType<typeof useJobGradeByJobDesignationListingLazyQuery>;
export type JobGradeByJobDesignationListingQueryResult = ApolloReactCommon.QueryResult<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>;
export const ApprovalPolicyDeleteDocument = gql`
    mutation ApprovalPolicyDelete($SubscriptionAccountID: String!, $ID: String!) {
  ApprovalPolicyDelete(SubscriptionAccountID: $SubscriptionAccountID, ID: $ID)
}
    `;
export type ApprovalPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>;

/**
 * __useApprovalPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyDeleteMutation, { data, loading, error }] = useApprovalPolicyDeleteMutation({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useApprovalPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>(ApprovalPolicyDeleteDocument, baseOptions);
      }
export type ApprovalPolicyDeleteMutationHookResult = ReturnType<typeof useApprovalPolicyDeleteMutation>;
export type ApprovalPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyDeleteMutation>;
export type ApprovalPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>;
export const ApprovalPolicyListDocument = gql`
    query ApprovalPolicyList($SubscriptionAccountID: String!, $Category: String!, $ID: String) {
  ApprovalPolicyList(SubscriptionAccountID: $SubscriptionAccountID, Category: $Category, ID: $ID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ID
    InUse
    SubscriptionAccountID
    Title
    Category
    ApprovalList {
      ID
      StepNo
      RoleID
      Predicate
      Amount
      Category
      RoleName
      EndIf
      Skip
    }
  }
}
    `;

/**
 * __useApprovalPolicyListQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Category: // value for 'Category'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useApprovalPolicyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>(ApprovalPolicyListDocument, baseOptions);
      }
export function useApprovalPolicyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>(ApprovalPolicyListDocument, baseOptions);
        }
export type ApprovalPolicyListQueryHookResult = ReturnType<typeof useApprovalPolicyListQuery>;
export type ApprovalPolicyListLazyQueryHookResult = ReturnType<typeof useApprovalPolicyListLazyQuery>;
export type ApprovalPolicyListQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>;
export const ApprovalPolicySubmitDocument = gql`
    mutation ApprovalPolicySubmit($input: NewApprovalPolicyInput!) {
  ApprovalPolicySubmit(input: $input)
}
    `;
export type ApprovalPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicySubmitMutation, ApprovalPolicySubmitMutationVariables>;

/**
 * __useApprovalPolicySubmitMutation__
 *
 * To run a mutation, you first call `useApprovalPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicySubmitMutation, { data, loading, error }] = useApprovalPolicySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovalPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicySubmitMutation, ApprovalPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicySubmitMutation, ApprovalPolicySubmitMutationVariables>(ApprovalPolicySubmitDocument, baseOptions);
      }
export type ApprovalPolicySubmitMutationHookResult = ReturnType<typeof useApprovalPolicySubmitMutation>;
export type ApprovalPolicySubmitMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicySubmitMutation>;
export type ApprovalPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicySubmitMutation, ApprovalPolicySubmitMutationVariables>;
export const ApprovalPolicyDetailsDocument = gql`
    query ApprovalPolicyDetails($ApprovalPolicyID: String!, $Category: String!) {
  ApprovalPolicyDetails(ApprovalPolicyID: $ApprovalPolicyID, Category: $Category) {
    ID
    Title
    ApprovalList {
      ID
      StepNo
      RoleID
      Predicate
      Amount
      Category
      RoleName
      EndIf
      Skip
      reporting_group_id
    }
  }
}
    `;

/**
 * __useApprovalPolicyDetailsQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyDetailsQuery({
 *   variables: {
 *      ApprovalPolicyID: // value for 'ApprovalPolicyID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useApprovalPolicyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyDetailsQuery, ApprovalPolicyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyDetailsQuery, ApprovalPolicyDetailsQueryVariables>(ApprovalPolicyDetailsDocument, baseOptions);
      }
export function useApprovalPolicyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyDetailsQuery, ApprovalPolicyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyDetailsQuery, ApprovalPolicyDetailsQueryVariables>(ApprovalPolicyDetailsDocument, baseOptions);
        }
export type ApprovalPolicyDetailsQueryHookResult = ReturnType<typeof useApprovalPolicyDetailsQuery>;
export type ApprovalPolicyDetailsLazyQueryHookResult = ReturnType<typeof useApprovalPolicyDetailsLazyQuery>;
export type ApprovalPolicyDetailsQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyDetailsQuery, ApprovalPolicyDetailsQueryVariables>;
export const IsApprovalPolicyTitleExistDocument = gql`
    query IsApprovalPolicyTitleExist($Title: String!, $Category: String!, $ApprovalPolicyID: String) {
  IsApprovalPolicyTitleExist(Title: $Title, Category: $Category, ApprovalPolicyID: $ApprovalPolicyID)
}
    `;

/**
 * __useIsApprovalPolicyTitleExistQuery__
 *
 * To run a query within a React component, call `useIsApprovalPolicyTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsApprovalPolicyTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsApprovalPolicyTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      Category: // value for 'Category'
 *      ApprovalPolicyID: // value for 'ApprovalPolicyID'
 *   },
 * });
 */
export function useIsApprovalPolicyTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsApprovalPolicyTitleExistQuery, IsApprovalPolicyTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsApprovalPolicyTitleExistQuery, IsApprovalPolicyTitleExistQueryVariables>(IsApprovalPolicyTitleExistDocument, baseOptions);
      }
export function useIsApprovalPolicyTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsApprovalPolicyTitleExistQuery, IsApprovalPolicyTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsApprovalPolicyTitleExistQuery, IsApprovalPolicyTitleExistQueryVariables>(IsApprovalPolicyTitleExistDocument, baseOptions);
        }
export type IsApprovalPolicyTitleExistQueryHookResult = ReturnType<typeof useIsApprovalPolicyTitleExistQuery>;
export type IsApprovalPolicyTitleExistLazyQueryHookResult = ReturnType<typeof useIsApprovalPolicyTitleExistLazyQuery>;
export type IsApprovalPolicyTitleExistQueryResult = ApolloReactCommon.QueryResult<IsApprovalPolicyTitleExistQuery, IsApprovalPolicyTitleExistQueryVariables>;
export const EmployeeListingDocument = gql`
    query EmployeeListing($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $offset: Float, $limit: Float, $ExcludeFormStatus: String) {
  EmployeeListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus) {
    EmployeeID
    SubscriptionUserID
    EmployeeNo
    EmploymentType
    JoinedReferenceDate
    JoinedDate
    ConfirmedDate
    Probation
    Status
    FormStatus
    EmployeeSalaryLogs {
      MovementType
      FromSalary
      ToSalary
      excelfilename
      EffectiveDate
      SalaryAdjustmentAmount
      Remark
    }
    OrgUnitID
    Supervisor {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
    }
    DesignationID
    Contact {
      FullName
    }
    Company {
      CompanyID
      CompanyName
    }
    Designation {
      JobDesignationID
      Name
    }
    Documents {
      DocumentFile
    }
    ResignedDate
    JobGradeID
    Department {
      Description
    }
    Division {
      Description
    }
    JobGrade {
      Description
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    EmployeeSubordinates {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
      }
    }
  }
}
    `;

/**
 * __useEmployeeListingQuery__
 *
 * To run a query within a React component, call `useEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingQuery, EmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingQuery, EmployeeListingQueryVariables>(EmployeeListingDocument, baseOptions);
      }
export function useEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingQuery, EmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingQuery, EmployeeListingQueryVariables>(EmployeeListingDocument, baseOptions);
        }
export type EmployeeListingQueryHookResult = ReturnType<typeof useEmployeeListingQuery>;
export type EmployeeListingLazyQueryHookResult = ReturnType<typeof useEmployeeListingLazyQuery>;
export type EmployeeListingQueryResult = ApolloReactCommon.QueryResult<EmployeeListingQuery, EmployeeListingQueryVariables>;
export const GetRoleDocument = gql`
    query getRole($IsApprovalPolicy: Boolean, $ID: String) {
  getRole(IsApprovalPolicy: $IsApprovalPolicy, ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
    roleUserAssignments {
      roleID
      userID
      entityID
      ID
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      IsApprovalPolicy: // value for 'IsApprovalPolicy'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const JobGradeListingByCompanyDocument = gql`
    query JobGradeListingByCompany($SubscriptionAccountID: String!) {
  JobGradeListingByCompany(SubscriptionAccountID: $SubscriptionAccountID) {
    JobGradeID
    SubscriptionAccountID
    Description
    LeaveBenefitID
    ClaimBenefitID
    JobDesignationGrades {
      JobDesignationGradeID
      JobDesignationID
      JobGradeID
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useJobGradeListingByCompanyQuery__
 *
 * To run a query within a React component, call `useJobGradeListingByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeListingByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeListingByCompanyQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobGradeListingByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>(JobGradeListingByCompanyDocument, baseOptions);
      }
export function useJobGradeListingByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>(JobGradeListingByCompanyDocument, baseOptions);
        }
export type JobGradeListingByCompanyQueryHookResult = ReturnType<typeof useJobGradeListingByCompanyQuery>;
export type JobGradeListingByCompanyLazyQueryHookResult = ReturnType<typeof useJobGradeListingByCompanyLazyQuery>;
export type JobGradeListingByCompanyQueryResult = ApolloReactCommon.QueryResult<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>;
export const CompanyListingDocument = gql`
    query CompanyListing($SubscriptionAccountID: String!) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID) {
    CreatedDT
    ModifiedDT
    CompanyID
    CompanyName
    BizRegNo
    Departments {
      DepartmentID
      Description
      DepartmentType
      Description
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useCompanyListingQuery__
 *
 * To run a query within a React component, call `useCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
      }
export function useCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
        }
export type CompanyListingQueryHookResult = ReturnType<typeof useCompanyListingQuery>;
export type CompanyListingLazyQueryHookResult = ReturnType<typeof useCompanyListingLazyQuery>;
export type CompanyListingQueryResult = ApolloReactCommon.QueryResult<CompanyListingQuery, CompanyListingQueryVariables>;
export const GetCompanyPermissionDocument = gql`
    query getCompanyPermission($hrPermissions: [HrPermission!], $CompanyID: String, $RoleID: String) {
  getCompanyPermission(hrPermissions: $hrPermissions, CompanyID: $CompanyID, RoleID: $RoleID)
}
    `;

/**
 * __useGetCompanyPermissionQuery__
 *
 * To run a query within a React component, call `useGetCompanyPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPermissionQuery({
 *   variables: {
 *      hrPermissions: // value for 'hrPermissions'
 *      CompanyID: // value for 'CompanyID'
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useGetCompanyPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>(GetCompanyPermissionDocument, baseOptions);
      }
export function useGetCompanyPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>(GetCompanyPermissionDocument, baseOptions);
        }
export type GetCompanyPermissionQueryHookResult = ReturnType<typeof useGetCompanyPermissionQuery>;
export type GetCompanyPermissionLazyQueryHookResult = ReturnType<typeof useGetCompanyPermissionLazyQuery>;
export type GetCompanyPermissionQueryResult = ApolloReactCommon.QueryResult<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>;
export const CompanyOrgUnitByCompanyIdArrayDocument = gql`
    query CompanyOrgUnitByCompanyIDArray($CompanyIDArray: [String!]) {
  CompanyOrgUnitList(CompanyIDArray: $CompanyIDArray)
}
    `;

/**
 * __useCompanyOrgUnitByCompanyIdArrayQuery__
 *
 * To run a query within a React component, call `useCompanyOrgUnitByCompanyIdArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitByCompanyIdArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOrgUnitByCompanyIdArrayQuery({
 *   variables: {
 *      CompanyIDArray: // value for 'CompanyIDArray'
 *   },
 * });
 */
export function useCompanyOrgUnitByCompanyIdArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
      }
export function useCompanyOrgUnitByCompanyIdArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
        }
export type CompanyOrgUnitByCompanyIdArrayQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayQuery>;
export type CompanyOrgUnitByCompanyIdArrayLazyQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayLazyQuery>;
export type CompanyOrgUnitByCompanyIdArrayQueryResult = ApolloReactCommon.QueryResult<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>;
export const SimpleCompanyListingDocument = gql`
    query SimpleCompanyListing($SubscriptionAccountID: String!, $CompanyIDs: [String!]) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID, CompanyIDs: $CompanyIDs) {
    CompanyID
    CompanyName
  }
}
    `;

/**
 * __useSimpleCompanyListingQuery__
 *
 * To run a query within a React component, call `useSimpleCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useSimpleCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
      }
export function useSimpleCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
        }
export type SimpleCompanyListingQueryHookResult = ReturnType<typeof useSimpleCompanyListingQuery>;
export type SimpleCompanyListingLazyQueryHookResult = ReturnType<typeof useSimpleCompanyListingLazyQuery>;
export type SimpleCompanyListingQueryResult = ApolloReactCommon.QueryResult<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>;
export const EmployeeListForJobInfoDocument = gql`
    query EmployeeListForJobInfo {
  EmployeeListForJobInfo
}
    `;

/**
 * __useEmployeeListForJobInfoQuery__
 *
 * To run a query within a React component, call `useEmployeeListForJobInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListForJobInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListForJobInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeListForJobInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>(EmployeeListForJobInfoDocument, baseOptions);
      }
export function useEmployeeListForJobInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>(EmployeeListForJobInfoDocument, baseOptions);
        }
export type EmployeeListForJobInfoQueryHookResult = ReturnType<typeof useEmployeeListForJobInfoQuery>;
export type EmployeeListForJobInfoLazyQueryHookResult = ReturnType<typeof useEmployeeListForJobInfoLazyQuery>;
export type EmployeeListForJobInfoQueryResult = ApolloReactCommon.QueryResult<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>;
export const EmployeeSummaryDocument = gql`
    query EmployeeSummary($EmployeeID: String!) {
  EmployeeSummary(EmployeeID: $EmployeeID) {
    RetirementAge
    EmployeeID
    CalendarPolicyID
    CalendarTitle
    Contact {
      ContactID
      FullName
      NickName
      Email
      Nationality
      MobileNo
      NRIC
      PassportNo
      PassportNoLHDN
      BirthDate
      Gender
      Marital
      Salutation
      Race
      Religion
      Address {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      PermanentAddress {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      SameAddress
      ProfilePicture
    }
    IsOKU
    IsHOD
    IsSuperUser
    IsInterCompanyTransfer
    EmployeeNo
    EmploymentType
    Status
    EmployeeStatus
    FormStatus
    Probation
    CalendarStateID
    JoinedReferenceDate
    StaffCategory
    StaffCategoryID
    OrgUnitID
    ActualConfirmedDate
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocCount
    ContractInfoDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
    Company {
      CompanyID
      CalendarTitle
      CompanyName
      CalendarStateID
      Contact {
        ContactID
        Address {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
      }
    }
    Department {
      DepartmentID
      Description
    }
    EmployeeDepartment {
      DepartmentID
      Description
    }
    Division {
      DepartmentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    JobGrade {
      JobGradeID
      Description
    }
    RecruitmentSource {
      RecruitmentSourceID
      Name
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    SubscriptionAccountID
    ReportTo
    Supervisor {
      EmployeeID
      Contact {
        FullName
      }
    }
    JoinedDate
    ConfirmedDate
    ResignedDate
    TenderResignDate
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      SalaryType
      FromSalary
      ToSalary
      TenderResignDate
      MovementType
      ExitReasonID
      Status
      ExitCompanyReason {
        PersonnelParameterID
        Name
      }
      LastEmploymentDate
      LastWorkingDate
      ModifiedDT
    }
    EmployeeBankInfo {
      EmployeeBankInfoID
      Bank
      AcctNo
      PaymentMethod
      CompanyBankAccount
      PayeeName
      PayeeID
    }
    EmployeeStatutory {
      EmployeeStatutoryID
      IsEPF
      EPFNo
      EPFVERate
      EPFVERateCmp
      IsSocso
      SocsoNo
      IsPCB
      PCBNo
      IsEIS
      EISNo
      IsAngkasa
      AngkasaNo
      IsASB
      ASBNo
      IsKWAP
      KWAPNo
      IsTBH
      TBHNo
      IsZakat
      ZakatNo
      ZakatAmount
      ZakatRate
      ResidentStatus
      CountryCode
      TaxMaritialStatus
      ZakatState
      IsHRDF
      ModifiedDT
      ModifiedBy
      CreatedBy
      CreatedDT
      UserLogs {
        columnName
        newColumnValue
        oldColumnValue
        createdDT
        createdBy
        modifiedDT
        modifiedBy
      }
      CompanyEPFAccount
      CompanySocsoAccount
      CompanyPCBAccount
      CompanyEISAccount
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
      CompanyID
      EmployeeID
      PeriodYear
      GrossTaxable
      EPFPaid
      PCBPaid
      ZAKATPaid
      ModifiedDT
      CreatedDT
    }
    EmployeeDependents {
      EmployeeDependentsID
      Relationship
      Education
      ChildEligibleRate
      Occupation
      Contact {
        ContactID
        FullName
        BirthDate
        MobileNo
        NRIC
        PassportNo
        Nationality
      }
      IsWorking
      IsOKU
      IsEmergencyContact
      PCBNo
      Education
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeAppraisals {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeAppraisalID
      EmployeeID
      AppraisalRecord
      Date
      Rating
      Score
      AppraiseeRemark
      AppraiserRemark
      AppraiserName
      DocumentFile
      BucketFileName
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    EmployeeDiaries {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeDiaryID
      EmployeeID
      Date
      CaseNo
      Description
      Countermeasure
      Private
      DocumentID
      Documents {
        DocumentID
        DocumentFile
        DocumentType
        Description
        BucketFileName
      }
    }
    EmployeeTrainings {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeTrainingID
      EmployeeID
      CourseName
      StartDate
      EndDate
      TrainingHour
      TrainingProvider
      Trainer
      TrainerRating
      TrainerScore
      Superior
      SuperiorRating
      SuperiorScore
      Document {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    EmployeeInsuranceSchemes {
      InsuranceSchemeID
      EmployeeInsuranceSchemeID
      StartDate
      EndDate
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      InsuranceScheme {
        Name
        Status
      }
    }
    EmployeeSubordinates {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
      }
    }
    EmployeeAssets {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeAssetID
      EmployeeID
      DateAssign
      Item
      Remark
    }
    LastUpdatedDates {
      EmployeeLastDate
      AssetsLastDate
      BIKLastDate
      CareerLastDate
      SubordinateLastDate
      TaxLastDate
      HealthInfoLastDate
      UserDefineFieldsLastDate
    }
    TotalEducation
    TotalJobHistory
    TotalPersonalDocument
    TotalCertificate
    RoleID
    RoleName
  }
}
    `;

/**
 * __useEmployeeSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>(EmployeeSummaryDocument, baseOptions);
      }
export function useEmployeeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>(EmployeeSummaryDocument, baseOptions);
        }
export type EmployeeSummaryQueryHookResult = ReturnType<typeof useEmployeeSummaryQuery>;
export type EmployeeSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeSummaryLazyQuery>;
export type EmployeeSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>;
export const PersonnelParameterListingDocument = gql`
    query PersonnelParameterListing {
  PersonnelParameterListing {
    PersonnelParameterID
    SubscriptionAccountID
    Name
    IsDefault
    Voluntary
    PersonnelParameterType
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePersonnelParameterListingQuery__
 *
 * To run a query within a React component, call `usePersonnelParameterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelParameterListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonnelParameterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>(PersonnelParameterListingDocument, baseOptions);
      }
export function usePersonnelParameterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>(PersonnelParameterListingDocument, baseOptions);
        }
export type PersonnelParameterListingQueryHookResult = ReturnType<typeof usePersonnelParameterListingQuery>;
export type PersonnelParameterListingLazyQueryHookResult = ReturnType<typeof usePersonnelParameterListingLazyQuery>;
export type PersonnelParameterListingQueryResult = ApolloReactCommon.QueryResult<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>;
export const EmployeeListingBySubscriptionAccountDocument = gql`
    query EmployeeListingBySubscriptionAccount($SubscriptionAccountID: String!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $offset: Float, $limit: Float, $ExcludeFormStatus: String) {
  EmployeeListingBySubscriptionAccount(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus) {
    RetirementAge
    OrgUnitID
    EmployeeID
    EmployeeNo
    DepartmentID
    DivisionID
    JobGradeID
    OrgUnitID
    JoinedDate
    ResignedDate
    Status
    CompanyID
    CalendarTitle
    CalendarStateID
    Contact {
      ContactID
      FullName
      MobileNo
      Email
    }
    OrgUnit {
      Description
    }
    Company {
      CompanyID
      CompanyName
    }
    EmployeeStatutory {
      EmployeeStatutoryID
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
    }
    EmployeeDependents {
      EmployeeDependentsID
    }
    EmployeeSalaryLogs {
      CareerLogID
      EffectiveDate
      FromSalary
      ToSalary
      SalaryAdjustmentAmount
      SalaryType
      Reason
      Remark
    }
    Department {
      DepartmentID
      DepartmentType
      ParentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    DocumentID
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    LastUpdatedDates {
      TaxLastDate
      Cp22LastDate
      BIKLastDate
    }
    TotalTaxRelief
    FormStatus
    EmploymentType
    CreatedDT
    ModifiedDT
    RoleID
    RoleName
  }
}
    `;

/**
 * __useEmployeeListingBySubscriptionAccountQuery__
 *
 * To run a query within a React component, call `useEmployeeListingBySubscriptionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingBySubscriptionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingBySubscriptionAccountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingBySubscriptionAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>(EmployeeListingBySubscriptionAccountDocument, baseOptions);
      }
export function useEmployeeListingBySubscriptionAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>(EmployeeListingBySubscriptionAccountDocument, baseOptions);
        }
export type EmployeeListingBySubscriptionAccountQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountQuery>;
export type EmployeeListingBySubscriptionAccountLazyQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountLazyQuery>;
export type EmployeeListingBySubscriptionAccountQueryResult = ApolloReactCommon.QueryResult<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>;
export const EmployeeNameListingBySubscriptionAccountDocument = gql`
    query EmployeeNameListingBySubscriptionAccount($SubscriptionAccountID: String, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $offset: Float, $limit: Float, $ExcludeFormStatus: String, $keyword: String, $Page: String) {
  EmployeeListingBySubscriptionAccount(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus, keyword: $keyword, Page: $Page) {
    EmployeeID
    Contact {
      ContactID
      FullName
    }
    Status
  }
}
    `;

/**
 * __useEmployeeNameListingBySubscriptionAccountQuery__
 *
 * To run a query within a React component, call `useEmployeeNameListingBySubscriptionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNameListingBySubscriptionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNameListingBySubscriptionAccountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      keyword: // value for 'keyword'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeNameListingBySubscriptionAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeNameListingBySubscriptionAccountQuery, EmployeeNameListingBySubscriptionAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeNameListingBySubscriptionAccountQuery, EmployeeNameListingBySubscriptionAccountQueryVariables>(EmployeeNameListingBySubscriptionAccountDocument, baseOptions);
      }
export function useEmployeeNameListingBySubscriptionAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeNameListingBySubscriptionAccountQuery, EmployeeNameListingBySubscriptionAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeNameListingBySubscriptionAccountQuery, EmployeeNameListingBySubscriptionAccountQueryVariables>(EmployeeNameListingBySubscriptionAccountDocument, baseOptions);
        }
export type EmployeeNameListingBySubscriptionAccountQueryHookResult = ReturnType<typeof useEmployeeNameListingBySubscriptionAccountQuery>;
export type EmployeeNameListingBySubscriptionAccountLazyQueryHookResult = ReturnType<typeof useEmployeeNameListingBySubscriptionAccountLazyQuery>;
export type EmployeeNameListingBySubscriptionAccountQueryResult = ApolloReactCommon.QueryResult<EmployeeNameListingBySubscriptionAccountQuery, EmployeeNameListingBySubscriptionAccountQueryVariables>;
export const SubordinateTreeDocument = gql`
    query SubordinateTree($EmployeeID: String!, $ModuleName: String, $level: Float) {
  SubordinateTree(EmployeeID: $EmployeeID, ModuleName: $ModuleName, level: $level)
}
    `;

/**
 * __useSubordinateTreeQuery__
 *
 * To run a query within a React component, call `useSubordinateTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateTreeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ModuleName: // value for 'ModuleName'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useSubordinateTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateTreeQuery, SubordinateTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateTreeQuery, SubordinateTreeQueryVariables>(SubordinateTreeDocument, baseOptions);
      }
export function useSubordinateTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateTreeQuery, SubordinateTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateTreeQuery, SubordinateTreeQueryVariables>(SubordinateTreeDocument, baseOptions);
        }
export type SubordinateTreeQueryHookResult = ReturnType<typeof useSubordinateTreeQuery>;
export type SubordinateTreeLazyQueryHookResult = ReturnType<typeof useSubordinateTreeLazyQuery>;
export type SubordinateTreeQueryResult = ApolloReactCommon.QueryResult<SubordinateTreeQuery, SubordinateTreeQueryVariables>;
export const UpdateEmployeeProfilePicDocument = gql`
    mutation updateEmployeeProfilePic($EmployeeID: String!, $DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfilePicUpdate(EmployeeID: $EmployeeID, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type UpdateEmployeeProfilePicMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;

/**
 * __useUpdateEmployeeProfilePicMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeProfilePicMutation, { data, loading, error }] = useUpdateEmployeeProfilePicMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useUpdateEmployeeProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>(UpdateEmployeeProfilePicDocument, baseOptions);
      }
export type UpdateEmployeeProfilePicMutationHookResult = ReturnType<typeof useUpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;
export const CompanyListingByEmailDocument = gql`
    query CompanyListingByEmail($EmployeeEmail: String!) {
  CompanyListingByEmail(EmployeeEmail: $EmployeeEmail)
}
    `;

/**
 * __useCompanyListingByEmailQuery__
 *
 * To run a query within a React component, call `useCompanyListingByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListingByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListingByEmailQuery({
 *   variables: {
 *      EmployeeEmail: // value for 'EmployeeEmail'
 *   },
 * });
 */
export function useCompanyListingByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>(CompanyListingByEmailDocument, baseOptions);
      }
export function useCompanyListingByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>(CompanyListingByEmailDocument, baseOptions);
        }
export type CompanyListingByEmailQueryHookResult = ReturnType<typeof useCompanyListingByEmailQuery>;
export type CompanyListingByEmailLazyQueryHookResult = ReturnType<typeof useCompanyListingByEmailLazyQuery>;
export type CompanyListingByEmailQueryResult = ApolloReactCommon.QueryResult<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>;
export const HomeSummaryDocument = gql`
    query HomeSummary($EmployeeID: String!) {
  HomeSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useHomeSummaryQuery__
 *
 * To run a query within a React component, call `useHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeSummaryQuery, HomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeSummaryQuery, HomeSummaryQueryVariables>(HomeSummaryDocument, baseOptions);
      }
export function useHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeSummaryQuery, HomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeSummaryQuery, HomeSummaryQueryVariables>(HomeSummaryDocument, baseOptions);
        }
export type HomeSummaryQueryHookResult = ReturnType<typeof useHomeSummaryQuery>;
export type HomeSummaryLazyQueryHookResult = ReturnType<typeof useHomeSummaryLazyQuery>;
export type HomeSummaryQueryResult = ApolloReactCommon.QueryResult<HomeSummaryQuery, HomeSummaryQueryVariables>;
export const MyEmployeeInfoDocument = gql`
    query MyEmployeeInfo($EmployeeID: String!) {
  MyEmployeeInfo(EmployeeID: $EmployeeID) {
    EmployeeID
    EmployeeNo
    DesignationID
    CalendarPolicyID
    OrgUnitID
    ContactID
    Contact {
      ContactID
      FullName
      Salutation
      EmployeeSalutation {
        Name
      }
    }
    OrgUnit {
      CompanyOrgUnitID
      Description
    }
    Company {
      CompanyID
      SubscriptionAccountID
      CompanyName
    }
    Designation {
      Name
    }
    Department {
      Description
    }
    JobGradeID
    TotalAnnualLeave
    TotalSickLeave
    TotalUnpaidLeave
    TotalClaimApproved
    TotalClaimAmountApproved
    TotalTravelRequest
    TotalPurchaseRequisition
    TotalCashAdvanceRequisition
    TotalOtherRequisition
    LastUpdatedDates {
      CareerLastDate
    }
    EmployeeSubordinates {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
      CompanyID
    }
    Documents {
      ParentID
      DocumentType
      FileSize
      DocumentFile
      DocumentID
    }
    RoleID
    RoleName
    Status
    ReportTo
    MyTodo
    MyNotification
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
  }
}
    `;

/**
 * __useMyEmployeeInfoQuery__
 *
 * To run a query within a React component, call `useMyEmployeeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEmployeeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEmployeeInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useMyEmployeeInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
      }
export function useMyEmployeeInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
        }
export type MyEmployeeInfoQueryHookResult = ReturnType<typeof useMyEmployeeInfoQuery>;
export type MyEmployeeInfoLazyQueryHookResult = ReturnType<typeof useMyEmployeeInfoLazyQuery>;
export type MyEmployeeInfoQueryResult = ApolloReactCommon.QueryResult<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>;
export const NewNotificationDocument = gql`
    subscription newNotification($ToEmployeeID: String!) {
  newNotifications(ToEmployeeID: $ToEmployeeID) {
    ToEmployeeID
    Message
  }
}
    `;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *      ToEmployeeID: // value for 'ToEmployeeID'
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, baseOptions);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewNotificationSubscription>;
export const UpdateEmployeeLastLoginCompanyDocument = gql`
    mutation UpdateEmployeeLastLoginCompany($EmployeeID: String!, $LastestAccessedEntity: String!) {
  UpdateEmployeeLastLoginCompany(EmployeeID: $EmployeeID, LastestAccessedEntity: $LastestAccessedEntity)
}
    `;
export type UpdateEmployeeLastLoginCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>;

/**
 * __useUpdateEmployeeLastLoginCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeLastLoginCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeLastLoginCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeLastLoginCompanyMutation, { data, loading, error }] = useUpdateEmployeeLastLoginCompanyMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      LastestAccessedEntity: // value for 'LastestAccessedEntity'
 *   },
 * });
 */
export function useUpdateEmployeeLastLoginCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>(UpdateEmployeeLastLoginCompanyDocument, baseOptions);
      }
export type UpdateEmployeeLastLoginCompanyMutationHookResult = ReturnType<typeof useUpdateEmployeeLastLoginCompanyMutation>;
export type UpdateEmployeeLastLoginCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeLastLoginCompanyMutation>;
export type UpdateEmployeeLastLoginCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>;
export const EmployeeShiftGroupAssignmentLogDocument = gql`
    query EmployeeShiftGroupAssignmentLog($EmployeeID: String!, $Today: Boolean) {
  EmployeeShiftGroupAssignmentLog(EmployeeID: $EmployeeID, Today: $Today) {
    ShiftGroupEmployeeAssignmentLogID
    ShiftGroupID
    ShiftGroup {
      Name
      ByQR
      QRSelfie
      ByLocation
      LocationSelfie
      ByBluetooth
      BluetoothSelfie
    }
    EmployeeID
    Start
    End
    Status
    hasDutyRoster
  }
}
    `;

/**
 * __useEmployeeShiftGroupAssignmentLogQuery__
 *
 * To run a query within a React component, call `useEmployeeShiftGroupAssignmentLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeShiftGroupAssignmentLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeShiftGroupAssignmentLogQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Today: // value for 'Today'
 *   },
 * });
 */
export function useEmployeeShiftGroupAssignmentLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>(EmployeeShiftGroupAssignmentLogDocument, baseOptions);
      }
export function useEmployeeShiftGroupAssignmentLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>(EmployeeShiftGroupAssignmentLogDocument, baseOptions);
        }
export type EmployeeShiftGroupAssignmentLogQueryHookResult = ReturnType<typeof useEmployeeShiftGroupAssignmentLogQuery>;
export type EmployeeShiftGroupAssignmentLogLazyQueryHookResult = ReturnType<typeof useEmployeeShiftGroupAssignmentLogLazyQuery>;
export type EmployeeShiftGroupAssignmentLogQueryResult = ApolloReactCommon.QueryResult<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>;
export const GetEmployeeAppSecurityAssignmentListingDocument = gql`
    query GetEmployeeAppSecurityAssignmentListing($CompanyID: String!, $EmployeeAppSecurityAssignmentID: String) {
  GetEmployeeAppSecurityAssignmentListing(CompanyID: $CompanyID, EmployeeAppSecurityAssignmentID: $EmployeeAppSecurityAssignmentID)
}
    `;

/**
 * __useGetEmployeeAppSecurityAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityAssignmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeAppSecurityAssignmentID: // value for 'EmployeeAppSecurityAssignmentID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>(GetEmployeeAppSecurityAssignmentListingDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>(GetEmployeeAppSecurityAssignmentListingDocument, baseOptions);
        }
export type GetEmployeeAppSecurityAssignmentListingQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityAssignmentListingQuery>;
export type GetEmployeeAppSecurityAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityAssignmentListingLazyQuery>;
export type GetEmployeeAppSecurityAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>;
export const GetPerformanceApprovalHistoryDocument = gql`
    query GetPerformanceApprovalHistory($ApprovalContactID: String!, $PerformanceRequestType: String!) {
  GetPerformanceApprovalHistory(ApprovalContactID: $ApprovalContactID, PerformanceRequestType: $PerformanceRequestType) {
    KpiExercise {
      ID
      Type
      KpiExerciseID
      ReviewGroupName
      Employee {
        EmployeeNo
        Contact {
          FullName
        }
      }
      EmployeeName
      EmployeeNo
      Status
      FromDate
      ToDate
      CreatedDT
      SubmitDate
      ApprovalStatus
      ApproverName
    }
    EmployeeReview {
      ID
      ReviewExerciseName
      ReviewGroupName
      FromDate
      ToDate
      ReviewFormType
      ReviewFormTemplateName
      Reviewer {
        EmployeeNo
        Contact {
          FullName
        }
      }
      Status
      CreatedDT
      Employee {
        EmployeeNo
        Contact {
          FullName
        }
      }
      ReviewProcessLog
      ApprovalHistory {
        ReviewExerciseName
        ReviewGroupName
        FromDate
        ToDate
        ReviewFormType
        ReviewFormTemplateName
        Reviewer {
          EmployeeNo
          Contact {
            FullName
          }
        }
        Status
        CreatedDT
      }
    }
  }
}
    `;

/**
 * __useGetPerformanceApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetPerformanceApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceApprovalHistoryQuery({
 *   variables: {
 *      ApprovalContactID: // value for 'ApprovalContactID'
 *      PerformanceRequestType: // value for 'PerformanceRequestType'
 *   },
 * });
 */
export function useGetPerformanceApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPerformanceApprovalHistoryQuery, GetPerformanceApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPerformanceApprovalHistoryQuery, GetPerformanceApprovalHistoryQueryVariables>(GetPerformanceApprovalHistoryDocument, baseOptions);
      }
export function useGetPerformanceApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPerformanceApprovalHistoryQuery, GetPerformanceApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPerformanceApprovalHistoryQuery, GetPerformanceApprovalHistoryQueryVariables>(GetPerformanceApprovalHistoryDocument, baseOptions);
        }
export type GetPerformanceApprovalHistoryQueryHookResult = ReturnType<typeof useGetPerformanceApprovalHistoryQuery>;
export type GetPerformanceApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetPerformanceApprovalHistoryLazyQuery>;
export type GetPerformanceApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<GetPerformanceApprovalHistoryQuery, GetPerformanceApprovalHistoryQueryVariables>;
export const NewsReadListingDocument = gql`
    query NewsReadListing($EmployeeID: String, $NewsID: [String!]) {
  NewsReadListing(EmployeeID: $EmployeeID, NewsID: $NewsID) {
    NewsReadID
    NewsID
    EmployeeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useNewsReadListingQuery__
 *
 * To run a query within a React component, call `useNewsReadListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsReadListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsReadListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      NewsID: // value for 'NewsID'
 *   },
 * });
 */
export function useNewsReadListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsReadListingQuery, NewsReadListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsReadListingQuery, NewsReadListingQueryVariables>(NewsReadListingDocument, baseOptions);
      }
export function useNewsReadListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsReadListingQuery, NewsReadListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsReadListingQuery, NewsReadListingQueryVariables>(NewsReadListingDocument, baseOptions);
        }
export type NewsReadListingQueryHookResult = ReturnType<typeof useNewsReadListingQuery>;
export type NewsReadListingLazyQueryHookResult = ReturnType<typeof useNewsReadListingLazyQuery>;
export type NewsReadListingQueryResult = ApolloReactCommon.QueryResult<NewsReadListingQuery, NewsReadListingQueryVariables>;
export const NewsReadInsertDocument = gql`
    mutation NewsReadInsert($NewsReadInput: NewsReadInput!) {
  NewsReadInsert(NewsReadInput: $NewsReadInput)
}
    `;
export type NewsReadInsertMutationFn = ApolloReactCommon.MutationFunction<NewsReadInsertMutation, NewsReadInsertMutationVariables>;

/**
 * __useNewsReadInsertMutation__
 *
 * To run a mutation, you first call `useNewsReadInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsReadInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsReadInsertMutation, { data, loading, error }] = useNewsReadInsertMutation({
 *   variables: {
 *      NewsReadInput: // value for 'NewsReadInput'
 *   },
 * });
 */
export function useNewsReadInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsReadInsertMutation, NewsReadInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsReadInsertMutation, NewsReadInsertMutationVariables>(NewsReadInsertDocument, baseOptions);
      }
export type NewsReadInsertMutationHookResult = ReturnType<typeof useNewsReadInsertMutation>;
export type NewsReadInsertMutationResult = ApolloReactCommon.MutationResult<NewsReadInsertMutation>;
export type NewsReadInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsReadInsertMutation, NewsReadInsertMutationVariables>;
export const BulletinListingDocument = gql`
    query BulletinListing($NewsID: String!) {
  BulletinListing(NewsID: $NewsID)
}
    `;

/**
 * __useBulletinListingQuery__
 *
 * To run a query within a React component, call `useBulletinListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinListingQuery({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *   },
 * });
 */
export function useBulletinListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulletinListingQuery, BulletinListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BulletinListingQuery, BulletinListingQueryVariables>(BulletinListingDocument, baseOptions);
      }
export function useBulletinListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulletinListingQuery, BulletinListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulletinListingQuery, BulletinListingQueryVariables>(BulletinListingDocument, baseOptions);
        }
export type BulletinListingQueryHookResult = ReturnType<typeof useBulletinListingQuery>;
export type BulletinListingLazyQueryHookResult = ReturnType<typeof useBulletinListingLazyQuery>;
export type BulletinListingQueryResult = ApolloReactCommon.QueryResult<BulletinListingQuery, BulletinListingQueryVariables>;
export const NewsListingDocument = gql`
    query NewsListing($SubscriptionAccountID: String!, $IsPublishedOnly: Boolean!, $StartMonth: String!, $EndMonth: String!, $offset: Float, $limit: Float, $isValid: Boolean) {
  NewsListing(SubscriptionAccountID: $SubscriptionAccountID, IsPublishedOnly: $IsPublishedOnly, StartMonth: $StartMonth, EndMonth: $EndMonth, offset: $offset, limit: $limit, isValid: $isValid) {
    NewsID
    SubscriptionAccountID
    Title
    Content
    Documents {
      DocumentID
      DocumentFile
      DocumentType
      Description
      BucketFileName
    }
    Contact {
      ContactID
      FullName
    }
    PublishedBy
    ValidFrom
    ValidTo
    Acknowledgement
    AcknowledgementStatement
    PostStatus
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ContentUrl
  }
}
    `;

/**
 * __useNewsListingQuery__
 *
 * To run a query within a React component, call `useNewsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      IsPublishedOnly: // value for 'IsPublishedOnly'
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useNewsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsListingQuery, NewsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsListingQuery, NewsListingQueryVariables>(NewsListingDocument, baseOptions);
      }
export function useNewsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsListingQuery, NewsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsListingQuery, NewsListingQueryVariables>(NewsListingDocument, baseOptions);
        }
export type NewsListingQueryHookResult = ReturnType<typeof useNewsListingQuery>;
export type NewsListingLazyQueryHookResult = ReturnType<typeof useNewsListingLazyQuery>;
export type NewsListingQueryResult = ApolloReactCommon.QueryResult<NewsListingQuery, NewsListingQueryVariables>;
export const NewsCountDocument = gql`
    query NewsCount($SubscriptionAccountID: String!, $IsPublishedOnly: Boolean!, $StartMonth: String!, $EndMonth: String!, $isValid: Boolean) {
  NewsCount(SubscriptionAccountID: $SubscriptionAccountID, IsPublishedOnly: $IsPublishedOnly, StartMonth: $StartMonth, EndMonth: $EndMonth, isValid: $isValid)
}
    `;

/**
 * __useNewsCountQuery__
 *
 * To run a query within a React component, call `useNewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsCountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      IsPublishedOnly: // value for 'IsPublishedOnly'
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useNewsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsCountQuery, NewsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsCountQuery, NewsCountQueryVariables>(NewsCountDocument, baseOptions);
      }
export function useNewsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsCountQuery, NewsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsCountQuery, NewsCountQueryVariables>(NewsCountDocument, baseOptions);
        }
export type NewsCountQueryHookResult = ReturnType<typeof useNewsCountQuery>;
export type NewsCountLazyQueryHookResult = ReturnType<typeof useNewsCountLazyQuery>;
export type NewsCountQueryResult = ApolloReactCommon.QueryResult<NewsCountQuery, NewsCountQueryVariables>;
export const CompanyDeclaredHolidayListingDocument = gql`
    query CompanyDeclaredHolidayListing($CompanyID: String!, $StateID: String, $Year: Float, $ContactID: String) {
  CompanyDeclaredHolidayListing(CompanyID: $CompanyID, StateID: $StateID, Year: $Year, ContactID: $ContactID) {
    CompanyDelaredHolidayID
    CompanyID
    StateID
    HolidayDate
    Description
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCompanyDeclaredHolidayListingQuery__
 *
 * To run a query within a React component, call `useCompanyDeclaredHolidayListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeclaredHolidayListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDeclaredHolidayListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *      Year: // value for 'Year'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useCompanyDeclaredHolidayListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>(CompanyDeclaredHolidayListingDocument, baseOptions);
      }
export function useCompanyDeclaredHolidayListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>(CompanyDeclaredHolidayListingDocument, baseOptions);
        }
export type CompanyDeclaredHolidayListingQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingQuery>;
export type CompanyDeclaredHolidayListingLazyQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingLazyQuery>;
export type CompanyDeclaredHolidayListingQueryResult = ApolloReactCommon.QueryResult<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>;
export const CompanySummaryDocument = gql`
    query CompanySummary($CompanyID: String!) {
  CompanySummary(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
    BizRegNo
    Sector
    ExcludeTaxRelief
    ProjectExpenseTrxDayLimit
    ProjectExpenseCutOff
    Departments {
      DepartmentID
    }
    Employees {
      EmployeeID
    }
    Contact {
      ContactID
      Email
      WebUrl
      OfficeNo
      MobileNo
      FaxNo
      Address {
        Line1
        Line2
        PostalCode
        City
        State
        Country
      }
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    CalendarStateID
    PayrollCutOffDay
    LeaveCutOffDay
    ClaimCutOffDay
    EnableExpenseAccount
    ClaimTrxDayLimit
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    LeaveRounding {
      LeaveRoundingID
      Title
    }
    CustomPayslipFormat
    CustomPayrollGlExportFormat
    CustomClaimGlExportFormat
    CustomProjectExpenseGlExportFormat
  }
}
    `;

/**
 * __useCompanySummaryQuery__
 *
 * To run a query within a React component, call `useCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanySummaryQuery, CompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanySummaryQuery, CompanySummaryQueryVariables>(CompanySummaryDocument, baseOptions);
      }
export function useCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySummaryQuery, CompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanySummaryQuery, CompanySummaryQueryVariables>(CompanySummaryDocument, baseOptions);
        }
export type CompanySummaryQueryHookResult = ReturnType<typeof useCompanySummaryQuery>;
export type CompanySummaryLazyQueryHookResult = ReturnType<typeof useCompanySummaryLazyQuery>;
export type CompanySummaryQueryResult = ApolloReactCommon.QueryResult<CompanySummaryQuery, CompanySummaryQueryVariables>;
export const EmployeeCalendarDocument = gql`
    query EmployeeCalendar($CompanyID: String!, $CalendarDate: DateTime!, $EmployeeID: String!, $ContactID: String) {
  EmployeeCalendar(CompanyID: $CompanyID, CalendarDate: $CalendarDate, EmployeeID: $EmployeeID, ContactID: $ContactID) {
    companyOffDays {
      DayOffDate
      Description
    }
    employeeLeaves {
      EmployeeID
      EmployeeNo
      FullName
      LeaveType
      LeaveHeaderID
      Remarks
      LeaveDate
      FromDate
      UntilDate
      ApproverID
      SessionType
      IsOffDay
      IsRestDay
      IsPublicHoliday
      LeaveApprovalStatus
    }
    employeeStaffLeaves {
      EmployeeID
      EmployeeNo
      LeaveHeaderID
      FullName
      LeaveType
      Remarks
      LeaveDate
      FromDate
      UntilDate
      SessionType
      IsOffDay
      IsRestDay
      IsPublicHoliday
      LeaveApprovalStatus
      OwnSubordinates
    }
    employeeBirthdays {
      EmployeeID
      EmployeeNo
      FullName
      BirthDate
      CompanyID
    }
  }
}
    `;

/**
 * __useEmployeeCalendarQuery__
 *
 * To run a query within a React component, call `useEmployeeCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CalendarDate: // value for 'CalendarDate'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useEmployeeCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>(EmployeeCalendarDocument, baseOptions);
      }
export function useEmployeeCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>(EmployeeCalendarDocument, baseOptions);
        }
export type EmployeeCalendarQueryHookResult = ReturnType<typeof useEmployeeCalendarQuery>;
export type EmployeeCalendarLazyQueryHookResult = ReturnType<typeof useEmployeeCalendarLazyQuery>;
export type EmployeeCalendarQueryResult = ApolloReactCommon.QueryResult<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>;
export const GetLeaveOffDayDocument = gql`
    query GetLeaveOffDay($CompanyID: String!) {
  GetLeaveOffDay(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLeaveOffDayQuery__
 *
 * To run a query within a React component, call `useGetLeaveOffDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveOffDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveOffDayQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLeaveOffDayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>(GetLeaveOffDayDocument, baseOptions);
      }
export function useGetLeaveOffDayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>(GetLeaveOffDayDocument, baseOptions);
        }
export type GetLeaveOffDayQueryHookResult = ReturnType<typeof useGetLeaveOffDayQuery>;
export type GetLeaveOffDayLazyQueryHookResult = ReturnType<typeof useGetLeaveOffDayLazyQuery>;
export type GetLeaveOffDayQueryResult = ApolloReactCommon.QueryResult<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>;
export const EmpAttendanceHistoryDocument = gql`
    query EmpAttendanceHistory($CompanyID: String!, $EmployeeID: String!, $FromDate: String!, $ToDate: String!) {
  EmpAttendanceHistory(CompanyID: $CompanyID, EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;

/**
 * __useEmpAttendanceHistoryQuery__
 *
 * To run a query within a React component, call `useEmpAttendanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmpAttendanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmpAttendanceHistoryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useEmpAttendanceHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>(EmpAttendanceHistoryDocument, baseOptions);
      }
export function useEmpAttendanceHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>(EmpAttendanceHistoryDocument, baseOptions);
        }
export type EmpAttendanceHistoryQueryHookResult = ReturnType<typeof useEmpAttendanceHistoryQuery>;
export type EmpAttendanceHistoryLazyQueryHookResult = ReturnType<typeof useEmpAttendanceHistoryLazyQuery>;
export type EmpAttendanceHistoryQueryResult = ApolloReactCommon.QueryResult<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>;
export const SubordinateListingDocument = gql`
    query SubordinateListing($EmployeeID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  SubordinateListing(EmployeeID: $EmployeeID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
    }
    EmployeeID
    CompanyID
    EmployeeNo
    Contact {
      ContactID
      FullName
      MobileNo
      Email
    }
    Department {
      DepartmentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    Company {
      CompanyName
      CompanyID
    }
    EmploymentType
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Documents {
      BucketFileName
      Description
      DocumentFile
      DocumentID
    }
  }
}
    `;

/**
 * __useSubordinateListingQuery__
 *
 * To run a query within a React component, call `useSubordinateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useSubordinateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateListingQuery, SubordinateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateListingQuery, SubordinateListingQueryVariables>(SubordinateListingDocument, baseOptions);
      }
export function useSubordinateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateListingQuery, SubordinateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateListingQuery, SubordinateListingQueryVariables>(SubordinateListingDocument, baseOptions);
        }
export type SubordinateListingQueryHookResult = ReturnType<typeof useSubordinateListingQuery>;
export type SubordinateListingLazyQueryHookResult = ReturnType<typeof useSubordinateListingLazyQuery>;
export type SubordinateListingQueryResult = ApolloReactCommon.QueryResult<SubordinateListingQuery, SubordinateListingQueryVariables>;
export const EmployeeCompanyListingDocument = gql`
    query EmployeeCompanyListing($EmployeeEmail: String!) {
  EmployeeCompanyListing(EmployeeEmail: $EmployeeEmail)
}
    `;

/**
 * __useEmployeeCompanyListingQuery__
 *
 * To run a query within a React component, call `useEmployeeCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCompanyListingQuery({
 *   variables: {
 *      EmployeeEmail: // value for 'EmployeeEmail'
 *   },
 * });
 */
export function useEmployeeCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>(EmployeeCompanyListingDocument, baseOptions);
      }
export function useEmployeeCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>(EmployeeCompanyListingDocument, baseOptions);
        }
export type EmployeeCompanyListingQueryHookResult = ReturnType<typeof useEmployeeCompanyListingQuery>;
export type EmployeeCompanyListingLazyQueryHookResult = ReturnType<typeof useEmployeeCompanyListingLazyQuery>;
export type EmployeeCompanyListingQueryResult = ApolloReactCommon.QueryResult<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>;
export const EmployeeNotificationListingDocument = gql`
    query EmployeeNotificationListing($EmployeeID: String!) {
  EmployeeNotificationListing(EmployeeID: $EmployeeID) {
    Message
    CreatedDT
    RequestType
    Topic
    Status
    NotificationType
    ReadStatus
    NotificationID
    Message
    LeaveDetails {
      ApprovalStatus
      TotalDay
      LeaveType
      ApproverName
      ApprovalRemark
      Remarks
      FromDate
      UntilDate
    }
    ClaimDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      ClaimSubject
      CreatedDT
      TotalClaim
      TotalClaimAmount
      TotalVerifiedAmount
      ClaimDetailsListing {
        ClaimAmountByFinance
        ClaimAmount
        ClaimRemarks
        ClaimDetailsID
        ReceiptDate
        ReceiptNo
        Documents {
          DocumentFile
          DocumentID
        }
        ClaimType {
          Name
        }
        FinanceApproveStatus
      }
    }
    RequisitionDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      RequisitionType
      DocumentFile
      Subject
      NumberOfItem
      CostPerItem
      Amount
      Remark
      StartDate
      EndDate
      TotalDays
      TravelFrom
      TravelTo
      TransportMode
      CreatedDT
    }
    DataChangesDetails {
      DataChangesID
      ApproverName
      ApprovalStatus
      ApprovalRemark
      CreatedDT
      Action
      Type
      ChangesData {
        DocumentID
        Nationality
        PassportNo
        NRIC
        Marital
        Race
        Religion
        IsOKU
        PermanentAddress {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        Address {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        EmployeeDependentsID
        ContactID
        IsEmergencyContact
        MobileNo
        Occupation
        Education
        ChildEligibleRate
        PCBNo
        FullName
        BirthDate
      }
      OldData {
        DocumentID
        Nationality
        PassportNo
        NRIC
        Marital
        Race
        Religion
        IsOKU
        PermanentAddress {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        Address {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        EmployeeDependentsID
        ContactID
        IsEmergencyContact
        MobileNo
        Occupation
        Education
        ChildEligibleRate
        PCBNo
        FullName
        BirthDate
      }
    }
    ProjectExpenseClaimDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      ClaimSubject
      CreatedDT
      TotalClaim
      TotalClaimAmount
      TotalVerifiedAmount
      DocumentIDs {
        DocumentID
        DocumentFile
      }
      ClaimDetailsListing {
        ClaimAmountByFinance
        ClaimAmount
        ClaimRemark
        ID
        ReceiptDate
        ReceiptNo
        ProjectExpenseType {
          ProjectExpenseType
        }
        FinanceApproveStatus
      }
    }
    PerformanceKpiDetail {
      ReviewGroup {
        Title
      }
      KpiExercise {
        ID
        KpiExerciseID
        EmployeeID
        FromDate
        ToDate
      }
    }
    PerformanceReviewDetail {
      ID
      ReviewGroupName
      ReviewExerciseName
      FromDate
      ToDate
    }
  }
}
    `;

/**
 * __useEmployeeNotificationListingQuery__
 *
 * To run a query within a React component, call `useEmployeeNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNotificationListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>(EmployeeNotificationListingDocument, baseOptions);
      }
export function useEmployeeNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>(EmployeeNotificationListingDocument, baseOptions);
        }
export type EmployeeNotificationListingQueryHookResult = ReturnType<typeof useEmployeeNotificationListingQuery>;
export type EmployeeNotificationListingLazyQueryHookResult = ReturnType<typeof useEmployeeNotificationListingLazyQuery>;
export type EmployeeNotificationListingQueryResult = ApolloReactCommon.QueryResult<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>;
export const UpdateNotificationReadStatusDocument = gql`
    mutation UpdateNotificationReadStatus($NotificationID: String!) {
  UpdateNotificationReadStatus(NotificationID: $NotificationID)
}
    `;
export type UpdateNotificationReadStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>;

/**
 * __useUpdateNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationReadStatusMutation, { data, loading, error }] = useUpdateNotificationReadStatusMutation({
 *   variables: {
 *      NotificationID: // value for 'NotificationID'
 *   },
 * });
 */
export function useUpdateNotificationReadStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>(UpdateNotificationReadStatusDocument, baseOptions);
      }
export type UpdateNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>;
export const NotificationReadStatusDeleteDocument = gql`
    mutation NotificationReadStatusDelete($ID: [String!]!) {
  NotificationReadStatusDelete(ID: $ID)
}
    `;
export type NotificationReadStatusDeleteMutationFn = ApolloReactCommon.MutationFunction<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>;

/**
 * __useNotificationReadStatusDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationReadStatusDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadStatusDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadStatusDeleteMutation, { data, loading, error }] = useNotificationReadStatusDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useNotificationReadStatusDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>(NotificationReadStatusDeleteDocument, baseOptions);
      }
export type NotificationReadStatusDeleteMutationHookResult = ReturnType<typeof useNotificationReadStatusDeleteMutation>;
export type NotificationReadStatusDeleteMutationResult = ApolloReactCommon.MutationResult<NotificationReadStatusDeleteMutation>;
export type NotificationReadStatusDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>;
export const UpdateBulkNotificationReadStatusDocument = gql`
    mutation UpdateBulkNotificationReadStatus($IDs: [String!]!) {
  UpdateBulkNotificationReadStatus(IDs: $IDs)
}
    `;
export type UpdateBulkNotificationReadStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>;

/**
 * __useUpdateBulkNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBulkNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkNotificationReadStatusMutation, { data, loading, error }] = useUpdateBulkNotificationReadStatusMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useUpdateBulkNotificationReadStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>(UpdateBulkNotificationReadStatusDocument, baseOptions);
      }
export type UpdateBulkNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateBulkNotificationReadStatusMutation>;
export type UpdateBulkNotificationReadStatusMutationResult = ApolloReactCommon.MutationResult<UpdateBulkNotificationReadStatusMutation>;
export type UpdateBulkNotificationReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>;
export const ToDoSummaryDocument = gql`
    query ToDoSummary($EmployeeID: String!) {
  ToDoSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useToDoSummaryQuery__
 *
 * To run a query within a React component, call `useToDoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useToDoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToDoSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useToDoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ToDoSummaryQuery, ToDoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ToDoSummaryQuery, ToDoSummaryQueryVariables>(ToDoSummaryDocument, baseOptions);
      }
export function useToDoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ToDoSummaryQuery, ToDoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ToDoSummaryQuery, ToDoSummaryQueryVariables>(ToDoSummaryDocument, baseOptions);
        }
export type ToDoSummaryQueryHookResult = ReturnType<typeof useToDoSummaryQuery>;
export type ToDoSummaryLazyQueryHookResult = ReturnType<typeof useToDoSummaryLazyQuery>;
export type ToDoSummaryQueryResult = ApolloReactCommon.QueryResult<ToDoSummaryQuery, ToDoSummaryQueryVariables>;
export const GetDocumentDocument = gql`
    query getDocument($DocumentID: String!) {
  getDocument(DocumentID: $DocumentID) {
    DocumentID
    DocumentFile
    Description
  }
}
    `;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
      }
export function useGetDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = ApolloReactCommon.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export const EmployeeDependentsSummaryDocument = gql`
    query EmployeeDependentsSummary($EmployeeDependentsID: String!) {
  EmployeeDependentsSummary(EmployeeDependentsID: $EmployeeDependentsID) {
    EmployeeDependentsID
    Relationship
    Education
    Contact {
      ContactID
      FullName
      MobileNo
      NRIC
      PassportNo
      Nationality
      BirthDate
    }
    IsOKU
    ChildEligibleRate
    Occupation
    IsEmergencyContact
    PCBNo
    DocumentID
  }
}
    `;

/**
 * __useEmployeeDependentsSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeDependentsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDependentsSummaryQuery({
 *   variables: {
 *      EmployeeDependentsID: // value for 'EmployeeDependentsID'
 *   },
 * });
 */
export function useEmployeeDependentsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>(EmployeeDependentsSummaryDocument, baseOptions);
      }
export function useEmployeeDependentsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>(EmployeeDependentsSummaryDocument, baseOptions);
        }
export type EmployeeDependentsSummaryQueryHookResult = ReturnType<typeof useEmployeeDependentsSummaryQuery>;
export type EmployeeDependentsSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeDependentsSummaryLazyQuery>;
export type EmployeeDependentsSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>;
export const ReportingGroupListingDocument = gql`
    query ReportingGroupListing {
  ReportingGroupListing {
    reporting_group_id
    name
    created_dt
    modified_dt
    GroupInUse
    CompanyInUse
  }
}
    `;

/**
 * __useReportingGroupListingQuery__
 *
 * To run a query within a React component, call `useReportingGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportingGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>(ReportingGroupListingDocument, baseOptions);
      }
export function useReportingGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>(ReportingGroupListingDocument, baseOptions);
        }
export type ReportingGroupListingQueryHookResult = ReturnType<typeof useReportingGroupListingQuery>;
export type ReportingGroupListingLazyQueryHookResult = ReturnType<typeof useReportingGroupListingLazyQuery>;
export type ReportingGroupListingQueryResult = ApolloReactCommon.QueryResult<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>;
export const PerformanceMenuSummaryDocument = gql`
    query PerformanceMenuSummary {
  PerformanceMenuSummary
}
    `;

/**
 * __usePerformanceMenuSummaryQuery__
 *
 * To run a query within a React component, call `usePerformanceMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceMenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformanceMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>(PerformanceMenuSummaryDocument, baseOptions);
      }
export function usePerformanceMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>(PerformanceMenuSummaryDocument, baseOptions);
        }
export type PerformanceMenuSummaryQueryHookResult = ReturnType<typeof usePerformanceMenuSummaryQuery>;
export type PerformanceMenuSummaryLazyQueryHookResult = ReturnType<typeof usePerformanceMenuSummaryLazyQuery>;
export type PerformanceMenuSummaryQueryResult = ApolloReactCommon.QueryResult<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>;
export const FormItemRatingListingDocument = gql`
    query FormItemRatingListing {
  FormItemRatingListing {
    ID
    Title
    InUse
    RatingScaleTable
    InputMethod
    AllowFraction
    AllowIncrement
    IncrementNumber
  }
}
    `;

/**
 * __useFormItemRatingListingQuery__
 *
 * To run a query within a React component, call `useFormItemRatingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormItemRatingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormItemRatingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>(FormItemRatingListingDocument, baseOptions);
      }
export function useFormItemRatingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>(FormItemRatingListingDocument, baseOptions);
        }
export type FormItemRatingListingQueryHookResult = ReturnType<typeof useFormItemRatingListingQuery>;
export type FormItemRatingListingLazyQueryHookResult = ReturnType<typeof useFormItemRatingListingLazyQuery>;
export type FormItemRatingListingQueryResult = ApolloReactCommon.QueryResult<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>;
export const FormItemRatingDetailsDocument = gql`
    query FormItemRatingDetails($FormItemRatingID: String!) {
  FormItemRatingDetails(FormItemRatingID: $FormItemRatingID) {
    ID
    Title
    To
    From
    OrderType
    RatingScaleTable
    InputMethod
    AllowFraction
    AllowIncrement
    IncrementNumber
  }
}
    `;

/**
 * __useFormItemRatingDetailsQuery__
 *
 * To run a query within a React component, call `useFormItemRatingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormItemRatingDetailsQuery({
 *   variables: {
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useFormItemRatingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>(FormItemRatingDetailsDocument, baseOptions);
      }
export function useFormItemRatingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>(FormItemRatingDetailsDocument, baseOptions);
        }
export type FormItemRatingDetailsQueryHookResult = ReturnType<typeof useFormItemRatingDetailsQuery>;
export type FormItemRatingDetailsLazyQueryHookResult = ReturnType<typeof useFormItemRatingDetailsLazyQuery>;
export type FormItemRatingDetailsQueryResult = ApolloReactCommon.QueryResult<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>;
export const IsFormItemRatingTitleExistDocument = gql`
    query IsFormItemRatingTitleExist($Title: String!, $FormItemRatingID: String) {
  IsFormItemRatingTitleExist(Title: $Title, FormItemRatingID: $FormItemRatingID)
}
    `;

/**
 * __useIsFormItemRatingTitleExistQuery__
 *
 * To run a query within a React component, call `useIsFormItemRatingTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFormItemRatingTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFormItemRatingTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useIsFormItemRatingTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>(IsFormItemRatingTitleExistDocument, baseOptions);
      }
export function useIsFormItemRatingTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>(IsFormItemRatingTitleExistDocument, baseOptions);
        }
export type IsFormItemRatingTitleExistQueryHookResult = ReturnType<typeof useIsFormItemRatingTitleExistQuery>;
export type IsFormItemRatingTitleExistLazyQueryHookResult = ReturnType<typeof useIsFormItemRatingTitleExistLazyQuery>;
export type IsFormItemRatingTitleExistQueryResult = ApolloReactCommon.QueryResult<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>;
export const FormItemRatingSubmitDocument = gql`
    mutation FormItemRatingSubmit($input: FormItemRatingInput!) {
  FormItemRatingSubmit(input: $input)
}
    `;
export type FormItemRatingSubmitMutationFn = ApolloReactCommon.MutationFunction<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>;

/**
 * __useFormItemRatingSubmitMutation__
 *
 * To run a mutation, you first call `useFormItemRatingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formItemRatingSubmitMutation, { data, loading, error }] = useFormItemRatingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormItemRatingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>(FormItemRatingSubmitDocument, baseOptions);
      }
export type FormItemRatingSubmitMutationHookResult = ReturnType<typeof useFormItemRatingSubmitMutation>;
export type FormItemRatingSubmitMutationResult = ApolloReactCommon.MutationResult<FormItemRatingSubmitMutation>;
export type FormItemRatingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>;
export const FormItemRatingDeleteDocument = gql`
    mutation FormItemRatingDelete($FormItemRatingID: String!) {
  FormItemRatingDelete(FormItemRatingID: $FormItemRatingID)
}
    `;
export type FormItemRatingDeleteMutationFn = ApolloReactCommon.MutationFunction<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>;

/**
 * __useFormItemRatingDeleteMutation__
 *
 * To run a mutation, you first call `useFormItemRatingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formItemRatingDeleteMutation, { data, loading, error }] = useFormItemRatingDeleteMutation({
 *   variables: {
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useFormItemRatingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>(FormItemRatingDeleteDocument, baseOptions);
      }
export type FormItemRatingDeleteMutationHookResult = ReturnType<typeof useFormItemRatingDeleteMutation>;
export type FormItemRatingDeleteMutationResult = ApolloReactCommon.MutationResult<FormItemRatingDeleteMutation>;
export type FormItemRatingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>;
export const ReviewGradingTableListingDocument = gql`
    query ReviewGradingTableListing {
  ReviewGradingTableListing {
    ID
    Title
    InUse
    GradingTable
  }
}
    `;

/**
 * __useReviewGradingTableListingQuery__
 *
 * To run a query within a React component, call `useReviewGradingTableListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGradingTableListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewGradingTableListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>(ReviewGradingTableListingDocument, baseOptions);
      }
export function useReviewGradingTableListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>(ReviewGradingTableListingDocument, baseOptions);
        }
export type ReviewGradingTableListingQueryHookResult = ReturnType<typeof useReviewGradingTableListingQuery>;
export type ReviewGradingTableListingLazyQueryHookResult = ReturnType<typeof useReviewGradingTableListingLazyQuery>;
export type ReviewGradingTableListingQueryResult = ApolloReactCommon.QueryResult<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>;
export const ReviewGradingTableDetailsDocument = gql`
    query ReviewGradingTableDetails($ReviewGradingTableID: String!) {
  ReviewGradingTableDetails(ReviewGradingTableID: $ReviewGradingTableID) {
    ID
    Title
    GradingTable
  }
}
    `;

/**
 * __useReviewGradingTableDetailsQuery__
 *
 * To run a query within a React component, call `useReviewGradingTableDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGradingTableDetailsQuery({
 *   variables: {
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useReviewGradingTableDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>(ReviewGradingTableDetailsDocument, baseOptions);
      }
export function useReviewGradingTableDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>(ReviewGradingTableDetailsDocument, baseOptions);
        }
export type ReviewGradingTableDetailsQueryHookResult = ReturnType<typeof useReviewGradingTableDetailsQuery>;
export type ReviewGradingTableDetailsLazyQueryHookResult = ReturnType<typeof useReviewGradingTableDetailsLazyQuery>;
export type ReviewGradingTableDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>;
export const IsReviewGradingTitleExistDocument = gql`
    query IsReviewGradingTitleExist($Title: String!, $ReviewGradingTableID: String) {
  IsReviewGradingTitleExist(Title: $Title, ReviewGradingTableID: $ReviewGradingTableID)
}
    `;

/**
 * __useIsReviewGradingTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewGradingTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewGradingTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewGradingTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useIsReviewGradingTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>(IsReviewGradingTitleExistDocument, baseOptions);
      }
export function useIsReviewGradingTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>(IsReviewGradingTitleExistDocument, baseOptions);
        }
export type IsReviewGradingTitleExistQueryHookResult = ReturnType<typeof useIsReviewGradingTitleExistQuery>;
export type IsReviewGradingTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewGradingTitleExistLazyQuery>;
export type IsReviewGradingTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>;
export const ReviewGradingTableSubmitDocument = gql`
    mutation ReviewGradingTableSubmit($input: ReviewGradingTableInput!) {
  ReviewGradingTableSubmit(input: $input)
}
    `;
export type ReviewGradingTableSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>;

/**
 * __useReviewGradingTableSubmitMutation__
 *
 * To run a mutation, you first call `useReviewGradingTableSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGradingTableSubmitMutation, { data, loading, error }] = useReviewGradingTableSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewGradingTableSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>(ReviewGradingTableSubmitDocument, baseOptions);
      }
export type ReviewGradingTableSubmitMutationHookResult = ReturnType<typeof useReviewGradingTableSubmitMutation>;
export type ReviewGradingTableSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewGradingTableSubmitMutation>;
export type ReviewGradingTableSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>;
export const ReviewGradingTableDeleteDocument = gql`
    mutation ReviewGradingTableDelete($ReviewGradingTableID: String!) {
  ReviewGradingTableDelete(ReviewGradingTableID: $ReviewGradingTableID)
}
    `;
export type ReviewGradingTableDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>;

/**
 * __useReviewGradingTableDeleteMutation__
 *
 * To run a mutation, you first call `useReviewGradingTableDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGradingTableDeleteMutation, { data, loading, error }] = useReviewGradingTableDeleteMutation({
 *   variables: {
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useReviewGradingTableDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>(ReviewGradingTableDeleteDocument, baseOptions);
      }
export type ReviewGradingTableDeleteMutationHookResult = ReturnType<typeof useReviewGradingTableDeleteMutation>;
export type ReviewGradingTableDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewGradingTableDeleteMutation>;
export type ReviewGradingTableDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>;
export const ReviewPolicyListingDocument = gql`
    query ReviewPolicyListing {
  ReviewPolicyListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewPolicyListingQuery__
 *
 * To run a query within a React component, call `useReviewPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPolicyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>(ReviewPolicyListingDocument, baseOptions);
      }
export function useReviewPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>(ReviewPolicyListingDocument, baseOptions);
        }
export type ReviewPolicyListingQueryHookResult = ReturnType<typeof useReviewPolicyListingQuery>;
export type ReviewPolicyListingLazyQueryHookResult = ReturnType<typeof useReviewPolicyListingLazyQuery>;
export type ReviewPolicyListingQueryResult = ApolloReactCommon.QueryResult<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>;
export const ReviewPolicyDetailsDocument = gql`
    query ReviewPolicyDetails($ReviewPolicyID: String!) {
  ReviewPolicyDetails(ReviewPolicyID: $ReviewPolicyID) {
    ID
    Title
    ReviewPolicyDetails
    ReviewGrading {
      ID
      Title
    }
  }
}
    `;

/**
 * __useReviewPolicyDetailsQuery__
 *
 * To run a query within a React component, call `useReviewPolicyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPolicyDetailsQuery({
 *   variables: {
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useReviewPolicyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>(ReviewPolicyDetailsDocument, baseOptions);
      }
export function useReviewPolicyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>(ReviewPolicyDetailsDocument, baseOptions);
        }
export type ReviewPolicyDetailsQueryHookResult = ReturnType<typeof useReviewPolicyDetailsQuery>;
export type ReviewPolicyDetailsLazyQueryHookResult = ReturnType<typeof useReviewPolicyDetailsLazyQuery>;
export type ReviewPolicyDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>;
export const IsReviewPolicyTitleExistDocument = gql`
    query IsReviewPolicyTitleExist($Title: String!, $ReviewPolicyID: String) {
  IsReviewPolicyTitleExist(Title: $Title, ReviewPolicyID: $ReviewPolicyID)
}
    `;

/**
 * __useIsReviewPolicyTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewPolicyTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewPolicyTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewPolicyTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useIsReviewPolicyTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>(IsReviewPolicyTitleExistDocument, baseOptions);
      }
export function useIsReviewPolicyTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>(IsReviewPolicyTitleExistDocument, baseOptions);
        }
export type IsReviewPolicyTitleExistQueryHookResult = ReturnType<typeof useIsReviewPolicyTitleExistQuery>;
export type IsReviewPolicyTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewPolicyTitleExistLazyQuery>;
export type IsReviewPolicyTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>;
export const ReviewPolicySubmitDocument = gql`
    mutation ReviewPolicySubmit($input: ReviewPolicyInput!) {
  ReviewPolicySubmit(input: $input)
}
    `;
export type ReviewPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>;

/**
 * __useReviewPolicySubmitMutation__
 *
 * To run a mutation, you first call `useReviewPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPolicySubmitMutation, { data, loading, error }] = useReviewPolicySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>(ReviewPolicySubmitDocument, baseOptions);
      }
export type ReviewPolicySubmitMutationHookResult = ReturnType<typeof useReviewPolicySubmitMutation>;
export type ReviewPolicySubmitMutationResult = ApolloReactCommon.MutationResult<ReviewPolicySubmitMutation>;
export type ReviewPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>;
export const ReviewPolicyDeleteDocument = gql`
    mutation ReviewPolicyDelete($ReviewPolicyID: String!) {
  ReviewPolicyDelete(ReviewPolicyID: $ReviewPolicyID)
}
    `;
export type ReviewPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>;

/**
 * __useReviewPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useReviewPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPolicyDeleteMutation, { data, loading, error }] = useReviewPolicyDeleteMutation({
 *   variables: {
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useReviewPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>(ReviewPolicyDeleteDocument, baseOptions);
      }
export type ReviewPolicyDeleteMutationHookResult = ReturnType<typeof useReviewPolicyDeleteMutation>;
export type ReviewPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewPolicyDeleteMutation>;
export type ReviewPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>;
export const KpiCategoryListingDocument = gql`
    query KpiCategoryListing {
  KpiCategoryListing {
    ID
    Title
    InUse
    ModifiedDT
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryListingQuery__
 *
 * To run a query within a React component, call `useKpiCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>(KpiCategoryListingDocument, baseOptions);
      }
export function useKpiCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>(KpiCategoryListingDocument, baseOptions);
        }
export type KpiCategoryListingQueryHookResult = ReturnType<typeof useKpiCategoryListingQuery>;
export type KpiCategoryListingLazyQueryHookResult = ReturnType<typeof useKpiCategoryListingLazyQuery>;
export type KpiCategoryListingQueryResult = ApolloReactCommon.QueryResult<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>;
export const KpiCategoryDetailsDocument = gql`
    query KpiCategoryDetails($KpiCategoryID: String!) {
  KpiCategoryDetails(KpiCategoryID: $KpiCategoryID) {
    ID
    Title
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryDetailsQuery__
 *
 * To run a query within a React component, call `useKpiCategoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryDetailsQuery({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiCategoryDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>(KpiCategoryDetailsDocument, baseOptions);
      }
export function useKpiCategoryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>(KpiCategoryDetailsDocument, baseOptions);
        }
export type KpiCategoryDetailsQueryHookResult = ReturnType<typeof useKpiCategoryDetailsQuery>;
export type KpiCategoryDetailsLazyQueryHookResult = ReturnType<typeof useKpiCategoryDetailsLazyQuery>;
export type KpiCategoryDetailsQueryResult = ApolloReactCommon.QueryResult<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>;
export const IsKpiCategoryTitleExistDocument = gql`
    query IsKpiCategoryTitleExist($Title: String!, $KpiCategoryID: String) {
  IsKpiCategoryTitleExist(Title: $Title, KpiCategoryID: $KpiCategoryID)
}
    `;

/**
 * __useIsKpiCategoryTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiCategoryTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiCategoryTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiCategoryTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useIsKpiCategoryTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>(IsKpiCategoryTitleExistDocument, baseOptions);
      }
export function useIsKpiCategoryTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>(IsKpiCategoryTitleExistDocument, baseOptions);
        }
export type IsKpiCategoryTitleExistQueryHookResult = ReturnType<typeof useIsKpiCategoryTitleExistQuery>;
export type IsKpiCategoryTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiCategoryTitleExistLazyQuery>;
export type IsKpiCategoryTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>;
export const KpiCategorySubmitDocument = gql`
    mutation KpiCategorySubmit($input: KpiCategoryInput!) {
  KpiCategorySubmit(input: $input)
}
    `;
export type KpiCategorySubmitMutationFn = ApolloReactCommon.MutationFunction<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>;

/**
 * __useKpiCategorySubmitMutation__
 *
 * To run a mutation, you first call `useKpiCategorySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiCategorySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiCategorySubmitMutation, { data, loading, error }] = useKpiCategorySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiCategorySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>(KpiCategorySubmitDocument, baseOptions);
      }
export type KpiCategorySubmitMutationHookResult = ReturnType<typeof useKpiCategorySubmitMutation>;
export type KpiCategorySubmitMutationResult = ApolloReactCommon.MutationResult<KpiCategorySubmitMutation>;
export type KpiCategorySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>;
export const KpiCategoryDeleteDocument = gql`
    mutation KpiCategoryDelete($KpiCategoryID: String!) {
  KpiCategoryDelete(KpiCategoryID: $KpiCategoryID)
}
    `;
export type KpiCategoryDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>;

/**
 * __useKpiCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useKpiCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiCategoryDeleteMutation, { data, loading, error }] = useKpiCategoryDeleteMutation({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiCategoryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>(KpiCategoryDeleteDocument, baseOptions);
      }
export type KpiCategoryDeleteMutationHookResult = ReturnType<typeof useKpiCategoryDeleteMutation>;
export type KpiCategoryDeleteMutationResult = ApolloReactCommon.MutationResult<KpiCategoryDeleteMutation>;
export type KpiCategoryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>;
export const KpiCategoryListingWithItemCountDocument = gql`
    query KpiCategoryListingWithItemCount {
  KpiCategoryListing {
    ID
    Title
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryListingWithItemCountQuery__
 *
 * To run a query within a React component, call `useKpiCategoryListingWithItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryListingWithItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryListingWithItemCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiCategoryListingWithItemCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>(KpiCategoryListingWithItemCountDocument, baseOptions);
      }
export function useKpiCategoryListingWithItemCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>(KpiCategoryListingWithItemCountDocument, baseOptions);
        }
export type KpiCategoryListingWithItemCountQueryHookResult = ReturnType<typeof useKpiCategoryListingWithItemCountQuery>;
export type KpiCategoryListingWithItemCountLazyQueryHookResult = ReturnType<typeof useKpiCategoryListingWithItemCountLazyQuery>;
export type KpiCategoryListingWithItemCountQueryResult = ApolloReactCommon.QueryResult<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>;
export const KpiPredefinedItemListingDocument = gql`
    query KpiPredefinedItemListing($KpiCategoryID: String!) {
  KpiPredefinedItemListing(KpiCategoryID: $KpiCategoryID) {
    ID
    Title
    Targets
    Initiatives
    InUse
    Status
  }
}
    `;

/**
 * __useKpiPredefinedItemListingQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedItemListingQuery({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiPredefinedItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>(KpiPredefinedItemListingDocument, baseOptions);
      }
export function useKpiPredefinedItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>(KpiPredefinedItemListingDocument, baseOptions);
        }
export type KpiPredefinedItemListingQueryHookResult = ReturnType<typeof useKpiPredefinedItemListingQuery>;
export type KpiPredefinedItemListingLazyQueryHookResult = ReturnType<typeof useKpiPredefinedItemListingLazyQuery>;
export type KpiPredefinedItemListingQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>;
export const KpiPredefinedItemDetailsDocument = gql`
    query KpiPredefinedItemDetails($KpiPredefinedItemID: String!) {
  KpiPredefinedItemDetails(KpiPredefinedItemID: $KpiPredefinedItemID) {
    ID
    Title
    FormItemRating {
      ID
      Title
    }
    Targets
    Initiatives
  }
}
    `;

/**
 * __useKpiPredefinedItemDetailsQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedItemDetailsQuery({
 *   variables: {
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useKpiPredefinedItemDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>(KpiPredefinedItemDetailsDocument, baseOptions);
      }
export function useKpiPredefinedItemDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>(KpiPredefinedItemDetailsDocument, baseOptions);
        }
export type KpiPredefinedItemDetailsQueryHookResult = ReturnType<typeof useKpiPredefinedItemDetailsQuery>;
export type KpiPredefinedItemDetailsLazyQueryHookResult = ReturnType<typeof useKpiPredefinedItemDetailsLazyQuery>;
export type KpiPredefinedItemDetailsQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>;
export const IsKpiPredefinedItemTitleExistDocument = gql`
    query IsKpiPredefinedItemTitleExist($Title: String!, $KpiCategoryID: String!, $KpiPredefinedItemID: String) {
  IsKpiPredefinedItemTitleExist(Title: $Title, KpiCategoryID: $KpiCategoryID, KpiPredefinedItemID: $KpiPredefinedItemID)
}
    `;

/**
 * __useIsKpiPredefinedItemTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiPredefinedItemTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiPredefinedItemTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiPredefinedItemTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useIsKpiPredefinedItemTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>(IsKpiPredefinedItemTitleExistDocument, baseOptions);
      }
export function useIsKpiPredefinedItemTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>(IsKpiPredefinedItemTitleExistDocument, baseOptions);
        }
export type IsKpiPredefinedItemTitleExistQueryHookResult = ReturnType<typeof useIsKpiPredefinedItemTitleExistQuery>;
export type IsKpiPredefinedItemTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiPredefinedItemTitleExistLazyQuery>;
export type IsKpiPredefinedItemTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>;
export const KpiPredefinedItemSubmitDocument = gql`
    mutation KpiPredefinedItemSubmit($input: KpiPredefinedItemInput!) {
  KpiPredefinedItemSubmit(input: $input)
}
    `;
export type KpiPredefinedItemSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>;

/**
 * __useKpiPredefinedItemSubmitMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemSubmitMutation, { data, loading, error }] = useKpiPredefinedItemSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedItemSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>(KpiPredefinedItemSubmitDocument, baseOptions);
      }
export type KpiPredefinedItemSubmitMutationHookResult = ReturnType<typeof useKpiPredefinedItemSubmitMutation>;
export type KpiPredefinedItemSubmitMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemSubmitMutation>;
export type KpiPredefinedItemSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>;
export const KpiPredefinedItemUpdateStatusDocument = gql`
    mutation KpiPredefinedItemUpdateStatus($input: KpiActiveInactiveInput!) {
  KpiPredefinedItemUpdateStatus(input: $input)
}
    `;
export type KpiPredefinedItemUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>;

/**
 * __useKpiPredefinedItemUpdateStatusMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemUpdateStatusMutation, { data, loading, error }] = useKpiPredefinedItemUpdateStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedItemUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>(KpiPredefinedItemUpdateStatusDocument, baseOptions);
      }
export type KpiPredefinedItemUpdateStatusMutationHookResult = ReturnType<typeof useKpiPredefinedItemUpdateStatusMutation>;
export type KpiPredefinedItemUpdateStatusMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemUpdateStatusMutation>;
export type KpiPredefinedItemUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>;
export const KpiPredefinedItemDeleteDocument = gql`
    mutation KpiPredefinedItemDelete($KpiPredefinedItemID: String!) {
  KpiPredefinedItemDelete(KpiPredefinedItemID: $KpiPredefinedItemID)
}
    `;
export type KpiPredefinedItemDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>;

/**
 * __useKpiPredefinedItemDeleteMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemDeleteMutation, { data, loading, error }] = useKpiPredefinedItemDeleteMutation({
 *   variables: {
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useKpiPredefinedItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>(KpiPredefinedItemDeleteDocument, baseOptions);
      }
export type KpiPredefinedItemDeleteMutationHookResult = ReturnType<typeof useKpiPredefinedItemDeleteMutation>;
export type KpiPredefinedItemDeleteMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemDeleteMutation>;
export type KpiPredefinedItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>;
export const KpiPredefinedTemplateListingDocument = gql`
    query KpiPredefinedTemplateListing {
  KpiPredefinedTemplateListing {
    ID
    Title
    InUse
    CategoryCount
    ItemCount
    WeightageType
    ModifiedDT
  }
}
    `;

/**
 * __useKpiPredefinedTemplateListingQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiPredefinedTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>(KpiPredefinedTemplateListingDocument, baseOptions);
      }
export function useKpiPredefinedTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>(KpiPredefinedTemplateListingDocument, baseOptions);
        }
export type KpiPredefinedTemplateListingQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateListingQuery>;
export type KpiPredefinedTemplateListingLazyQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateListingLazyQuery>;
export type KpiPredefinedTemplateListingQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>;
export const KpiPredefinedTemplateItemListingDocument = gql`
    query KpiPredefinedTemplateItemListing {
  KpiPredefinedTemplateItemListing {
    ID
    Title
    Targets
    Initiatives
    InUse
    Status
    KpiCategoryID
  }
}
    `;

/**
 * __useKpiPredefinedTemplateItemListingQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedTemplateItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedTemplateItemListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiPredefinedTemplateItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedTemplateItemListingQuery, KpiPredefinedTemplateItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedTemplateItemListingQuery, KpiPredefinedTemplateItemListingQueryVariables>(KpiPredefinedTemplateItemListingDocument, baseOptions);
      }
export function useKpiPredefinedTemplateItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedTemplateItemListingQuery, KpiPredefinedTemplateItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedTemplateItemListingQuery, KpiPredefinedTemplateItemListingQueryVariables>(KpiPredefinedTemplateItemListingDocument, baseOptions);
        }
export type KpiPredefinedTemplateItemListingQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateItemListingQuery>;
export type KpiPredefinedTemplateItemListingLazyQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateItemListingLazyQuery>;
export type KpiPredefinedTemplateItemListingQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedTemplateItemListingQuery, KpiPredefinedTemplateItemListingQueryVariables>;
export const KpiPredefinedTemplateDetailsDocument = gql`
    query KpiPredefinedTemplateDetails($KpiPredefinedTemplateID: String!) {
  KpiPredefinedTemplateDetails(KpiPredefinedTemplateID: $KpiPredefinedTemplateID) {
    ID
    Title
    Description
    WeightageType
    KpiWeightage
  }
}
    `;

/**
 * __useKpiPredefinedTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedTemplateDetailsQuery({
 *   variables: {
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useKpiPredefinedTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>(KpiPredefinedTemplateDetailsDocument, baseOptions);
      }
export function useKpiPredefinedTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>(KpiPredefinedTemplateDetailsDocument, baseOptions);
        }
export type KpiPredefinedTemplateDetailsQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateDetailsQuery>;
export type KpiPredefinedTemplateDetailsLazyQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateDetailsLazyQuery>;
export type KpiPredefinedTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>;
export const IsKpiPredefinedTemplateTitleExistDocument = gql`
    query IsKpiPredefinedTemplateTitleExist($Title: String!, $KpiPredefinedTemplateID: String) {
  IsKpiPredefinedTemplateTitleExist(Title: $Title, KpiPredefinedTemplateID: $KpiPredefinedTemplateID)
}
    `;

/**
 * __useIsKpiPredefinedTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiPredefinedTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiPredefinedTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiPredefinedTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useIsKpiPredefinedTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>(IsKpiPredefinedTemplateTitleExistDocument, baseOptions);
      }
export function useIsKpiPredefinedTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>(IsKpiPredefinedTemplateTitleExistDocument, baseOptions);
        }
export type IsKpiPredefinedTemplateTitleExistQueryHookResult = ReturnType<typeof useIsKpiPredefinedTemplateTitleExistQuery>;
export type IsKpiPredefinedTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiPredefinedTemplateTitleExistLazyQuery>;
export type IsKpiPredefinedTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>;
export const KpiPredefinedTemplateSubmitDocument = gql`
    mutation KpiPredefinedTemplateSubmit($input: KpiPredefinedTemplateInput!) {
  KpiPredefinedTemplateSubmit(input: $input)
}
    `;
export type KpiPredefinedTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>;

/**
 * __useKpiPredefinedTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedTemplateSubmitMutation, { data, loading, error }] = useKpiPredefinedTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>(KpiPredefinedTemplateSubmitDocument, baseOptions);
      }
export type KpiPredefinedTemplateSubmitMutationHookResult = ReturnType<typeof useKpiPredefinedTemplateSubmitMutation>;
export type KpiPredefinedTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedTemplateSubmitMutation>;
export type KpiPredefinedTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>;
export const KpiPredefinedTemplateDeleteDocument = gql`
    mutation KpiPredefinedTemplateDelete($KpiPredefinedTemplateID: String!) {
  KpiPredefinedTemplateDelete(KpiPredefinedTemplateID: $KpiPredefinedTemplateID)
}
    `;
export type KpiPredefinedTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>;

/**
 * __useKpiPredefinedTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedTemplateDeleteMutation, { data, loading, error }] = useKpiPredefinedTemplateDeleteMutation({
 *   variables: {
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useKpiPredefinedTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>(KpiPredefinedTemplateDeleteDocument, baseOptions);
      }
export type KpiPredefinedTemplateDeleteMutationHookResult = ReturnType<typeof useKpiPredefinedTemplateDeleteMutation>;
export type KpiPredefinedTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedTemplateDeleteMutation>;
export type KpiPredefinedTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>;
export const ReviewItemListingDocument = gql`
    query ReviewItemListing {
  ReviewItemListing {
    ID
    Description
    Title
    InUse
    ApplicableTo
    Code
    ItemRatingMethod
  }
}
    `;

/**
 * __useReviewItemListingQuery__
 *
 * To run a query within a React component, call `useReviewItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewItemListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewItemListingQuery, ReviewItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewItemListingQuery, ReviewItemListingQueryVariables>(ReviewItemListingDocument, baseOptions);
      }
export function useReviewItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewItemListingQuery, ReviewItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewItemListingQuery, ReviewItemListingQueryVariables>(ReviewItemListingDocument, baseOptions);
        }
export type ReviewItemListingQueryHookResult = ReturnType<typeof useReviewItemListingQuery>;
export type ReviewItemListingLazyQueryHookResult = ReturnType<typeof useReviewItemListingLazyQuery>;
export type ReviewItemListingQueryResult = ApolloReactCommon.QueryResult<ReviewItemListingQuery, ReviewItemListingQueryVariables>;
export const ReviewItemDetailsDocument = gql`
    query ReviewItemDetails($ReviewItemID: String!) {
  ReviewItemDetails(ReviewItemID: $ReviewItemID) {
    ID
    Title
    Code
    Description
    ItemRatingMethod
    StaffComment
    SuperiorComment
    FormItemRating {
      ID
      Title
    }
    ApplicableTo
  }
}
    `;

/**
 * __useReviewItemDetailsQuery__
 *
 * To run a query within a React component, call `useReviewItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewItemDetailsQuery({
 *   variables: {
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useReviewItemDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>(ReviewItemDetailsDocument, baseOptions);
      }
export function useReviewItemDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>(ReviewItemDetailsDocument, baseOptions);
        }
export type ReviewItemDetailsQueryHookResult = ReturnType<typeof useReviewItemDetailsQuery>;
export type ReviewItemDetailsLazyQueryHookResult = ReturnType<typeof useReviewItemDetailsLazyQuery>;
export type ReviewItemDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>;
export const IsReviewItemCodeExistDocument = gql`
    query IsReviewItemCodeExist($Code: String!, $ReviewItemID: String) {
  IsReviewItemCodeExist(Code: $Code, ReviewItemID: $ReviewItemID)
}
    `;

/**
 * __useIsReviewItemCodeExistQuery__
 *
 * To run a query within a React component, call `useIsReviewItemCodeExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewItemCodeExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewItemCodeExistQuery({
 *   variables: {
 *      Code: // value for 'Code'
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useIsReviewItemCodeExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>(IsReviewItemCodeExistDocument, baseOptions);
      }
export function useIsReviewItemCodeExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>(IsReviewItemCodeExistDocument, baseOptions);
        }
export type IsReviewItemCodeExistQueryHookResult = ReturnType<typeof useIsReviewItemCodeExistQuery>;
export type IsReviewItemCodeExistLazyQueryHookResult = ReturnType<typeof useIsReviewItemCodeExistLazyQuery>;
export type IsReviewItemCodeExistQueryResult = ApolloReactCommon.QueryResult<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>;
export const ReviewItemSubmitDocument = gql`
    mutation ReviewItemSubmit($input: ReviewItemInput!) {
  ReviewItemSubmit(input: $input)
}
    `;
export type ReviewItemSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>;

/**
 * __useReviewItemSubmitMutation__
 *
 * To run a mutation, you first call `useReviewItemSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewItemSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewItemSubmitMutation, { data, loading, error }] = useReviewItemSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewItemSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>(ReviewItemSubmitDocument, baseOptions);
      }
export type ReviewItemSubmitMutationHookResult = ReturnType<typeof useReviewItemSubmitMutation>;
export type ReviewItemSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewItemSubmitMutation>;
export type ReviewItemSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>;
export const ReviewItemDeleteDocument = gql`
    mutation ReviewItemDelete($ReviewItemID: String!) {
  ReviewItemDelete(ReviewItemID: $ReviewItemID)
}
    `;
export type ReviewItemDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>;

/**
 * __useReviewItemDeleteMutation__
 *
 * To run a mutation, you first call `useReviewItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewItemDeleteMutation, { data, loading, error }] = useReviewItemDeleteMutation({
 *   variables: {
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useReviewItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>(ReviewItemDeleteDocument, baseOptions);
      }
export type ReviewItemDeleteMutationHookResult = ReturnType<typeof useReviewItemDeleteMutation>;
export type ReviewItemDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewItemDeleteMutation>;
export type ReviewItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>;
export const ReviewTemplateListingDocument = gql`
    query ReviewTemplateListing {
  ReviewTemplateListing {
    ID
    Title
    InUse
    ItemCount
    ModifiedDT
  }
}
    `;

/**
 * __useReviewTemplateListingQuery__
 *
 * To run a query within a React component, call `useReviewTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>(ReviewTemplateListingDocument, baseOptions);
      }
export function useReviewTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>(ReviewTemplateListingDocument, baseOptions);
        }
export type ReviewTemplateListingQueryHookResult = ReturnType<typeof useReviewTemplateListingQuery>;
export type ReviewTemplateListingLazyQueryHookResult = ReturnType<typeof useReviewTemplateListingLazyQuery>;
export type ReviewTemplateListingQueryResult = ApolloReactCommon.QueryResult<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>;
export const ReviewTemplateDetailsDocument = gql`
    query ReviewTemplateDetails($ReviewTemplateID: String!) {
  ReviewTemplateDetails(ReviewTemplateID: $ReviewTemplateID) {
    ID
    Title
    Description
    ApplicableTo
    WeightageType
    ReviewItemList
  }
}
    `;

/**
 * __useReviewTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useReviewTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTemplateDetailsQuery({
 *   variables: {
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useReviewTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>(ReviewTemplateDetailsDocument, baseOptions);
      }
export function useReviewTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>(ReviewTemplateDetailsDocument, baseOptions);
        }
export type ReviewTemplateDetailsQueryHookResult = ReturnType<typeof useReviewTemplateDetailsQuery>;
export type ReviewTemplateDetailsLazyQueryHookResult = ReturnType<typeof useReviewTemplateDetailsLazyQuery>;
export type ReviewTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>;
export const IsReviewTemplateTitleExistDocument = gql`
    query IsReviewTemplateTitleExist($Title: String!, $ReviewTemplateID: String) {
  IsReviewTemplateTitleExist(Title: $Title, ReviewTemplateID: $ReviewTemplateID)
}
    `;

/**
 * __useIsReviewTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useIsReviewTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>(IsReviewTemplateTitleExistDocument, baseOptions);
      }
export function useIsReviewTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>(IsReviewTemplateTitleExistDocument, baseOptions);
        }
export type IsReviewTemplateTitleExistQueryHookResult = ReturnType<typeof useIsReviewTemplateTitleExistQuery>;
export type IsReviewTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewTemplateTitleExistLazyQuery>;
export type IsReviewTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>;
export const ReviewTemplateSubmitDocument = gql`
    mutation ReviewTemplateSubmit($input: ReviewTemplateInput!) {
  ReviewTemplateSubmit(input: $input)
}
    `;
export type ReviewTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>;

/**
 * __useReviewTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useReviewTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTemplateSubmitMutation, { data, loading, error }] = useReviewTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>(ReviewTemplateSubmitDocument, baseOptions);
      }
export type ReviewTemplateSubmitMutationHookResult = ReturnType<typeof useReviewTemplateSubmitMutation>;
export type ReviewTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewTemplateSubmitMutation>;
export type ReviewTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>;
export const ReviewTemplateDeleteDocument = gql`
    mutation ReviewTemplateDelete($ReviewTemplateID: String!) {
  ReviewTemplateDelete(ReviewTemplateID: $ReviewTemplateID)
}
    `;
export type ReviewTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>;

/**
 * __useReviewTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useReviewTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTemplateDeleteMutation, { data, loading, error }] = useReviewTemplateDeleteMutation({
 *   variables: {
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useReviewTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>(ReviewTemplateDeleteDocument, baseOptions);
      }
export type ReviewTemplateDeleteMutationHookResult = ReturnType<typeof useReviewTemplateDeleteMutation>;
export type ReviewTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewTemplateDeleteMutation>;
export type ReviewTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>;
export const ReviewFormTemplateListingDocument = gql`
    query ReviewFormTemplateListing {
  ReviewFormTemplateListing {
    ID
    Title
    InUse
    ModifiedDT
    ReviewType
    ApplicableTo
  }
}
    `;

/**
 * __useReviewFormTemplateListingQuery__
 *
 * To run a query within a React component, call `useReviewFormTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFormTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewFormTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>(ReviewFormTemplateListingDocument, baseOptions);
      }
export function useReviewFormTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>(ReviewFormTemplateListingDocument, baseOptions);
        }
export type ReviewFormTemplateListingQueryHookResult = ReturnType<typeof useReviewFormTemplateListingQuery>;
export type ReviewFormTemplateListingLazyQueryHookResult = ReturnType<typeof useReviewFormTemplateListingLazyQuery>;
export type ReviewFormTemplateListingQueryResult = ApolloReactCommon.QueryResult<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>;
export const ReviewFormTemplateDetailsDocument = gql`
    query ReviewFormTemplateDetails($ReviewFormTemplateID: String!) {
  ReviewFormTemplateDetails(ReviewFormTemplateID: $ReviewFormTemplateID) {
    ID
    Title
    Description
    WeightageType
    ReviewProcessPolicy {
      Title
      ID
    }
    ReviewGradingTable {
      ID
      Title
    }
    ReviewType
    ApplicableTo
    ReviewSection {
      ID
      Title
      Type
      Weightage
      KpiSource
      Sequence
      AllowItemEdited
      ItemEditedByEmployee
      ItemEditedBySuperior
      AllowItemZero
      KpiPredefinedTemplate {
        ID
        Title
      }
      FormItemRating {
        ID
        Title
      }
      ReviewTemplate {
        ID
        Title
      }
      Description
    }
    AllowSectionEdited
    SectionEditedByEmployee
    SectionEditedBySuperior
    AllowSectionZero
  }
}
    `;

/**
 * __useReviewFormTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useReviewFormTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFormTemplateDetailsQuery({
 *   variables: {
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useReviewFormTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>(ReviewFormTemplateDetailsDocument, baseOptions);
      }
export function useReviewFormTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>(ReviewFormTemplateDetailsDocument, baseOptions);
        }
export type ReviewFormTemplateDetailsQueryHookResult = ReturnType<typeof useReviewFormTemplateDetailsQuery>;
export type ReviewFormTemplateDetailsLazyQueryHookResult = ReturnType<typeof useReviewFormTemplateDetailsLazyQuery>;
export type ReviewFormTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>;
export const IsReviewFormTemplateTitleExistDocument = gql`
    query IsReviewFormTemplateTitleExist($Title: String!, $ReviewFormTemplateID: String) {
  IsReviewFormTemplateTitleExist(Title: $Title, ReviewFormTemplateID: $ReviewFormTemplateID)
}
    `;

/**
 * __useIsReviewFormTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewFormTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewFormTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewFormTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useIsReviewFormTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>(IsReviewFormTemplateTitleExistDocument, baseOptions);
      }
export function useIsReviewFormTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>(IsReviewFormTemplateTitleExistDocument, baseOptions);
        }
export type IsReviewFormTemplateTitleExistQueryHookResult = ReturnType<typeof useIsReviewFormTemplateTitleExistQuery>;
export type IsReviewFormTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewFormTemplateTitleExistLazyQuery>;
export type IsReviewFormTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>;
export const ReviewFormTemplateSubmitDocument = gql`
    mutation ReviewFormTemplateSubmit($input: ReviewFormTemplateInput!) {
  ReviewFormTemplateSubmit(input: $input)
}
    `;
export type ReviewFormTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>;

/**
 * __useReviewFormTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useReviewFormTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewFormTemplateSubmitMutation, { data, loading, error }] = useReviewFormTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewFormTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>(ReviewFormTemplateSubmitDocument, baseOptions);
      }
export type ReviewFormTemplateSubmitMutationHookResult = ReturnType<typeof useReviewFormTemplateSubmitMutation>;
export type ReviewFormTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewFormTemplateSubmitMutation>;
export type ReviewFormTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>;
export const ReviewFormTemplateDeleteDocument = gql`
    mutation ReviewFormTemplateDelete($ReviewFormTemplateID: String!) {
  ReviewFormTemplateDelete(ReviewFormTemplateID: $ReviewFormTemplateID)
}
    `;
export type ReviewFormTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>;

/**
 * __useReviewFormTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useReviewFormTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewFormTemplateDeleteMutation, { data, loading, error }] = useReviewFormTemplateDeleteMutation({
 *   variables: {
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useReviewFormTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>(ReviewFormTemplateDeleteDocument, baseOptions);
      }
export type ReviewFormTemplateDeleteMutationHookResult = ReturnType<typeof useReviewFormTemplateDeleteMutation>;
export type ReviewFormTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewFormTemplateDeleteMutation>;
export type ReviewFormTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>;
export const ReviewGroupListingDocument = gql`
    query ReviewGroupListing {
  ReviewGroupListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewGroupListingQuery__
 *
 * To run a query within a React component, call `useReviewGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>(ReviewGroupListingDocument, baseOptions);
      }
export function useReviewGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>(ReviewGroupListingDocument, baseOptions);
        }
export type ReviewGroupListingQueryHookResult = ReturnType<typeof useReviewGroupListingQuery>;
export type ReviewGroupListingLazyQueryHookResult = ReturnType<typeof useReviewGroupListingLazyQuery>;
export type ReviewGroupListingQueryResult = ApolloReactCommon.QueryResult<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>;
export const IsReviewGroupTitleExistDocument = gql`
    query IsReviewGroupTitleExist($Title: String!, $ReviewGroupID: String) {
  IsReviewGroupTitleExist(Title: $Title, ReviewGroupID: $ReviewGroupID)
}
    `;

/**
 * __useIsReviewGroupTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewGroupTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewGroupTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewGroupTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewGroupID: // value for 'ReviewGroupID'
 *   },
 * });
 */
export function useIsReviewGroupTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>(IsReviewGroupTitleExistDocument, baseOptions);
      }
export function useIsReviewGroupTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>(IsReviewGroupTitleExistDocument, baseOptions);
        }
export type IsReviewGroupTitleExistQueryHookResult = ReturnType<typeof useIsReviewGroupTitleExistQuery>;
export type IsReviewGroupTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewGroupTitleExistLazyQuery>;
export type IsReviewGroupTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>;
export const ReviewGroupSubmitDocument = gql`
    mutation ReviewGroupSubmit($input: ReviewGroupInput!) {
  ReviewGroupSubmit(input: $input)
}
    `;
export type ReviewGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>;

/**
 * __useReviewGroupSubmitMutation__
 *
 * To run a mutation, you first call `useReviewGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGroupSubmitMutation, { data, loading, error }] = useReviewGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>(ReviewGroupSubmitDocument, baseOptions);
      }
export type ReviewGroupSubmitMutationHookResult = ReturnType<typeof useReviewGroupSubmitMutation>;
export type ReviewGroupSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewGroupSubmitMutation>;
export type ReviewGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>;
export const ReviewGroupDeleteDocument = gql`
    mutation ReviewGroupDelete($ReviewGroupID: String!) {
  ReviewGroupDelete(ReviewGroupID: $ReviewGroupID)
}
    `;
export type ReviewGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>;

/**
 * __useReviewGroupDeleteMutation__
 *
 * To run a mutation, you first call `useReviewGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGroupDeleteMutation, { data, loading, error }] = useReviewGroupDeleteMutation({
 *   variables: {
 *      ReviewGroupID: // value for 'ReviewGroupID'
 *   },
 * });
 */
export function useReviewGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>(ReviewGroupDeleteDocument, baseOptions);
      }
export type ReviewGroupDeleteMutationHookResult = ReturnType<typeof useReviewGroupDeleteMutation>;
export type ReviewGroupDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewGroupDeleteMutation>;
export type ReviewGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>;
export const RewardGroupListingDocument = gql`
    query RewardGroupListing {
  RewardGroupListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useRewardGroupListingQuery__
 *
 * To run a query within a React component, call `useRewardGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RewardGroupListingQuery, RewardGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RewardGroupListingQuery, RewardGroupListingQueryVariables>(RewardGroupListingDocument, baseOptions);
      }
export function useRewardGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RewardGroupListingQuery, RewardGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RewardGroupListingQuery, RewardGroupListingQueryVariables>(RewardGroupListingDocument, baseOptions);
        }
export type RewardGroupListingQueryHookResult = ReturnType<typeof useRewardGroupListingQuery>;
export type RewardGroupListingLazyQueryHookResult = ReturnType<typeof useRewardGroupListingLazyQuery>;
export type RewardGroupListingQueryResult = ApolloReactCommon.QueryResult<RewardGroupListingQuery, RewardGroupListingQueryVariables>;
export const IsRewardGroupTitleExistDocument = gql`
    query IsRewardGroupTitleExist($Title: String!, $RewardGroupID: String) {
  IsRewardGroupTitleExist(Title: $Title, RewardGroupID: $RewardGroupID)
}
    `;

/**
 * __useIsRewardGroupTitleExistQuery__
 *
 * To run a query within a React component, call `useIsRewardGroupTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRewardGroupTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRewardGroupTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      RewardGroupID: // value for 'RewardGroupID'
 *   },
 * });
 */
export function useIsRewardGroupTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>(IsRewardGroupTitleExistDocument, baseOptions);
      }
export function useIsRewardGroupTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>(IsRewardGroupTitleExistDocument, baseOptions);
        }
export type IsRewardGroupTitleExistQueryHookResult = ReturnType<typeof useIsRewardGroupTitleExistQuery>;
export type IsRewardGroupTitleExistLazyQueryHookResult = ReturnType<typeof useIsRewardGroupTitleExistLazyQuery>;
export type IsRewardGroupTitleExistQueryResult = ApolloReactCommon.QueryResult<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>;
export const RewardGroupSubmitDocument = gql`
    mutation RewardGroupSubmit($input: RewardGroupInput!) {
  RewardGroupSubmit(input: $input)
}
    `;
export type RewardGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>;

/**
 * __useRewardGroupSubmitMutation__
 *
 * To run a mutation, you first call `useRewardGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewardGroupSubmitMutation, { data, loading, error }] = useRewardGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRewardGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>(RewardGroupSubmitDocument, baseOptions);
      }
export type RewardGroupSubmitMutationHookResult = ReturnType<typeof useRewardGroupSubmitMutation>;
export type RewardGroupSubmitMutationResult = ApolloReactCommon.MutationResult<RewardGroupSubmitMutation>;
export type RewardGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>;
export const RewardGroupDeleteDocument = gql`
    mutation RewardGroupDelete($RewardGroupID: String!) {
  RewardGroupDelete(RewardGroupID: $RewardGroupID)
}
    `;
export type RewardGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>;

/**
 * __useRewardGroupDeleteMutation__
 *
 * To run a mutation, you first call `useRewardGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewardGroupDeleteMutation, { data, loading, error }] = useRewardGroupDeleteMutation({
 *   variables: {
 *      RewardGroupID: // value for 'RewardGroupID'
 *   },
 * });
 */
export function useRewardGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>(RewardGroupDeleteDocument, baseOptions);
      }
export type RewardGroupDeleteMutationHookResult = ReturnType<typeof useRewardGroupDeleteMutation>;
export type RewardGroupDeleteMutationResult = ApolloReactCommon.MutationResult<RewardGroupDeleteMutation>;
export type RewardGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>;
export const PerformanceMainMenuSummaryDocument = gql`
    query PerformanceMainMenuSummary {
  PerformanceMainMenuSummary
}
    `;

/**
 * __usePerformanceMainMenuSummaryQuery__
 *
 * To run a query within a React component, call `usePerformanceMainMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceMainMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceMainMenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformanceMainMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceMainMenuSummaryQuery, PerformanceMainMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceMainMenuSummaryQuery, PerformanceMainMenuSummaryQueryVariables>(PerformanceMainMenuSummaryDocument, baseOptions);
      }
export function usePerformanceMainMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceMainMenuSummaryQuery, PerformanceMainMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceMainMenuSummaryQuery, PerformanceMainMenuSummaryQueryVariables>(PerformanceMainMenuSummaryDocument, baseOptions);
        }
export type PerformanceMainMenuSummaryQueryHookResult = ReturnType<typeof usePerformanceMainMenuSummaryQuery>;
export type PerformanceMainMenuSummaryLazyQueryHookResult = ReturnType<typeof usePerformanceMainMenuSummaryLazyQuery>;
export type PerformanceMainMenuSummaryQueryResult = ApolloReactCommon.QueryResult<PerformanceMainMenuSummaryQuery, PerformanceMainMenuSummaryQueryVariables>;
export const PerformanceGroupMatrixListingDocument = gql`
    query PerformanceGroupMatrixListing($GroupType: String!) {
  PerformanceGroupMatrixListing(GroupType: $GroupType) {
    ID
    GroupID
    ConditionList
    TotalCriteria
    CriteriaDesc
    ReviewGroup {
      ID
      Title
    }
    RewardGroup {
      ID
      Title
    }
  }
}
    `;

/**
 * __usePerformanceGroupMatrixListingQuery__
 *
 * To run a query within a React component, call `usePerformanceGroupMatrixListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceGroupMatrixListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceGroupMatrixListingQuery({
 *   variables: {
 *      GroupType: // value for 'GroupType'
 *   },
 * });
 */
export function usePerformanceGroupMatrixListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceGroupMatrixListingQuery, PerformanceGroupMatrixListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceGroupMatrixListingQuery, PerformanceGroupMatrixListingQueryVariables>(PerformanceGroupMatrixListingDocument, baseOptions);
      }
export function usePerformanceGroupMatrixListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceGroupMatrixListingQuery, PerformanceGroupMatrixListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceGroupMatrixListingQuery, PerformanceGroupMatrixListingQueryVariables>(PerformanceGroupMatrixListingDocument, baseOptions);
        }
export type PerformanceGroupMatrixListingQueryHookResult = ReturnType<typeof usePerformanceGroupMatrixListingQuery>;
export type PerformanceGroupMatrixListingLazyQueryHookResult = ReturnType<typeof usePerformanceGroupMatrixListingLazyQuery>;
export type PerformanceGroupMatrixListingQueryResult = ApolloReactCommon.QueryResult<PerformanceGroupMatrixListingQuery, PerformanceGroupMatrixListingQueryVariables>;
export const PerformanceGroupMatrixSubmitDocument = gql`
    mutation PerformanceGroupMatrixSubmit($input: PerformanceGroupMatrixInput!) {
  PerformanceGroupMatrixSubmit(input: $input)
}
    `;
export type PerformanceGroupMatrixSubmitMutationFn = ApolloReactCommon.MutationFunction<PerformanceGroupMatrixSubmitMutation, PerformanceGroupMatrixSubmitMutationVariables>;

/**
 * __usePerformanceGroupMatrixSubmitMutation__
 *
 * To run a mutation, you first call `usePerformanceGroupMatrixSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformanceGroupMatrixSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performanceGroupMatrixSubmitMutation, { data, loading, error }] = usePerformanceGroupMatrixSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformanceGroupMatrixSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PerformanceGroupMatrixSubmitMutation, PerformanceGroupMatrixSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<PerformanceGroupMatrixSubmitMutation, PerformanceGroupMatrixSubmitMutationVariables>(PerformanceGroupMatrixSubmitDocument, baseOptions);
      }
export type PerformanceGroupMatrixSubmitMutationHookResult = ReturnType<typeof usePerformanceGroupMatrixSubmitMutation>;
export type PerformanceGroupMatrixSubmitMutationResult = ApolloReactCommon.MutationResult<PerformanceGroupMatrixSubmitMutation>;
export type PerformanceGroupMatrixSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<PerformanceGroupMatrixSubmitMutation, PerformanceGroupMatrixSubmitMutationVariables>;
export const PerformanceGroupMatrixDeleteDocument = gql`
    mutation PerformanceGroupMatrixDelete($PerformanceGroupMatrixID: String!) {
  PerformanceGroupMatrixDelete(PerformanceGroupMatrixID: $PerformanceGroupMatrixID)
}
    `;
export type PerformanceGroupMatrixDeleteMutationFn = ApolloReactCommon.MutationFunction<PerformanceGroupMatrixDeleteMutation, PerformanceGroupMatrixDeleteMutationVariables>;

/**
 * __usePerformanceGroupMatrixDeleteMutation__
 *
 * To run a mutation, you first call `usePerformanceGroupMatrixDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformanceGroupMatrixDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performanceGroupMatrixDeleteMutation, { data, loading, error }] = usePerformanceGroupMatrixDeleteMutation({
 *   variables: {
 *      PerformanceGroupMatrixID: // value for 'PerformanceGroupMatrixID'
 *   },
 * });
 */
export function usePerformanceGroupMatrixDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PerformanceGroupMatrixDeleteMutation, PerformanceGroupMatrixDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PerformanceGroupMatrixDeleteMutation, PerformanceGroupMatrixDeleteMutationVariables>(PerformanceGroupMatrixDeleteDocument, baseOptions);
      }
export type PerformanceGroupMatrixDeleteMutationHookResult = ReturnType<typeof usePerformanceGroupMatrixDeleteMutation>;
export type PerformanceGroupMatrixDeleteMutationResult = ApolloReactCommon.MutationResult<PerformanceGroupMatrixDeleteMutation>;
export type PerformanceGroupMatrixDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PerformanceGroupMatrixDeleteMutation, PerformanceGroupMatrixDeleteMutationVariables>;
export const KpiCategoryWithOrgGoalListingDocument = gql`
    query KpiCategoryWithOrgGoalListing($Year: Float!) {
  KpiCategoryWithOrgGoalListing(Year: $Year) {
    ID
    Title
    OrgGoals {
      ID
      Goal
      PercentageComplete
    }
  }
}
    `;

/**
 * __useKpiCategoryWithOrgGoalListingQuery__
 *
 * To run a query within a React component, call `useKpiCategoryWithOrgGoalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryWithOrgGoalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryWithOrgGoalListingQuery({
 *   variables: {
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useKpiCategoryWithOrgGoalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryWithOrgGoalListingQuery, KpiCategoryWithOrgGoalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryWithOrgGoalListingQuery, KpiCategoryWithOrgGoalListingQueryVariables>(KpiCategoryWithOrgGoalListingDocument, baseOptions);
      }
export function useKpiCategoryWithOrgGoalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryWithOrgGoalListingQuery, KpiCategoryWithOrgGoalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryWithOrgGoalListingQuery, KpiCategoryWithOrgGoalListingQueryVariables>(KpiCategoryWithOrgGoalListingDocument, baseOptions);
        }
export type KpiCategoryWithOrgGoalListingQueryHookResult = ReturnType<typeof useKpiCategoryWithOrgGoalListingQuery>;
export type KpiCategoryWithOrgGoalListingLazyQueryHookResult = ReturnType<typeof useKpiCategoryWithOrgGoalListingLazyQuery>;
export type KpiCategoryWithOrgGoalListingQueryResult = ApolloReactCommon.QueryResult<KpiCategoryWithOrgGoalListingQuery, KpiCategoryWithOrgGoalListingQueryVariables>;
export const OrganizationalGoalByCategoryListingDocument = gql`
    query OrganizationalGoalByCategoryListing($KpiCategoryID: String!, $Year: Float!) {
  OrganizationalGoalByCategoryListing(KpiCategoryID: $KpiCategoryID, Year: $Year) {
    ID
    Goal
    Objective
    PercentageComplete
    InUse
  }
}
    `;

/**
 * __useOrganizationalGoalByCategoryListingQuery__
 *
 * To run a query within a React component, call `useOrganizationalGoalByCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationalGoalByCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationalGoalByCategoryListingQuery({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useOrganizationalGoalByCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationalGoalByCategoryListingQuery, OrganizationalGoalByCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationalGoalByCategoryListingQuery, OrganizationalGoalByCategoryListingQueryVariables>(OrganizationalGoalByCategoryListingDocument, baseOptions);
      }
export function useOrganizationalGoalByCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationalGoalByCategoryListingQuery, OrganizationalGoalByCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationalGoalByCategoryListingQuery, OrganizationalGoalByCategoryListingQueryVariables>(OrganizationalGoalByCategoryListingDocument, baseOptions);
        }
export type OrganizationalGoalByCategoryListingQueryHookResult = ReturnType<typeof useOrganizationalGoalByCategoryListingQuery>;
export type OrganizationalGoalByCategoryListingLazyQueryHookResult = ReturnType<typeof useOrganizationalGoalByCategoryListingLazyQuery>;
export type OrganizationalGoalByCategoryListingQueryResult = ApolloReactCommon.QueryResult<OrganizationalGoalByCategoryListingQuery, OrganizationalGoalByCategoryListingQueryVariables>;
export const OrganizationalGoalDetailsDocument = gql`
    query OrganizationalGoalDetails($OrganizationalGoalID: String!) {
  OrganizationalGoalDetails(OrganizationalGoalID: $OrganizationalGoalID) {
    ID
    Goal
    Objective
    PercentageComplete
  }
}
    `;

/**
 * __useOrganizationalGoalDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationalGoalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationalGoalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationalGoalDetailsQuery({
 *   variables: {
 *      OrganizationalGoalID: // value for 'OrganizationalGoalID'
 *   },
 * });
 */
export function useOrganizationalGoalDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationalGoalDetailsQuery, OrganizationalGoalDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationalGoalDetailsQuery, OrganizationalGoalDetailsQueryVariables>(OrganizationalGoalDetailsDocument, baseOptions);
      }
export function useOrganizationalGoalDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationalGoalDetailsQuery, OrganizationalGoalDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationalGoalDetailsQuery, OrganizationalGoalDetailsQueryVariables>(OrganizationalGoalDetailsDocument, baseOptions);
        }
export type OrganizationalGoalDetailsQueryHookResult = ReturnType<typeof useOrganizationalGoalDetailsQuery>;
export type OrganizationalGoalDetailsLazyQueryHookResult = ReturnType<typeof useOrganizationalGoalDetailsLazyQuery>;
export type OrganizationalGoalDetailsQueryResult = ApolloReactCommon.QueryResult<OrganizationalGoalDetailsQuery, OrganizationalGoalDetailsQueryVariables>;
export const IsOrgGoalExistByCategoryDocument = gql`
    query IsOrgGoalExistByCategory($Goal: String!, $Year: Float!, $KpiCategoryID: String!, $OrgGoalID: String) {
  IsOrgGoalExistByCategory(Goal: $Goal, Year: $Year, KpiCategoryID: $KpiCategoryID, OrgGoalID: $OrgGoalID)
}
    `;

/**
 * __useIsOrgGoalExistByCategoryQuery__
 *
 * To run a query within a React component, call `useIsOrgGoalExistByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOrgGoalExistByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOrgGoalExistByCategoryQuery({
 *   variables: {
 *      Goal: // value for 'Goal'
 *      Year: // value for 'Year'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *      OrgGoalID: // value for 'OrgGoalID'
 *   },
 * });
 */
export function useIsOrgGoalExistByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsOrgGoalExistByCategoryQuery, IsOrgGoalExistByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<IsOrgGoalExistByCategoryQuery, IsOrgGoalExistByCategoryQueryVariables>(IsOrgGoalExistByCategoryDocument, baseOptions);
      }
export function useIsOrgGoalExistByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsOrgGoalExistByCategoryQuery, IsOrgGoalExistByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsOrgGoalExistByCategoryQuery, IsOrgGoalExistByCategoryQueryVariables>(IsOrgGoalExistByCategoryDocument, baseOptions);
        }
export type IsOrgGoalExistByCategoryQueryHookResult = ReturnType<typeof useIsOrgGoalExistByCategoryQuery>;
export type IsOrgGoalExistByCategoryLazyQueryHookResult = ReturnType<typeof useIsOrgGoalExistByCategoryLazyQuery>;
export type IsOrgGoalExistByCategoryQueryResult = ApolloReactCommon.QueryResult<IsOrgGoalExistByCategoryQuery, IsOrgGoalExistByCategoryQueryVariables>;
export const OrganizationalGoalSubmitDocument = gql`
    mutation OrganizationalGoalSubmit($input: OrganizationalGoalInput!) {
  OrganizationalGoalSubmit(input: $input)
}
    `;
export type OrganizationalGoalSubmitMutationFn = ApolloReactCommon.MutationFunction<OrganizationalGoalSubmitMutation, OrganizationalGoalSubmitMutationVariables>;

/**
 * __useOrganizationalGoalSubmitMutation__
 *
 * To run a mutation, you first call `useOrganizationalGoalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationalGoalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationalGoalSubmitMutation, { data, loading, error }] = useOrganizationalGoalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationalGoalSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationalGoalSubmitMutation, OrganizationalGoalSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationalGoalSubmitMutation, OrganizationalGoalSubmitMutationVariables>(OrganizationalGoalSubmitDocument, baseOptions);
      }
export type OrganizationalGoalSubmitMutationHookResult = ReturnType<typeof useOrganizationalGoalSubmitMutation>;
export type OrganizationalGoalSubmitMutationResult = ApolloReactCommon.MutationResult<OrganizationalGoalSubmitMutation>;
export type OrganizationalGoalSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationalGoalSubmitMutation, OrganizationalGoalSubmitMutationVariables>;
export const OrganizationalGoalDeleteDocument = gql`
    mutation OrganizationalGoalDelete($OrganizationalGoalID: String!) {
  OrganizationalGoalDelete(OrganizationalGoalID: $OrganizationalGoalID)
}
    `;
export type OrganizationalGoalDeleteMutationFn = ApolloReactCommon.MutationFunction<OrganizationalGoalDeleteMutation, OrganizationalGoalDeleteMutationVariables>;

/**
 * __useOrganizationalGoalDeleteMutation__
 *
 * To run a mutation, you first call `useOrganizationalGoalDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationalGoalDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationalGoalDeleteMutation, { data, loading, error }] = useOrganizationalGoalDeleteMutation({
 *   variables: {
 *      OrganizationalGoalID: // value for 'OrganizationalGoalID'
 *   },
 * });
 */
export function useOrganizationalGoalDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationalGoalDeleteMutation, OrganizationalGoalDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationalGoalDeleteMutation, OrganizationalGoalDeleteMutationVariables>(OrganizationalGoalDeleteDocument, baseOptions);
      }
export type OrganizationalGoalDeleteMutationHookResult = ReturnType<typeof useOrganizationalGoalDeleteMutation>;
export type OrganizationalGoalDeleteMutationResult = ApolloReactCommon.MutationResult<OrganizationalGoalDeleteMutation>;
export type OrganizationalGoalDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationalGoalDeleteMutation, OrganizationalGoalDeleteMutationVariables>;
export const OrgKpiPlanningTreeViewListingDocument = gql`
    query OrgKpiPlanningTreeViewListing($Year: Float!) {
  OrgKpiPlanningTreeViewListing(Year: $Year) {
    ID
    OrgUnitID
    TotalGoals
    Year
    OwnerID
    CompanyID
    Owner {
      EmployeeNo
      Contact {
        FullName
      }
    }
    PercentageComplete
  }
}
    `;

/**
 * __useOrgKpiPlanningTreeViewListingQuery__
 *
 * To run a query within a React component, call `useOrgKpiPlanningTreeViewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgKpiPlanningTreeViewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgKpiPlanningTreeViewListingQuery({
 *   variables: {
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useOrgKpiPlanningTreeViewListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgKpiPlanningTreeViewListingQuery, OrgKpiPlanningTreeViewListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgKpiPlanningTreeViewListingQuery, OrgKpiPlanningTreeViewListingQueryVariables>(OrgKpiPlanningTreeViewListingDocument, baseOptions);
      }
export function useOrgKpiPlanningTreeViewListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgKpiPlanningTreeViewListingQuery, OrgKpiPlanningTreeViewListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgKpiPlanningTreeViewListingQuery, OrgKpiPlanningTreeViewListingQueryVariables>(OrgKpiPlanningTreeViewListingDocument, baseOptions);
        }
export type OrgKpiPlanningTreeViewListingQueryHookResult = ReturnType<typeof useOrgKpiPlanningTreeViewListingQuery>;
export type OrgKpiPlanningTreeViewListingLazyQueryHookResult = ReturnType<typeof useOrgKpiPlanningTreeViewListingLazyQuery>;
export type OrgKpiPlanningTreeViewListingQueryResult = ApolloReactCommon.QueryResult<OrgKpiPlanningTreeViewListingQuery, OrgKpiPlanningTreeViewListingQueryVariables>;
export const KpiCategoryWithOrgKpiPlanningListingDocument = gql`
    query KpiCategoryWithOrgKpiPlanningListing($OrgKpiPlanningID: String!) {
  KpiCategoryWithOrgKpiPlanningListing(OrgKpiPlanningID: $OrgKpiPlanningID) {
    ID
    Title
    OrgKpiPlannings {
      ID
      KPI
      Target
      Initiatives
      PercentageComplete
    }
  }
}
    `;

/**
 * __useKpiCategoryWithOrgKpiPlanningListingQuery__
 *
 * To run a query within a React component, call `useKpiCategoryWithOrgKpiPlanningListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryWithOrgKpiPlanningListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryWithOrgKpiPlanningListingQuery({
 *   variables: {
 *      OrgKpiPlanningID: // value for 'OrgKpiPlanningID'
 *   },
 * });
 */
export function useKpiCategoryWithOrgKpiPlanningListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryWithOrgKpiPlanningListingQuery, KpiCategoryWithOrgKpiPlanningListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryWithOrgKpiPlanningListingQuery, KpiCategoryWithOrgKpiPlanningListingQueryVariables>(KpiCategoryWithOrgKpiPlanningListingDocument, baseOptions);
      }
export function useKpiCategoryWithOrgKpiPlanningListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryWithOrgKpiPlanningListingQuery, KpiCategoryWithOrgKpiPlanningListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryWithOrgKpiPlanningListingQuery, KpiCategoryWithOrgKpiPlanningListingQueryVariables>(KpiCategoryWithOrgKpiPlanningListingDocument, baseOptions);
        }
export type KpiCategoryWithOrgKpiPlanningListingQueryHookResult = ReturnType<typeof useKpiCategoryWithOrgKpiPlanningListingQuery>;
export type KpiCategoryWithOrgKpiPlanningListingLazyQueryHookResult = ReturnType<typeof useKpiCategoryWithOrgKpiPlanningListingLazyQuery>;
export type KpiCategoryWithOrgKpiPlanningListingQueryResult = ApolloReactCommon.QueryResult<KpiCategoryWithOrgKpiPlanningListingQuery, KpiCategoryWithOrgKpiPlanningListingQueryVariables>;
export const OrgKpiPlanningDetailsByKpiCategoryListingDocument = gql`
    query OrgKpiPlanningDetailsByKpiCategoryListing($OrgKpiPlanningID: String!, $KpiCategoryID: String!) {
  OrgKpiPlanningDetailsByKpiCategoryListing(OrgKpiPlanningID: $OrgKpiPlanningID, KpiCategoryID: $KpiCategoryID) {
    ID
    OrgGoal {
      ID
      Goal
    }
    KpiCategory {
      Title
    }
    KPI
    Target
    Initiatives
    PercentageComplete
  }
}
    `;

/**
 * __useOrgKpiPlanningDetailsByKpiCategoryListingQuery__
 *
 * To run a query within a React component, call `useOrgKpiPlanningDetailsByKpiCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgKpiPlanningDetailsByKpiCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgKpiPlanningDetailsByKpiCategoryListingQuery({
 *   variables: {
 *      OrgKpiPlanningID: // value for 'OrgKpiPlanningID'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useOrgKpiPlanningDetailsByKpiCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgKpiPlanningDetailsByKpiCategoryListingQuery, OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgKpiPlanningDetailsByKpiCategoryListingQuery, OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables>(OrgKpiPlanningDetailsByKpiCategoryListingDocument, baseOptions);
      }
export function useOrgKpiPlanningDetailsByKpiCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgKpiPlanningDetailsByKpiCategoryListingQuery, OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgKpiPlanningDetailsByKpiCategoryListingQuery, OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables>(OrgKpiPlanningDetailsByKpiCategoryListingDocument, baseOptions);
        }
export type OrgKpiPlanningDetailsByKpiCategoryListingQueryHookResult = ReturnType<typeof useOrgKpiPlanningDetailsByKpiCategoryListingQuery>;
export type OrgKpiPlanningDetailsByKpiCategoryListingLazyQueryHookResult = ReturnType<typeof useOrgKpiPlanningDetailsByKpiCategoryListingLazyQuery>;
export type OrgKpiPlanningDetailsByKpiCategoryListingQueryResult = ApolloReactCommon.QueryResult<OrgKpiPlanningDetailsByKpiCategoryListingQuery, OrgKpiPlanningDetailsByKpiCategoryListingQueryVariables>;
export const IsOrgKpiExistByCategoryDocument = gql`
    query IsOrgKpiExistByCategory($KPI: String!, $KpiCategoryID: String!, $OrgKpiPlanningID: String!, $OrgKpiPlanningDetailslID: String) {
  IsOrgKpiExistByCategory(KPI: $KPI, OrgKpiPlanningID: $OrgKpiPlanningID, KpiCategoryID: $KpiCategoryID, OrgKpiPlanningDetailslID: $OrgKpiPlanningDetailslID)
}
    `;

/**
 * __useIsOrgKpiExistByCategoryQuery__
 *
 * To run a query within a React component, call `useIsOrgKpiExistByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOrgKpiExistByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOrgKpiExistByCategoryQuery({
 *   variables: {
 *      KPI: // value for 'KPI'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *      OrgKpiPlanningID: // value for 'OrgKpiPlanningID'
 *      OrgKpiPlanningDetailslID: // value for 'OrgKpiPlanningDetailslID'
 *   },
 * });
 */
export function useIsOrgKpiExistByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsOrgKpiExistByCategoryQuery, IsOrgKpiExistByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<IsOrgKpiExistByCategoryQuery, IsOrgKpiExistByCategoryQueryVariables>(IsOrgKpiExistByCategoryDocument, baseOptions);
      }
export function useIsOrgKpiExistByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsOrgKpiExistByCategoryQuery, IsOrgKpiExistByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsOrgKpiExistByCategoryQuery, IsOrgKpiExistByCategoryQueryVariables>(IsOrgKpiExistByCategoryDocument, baseOptions);
        }
export type IsOrgKpiExistByCategoryQueryHookResult = ReturnType<typeof useIsOrgKpiExistByCategoryQuery>;
export type IsOrgKpiExistByCategoryLazyQueryHookResult = ReturnType<typeof useIsOrgKpiExistByCategoryLazyQuery>;
export type IsOrgKpiExistByCategoryQueryResult = ApolloReactCommon.QueryResult<IsOrgKpiExistByCategoryQuery, IsOrgKpiExistByCategoryQueryVariables>;
export const OrgKpiPlanningSubmitDocument = gql`
    mutation OrgKpiPlanningSubmit($input: OrgKpiPlanningInput!) {
  OrgKpiPlanningSubmit(input: $input)
}
    `;
export type OrgKpiPlanningSubmitMutationFn = ApolloReactCommon.MutationFunction<OrgKpiPlanningSubmitMutation, OrgKpiPlanningSubmitMutationVariables>;

/**
 * __useOrgKpiPlanningSubmitMutation__
 *
 * To run a mutation, you first call `useOrgKpiPlanningSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgKpiPlanningSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgKpiPlanningSubmitMutation, { data, loading, error }] = useOrgKpiPlanningSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgKpiPlanningSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrgKpiPlanningSubmitMutation, OrgKpiPlanningSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<OrgKpiPlanningSubmitMutation, OrgKpiPlanningSubmitMutationVariables>(OrgKpiPlanningSubmitDocument, baseOptions);
      }
export type OrgKpiPlanningSubmitMutationHookResult = ReturnType<typeof useOrgKpiPlanningSubmitMutation>;
export type OrgKpiPlanningSubmitMutationResult = ApolloReactCommon.MutationResult<OrgKpiPlanningSubmitMutation>;
export type OrgKpiPlanningSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<OrgKpiPlanningSubmitMutation, OrgKpiPlanningSubmitMutationVariables>;
export const OrgKpiPlanningDetailSubmitDocument = gql`
    mutation OrgKpiPlanningDetailSubmit($input: OrgKpiPlanningDetailsInput!) {
  OrgKpiPlanningDetailSubmit(input: $input)
}
    `;
export type OrgKpiPlanningDetailSubmitMutationFn = ApolloReactCommon.MutationFunction<OrgKpiPlanningDetailSubmitMutation, OrgKpiPlanningDetailSubmitMutationVariables>;

/**
 * __useOrgKpiPlanningDetailSubmitMutation__
 *
 * To run a mutation, you first call `useOrgKpiPlanningDetailSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgKpiPlanningDetailSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgKpiPlanningDetailSubmitMutation, { data, loading, error }] = useOrgKpiPlanningDetailSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgKpiPlanningDetailSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrgKpiPlanningDetailSubmitMutation, OrgKpiPlanningDetailSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<OrgKpiPlanningDetailSubmitMutation, OrgKpiPlanningDetailSubmitMutationVariables>(OrgKpiPlanningDetailSubmitDocument, baseOptions);
      }
export type OrgKpiPlanningDetailSubmitMutationHookResult = ReturnType<typeof useOrgKpiPlanningDetailSubmitMutation>;
export type OrgKpiPlanningDetailSubmitMutationResult = ApolloReactCommon.MutationResult<OrgKpiPlanningDetailSubmitMutation>;
export type OrgKpiPlanningDetailSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<OrgKpiPlanningDetailSubmitMutation, OrgKpiPlanningDetailSubmitMutationVariables>;
export const OrgKpiPlanningDetailsDeleteDocument = gql`
    mutation OrgKpiPlanningDetailsDelete($OrgKpiPlanningDetailsID: String!) {
  OrgKpiPlanningDetailsDelete(OrgKpiPlanningDetailsID: $OrgKpiPlanningDetailsID)
}
    `;
export type OrgKpiPlanningDetailsDeleteMutationFn = ApolloReactCommon.MutationFunction<OrgKpiPlanningDetailsDeleteMutation, OrgKpiPlanningDetailsDeleteMutationVariables>;

/**
 * __useOrgKpiPlanningDetailsDeleteMutation__
 *
 * To run a mutation, you first call `useOrgKpiPlanningDetailsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgKpiPlanningDetailsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgKpiPlanningDetailsDeleteMutation, { data, loading, error }] = useOrgKpiPlanningDetailsDeleteMutation({
 *   variables: {
 *      OrgKpiPlanningDetailsID: // value for 'OrgKpiPlanningDetailsID'
 *   },
 * });
 */
export function useOrgKpiPlanningDetailsDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrgKpiPlanningDetailsDeleteMutation, OrgKpiPlanningDetailsDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OrgKpiPlanningDetailsDeleteMutation, OrgKpiPlanningDetailsDeleteMutationVariables>(OrgKpiPlanningDetailsDeleteDocument, baseOptions);
      }
export type OrgKpiPlanningDetailsDeleteMutationHookResult = ReturnType<typeof useOrgKpiPlanningDetailsDeleteMutation>;
export type OrgKpiPlanningDetailsDeleteMutationResult = ApolloReactCommon.MutationResult<OrgKpiPlanningDetailsDeleteMutation>;
export type OrgKpiPlanningDetailsDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OrgKpiPlanningDetailsDeleteMutation, OrgKpiPlanningDetailsDeleteMutationVariables>;
export const KpiExerciseSubmenuSummaryDocument = gql`
    query KpiExerciseSubmenuSummary {
  KpiExerciseSubmenuSummary
}
    `;

/**
 * __useKpiExerciseSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useKpiExerciseSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiExerciseSubmenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiExerciseSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiExerciseSubmenuSummaryQuery, KpiExerciseSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiExerciseSubmenuSummaryQuery, KpiExerciseSubmenuSummaryQueryVariables>(KpiExerciseSubmenuSummaryDocument, baseOptions);
      }
export function useKpiExerciseSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiExerciseSubmenuSummaryQuery, KpiExerciseSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiExerciseSubmenuSummaryQuery, KpiExerciseSubmenuSummaryQueryVariables>(KpiExerciseSubmenuSummaryDocument, baseOptions);
        }
export type KpiExerciseSubmenuSummaryQueryHookResult = ReturnType<typeof useKpiExerciseSubmenuSummaryQuery>;
export type KpiExerciseSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useKpiExerciseSubmenuSummaryLazyQuery>;
export type KpiExerciseSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<KpiExerciseSubmenuSummaryQuery, KpiExerciseSubmenuSummaryQueryVariables>;
export const KpiExerciseSetupListingDocument = gql`
    query KpiExerciseSetupListing {
  KpiExerciseSetupListing {
    ID
    KpiExerciseID
    FromDate
    ToDate
    InUse
  }
}
    `;

/**
 * __useKpiExerciseSetupListingQuery__
 *
 * To run a query within a React component, call `useKpiExerciseSetupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiExerciseSetupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiExerciseSetupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiExerciseSetupListingQuery, KpiExerciseSetupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiExerciseSetupListingQuery, KpiExerciseSetupListingQueryVariables>(KpiExerciseSetupListingDocument, baseOptions);
      }
export function useKpiExerciseSetupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiExerciseSetupListingQuery, KpiExerciseSetupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiExerciseSetupListingQuery, KpiExerciseSetupListingQueryVariables>(KpiExerciseSetupListingDocument, baseOptions);
        }
export type KpiExerciseSetupListingQueryHookResult = ReturnType<typeof useKpiExerciseSetupListingQuery>;
export type KpiExerciseSetupListingLazyQueryHookResult = ReturnType<typeof useKpiExerciseSetupListingLazyQuery>;
export type KpiExerciseSetupListingQueryResult = ApolloReactCommon.QueryResult<KpiExerciseSetupListingQuery, KpiExerciseSetupListingQueryVariables>;
export const KpiExerciseSetupInfoDocument = gql`
    query KpiExerciseSetupInfo($KpiExerciseSetupID: String!) {
  KpiExerciseSetupInfo(KpiExerciseSetupID: $KpiExerciseSetupID) {
    ID
    KpiExerciseID
    FromDate
    ToDate
    AllowToReInitiate
    KpiExerciseSetupDetails {
      ID
      ReviewGroupID
      KpiRule
      KpiPredefinedTemplateID
      EditableWeightage
      FormItemRatingID
      WeightageType
      CompositionRules
      KpiWeightage
    }
  }
}
    `;

/**
 * __useKpiExerciseSetupInfoQuery__
 *
 * To run a query within a React component, call `useKpiExerciseSetupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiExerciseSetupInfoQuery({
 *   variables: {
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *   },
 * });
 */
export function useKpiExerciseSetupInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiExerciseSetupInfoQuery, KpiExerciseSetupInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiExerciseSetupInfoQuery, KpiExerciseSetupInfoQueryVariables>(KpiExerciseSetupInfoDocument, baseOptions);
      }
export function useKpiExerciseSetupInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiExerciseSetupInfoQuery, KpiExerciseSetupInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiExerciseSetupInfoQuery, KpiExerciseSetupInfoQueryVariables>(KpiExerciseSetupInfoDocument, baseOptions);
        }
export type KpiExerciseSetupInfoQueryHookResult = ReturnType<typeof useKpiExerciseSetupInfoQuery>;
export type KpiExerciseSetupInfoLazyQueryHookResult = ReturnType<typeof useKpiExerciseSetupInfoLazyQuery>;
export type KpiExerciseSetupInfoQueryResult = ApolloReactCommon.QueryResult<KpiExerciseSetupInfoQuery, KpiExerciseSetupInfoQueryVariables>;
export const IsKpiExerciseIdExistByCompanyDocument = gql`
    query IsKpiExerciseIDExistByCompany($KpiExerciseID: String!, $KpiExerciseSetupID: String) {
  IsKpiExerciseIDExistByCompany(KpiExerciseID: $KpiExerciseID, KpiExerciseSetupID: $KpiExerciseSetupID)
}
    `;

/**
 * __useIsKpiExerciseIdExistByCompanyQuery__
 *
 * To run a query within a React component, call `useIsKpiExerciseIdExistByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiExerciseIdExistByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiExerciseIdExistByCompanyQuery({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *   },
 * });
 */
export function useIsKpiExerciseIdExistByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiExerciseIdExistByCompanyQuery, IsKpiExerciseIdExistByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiExerciseIdExistByCompanyQuery, IsKpiExerciseIdExistByCompanyQueryVariables>(IsKpiExerciseIdExistByCompanyDocument, baseOptions);
      }
export function useIsKpiExerciseIdExistByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiExerciseIdExistByCompanyQuery, IsKpiExerciseIdExistByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiExerciseIdExistByCompanyQuery, IsKpiExerciseIdExistByCompanyQueryVariables>(IsKpiExerciseIdExistByCompanyDocument, baseOptions);
        }
export type IsKpiExerciseIdExistByCompanyQueryHookResult = ReturnType<typeof useIsKpiExerciseIdExistByCompanyQuery>;
export type IsKpiExerciseIdExistByCompanyLazyQueryHookResult = ReturnType<typeof useIsKpiExerciseIdExistByCompanyLazyQuery>;
export type IsKpiExerciseIdExistByCompanyQueryResult = ApolloReactCommon.QueryResult<IsKpiExerciseIdExistByCompanyQuery, IsKpiExerciseIdExistByCompanyQueryVariables>;
export const IsKpiExerciseDateRangeOverlappingByCompanyDocument = gql`
    query IsKpiExerciseDateRangeOverlappingByCompany($FromDate: DateTime!, $ToDate: DateTime!, $KpiExerciseSetupID: String) {
  IsKpiExerciseDateRangeOverlappingByCompany(FromDate: $FromDate, ToDate: $ToDate, KpiExerciseSetupID: $KpiExerciseSetupID)
}
    `;

/**
 * __useIsKpiExerciseDateRangeOverlappingByCompanyQuery__
 *
 * To run a query within a React component, call `useIsKpiExerciseDateRangeOverlappingByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiExerciseDateRangeOverlappingByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiExerciseDateRangeOverlappingByCompanyQuery({
 *   variables: {
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *   },
 * });
 */
export function useIsKpiExerciseDateRangeOverlappingByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiExerciseDateRangeOverlappingByCompanyQuery, IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiExerciseDateRangeOverlappingByCompanyQuery, IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables>(IsKpiExerciseDateRangeOverlappingByCompanyDocument, baseOptions);
      }
export function useIsKpiExerciseDateRangeOverlappingByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiExerciseDateRangeOverlappingByCompanyQuery, IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiExerciseDateRangeOverlappingByCompanyQuery, IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables>(IsKpiExerciseDateRangeOverlappingByCompanyDocument, baseOptions);
        }
export type IsKpiExerciseDateRangeOverlappingByCompanyQueryHookResult = ReturnType<typeof useIsKpiExerciseDateRangeOverlappingByCompanyQuery>;
export type IsKpiExerciseDateRangeOverlappingByCompanyLazyQueryHookResult = ReturnType<typeof useIsKpiExerciseDateRangeOverlappingByCompanyLazyQuery>;
export type IsKpiExerciseDateRangeOverlappingByCompanyQueryResult = ApolloReactCommon.QueryResult<IsKpiExerciseDateRangeOverlappingByCompanyQuery, IsKpiExerciseDateRangeOverlappingByCompanyQueryVariables>;
export const KpiExerciseSetupSubmitDocument = gql`
    mutation KpiExerciseSetupSubmit($input: KpiExerciseSetupInput!) {
  KpiExerciseSetupSubmit(input: $input)
}
    `;
export type KpiExerciseSetupSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseSetupSubmitMutation, KpiExerciseSetupSubmitMutationVariables>;

/**
 * __useKpiExerciseSetupSubmitMutation__
 *
 * To run a mutation, you first call `useKpiExerciseSetupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseSetupSubmitMutation, { data, loading, error }] = useKpiExerciseSetupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiExerciseSetupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseSetupSubmitMutation, KpiExerciseSetupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseSetupSubmitMutation, KpiExerciseSetupSubmitMutationVariables>(KpiExerciseSetupSubmitDocument, baseOptions);
      }
export type KpiExerciseSetupSubmitMutationHookResult = ReturnType<typeof useKpiExerciseSetupSubmitMutation>;
export type KpiExerciseSetupSubmitMutationResult = ApolloReactCommon.MutationResult<KpiExerciseSetupSubmitMutation>;
export type KpiExerciseSetupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseSetupSubmitMutation, KpiExerciseSetupSubmitMutationVariables>;
export const KpiExerciseSetupCopyDocument = gql`
    mutation KpiExerciseSetupCopy($KpiExerciseSetupID: String!, $KpiExerciseID: String!, $FromDate: DateTime!, $ToDate: DateTime!) {
  KpiExerciseSetupCopy(KpiExerciseSetupID: $KpiExerciseSetupID, KpiExerciseID: $KpiExerciseID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;
export type KpiExerciseSetupCopyMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseSetupCopyMutation, KpiExerciseSetupCopyMutationVariables>;

/**
 * __useKpiExerciseSetupCopyMutation__
 *
 * To run a mutation, you first call `useKpiExerciseSetupCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseSetupCopyMutation, { data, loading, error }] = useKpiExerciseSetupCopyMutation({
 *   variables: {
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useKpiExerciseSetupCopyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseSetupCopyMutation, KpiExerciseSetupCopyMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseSetupCopyMutation, KpiExerciseSetupCopyMutationVariables>(KpiExerciseSetupCopyDocument, baseOptions);
      }
export type KpiExerciseSetupCopyMutationHookResult = ReturnType<typeof useKpiExerciseSetupCopyMutation>;
export type KpiExerciseSetupCopyMutationResult = ApolloReactCommon.MutationResult<KpiExerciseSetupCopyMutation>;
export type KpiExerciseSetupCopyMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseSetupCopyMutation, KpiExerciseSetupCopyMutationVariables>;
export const KpiExerciseSetupGenerateDocument = gql`
    mutation KpiExerciseSetupGenerate($KpiExerciseSetupID: String!, $Overwrite: Boolean) {
  KpiExerciseSetupGenerate(KpiExerciseSetupID: $KpiExerciseSetupID, Overwrite: $Overwrite)
}
    `;
export type KpiExerciseSetupGenerateMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseSetupGenerateMutation, KpiExerciseSetupGenerateMutationVariables>;

/**
 * __useKpiExerciseSetupGenerateMutation__
 *
 * To run a mutation, you first call `useKpiExerciseSetupGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseSetupGenerateMutation, { data, loading, error }] = useKpiExerciseSetupGenerateMutation({
 *   variables: {
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *      Overwrite: // value for 'Overwrite'
 *   },
 * });
 */
export function useKpiExerciseSetupGenerateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseSetupGenerateMutation, KpiExerciseSetupGenerateMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseSetupGenerateMutation, KpiExerciseSetupGenerateMutationVariables>(KpiExerciseSetupGenerateDocument, baseOptions);
      }
export type KpiExerciseSetupGenerateMutationHookResult = ReturnType<typeof useKpiExerciseSetupGenerateMutation>;
export type KpiExerciseSetupGenerateMutationResult = ApolloReactCommon.MutationResult<KpiExerciseSetupGenerateMutation>;
export type KpiExerciseSetupGenerateMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseSetupGenerateMutation, KpiExerciseSetupGenerateMutationVariables>;
export const KpiExerciseSetupDeleteDocument = gql`
    mutation KpiExerciseSetupDelete($KpiExerciseSetupID: String!) {
  KpiExerciseSetupDelete(KpiExerciseSetupID: $KpiExerciseSetupID)
}
    `;
export type KpiExerciseSetupDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseSetupDeleteMutation, KpiExerciseSetupDeleteMutationVariables>;

/**
 * __useKpiExerciseSetupDeleteMutation__
 *
 * To run a mutation, you first call `useKpiExerciseSetupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSetupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseSetupDeleteMutation, { data, loading, error }] = useKpiExerciseSetupDeleteMutation({
 *   variables: {
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *   },
 * });
 */
export function useKpiExerciseSetupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseSetupDeleteMutation, KpiExerciseSetupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseSetupDeleteMutation, KpiExerciseSetupDeleteMutationVariables>(KpiExerciseSetupDeleteDocument, baseOptions);
      }
export type KpiExerciseSetupDeleteMutationHookResult = ReturnType<typeof useKpiExerciseSetupDeleteMutation>;
export type KpiExerciseSetupDeleteMutationResult = ApolloReactCommon.MutationResult<KpiExerciseSetupDeleteMutation>;
export type KpiExerciseSetupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseSetupDeleteMutation, KpiExerciseSetupDeleteMutationVariables>;
export const KpiExerciseListingDocument = gql`
    query KpiExerciseListing($KpiExerciseIDs: [String!]!, $OrgUnitIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $KpiFormStatus: [String!]) {
  KpiExerciseListing(KpiExerciseIDs: $KpiExerciseIDs, OrgUnitIDs: $OrgUnitIDs, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, KpiFormStatus: $KpiFormStatus) {
    EmployeeKpiExerciseID
    EmployeeSubmitDT
    ID
    KpiExercise {
      ID
      KpiExerciseSetupID
      KpiExerciseID
      AllowToReInitiate
      Status
      CreatedDT
      FromDate
      ToDate
      ApprovalDT
      ApprovalStatus
      ApprovalRemark
      Employee {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
        Documents {
          DocumentFile
        }
      }
      Superior {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
      }
    }
    ReviewGroup {
      ID
      Title
    }
    KpiRule
    FormItemRating {
      Title
      ID
    }
    KpiPredefinedTemplate {
      ID
      Status
      KpiWeightage
      Title
    }
    WeightageType
    EditableWeightage
    CompositionRules
    KpiWeightage
  }
}
    `;

/**
 * __useKpiExerciseListingQuery__
 *
 * To run a query within a React component, call `useKpiExerciseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiExerciseListingQuery({
 *   variables: {
 *      KpiExerciseIDs: // value for 'KpiExerciseIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      KpiFormStatus: // value for 'KpiFormStatus'
 *   },
 * });
 */
export function useKpiExerciseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiExerciseListingQuery, KpiExerciseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiExerciseListingQuery, KpiExerciseListingQueryVariables>(KpiExerciseListingDocument, baseOptions);
      }
export function useKpiExerciseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiExerciseListingQuery, KpiExerciseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiExerciseListingQuery, KpiExerciseListingQueryVariables>(KpiExerciseListingDocument, baseOptions);
        }
export type KpiExerciseListingQueryHookResult = ReturnType<typeof useKpiExerciseListingQuery>;
export type KpiExerciseListingLazyQueryHookResult = ReturnType<typeof useKpiExerciseListingLazyQuery>;
export type KpiExerciseListingQueryResult = ApolloReactCommon.QueryResult<KpiExerciseListingQuery, KpiExerciseListingQueryVariables>;
export const ViewEmployeeKpiDetailsDocument = gql`
    query ViewEmployeeKpiDetails($EmployeeKpiExerciseID: String!) {
  EmployeeKpiDetails(EmployeeKpiID: $EmployeeKpiExerciseID) {
    ID
    EmployeeKpiDetails
    KpiExerciseDetail {
      WeightageType
      EmployeeSubmitDT
      ID
      KpiExercise {
        ID
        KpiExerciseID
        AllowToReInitiate
        Status
        CreatedDT
        FromDate
        ToDate
        ApprovalDT
        ApprovalStatus
        ApprovalRemark
        Employee {
          EmployeeID
          EmployeeNo
          Contact {
            FullName
          }
          Documents {
            DocumentFile
          }
        }
        Superior {
          EmployeeID
          EmployeeNo
          Contact {
            FullName
          }
        }
      }
      ReviewGroup {
        ID
        Title
      }
      KpiRule
      FormItemRating {
        Title
        ID
      }
      KpiPredefinedTemplate {
        ID
        Status
        KpiWeightage
        Title
      }
      EditableWeightage
      CompositionRules
      KpiWeightage
    }
  }
}
    `;

/**
 * __useViewEmployeeKpiDetailsQuery__
 *
 * To run a query within a React component, call `useViewEmployeeKpiDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewEmployeeKpiDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewEmployeeKpiDetailsQuery({
 *   variables: {
 *      EmployeeKpiExerciseID: // value for 'EmployeeKpiExerciseID'
 *   },
 * });
 */
export function useViewEmployeeKpiDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewEmployeeKpiDetailsQuery, ViewEmployeeKpiDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewEmployeeKpiDetailsQuery, ViewEmployeeKpiDetailsQueryVariables>(ViewEmployeeKpiDetailsDocument, baseOptions);
      }
export function useViewEmployeeKpiDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewEmployeeKpiDetailsQuery, ViewEmployeeKpiDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewEmployeeKpiDetailsQuery, ViewEmployeeKpiDetailsQueryVariables>(ViewEmployeeKpiDetailsDocument, baseOptions);
        }
export type ViewEmployeeKpiDetailsQueryHookResult = ReturnType<typeof useViewEmployeeKpiDetailsQuery>;
export type ViewEmployeeKpiDetailsLazyQueryHookResult = ReturnType<typeof useViewEmployeeKpiDetailsLazyQuery>;
export type ViewEmployeeKpiDetailsQueryResult = ApolloReactCommon.QueryResult<ViewEmployeeKpiDetailsQuery, ViewEmployeeKpiDetailsQueryVariables>;
export const KpiExerciseChangeSuperiorDocument = gql`
    mutation KpiExerciseChangeSuperior($KpiExerciseID: String!, $SuperiorID: String!) {
  KpiExerciseChangeSuperior(KpiExerciseID: $KpiExerciseID, SuperiorID: $SuperiorID)
}
    `;
export type KpiExerciseChangeSuperiorMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseChangeSuperiorMutation, KpiExerciseChangeSuperiorMutationVariables>;

/**
 * __useKpiExerciseChangeSuperiorMutation__
 *
 * To run a mutation, you first call `useKpiExerciseChangeSuperiorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseChangeSuperiorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseChangeSuperiorMutation, { data, loading, error }] = useKpiExerciseChangeSuperiorMutation({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      SuperiorID: // value for 'SuperiorID'
 *   },
 * });
 */
export function useKpiExerciseChangeSuperiorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseChangeSuperiorMutation, KpiExerciseChangeSuperiorMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseChangeSuperiorMutation, KpiExerciseChangeSuperiorMutationVariables>(KpiExerciseChangeSuperiorDocument, baseOptions);
      }
export type KpiExerciseChangeSuperiorMutationHookResult = ReturnType<typeof useKpiExerciseChangeSuperiorMutation>;
export type KpiExerciseChangeSuperiorMutationResult = ApolloReactCommon.MutationResult<KpiExerciseChangeSuperiorMutation>;
export type KpiExerciseChangeSuperiorMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseChangeSuperiorMutation, KpiExerciseChangeSuperiorMutationVariables>;
export const KpiExerciseSubmitDocument = gql`
    mutation KpiExerciseSubmit($input: KpiExerciseDetailsEditInput!) {
  KpiExerciseSubmit(input: $input)
}
    `;
export type KpiExerciseSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseSubmitMutation, KpiExerciseSubmitMutationVariables>;

/**
 * __useKpiExerciseSubmitMutation__
 *
 * To run a mutation, you first call `useKpiExerciseSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseSubmitMutation, { data, loading, error }] = useKpiExerciseSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiExerciseSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseSubmitMutation, KpiExerciseSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseSubmitMutation, KpiExerciseSubmitMutationVariables>(KpiExerciseSubmitDocument, baseOptions);
      }
export type KpiExerciseSubmitMutationHookResult = ReturnType<typeof useKpiExerciseSubmitMutation>;
export type KpiExerciseSubmitMutationResult = ApolloReactCommon.MutationResult<KpiExerciseSubmitMutation>;
export type KpiExerciseSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseSubmitMutation, KpiExerciseSubmitMutationVariables>;
export const KpiExerciseDeleteDocument = gql`
    mutation KpiExerciseDelete($KpiExerciseID: String!) {
  KpiExerciseDelete(KpiExerciseID: $KpiExerciseID)
}
    `;
export type KpiExerciseDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseDeleteMutation, KpiExerciseDeleteMutationVariables>;

/**
 * __useKpiExerciseDeleteMutation__
 *
 * To run a mutation, you first call `useKpiExerciseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseDeleteMutation, { data, loading, error }] = useKpiExerciseDeleteMutation({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *   },
 * });
 */
export function useKpiExerciseDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseDeleteMutation, KpiExerciseDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseDeleteMutation, KpiExerciseDeleteMutationVariables>(KpiExerciseDeleteDocument, baseOptions);
      }
export type KpiExerciseDeleteMutationHookResult = ReturnType<typeof useKpiExerciseDeleteMutation>;
export type KpiExerciseDeleteMutationResult = ApolloReactCommon.MutationResult<KpiExerciseDeleteMutation>;
export type KpiExerciseDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseDeleteMutation, KpiExerciseDeleteMutationVariables>;
export const KpiExerciseInitiateDraftDocument = gql`
    mutation KpiExerciseInitiateDraft($KpiExerciseIDs: [String!]!) {
  KpiExerciseInitiateDraft(KpiExerciseIDs: $KpiExerciseIDs)
}
    `;
export type KpiExerciseInitiateDraftMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseInitiateDraftMutation, KpiExerciseInitiateDraftMutationVariables>;

/**
 * __useKpiExerciseInitiateDraftMutation__
 *
 * To run a mutation, you first call `useKpiExerciseInitiateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseInitiateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseInitiateDraftMutation, { data, loading, error }] = useKpiExerciseInitiateDraftMutation({
 *   variables: {
 *      KpiExerciseIDs: // value for 'KpiExerciseIDs'
 *   },
 * });
 */
export function useKpiExerciseInitiateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseInitiateDraftMutation, KpiExerciseInitiateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseInitiateDraftMutation, KpiExerciseInitiateDraftMutationVariables>(KpiExerciseInitiateDraftDocument, baseOptions);
      }
export type KpiExerciseInitiateDraftMutationHookResult = ReturnType<typeof useKpiExerciseInitiateDraftMutation>;
export type KpiExerciseInitiateDraftMutationResult = ApolloReactCommon.MutationResult<KpiExerciseInitiateDraftMutation>;
export type KpiExerciseInitiateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseInitiateDraftMutation, KpiExerciseInitiateDraftMutationVariables>;
export const KpiExerciseChangeStatusDocument = gql`
    mutation KpiExerciseChangeStatus($KpiExerciseID: String!, $StatusToChange: Status!, $Comment: String) {
  KpiExerciseChangeStatus(KpiExerciseID: $KpiExerciseID, StatusToChange: $StatusToChange, Comment: $Comment)
}
    `;
export type KpiExerciseChangeStatusMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseChangeStatusMutation, KpiExerciseChangeStatusMutationVariables>;

/**
 * __useKpiExerciseChangeStatusMutation__
 *
 * To run a mutation, you first call `useKpiExerciseChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseChangeStatusMutation, { data, loading, error }] = useKpiExerciseChangeStatusMutation({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      StatusToChange: // value for 'StatusToChange'
 *      Comment: // value for 'Comment'
 *   },
 * });
 */
export function useKpiExerciseChangeStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseChangeStatusMutation, KpiExerciseChangeStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseChangeStatusMutation, KpiExerciseChangeStatusMutationVariables>(KpiExerciseChangeStatusDocument, baseOptions);
      }
export type KpiExerciseChangeStatusMutationHookResult = ReturnType<typeof useKpiExerciseChangeStatusMutation>;
export type KpiExerciseChangeStatusMutationResult = ApolloReactCommon.MutationResult<KpiExerciseChangeStatusMutation>;
export type KpiExerciseChangeStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseChangeStatusMutation, KpiExerciseChangeStatusMutationVariables>;
export const KpiExerciseCopyDocument = gql`
    mutation KpiExerciseCopy($KpiExerciseID: String!, $SuperiorID: String!) {
  KpiExerciseCopy(KpiExerciseID: $KpiExerciseID, SuperiorID: $SuperiorID)
}
    `;
export type KpiExerciseCopyMutationFn = ApolloReactCommon.MutationFunction<KpiExerciseCopyMutation, KpiExerciseCopyMutationVariables>;

/**
 * __useKpiExerciseCopyMutation__
 *
 * To run a mutation, you first call `useKpiExerciseCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiExerciseCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiExerciseCopyMutation, { data, loading, error }] = useKpiExerciseCopyMutation({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      SuperiorID: // value for 'SuperiorID'
 *   },
 * });
 */
export function useKpiExerciseCopyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiExerciseCopyMutation, KpiExerciseCopyMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiExerciseCopyMutation, KpiExerciseCopyMutationVariables>(KpiExerciseCopyDocument, baseOptions);
      }
export type KpiExerciseCopyMutationHookResult = ReturnType<typeof useKpiExerciseCopyMutation>;
export type KpiExerciseCopyMutationResult = ApolloReactCommon.MutationResult<KpiExerciseCopyMutation>;
export type KpiExerciseCopyMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiExerciseCopyMutation, KpiExerciseCopyMutationVariables>;
export const PerformanceEmployeeListingDocument = gql`
    query PerformanceEmployeeListing($RequestType: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitIDs: [String!], $offset: Float, $limit: Float) {
  PerformanceEmployeeListing(RequestType: $RequestType, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitIDs, offset: $offset, limit: $limit) {
    EmployeeID
    ContactID
    EmployeeNo
    Contact {
      FullName
    }
    Company {
      CompanyName
    }
    Documents {
      DocumentFile
    }
    TotalKpiRecord
    TotalReviewRecord
  }
}
    `;

/**
 * __usePerformanceEmployeeListingQuery__
 *
 * To run a query within a React component, call `usePerformanceEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceEmployeeListingQuery({
 *   variables: {
 *      RequestType: // value for 'RequestType'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePerformanceEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceEmployeeListingQuery, PerformanceEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceEmployeeListingQuery, PerformanceEmployeeListingQueryVariables>(PerformanceEmployeeListingDocument, baseOptions);
      }
export function usePerformanceEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceEmployeeListingQuery, PerformanceEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceEmployeeListingQuery, PerformanceEmployeeListingQueryVariables>(PerformanceEmployeeListingDocument, baseOptions);
        }
export type PerformanceEmployeeListingQueryHookResult = ReturnType<typeof usePerformanceEmployeeListingQuery>;
export type PerformanceEmployeeListingLazyQueryHookResult = ReturnType<typeof usePerformanceEmployeeListingLazyQuery>;
export type PerformanceEmployeeListingQueryResult = ApolloReactCommon.QueryResult<PerformanceEmployeeListingQuery, PerformanceEmployeeListingQueryVariables>;
export const ReviewExerciseSubmenuSummaryDocument = gql`
    query ReviewExerciseSubmenuSummary {
  ReviewExerciseSubmenuSummary
}
    `;

/**
 * __useReviewExerciseSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useReviewExerciseSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewExerciseSubmenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewExerciseSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewExerciseSubmenuSummaryQuery, ReviewExerciseSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewExerciseSubmenuSummaryQuery, ReviewExerciseSubmenuSummaryQueryVariables>(ReviewExerciseSubmenuSummaryDocument, baseOptions);
      }
export function useReviewExerciseSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewExerciseSubmenuSummaryQuery, ReviewExerciseSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewExerciseSubmenuSummaryQuery, ReviewExerciseSubmenuSummaryQueryVariables>(ReviewExerciseSubmenuSummaryDocument, baseOptions);
        }
export type ReviewExerciseSubmenuSummaryQueryHookResult = ReturnType<typeof useReviewExerciseSubmenuSummaryQuery>;
export type ReviewExerciseSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useReviewExerciseSubmenuSummaryLazyQuery>;
export type ReviewExerciseSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<ReviewExerciseSubmenuSummaryQuery, ReviewExerciseSubmenuSummaryQueryVariables>;
export const ReviewExerciseSetupListingDocument = gql`
    query ReviewExerciseSetupListing {
  ReviewExerciseSetupListing {
    ID
    ReviewExerciseID
    FromDate
    ToDate
    InUse
  }
}
    `;

/**
 * __useReviewExerciseSetupListingQuery__
 *
 * To run a query within a React component, call `useReviewExerciseSetupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewExerciseSetupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewExerciseSetupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewExerciseSetupListingQuery, ReviewExerciseSetupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewExerciseSetupListingQuery, ReviewExerciseSetupListingQueryVariables>(ReviewExerciseSetupListingDocument, baseOptions);
      }
export function useReviewExerciseSetupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewExerciseSetupListingQuery, ReviewExerciseSetupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewExerciseSetupListingQuery, ReviewExerciseSetupListingQueryVariables>(ReviewExerciseSetupListingDocument, baseOptions);
        }
export type ReviewExerciseSetupListingQueryHookResult = ReturnType<typeof useReviewExerciseSetupListingQuery>;
export type ReviewExerciseSetupListingLazyQueryHookResult = ReturnType<typeof useReviewExerciseSetupListingLazyQuery>;
export type ReviewExerciseSetupListingQueryResult = ApolloReactCommon.QueryResult<ReviewExerciseSetupListingQuery, ReviewExerciseSetupListingQueryVariables>;
export const ReviewExerciseSetupInfoDocument = gql`
    query ReviewExerciseSetupInfo($ReviewExerciseSetupID: String!) {
  ReviewExerciseSetupInfo(ReviewExerciseSetupID: $ReviewExerciseSetupID) {
    ID
    ReviewExerciseID
    FromDate
    ToDate
    ReviewType
    KpiAsAtDate
    ExcludeResigning
    AllowToReInitiate
    GenerateForCurrentReporting
    UseRecentKpi
    AdhocSetupDetails
    ReviewExerciseSetupDetails {
      ID
      ReviewGroupID
      ReviewGroupName
      ConfirmDueFrom
      ConfirmDueTo
      SuperiorSelfReviewFormTemplateID
      PeerReviewFormTemplateID
      SubordinateReviewFormTemplateID
      ReviewFormTemplateName
    }
  }
}
    `;

/**
 * __useReviewExerciseSetupInfoQuery__
 *
 * To run a query within a React component, call `useReviewExerciseSetupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewExerciseSetupInfoQuery({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *   },
 * });
 */
export function useReviewExerciseSetupInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewExerciseSetupInfoQuery, ReviewExerciseSetupInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewExerciseSetupInfoQuery, ReviewExerciseSetupInfoQueryVariables>(ReviewExerciseSetupInfoDocument, baseOptions);
      }
export function useReviewExerciseSetupInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewExerciseSetupInfoQuery, ReviewExerciseSetupInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewExerciseSetupInfoQuery, ReviewExerciseSetupInfoQueryVariables>(ReviewExerciseSetupInfoDocument, baseOptions);
        }
export type ReviewExerciseSetupInfoQueryHookResult = ReturnType<typeof useReviewExerciseSetupInfoQuery>;
export type ReviewExerciseSetupInfoLazyQueryHookResult = ReturnType<typeof useReviewExerciseSetupInfoLazyQuery>;
export type ReviewExerciseSetupInfoQueryResult = ApolloReactCommon.QueryResult<ReviewExerciseSetupInfoQuery, ReviewExerciseSetupInfoQueryVariables>;
export const IsReviewExerciseIdExistDocument = gql`
    query IsReviewExerciseIDExist($ReviewExerciseID: String!, $ReviewExerciseSetupID: String) {
  IsReviewExerciseIDExist(ReviewExerciseID: $ReviewExerciseID, ReviewExerciseSetupID: $ReviewExerciseSetupID)
}
    `;

/**
 * __useIsReviewExerciseIdExistQuery__
 *
 * To run a query within a React component, call `useIsReviewExerciseIdExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewExerciseIdExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewExerciseIdExistQuery({
 *   variables: {
 *      ReviewExerciseID: // value for 'ReviewExerciseID'
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *   },
 * });
 */
export function useIsReviewExerciseIdExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewExerciseIdExistQuery, IsReviewExerciseIdExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewExerciseIdExistQuery, IsReviewExerciseIdExistQueryVariables>(IsReviewExerciseIdExistDocument, baseOptions);
      }
export function useIsReviewExerciseIdExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewExerciseIdExistQuery, IsReviewExerciseIdExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewExerciseIdExistQuery, IsReviewExerciseIdExistQueryVariables>(IsReviewExerciseIdExistDocument, baseOptions);
        }
export type IsReviewExerciseIdExistQueryHookResult = ReturnType<typeof useIsReviewExerciseIdExistQuery>;
export type IsReviewExerciseIdExistLazyQueryHookResult = ReturnType<typeof useIsReviewExerciseIdExistLazyQuery>;
export type IsReviewExerciseIdExistQueryResult = ApolloReactCommon.QueryResult<IsReviewExerciseIdExistQuery, IsReviewExerciseIdExistQueryVariables>;
export const PeerRegistrationListingDocument = gql`
    query PeerRegistrationListing($ReviewExerciseSetupID: String!) {
  PeerRegistrationListing(ReviewExerciseSetupID: $ReviewExerciseSetupID) {
    ID
    ReviewExerciseSetupID
    EmployeeID
    Employee {
      EmployeeNo
      EmploymentType
      OrgUnitID
      OrgUnit {
        Description
      }
      Status
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
    }
    PeerIDs
    PeerInfoListing {
      EmployeeID
      EmployeeNo
      EmploymentType
      OrgUnitID
      OrgUnit {
        Description
      }
      Status
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
    }
  }
}
    `;

/**
 * __usePeerRegistrationListingQuery__
 *
 * To run a query within a React component, call `usePeerRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerRegistrationListingQuery({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *   },
 * });
 */
export function usePeerRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PeerRegistrationListingQuery, PeerRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PeerRegistrationListingQuery, PeerRegistrationListingQueryVariables>(PeerRegistrationListingDocument, baseOptions);
      }
export function usePeerRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeerRegistrationListingQuery, PeerRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PeerRegistrationListingQuery, PeerRegistrationListingQueryVariables>(PeerRegistrationListingDocument, baseOptions);
        }
export type PeerRegistrationListingQueryHookResult = ReturnType<typeof usePeerRegistrationListingQuery>;
export type PeerRegistrationListingLazyQueryHookResult = ReturnType<typeof usePeerRegistrationListingLazyQuery>;
export type PeerRegistrationListingQueryResult = ApolloReactCommon.QueryResult<PeerRegistrationListingQuery, PeerRegistrationListingQueryVariables>;
export const PeerRegistrationSubmitDocument = gql`
    mutation PeerRegistrationSubmit($ReviewExerciseSetupID: String!, $input: [ReviewPeerRegistrationInput!]) {
  PeerRegistrationSubmit(ReviewExerciseSetupID: $ReviewExerciseSetupID, input: $input)
}
    `;
export type PeerRegistrationSubmitMutationFn = ApolloReactCommon.MutationFunction<PeerRegistrationSubmitMutation, PeerRegistrationSubmitMutationVariables>;

/**
 * __usePeerRegistrationSubmitMutation__
 *
 * To run a mutation, you first call `usePeerRegistrationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeerRegistrationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [peerRegistrationSubmitMutation, { data, loading, error }] = usePeerRegistrationSubmitMutation({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeerRegistrationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PeerRegistrationSubmitMutation, PeerRegistrationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<PeerRegistrationSubmitMutation, PeerRegistrationSubmitMutationVariables>(PeerRegistrationSubmitDocument, baseOptions);
      }
export type PeerRegistrationSubmitMutationHookResult = ReturnType<typeof usePeerRegistrationSubmitMutation>;
export type PeerRegistrationSubmitMutationResult = ApolloReactCommon.MutationResult<PeerRegistrationSubmitMutation>;
export type PeerRegistrationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<PeerRegistrationSubmitMutation, PeerRegistrationSubmitMutationVariables>;
export const ReviewExerciseSetupSubmitDocument = gql`
    mutation ReviewExerciseSetupSubmit($input: ReviewExerciseSetupInput!) {
  ReviewExerciseSetupSubmit(input: $input)
}
    `;
export type ReviewExerciseSetupSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewExerciseSetupSubmitMutation, ReviewExerciseSetupSubmitMutationVariables>;

/**
 * __useReviewExerciseSetupSubmitMutation__
 *
 * To run a mutation, you first call `useReviewExerciseSetupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewExerciseSetupSubmitMutation, { data, loading, error }] = useReviewExerciseSetupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewExerciseSetupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewExerciseSetupSubmitMutation, ReviewExerciseSetupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewExerciseSetupSubmitMutation, ReviewExerciseSetupSubmitMutationVariables>(ReviewExerciseSetupSubmitDocument, baseOptions);
      }
export type ReviewExerciseSetupSubmitMutationHookResult = ReturnType<typeof useReviewExerciseSetupSubmitMutation>;
export type ReviewExerciseSetupSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewExerciseSetupSubmitMutation>;
export type ReviewExerciseSetupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewExerciseSetupSubmitMutation, ReviewExerciseSetupSubmitMutationVariables>;
export const ReviewExerciseSetupCopyDocument = gql`
    mutation ReviewExerciseSetupCopy($ReviewExerciseSetupID: String!, $ReviewExerciseID: String!, $FromDate: DateTime!, $ToDate: DateTime!, $KpiAsAtDate: DateTime!) {
  ReviewExerciseSetupCopy(ReviewExerciseSetupID: $ReviewExerciseSetupID, ReviewExerciseID: $ReviewExerciseID, FromDate: $FromDate, ToDate: $ToDate, KpiAsAtDate: $KpiAsAtDate)
}
    `;
export type ReviewExerciseSetupCopyMutationFn = ApolloReactCommon.MutationFunction<ReviewExerciseSetupCopyMutation, ReviewExerciseSetupCopyMutationVariables>;

/**
 * __useReviewExerciseSetupCopyMutation__
 *
 * To run a mutation, you first call `useReviewExerciseSetupCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewExerciseSetupCopyMutation, { data, loading, error }] = useReviewExerciseSetupCopyMutation({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *      ReviewExerciseID: // value for 'ReviewExerciseID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      KpiAsAtDate: // value for 'KpiAsAtDate'
 *   },
 * });
 */
export function useReviewExerciseSetupCopyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewExerciseSetupCopyMutation, ReviewExerciseSetupCopyMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewExerciseSetupCopyMutation, ReviewExerciseSetupCopyMutationVariables>(ReviewExerciseSetupCopyDocument, baseOptions);
      }
export type ReviewExerciseSetupCopyMutationHookResult = ReturnType<typeof useReviewExerciseSetupCopyMutation>;
export type ReviewExerciseSetupCopyMutationResult = ApolloReactCommon.MutationResult<ReviewExerciseSetupCopyMutation>;
export type ReviewExerciseSetupCopyMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewExerciseSetupCopyMutation, ReviewExerciseSetupCopyMutationVariables>;
export const ReviewExerciseSetupGenerateDocument = gql`
    mutation ReviewExerciseSetupGenerate($ReviewExerciseSetupID: String!, $Overwrite: Boolean) {
  ReviewExerciseSetupGenerate(ReviewExerciseSetupID: $ReviewExerciseSetupID, Overwrite: $Overwrite)
}
    `;
export type ReviewExerciseSetupGenerateMutationFn = ApolloReactCommon.MutationFunction<ReviewExerciseSetupGenerateMutation, ReviewExerciseSetupGenerateMutationVariables>;

/**
 * __useReviewExerciseSetupGenerateMutation__
 *
 * To run a mutation, you first call `useReviewExerciseSetupGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewExerciseSetupGenerateMutation, { data, loading, error }] = useReviewExerciseSetupGenerateMutation({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *      Overwrite: // value for 'Overwrite'
 *   },
 * });
 */
export function useReviewExerciseSetupGenerateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewExerciseSetupGenerateMutation, ReviewExerciseSetupGenerateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewExerciseSetupGenerateMutation, ReviewExerciseSetupGenerateMutationVariables>(ReviewExerciseSetupGenerateDocument, baseOptions);
      }
export type ReviewExerciseSetupGenerateMutationHookResult = ReturnType<typeof useReviewExerciseSetupGenerateMutation>;
export type ReviewExerciseSetupGenerateMutationResult = ApolloReactCommon.MutationResult<ReviewExerciseSetupGenerateMutation>;
export type ReviewExerciseSetupGenerateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewExerciseSetupGenerateMutation, ReviewExerciseSetupGenerateMutationVariables>;
export const ReviewExerciseSetupDeleteDocument = gql`
    mutation ReviewExerciseSetupDelete($ReviewExerciseSetupID: String!) {
  ReviewExerciseSetupDelete(ReviewExerciseSetupID: $ReviewExerciseSetupID)
}
    `;
export type ReviewExerciseSetupDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewExerciseSetupDeleteMutation, ReviewExerciseSetupDeleteMutationVariables>;

/**
 * __useReviewExerciseSetupDeleteMutation__
 *
 * To run a mutation, you first call `useReviewExerciseSetupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseSetupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewExerciseSetupDeleteMutation, { data, loading, error }] = useReviewExerciseSetupDeleteMutation({
 *   variables: {
 *      ReviewExerciseSetupID: // value for 'ReviewExerciseSetupID'
 *   },
 * });
 */
export function useReviewExerciseSetupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewExerciseSetupDeleteMutation, ReviewExerciseSetupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewExerciseSetupDeleteMutation, ReviewExerciseSetupDeleteMutationVariables>(ReviewExerciseSetupDeleteDocument, baseOptions);
      }
export type ReviewExerciseSetupDeleteMutationHookResult = ReturnType<typeof useReviewExerciseSetupDeleteMutation>;
export type ReviewExerciseSetupDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewExerciseSetupDeleteMutation>;
export type ReviewExerciseSetupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewExerciseSetupDeleteMutation, ReviewExerciseSetupDeleteMutationVariables>;
export const ReviewExerciseListingDocument = gql`
    query ReviewExerciseListing($ReviewExerciseIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitIDs: [String!], $ReviewFormType: [AppraiseeType!], $ReviewFormStatus: [String!]) {
  ReviewExerciseListing(ReviewExerciseIDs: $ReviewExerciseIDs, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitIDs: $OrgUnitIDs, ReviewFormType: $ReviewFormType, ReviewFormStatus: $ReviewFormStatus) {
    ReviewExerciseSetupID
    ReviewExerciseName
    ReviewExercise {
      ID
      EmployeeID
      Employee {
        EmployeeNo
        Documents {
          DocumentFile
        }
        Contact {
          FullName
        }
      }
      ReviewGroupID
      ReviewGroupName
      ReviewExerciseSetup {
        AllowToReInitiate
        ReviewType
      }
      EmployeeReview {
        ID
        EmployeeRating
        ReviewerRating
        OverallGrade
        ReviewType
        ReviewFormType
        ReviewerID
        Reviewer {
          EmployeeNo
          Documents {
            DocumentFile
          }
          Contact {
            FullName
          }
        }
        FromDate
        ToDate
        ReviewFormTemplateID
        ReviewFormTemplateName
        Status
        ReviewProcessLog
      }
    }
  }
}
    `;

/**
 * __useReviewExerciseListingQuery__
 *
 * To run a query within a React component, call `useReviewExerciseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewExerciseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewExerciseListingQuery({
 *   variables: {
 *      ReviewExerciseIDs: // value for 'ReviewExerciseIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      ReviewFormType: // value for 'ReviewFormType'
 *      ReviewFormStatus: // value for 'ReviewFormStatus'
 *   },
 * });
 */
export function useReviewExerciseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewExerciseListingQuery, ReviewExerciseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewExerciseListingQuery, ReviewExerciseListingQueryVariables>(ReviewExerciseListingDocument, baseOptions);
      }
export function useReviewExerciseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewExerciseListingQuery, ReviewExerciseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewExerciseListingQuery, ReviewExerciseListingQueryVariables>(ReviewExerciseListingDocument, baseOptions);
        }
export type ReviewExerciseListingQueryHookResult = ReturnType<typeof useReviewExerciseListingQuery>;
export type ReviewExerciseListingLazyQueryHookResult = ReturnType<typeof useReviewExerciseListingLazyQuery>;
export type ReviewExerciseListingQueryResult = ApolloReactCommon.QueryResult<ReviewExerciseListingQuery, ReviewExerciseListingQueryVariables>;
export const GetEmployeeReviewFormDocument = gql`
    query GetEmployeeReviewForm($EmployeeReviewID: String!) {
  GetEmployeeReviewForm(EmployeeReviewID: $EmployeeReviewID) {
    ID
    Status
    ReviewExerciseName
    HasNextLevelApproval
    IsSendBackToInitaite
    ReviewGroupName
    ReviewFormType
    WeightageType
    ReviewFormTemplateName
    FromDate
    ToDate
    EmployeeRating
    ReviewerRating
    OverallGrade
    ReviewGradingTable
    ReviewProcessLog
    Reviewer {
      EmployeeNo
      Contact {
        FullName
      }
    }
    EmployeeID
    Employee {
      ContactID
      RoleID
      RoleName
      JobGradeID
      EmployeeNo
      Documents {
        DocumentFile
      }
      Contact {
        FullName
      }
      Designation {
        JobDesignationID
        Name
      }
      EmployeeCurrentSalary
    }
    ReviewDate
    EmployeeReviewSection {
      ID
      EmployeeViewable
      Recommendation
      Weightage
      ReviewSection {
        Title
        Description
        Type
        Sequence
        AllowItemEdited
        ItemEditedByEmployee
        ItemEditedBySuperior
        AllowItemZero
      }
      EmployeeReviewSectionItem {
        ID
        KpiCategoryID
        KPI
        Weightage
        ItemWeightage
        ItemDetails
        EmployeeReview
        ReviewerReview
        Sequence
        ReviewConversationLog {
          ID
          EmployeeID
          CreatedDT
          Employee {
            EmployeeNo
            Contact {
              FullName
            }
            Documents {
              DocumentFile
            }
          }
          CommentBy
          Comment
          Documents {
            DocumentID
            DocumentFile
          }
          VoiceDocument {
            DocumentID
            DocumentFile
          }
        }
      }
    }
    ReviewFormTemplate {
      AllowSectionEdited
      SectionEditedByEmployee
      SectionEditedBySuperior
      AllowSectionZero
    }
  }
}
    `;

/**
 * __useGetEmployeeReviewFormQuery__
 *
 * To run a query within a React component, call `useGetEmployeeReviewFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeReviewFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeReviewFormQuery({
 *   variables: {
 *      EmployeeReviewID: // value for 'EmployeeReviewID'
 *   },
 * });
 */
export function useGetEmployeeReviewFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeReviewFormQuery, GetEmployeeReviewFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeReviewFormQuery, GetEmployeeReviewFormQueryVariables>(GetEmployeeReviewFormDocument, baseOptions);
      }
export function useGetEmployeeReviewFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeReviewFormQuery, GetEmployeeReviewFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeReviewFormQuery, GetEmployeeReviewFormQueryVariables>(GetEmployeeReviewFormDocument, baseOptions);
        }
export type GetEmployeeReviewFormQueryHookResult = ReturnType<typeof useGetEmployeeReviewFormQuery>;
export type GetEmployeeReviewFormLazyQueryHookResult = ReturnType<typeof useGetEmployeeReviewFormLazyQuery>;
export type GetEmployeeReviewFormQueryResult = ApolloReactCommon.QueryResult<GetEmployeeReviewFormQuery, GetEmployeeReviewFormQueryVariables>;
export const IsSuperiorAlreadyExistDocument = gql`
    query IsSuperiorAlreadyExist($ReviewExerciseID: String, $KpiExerciseSetupID: String, $EmployeeID: String!, $NewSuperiorID: String!) {
  IsSuperiorAlreadyExist(ReviewExerciseID: $ReviewExerciseID, KpiExerciseSetupID: $KpiExerciseSetupID, EmployeeID: $EmployeeID, NewSuperiorID: $NewSuperiorID)
}
    `;

/**
 * __useIsSuperiorAlreadyExistQuery__
 *
 * To run a query within a React component, call `useIsSuperiorAlreadyExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSuperiorAlreadyExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSuperiorAlreadyExistQuery({
 *   variables: {
 *      ReviewExerciseID: // value for 'ReviewExerciseID'
 *      KpiExerciseSetupID: // value for 'KpiExerciseSetupID'
 *      EmployeeID: // value for 'EmployeeID'
 *      NewSuperiorID: // value for 'NewSuperiorID'
 *   },
 * });
 */
export function useIsSuperiorAlreadyExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsSuperiorAlreadyExistQuery, IsSuperiorAlreadyExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsSuperiorAlreadyExistQuery, IsSuperiorAlreadyExistQueryVariables>(IsSuperiorAlreadyExistDocument, baseOptions);
      }
export function useIsSuperiorAlreadyExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsSuperiorAlreadyExistQuery, IsSuperiorAlreadyExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsSuperiorAlreadyExistQuery, IsSuperiorAlreadyExistQueryVariables>(IsSuperiorAlreadyExistDocument, baseOptions);
        }
export type IsSuperiorAlreadyExistQueryHookResult = ReturnType<typeof useIsSuperiorAlreadyExistQuery>;
export type IsSuperiorAlreadyExistLazyQueryHookResult = ReturnType<typeof useIsSuperiorAlreadyExistLazyQuery>;
export type IsSuperiorAlreadyExistQueryResult = ApolloReactCommon.QueryResult<IsSuperiorAlreadyExistQuery, IsSuperiorAlreadyExistQueryVariables>;
export const EmployeeReviewUpdateSetupDocument = gql`
    mutation EmployeeReviewUpdateSetup($input: EmployeeReviewSetupInput!) {
  EmployeeReviewUpdateSetup(input: $input)
}
    `;
export type EmployeeReviewUpdateSetupMutationFn = ApolloReactCommon.MutationFunction<EmployeeReviewUpdateSetupMutation, EmployeeReviewUpdateSetupMutationVariables>;

/**
 * __useEmployeeReviewUpdateSetupMutation__
 *
 * To run a mutation, you first call `useEmployeeReviewUpdateSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeReviewUpdateSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeReviewUpdateSetupMutation, { data, loading, error }] = useEmployeeReviewUpdateSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeReviewUpdateSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeReviewUpdateSetupMutation, EmployeeReviewUpdateSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeReviewUpdateSetupMutation, EmployeeReviewUpdateSetupMutationVariables>(EmployeeReviewUpdateSetupDocument, baseOptions);
      }
export type EmployeeReviewUpdateSetupMutationHookResult = ReturnType<typeof useEmployeeReviewUpdateSetupMutation>;
export type EmployeeReviewUpdateSetupMutationResult = ApolloReactCommon.MutationResult<EmployeeReviewUpdateSetupMutation>;
export type EmployeeReviewUpdateSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeReviewUpdateSetupMutation, EmployeeReviewUpdateSetupMutationVariables>;
export const CopyEmployeeReviewExerciseDocument = gql`
    mutation CopyEmployeeReviewExercise($EmployeeReviewID: String!, $ReviewerID: String!, $FromDate: DateTime!, $ToDate: DateTime!) {
  CopyEmployeeReviewExercise(EmployeeReviewID: $EmployeeReviewID, ReviewerID: $ReviewerID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;
export type CopyEmployeeReviewExerciseMutationFn = ApolloReactCommon.MutationFunction<CopyEmployeeReviewExerciseMutation, CopyEmployeeReviewExerciseMutationVariables>;

/**
 * __useCopyEmployeeReviewExerciseMutation__
 *
 * To run a mutation, you first call `useCopyEmployeeReviewExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEmployeeReviewExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEmployeeReviewExerciseMutation, { data, loading, error }] = useCopyEmployeeReviewExerciseMutation({
 *   variables: {
 *      EmployeeReviewID: // value for 'EmployeeReviewID'
 *      ReviewerID: // value for 'ReviewerID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useCopyEmployeeReviewExerciseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyEmployeeReviewExerciseMutation, CopyEmployeeReviewExerciseMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyEmployeeReviewExerciseMutation, CopyEmployeeReviewExerciseMutationVariables>(CopyEmployeeReviewExerciseDocument, baseOptions);
      }
export type CopyEmployeeReviewExerciseMutationHookResult = ReturnType<typeof useCopyEmployeeReviewExerciseMutation>;
export type CopyEmployeeReviewExerciseMutationResult = ApolloReactCommon.MutationResult<CopyEmployeeReviewExerciseMutation>;
export type CopyEmployeeReviewExerciseMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyEmployeeReviewExerciseMutation, CopyEmployeeReviewExerciseMutationVariables>;
export const EmployeeReviewFormStatusUpdateDocument = gql`
    mutation EmployeeReviewFormStatusUpdate($EmployeeReviewIDs: [String!]!, $StatusToChange: Status!, $Comment: String, $IsSendBack: Boolean) {
  EmployeeReviewFormStatusUpdate(EmployeeReviewIDs: $EmployeeReviewIDs, StatusToChange: $StatusToChange, Comment: $Comment, IsSendBack: $IsSendBack)
}
    `;
export type EmployeeReviewFormStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeReviewFormStatusUpdateMutation, EmployeeReviewFormStatusUpdateMutationVariables>;

/**
 * __useEmployeeReviewFormStatusUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeReviewFormStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeReviewFormStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeReviewFormStatusUpdateMutation, { data, loading, error }] = useEmployeeReviewFormStatusUpdateMutation({
 *   variables: {
 *      EmployeeReviewIDs: // value for 'EmployeeReviewIDs'
 *      StatusToChange: // value for 'StatusToChange'
 *      Comment: // value for 'Comment'
 *      IsSendBack: // value for 'IsSendBack'
 *   },
 * });
 */
export function useEmployeeReviewFormStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeReviewFormStatusUpdateMutation, EmployeeReviewFormStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeReviewFormStatusUpdateMutation, EmployeeReviewFormStatusUpdateMutationVariables>(EmployeeReviewFormStatusUpdateDocument, baseOptions);
      }
export type EmployeeReviewFormStatusUpdateMutationHookResult = ReturnType<typeof useEmployeeReviewFormStatusUpdateMutation>;
export type EmployeeReviewFormStatusUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeReviewFormStatusUpdateMutation>;
export type EmployeeReviewFormStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeReviewFormStatusUpdateMutation, EmployeeReviewFormStatusUpdateMutationVariables>;
export const SubmitEmployeeReviewFormDocument = gql`
    mutation SubmitEmployeeReviewForm($input: ReviewFormSubmitInput!, $Comment: String) {
  SubmitEmployeeReviewForm(input: $input, Comment: $Comment)
}
    `;
export type SubmitEmployeeReviewFormMutationFn = ApolloReactCommon.MutationFunction<SubmitEmployeeReviewFormMutation, SubmitEmployeeReviewFormMutationVariables>;

/**
 * __useSubmitEmployeeReviewFormMutation__
 *
 * To run a mutation, you first call `useSubmitEmployeeReviewFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEmployeeReviewFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEmployeeReviewFormMutation, { data, loading, error }] = useSubmitEmployeeReviewFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *      Comment: // value for 'Comment'
 *   },
 * });
 */
export function useSubmitEmployeeReviewFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEmployeeReviewFormMutation, SubmitEmployeeReviewFormMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEmployeeReviewFormMutation, SubmitEmployeeReviewFormMutationVariables>(SubmitEmployeeReviewFormDocument, baseOptions);
      }
export type SubmitEmployeeReviewFormMutationHookResult = ReturnType<typeof useSubmitEmployeeReviewFormMutation>;
export type SubmitEmployeeReviewFormMutationResult = ApolloReactCommon.MutationResult<SubmitEmployeeReviewFormMutation>;
export type SubmitEmployeeReviewFormMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEmployeeReviewFormMutation, SubmitEmployeeReviewFormMutationVariables>;
export const GetConversationLogByReviewFormItemDocument = gql`
    query GetConversationLogByReviewFormItem($EmployeeReviewSectionItemID: String!) {
  GetConversationLogByReviewFormItem(EmployeeReviewSectionItemID: $EmployeeReviewSectionItemID) {
    ID
    EmployeeID
    CreatedDT
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
      Documents {
        DocumentFile
      }
    }
    CommentBy
    Comment
    Documents {
      DocumentID
      DocumentFile
    }
    VoiceDocument {
      DocumentID
      DocumentFile
    }
  }
}
    `;

/**
 * __useGetConversationLogByReviewFormItemQuery__
 *
 * To run a query within a React component, call `useGetConversationLogByReviewFormItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationLogByReviewFormItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationLogByReviewFormItemQuery({
 *   variables: {
 *      EmployeeReviewSectionItemID: // value for 'EmployeeReviewSectionItemID'
 *   },
 * });
 */
export function useGetConversationLogByReviewFormItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConversationLogByReviewFormItemQuery, GetConversationLogByReviewFormItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConversationLogByReviewFormItemQuery, GetConversationLogByReviewFormItemQueryVariables>(GetConversationLogByReviewFormItemDocument, baseOptions);
      }
export function useGetConversationLogByReviewFormItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConversationLogByReviewFormItemQuery, GetConversationLogByReviewFormItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConversationLogByReviewFormItemQuery, GetConversationLogByReviewFormItemQueryVariables>(GetConversationLogByReviewFormItemDocument, baseOptions);
        }
export type GetConversationLogByReviewFormItemQueryHookResult = ReturnType<typeof useGetConversationLogByReviewFormItemQuery>;
export type GetConversationLogByReviewFormItemLazyQueryHookResult = ReturnType<typeof useGetConversationLogByReviewFormItemLazyQuery>;
export type GetConversationLogByReviewFormItemQueryResult = ApolloReactCommon.QueryResult<GetConversationLogByReviewFormItemQuery, GetConversationLogByReviewFormItemQueryVariables>;
export const AddConversationLogDocument = gql`
    mutation AddConversationLog($input: ReviewConversationLogInput!) {
  AddConversationLog(input: $input)
}
    `;
export type AddConversationLogMutationFn = ApolloReactCommon.MutationFunction<AddConversationLogMutation, AddConversationLogMutationVariables>;

/**
 * __useAddConversationLogMutation__
 *
 * To run a mutation, you first call `useAddConversationLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConversationLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConversationLogMutation, { data, loading, error }] = useAddConversationLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConversationLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddConversationLogMutation, AddConversationLogMutationVariables>) {
        return ApolloReactHooks.useMutation<AddConversationLogMutation, AddConversationLogMutationVariables>(AddConversationLogDocument, baseOptions);
      }
export type AddConversationLogMutationHookResult = ReturnType<typeof useAddConversationLogMutation>;
export type AddConversationLogMutationResult = ApolloReactCommon.MutationResult<AddConversationLogMutation>;
export type AddConversationLogMutationOptions = ApolloReactCommon.BaseMutationOptions<AddConversationLogMutation, AddConversationLogMutationVariables>;
export const GetPerformanceToDoDocument = gql`
    query GetPerformanceToDo($ContactID: String!, $PerformanceRequestType: String!) {
  GetPerformanceToDo(ContactID: $ContactID, PerformanceRequestType: $PerformanceRequestType) {
    KpiExercise {
      ID
      Type
      KpiExerciseID
      ReviewGroupName
      EmployeeID
      Employee {
        ContactID
        EmployeeNo
        Documents {
          DocumentFile
        }
        Contact {
          FullName
        }
      }
      Approver {
        Contact {
          FullName
          ContactID
        }
      }
      EmployeeName
      EmployeeNo
      SuperiorID
      Status
      FromDate
      ToDate
      CreatedDT
      SubmitDate
      ApprovalStatus
      ApproverName
      ApprovalHistory {
        EmployeeID
        SuperiorID
        KpiExerciseID
        ReviewGroupName
        FromDate
        ToDate
        CreatedDT
        ApprovalStatus
      }
    }
    EmployeeReview {
      ID
      ReviewExerciseName
      ReviewGroupName
      FromDate
      ToDate
      ReviewFormType
      ReviewFormTemplateName
      Reviewer {
        EmployeeNo
        Contact {
          FullName
        }
      }
      Status
      CreatedDT
      Employee {
        ContactID
        EmployeeNo
        Documents {
          DocumentFile
        }
        Contact {
          FullName
        }
      }
      ReviewProcessLog
      ApprovalHistory {
        ReviewExerciseName
        ReviewGroupName
        FromDate
        ToDate
        ReviewFormType
        ReviewFormTemplateName
        Reviewer {
          EmployeeNo
          Contact {
            FullName
          }
        }
        Status
        CreatedDT
      }
    }
  }
}
    `;

/**
 * __useGetPerformanceToDoQuery__
 *
 * To run a query within a React component, call `useGetPerformanceToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceToDoQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      PerformanceRequestType: // value for 'PerformanceRequestType'
 *   },
 * });
 */
export function useGetPerformanceToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPerformanceToDoQuery, GetPerformanceToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPerformanceToDoQuery, GetPerformanceToDoQueryVariables>(GetPerformanceToDoDocument, baseOptions);
      }
export function useGetPerformanceToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPerformanceToDoQuery, GetPerformanceToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPerformanceToDoQuery, GetPerformanceToDoQueryVariables>(GetPerformanceToDoDocument, baseOptions);
        }
export type GetPerformanceToDoQueryHookResult = ReturnType<typeof useGetPerformanceToDoQuery>;
export type GetPerformanceToDoLazyQueryHookResult = ReturnType<typeof useGetPerformanceToDoLazyQuery>;
export type GetPerformanceToDoQueryResult = ApolloReactCommon.QueryResult<GetPerformanceToDoQuery, GetPerformanceToDoQueryVariables>;
export const MyPerformanceSummaryDocument = gql`
    query MyPerformanceSummary($EmployeeID: String!, $ContactID: String) {
  MyPerformanceSummary(EmployeeID: $EmployeeID, ContactID: $ContactID)
}
    `;

/**
 * __useMyPerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useMyPerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPerformanceSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useMyPerformanceSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyPerformanceSummaryQuery, MyPerformanceSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<MyPerformanceSummaryQuery, MyPerformanceSummaryQueryVariables>(MyPerformanceSummaryDocument, baseOptions);
      }
export function useMyPerformanceSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyPerformanceSummaryQuery, MyPerformanceSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyPerformanceSummaryQuery, MyPerformanceSummaryQueryVariables>(MyPerformanceSummaryDocument, baseOptions);
        }
export type MyPerformanceSummaryQueryHookResult = ReturnType<typeof useMyPerformanceSummaryQuery>;
export type MyPerformanceSummaryLazyQueryHookResult = ReturnType<typeof useMyPerformanceSummaryLazyQuery>;
export type MyPerformanceSummaryQueryResult = ApolloReactCommon.QueryResult<MyPerformanceSummaryQuery, MyPerformanceSummaryQueryVariables>;
export const EmployeeKpiListingDocument = gql`
    query EmployeeKpiListing($EmployeeID: String!, $FromDate: DateTime, $ToDate: DateTime, $ContactID: String) {
  EmployeeKpiListing(EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate, ContactID: $ContactID) {
    ID
    EmployeeKpiDetails
    CreatedDT
    ModifiedDT
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    KpiExerciseDetail {
      KpiExerciseID
      KpiRule
      CompositionRules
      WeightageType
      EditableWeightage
      FormItemRatingID
      ReviewGroup {
        ID
        Title
      }
      KpiPredefinedTemplate {
        ID
        Title
      }
      FormItemRating {
        ID
        Title
      }
      KpiExercise {
        ApprovalID
        KpiExerciseID
        FromDate
        ToDate
        Status
        Superior {
          EmployeeNo
          Contact {
            FullName
          }
        }
        ApprovalStatus
        ApprovalDT
        ApprovalRemark
      }
    }
  }
}
    `;

/**
 * __useEmployeeKpiListingQuery__
 *
 * To run a query within a React component, call `useEmployeeKpiListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeKpiListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeKpiListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useEmployeeKpiListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeKpiListingQuery, EmployeeKpiListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeKpiListingQuery, EmployeeKpiListingQueryVariables>(EmployeeKpiListingDocument, baseOptions);
      }
export function useEmployeeKpiListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeKpiListingQuery, EmployeeKpiListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeKpiListingQuery, EmployeeKpiListingQueryVariables>(EmployeeKpiListingDocument, baseOptions);
        }
export type EmployeeKpiListingQueryHookResult = ReturnType<typeof useEmployeeKpiListingQuery>;
export type EmployeeKpiListingLazyQueryHookResult = ReturnType<typeof useEmployeeKpiListingLazyQuery>;
export type EmployeeKpiListingQueryResult = ApolloReactCommon.QueryResult<EmployeeKpiListingQuery, EmployeeKpiListingQueryVariables>;
export const SubmitEmployeeKpiDocument = gql`
    mutation SubmitEmployeeKpi($input: EmployeeKpiInput!) {
  SubmitEmployeeKpi(input: $input)
}
    `;
export type SubmitEmployeeKpiMutationFn = ApolloReactCommon.MutationFunction<SubmitEmployeeKpiMutation, SubmitEmployeeKpiMutationVariables>;

/**
 * __useSubmitEmployeeKpiMutation__
 *
 * To run a mutation, you first call `useSubmitEmployeeKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEmployeeKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEmployeeKpiMutation, { data, loading, error }] = useSubmitEmployeeKpiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitEmployeeKpiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEmployeeKpiMutation, SubmitEmployeeKpiMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEmployeeKpiMutation, SubmitEmployeeKpiMutationVariables>(SubmitEmployeeKpiDocument, baseOptions);
      }
export type SubmitEmployeeKpiMutationHookResult = ReturnType<typeof useSubmitEmployeeKpiMutation>;
export type SubmitEmployeeKpiMutationResult = ApolloReactCommon.MutationResult<SubmitEmployeeKpiMutation>;
export type SubmitEmployeeKpiMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEmployeeKpiMutation, SubmitEmployeeKpiMutationVariables>;
export const EmployeeKpiRepoListingDocument = gql`
    query EmployeeKpiRepoListing($EmployeeID: String!, $ContactID: String) {
  EmployeeKpiRepoListing(EmployeeID: $EmployeeID, ContactID: $ContactID) {
    ID
    EmployeeKpiDetails
  }
}
    `;

/**
 * __useEmployeeKpiRepoListingQuery__
 *
 * To run a query within a React component, call `useEmployeeKpiRepoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeKpiRepoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeKpiRepoListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useEmployeeKpiRepoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeKpiRepoListingQuery, EmployeeKpiRepoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeKpiRepoListingQuery, EmployeeKpiRepoListingQueryVariables>(EmployeeKpiRepoListingDocument, baseOptions);
      }
export function useEmployeeKpiRepoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeKpiRepoListingQuery, EmployeeKpiRepoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeKpiRepoListingQuery, EmployeeKpiRepoListingQueryVariables>(EmployeeKpiRepoListingDocument, baseOptions);
        }
export type EmployeeKpiRepoListingQueryHookResult = ReturnType<typeof useEmployeeKpiRepoListingQuery>;
export type EmployeeKpiRepoListingLazyQueryHookResult = ReturnType<typeof useEmployeeKpiRepoListingLazyQuery>;
export type EmployeeKpiRepoListingQueryResult = ApolloReactCommon.QueryResult<EmployeeKpiRepoListingQuery, EmployeeKpiRepoListingQueryVariables>;
export const RetrieveEmployeeKpiDetailFromRepoDocument = gql`
    query RetrieveEmployeeKpiDetailFromRepo($EmployeeKpiRepositoryID: String!) {
  RetrieveEmployeeKpiDetailFromRepo(EmployeeKpiRepositoryID: $EmployeeKpiRepositoryID) {
    ID
    EmployeeKpiDetails
  }
}
    `;

/**
 * __useRetrieveEmployeeKpiDetailFromRepoQuery__
 *
 * To run a query within a React component, call `useRetrieveEmployeeKpiDetailFromRepoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveEmployeeKpiDetailFromRepoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveEmployeeKpiDetailFromRepoQuery({
 *   variables: {
 *      EmployeeKpiRepositoryID: // value for 'EmployeeKpiRepositoryID'
 *   },
 * });
 */
export function useRetrieveEmployeeKpiDetailFromRepoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetrieveEmployeeKpiDetailFromRepoQuery, RetrieveEmployeeKpiDetailFromRepoQueryVariables>) {
        return ApolloReactHooks.useQuery<RetrieveEmployeeKpiDetailFromRepoQuery, RetrieveEmployeeKpiDetailFromRepoQueryVariables>(RetrieveEmployeeKpiDetailFromRepoDocument, baseOptions);
      }
export function useRetrieveEmployeeKpiDetailFromRepoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetrieveEmployeeKpiDetailFromRepoQuery, RetrieveEmployeeKpiDetailFromRepoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetrieveEmployeeKpiDetailFromRepoQuery, RetrieveEmployeeKpiDetailFromRepoQueryVariables>(RetrieveEmployeeKpiDetailFromRepoDocument, baseOptions);
        }
export type RetrieveEmployeeKpiDetailFromRepoQueryHookResult = ReturnType<typeof useRetrieveEmployeeKpiDetailFromRepoQuery>;
export type RetrieveEmployeeKpiDetailFromRepoLazyQueryHookResult = ReturnType<typeof useRetrieveEmployeeKpiDetailFromRepoLazyQuery>;
export type RetrieveEmployeeKpiDetailFromRepoQueryResult = ApolloReactCommon.QueryResult<RetrieveEmployeeKpiDetailFromRepoQuery, RetrieveEmployeeKpiDetailFromRepoQueryVariables>;
export const IsKpiExistInRepoDocument = gql`
    query IsKpiExistInRepo($KPI: String!, $ContactID: String!, $KpiRepoID: String) {
  IsKpiExistInRepo(KPI: $KPI, ContactID: $ContactID, KpiRepoID: $KpiRepoID)
}
    `;

/**
 * __useIsKpiExistInRepoQuery__
 *
 * To run a query within a React component, call `useIsKpiExistInRepoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiExistInRepoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiExistInRepoQuery({
 *   variables: {
 *      KPI: // value for 'KPI'
 *      ContactID: // value for 'ContactID'
 *      KpiRepoID: // value for 'KpiRepoID'
 *   },
 * });
 */
export function useIsKpiExistInRepoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiExistInRepoQuery, IsKpiExistInRepoQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiExistInRepoQuery, IsKpiExistInRepoQueryVariables>(IsKpiExistInRepoDocument, baseOptions);
      }
export function useIsKpiExistInRepoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiExistInRepoQuery, IsKpiExistInRepoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiExistInRepoQuery, IsKpiExistInRepoQueryVariables>(IsKpiExistInRepoDocument, baseOptions);
        }
export type IsKpiExistInRepoQueryHookResult = ReturnType<typeof useIsKpiExistInRepoQuery>;
export type IsKpiExistInRepoLazyQueryHookResult = ReturnType<typeof useIsKpiExistInRepoLazyQuery>;
export type IsKpiExistInRepoQueryResult = ApolloReactCommon.QueryResult<IsKpiExistInRepoQuery, IsKpiExistInRepoQueryVariables>;
export const SaveEmployeeKpiInRepoDocument = gql`
    mutation SaveEmployeeKpiInRepo($input: EmployeeKpiRepositoryInput!) {
  SaveEmployeeKpiInRepo(input: $input)
}
    `;
export type SaveEmployeeKpiInRepoMutationFn = ApolloReactCommon.MutationFunction<SaveEmployeeKpiInRepoMutation, SaveEmployeeKpiInRepoMutationVariables>;

/**
 * __useSaveEmployeeKpiInRepoMutation__
 *
 * To run a mutation, you first call `useSaveEmployeeKpiInRepoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployeeKpiInRepoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployeeKpiInRepoMutation, { data, loading, error }] = useSaveEmployeeKpiInRepoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEmployeeKpiInRepoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveEmployeeKpiInRepoMutation, SaveEmployeeKpiInRepoMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveEmployeeKpiInRepoMutation, SaveEmployeeKpiInRepoMutationVariables>(SaveEmployeeKpiInRepoDocument, baseOptions);
      }
export type SaveEmployeeKpiInRepoMutationHookResult = ReturnType<typeof useSaveEmployeeKpiInRepoMutation>;
export type SaveEmployeeKpiInRepoMutationResult = ApolloReactCommon.MutationResult<SaveEmployeeKpiInRepoMutation>;
export type SaveEmployeeKpiInRepoMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveEmployeeKpiInRepoMutation, SaveEmployeeKpiInRepoMutationVariables>;
export const EmployeeKpiRepoDeleteDocument = gql`
    mutation EmployeeKpiRepoDelete($EmployeeKpiRepositoryID: String!) {
  EmployeeKpiRepoDelete(EmployeeKpiRepositoryID: $EmployeeKpiRepositoryID)
}
    `;
export type EmployeeKpiRepoDeleteMutationFn = ApolloReactCommon.MutationFunction<EmployeeKpiRepoDeleteMutation, EmployeeKpiRepoDeleteMutationVariables>;

/**
 * __useEmployeeKpiRepoDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeKpiRepoDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeKpiRepoDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeKpiRepoDeleteMutation, { data, loading, error }] = useEmployeeKpiRepoDeleteMutation({
 *   variables: {
 *      EmployeeKpiRepositoryID: // value for 'EmployeeKpiRepositoryID'
 *   },
 * });
 */
export function useEmployeeKpiRepoDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeKpiRepoDeleteMutation, EmployeeKpiRepoDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeKpiRepoDeleteMutation, EmployeeKpiRepoDeleteMutationVariables>(EmployeeKpiRepoDeleteDocument, baseOptions);
      }
export type EmployeeKpiRepoDeleteMutationHookResult = ReturnType<typeof useEmployeeKpiRepoDeleteMutation>;
export type EmployeeKpiRepoDeleteMutationResult = ApolloReactCommon.MutationResult<EmployeeKpiRepoDeleteMutation>;
export type EmployeeKpiRepoDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeKpiRepoDeleteMutation, EmployeeKpiRepoDeleteMutationVariables>;
export const GetEmployeeReviewListingDocument = gql`
    query GetEmployeeReviewListing($ContactID: String!, $FromDate: DateTime!, $ToDate: DateTime!, $ReviewFormType: [AppraiseeType!], $ReviewFormStatus: [Status!]) {
  GetEmployeeReviewListing(ContactID: $ContactID, FromDate: $FromDate, ToDate: $ToDate, ReviewFormType: $ReviewFormType, ReviewFormStatus: $ReviewFormStatus) {
    ID
    Employee {
      Documents {
        DocumentFile
      }
      Contact {
        FullName
      }
    }
    ReviewExerciseName
    ReviewGroupID
    ReviewGroupName
    EmployeeReview {
      ID
      EmployeeRating
      ReviewerRating
      OverallGrade
      ReviewFormType
      ReviewerID
      Reviewer {
        EmployeeNo
        Contact {
          FullName
        }
        Documents {
          DocumentFile
        }
      }
      FromDate
      ToDate
      ReviewFormTemplateID
      ReviewFormTemplateName
      Status
      ReviewProcessLog
    }
  }
}
    `;

/**
 * __useGetEmployeeReviewListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeReviewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeReviewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeReviewListingQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      ReviewFormType: // value for 'ReviewFormType'
 *      ReviewFormStatus: // value for 'ReviewFormStatus'
 *   },
 * });
 */
export function useGetEmployeeReviewListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeReviewListingQuery, GetEmployeeReviewListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeReviewListingQuery, GetEmployeeReviewListingQueryVariables>(GetEmployeeReviewListingDocument, baseOptions);
      }
export function useGetEmployeeReviewListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeReviewListingQuery, GetEmployeeReviewListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeReviewListingQuery, GetEmployeeReviewListingQueryVariables>(GetEmployeeReviewListingDocument, baseOptions);
        }
export type GetEmployeeReviewListingQueryHookResult = ReturnType<typeof useGetEmployeeReviewListingQuery>;
export type GetEmployeeReviewListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeReviewListingLazyQuery>;
export type GetEmployeeReviewListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeReviewListingQuery, GetEmployeeReviewListingQueryVariables>;
export const GetEmployeeReviewActionListingDocument = gql`
    query GetEmployeeReviewActionListing($ContactID: String!, $FromDate: DateTime, $ToDate: DateTime, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitIDs: [String!], $ReviewFormType: [AppraiseeType!], $ReviewFormStatus: [Status!]) {
  GetEmployeeReviewActionListing(ContactID: $ContactID, FromDate: $FromDate, ToDate: $ToDate, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitIDs: $OrgUnitIDs, ReviewFormType: $ReviewFormType, ReviewFormStatus: $ReviewFormStatus) {
    ID
    ReviewExerciseName
    EmployeeReview {
      ID
      ReviewerID
      HasNextLevelApproval
      IsSendBackToInitaite
      EmployeeRating
      ReviewerRating
      OverallGrade
      ReviewFormType
      ReviewGroupName
      Employee {
        EmployeeNo
        ContactID
        Documents {
          DocumentFile
        }
        Contact {
          FullName
        }
      }
      Reviewer {
        EmployeeNo
        Contact {
          FullName
        }
        Documents {
          DocumentFile
        }
      }
      FromDate
      ToDate
      ReviewFormTemplateName
      Status
      ReviewProcessLog
    }
  }
}
    `;

/**
 * __useGetEmployeeReviewActionListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeReviewActionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeReviewActionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeReviewActionListingQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      ReviewFormType: // value for 'ReviewFormType'
 *      ReviewFormStatus: // value for 'ReviewFormStatus'
 *   },
 * });
 */
export function useGetEmployeeReviewActionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeReviewActionListingQuery, GetEmployeeReviewActionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeReviewActionListingQuery, GetEmployeeReviewActionListingQueryVariables>(GetEmployeeReviewActionListingDocument, baseOptions);
      }
export function useGetEmployeeReviewActionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeReviewActionListingQuery, GetEmployeeReviewActionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeReviewActionListingQuery, GetEmployeeReviewActionListingQueryVariables>(GetEmployeeReviewActionListingDocument, baseOptions);
        }
export type GetEmployeeReviewActionListingQueryHookResult = ReturnType<typeof useGetEmployeeReviewActionListingQuery>;
export type GetEmployeeReviewActionListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeReviewActionListingLazyQuery>;
export type GetEmployeeReviewActionListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeReviewActionListingQuery, GetEmployeeReviewActionListingQueryVariables>;
export const MySubordinatePerformanceSummaryDocument = gql`
    query MySubordinatePerformanceSummary($EmployeeIDs: [String!]!, $SuperiorContactID: String!) {
  MySubordinatePerformanceSummary(EmployeeIDs: $EmployeeIDs, ContactID: $SuperiorContactID)
}
    `;

/**
 * __useMySubordinatePerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useMySubordinatePerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySubordinatePerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySubordinatePerformanceSummaryQuery({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      SuperiorContactID: // value for 'SuperiorContactID'
 *   },
 * });
 */
export function useMySubordinatePerformanceSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MySubordinatePerformanceSummaryQuery, MySubordinatePerformanceSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<MySubordinatePerformanceSummaryQuery, MySubordinatePerformanceSummaryQueryVariables>(MySubordinatePerformanceSummaryDocument, baseOptions);
      }
export function useMySubordinatePerformanceSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySubordinatePerformanceSummaryQuery, MySubordinatePerformanceSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MySubordinatePerformanceSummaryQuery, MySubordinatePerformanceSummaryQueryVariables>(MySubordinatePerformanceSummaryDocument, baseOptions);
        }
export type MySubordinatePerformanceSummaryQueryHookResult = ReturnType<typeof useMySubordinatePerformanceSummaryQuery>;
export type MySubordinatePerformanceSummaryLazyQueryHookResult = ReturnType<typeof useMySubordinatePerformanceSummaryLazyQuery>;
export type MySubordinatePerformanceSummaryQueryResult = ApolloReactCommon.QueryResult<MySubordinatePerformanceSummaryQuery, MySubordinatePerformanceSummaryQueryVariables>;
export const SubordinateKpiEmpListingDocument = gql`
    query SubordinateKpiEmpListing($SuperiorContactID: String!) {
  SubordinateKpiEmpListing(ContactID: $SuperiorContactID) {
    EmployeeID
    ContactID
    EmployeeNo
    Contact {
      FullName
    }
    Company {
      CompanyName
    }
    Documents {
      DocumentFile
    }
    TotalKpiRecord
    KpiPendingActionCount
  }
}
    `;

/**
 * __useSubordinateKpiEmpListingQuery__
 *
 * To run a query within a React component, call `useSubordinateKpiEmpListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateKpiEmpListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateKpiEmpListingQuery({
 *   variables: {
 *      SuperiorContactID: // value for 'SuperiorContactID'
 *   },
 * });
 */
export function useSubordinateKpiEmpListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateKpiEmpListingQuery, SubordinateKpiEmpListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateKpiEmpListingQuery, SubordinateKpiEmpListingQueryVariables>(SubordinateKpiEmpListingDocument, baseOptions);
      }
export function useSubordinateKpiEmpListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateKpiEmpListingQuery, SubordinateKpiEmpListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateKpiEmpListingQuery, SubordinateKpiEmpListingQueryVariables>(SubordinateKpiEmpListingDocument, baseOptions);
        }
export type SubordinateKpiEmpListingQueryHookResult = ReturnType<typeof useSubordinateKpiEmpListingQuery>;
export type SubordinateKpiEmpListingLazyQueryHookResult = ReturnType<typeof useSubordinateKpiEmpListingLazyQuery>;
export type SubordinateKpiEmpListingQueryResult = ApolloReactCommon.QueryResult<SubordinateKpiEmpListingQuery, SubordinateKpiEmpListingQueryVariables>;
export const KpiListingBySubordinateDocument = gql`
    query KpiListingBySubordinate($SubordinateEmployeeID: String!, $SubordinateContactID: String) {
  EmployeeKpiListing(EmployeeID: $SubordinateEmployeeID, ContactID: $SubordinateContactID) {
    ID
    EmployeeKpiDetails
    CreatedDT
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
      Documents {
        DocumentID
        DocumentFile
      }
    }
    KpiExerciseDetail {
      KpiExerciseID
      KpiRule
      CompositionRules
      WeightageType
      EditableWeightage
      FormItemRatingID
      KpiExercise {
        ApprovalID
        KpiExerciseID
        AllowToReInitiate
        FromDate
        ToDate
        Status
        Superior {
          EmployeeNo
          Contact {
            FullName
          }
        }
        ApprovalStatus
        ApprovalDT
        ApprovalRemark
      }
      ReviewGroup {
        Title
      }
      KpiPredefinedTemplate {
        ID
        Title
      }
      FormItemRating {
        ID
        Title
      }
    }
  }
}
    `;

/**
 * __useKpiListingBySubordinateQuery__
 *
 * To run a query within a React component, call `useKpiListingBySubordinateQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiListingBySubordinateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiListingBySubordinateQuery({
 *   variables: {
 *      SubordinateEmployeeID: // value for 'SubordinateEmployeeID'
 *      SubordinateContactID: // value for 'SubordinateContactID'
 *   },
 * });
 */
export function useKpiListingBySubordinateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiListingBySubordinateQuery, KpiListingBySubordinateQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiListingBySubordinateQuery, KpiListingBySubordinateQueryVariables>(KpiListingBySubordinateDocument, baseOptions);
      }
export function useKpiListingBySubordinateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiListingBySubordinateQuery, KpiListingBySubordinateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiListingBySubordinateQuery, KpiListingBySubordinateQueryVariables>(KpiListingBySubordinateDocument, baseOptions);
        }
export type KpiListingBySubordinateQueryHookResult = ReturnType<typeof useKpiListingBySubordinateQuery>;
export type KpiListingBySubordinateLazyQueryHookResult = ReturnType<typeof useKpiListingBySubordinateLazyQuery>;
export type KpiListingBySubordinateQueryResult = ApolloReactCommon.QueryResult<KpiListingBySubordinateQuery, KpiListingBySubordinateQueryVariables>;
export const SubmitSubordinateKpiDocument = gql`
    mutation SubmitSubordinateKpi($input: EmployeeKpiInput!, $IsSubmitByApprover: Boolean, $Comment: String) {
  SubmitEmployeeKpi(input: $input, IsSubmitByApprover: $IsSubmitByApprover, Comment: $Comment)
}
    `;
export type SubmitSubordinateKpiMutationFn = ApolloReactCommon.MutationFunction<SubmitSubordinateKpiMutation, SubmitSubordinateKpiMutationVariables>;

/**
 * __useSubmitSubordinateKpiMutation__
 *
 * To run a mutation, you first call `useSubmitSubordinateKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubordinateKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubordinateKpiMutation, { data, loading, error }] = useSubmitSubordinateKpiMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSubmitByApprover: // value for 'IsSubmitByApprover'
 *      Comment: // value for 'Comment'
 *   },
 * });
 */
export function useSubmitSubordinateKpiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSubordinateKpiMutation, SubmitSubordinateKpiMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSubordinateKpiMutation, SubmitSubordinateKpiMutationVariables>(SubmitSubordinateKpiDocument, baseOptions);
      }
export type SubmitSubordinateKpiMutationHookResult = ReturnType<typeof useSubmitSubordinateKpiMutation>;
export type SubmitSubordinateKpiMutationResult = ApolloReactCommon.MutationResult<SubmitSubordinateKpiMutation>;
export type SubmitSubordinateKpiMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSubordinateKpiMutation, SubmitSubordinateKpiMutationVariables>;
export const SubordinateKpiChangeStatusDocument = gql`
    mutation SubordinateKpiChangeStatus($KpiExerciseID: String!, $StatusToChange: Status!, $Comment: String) {
  KpiExerciseChangeStatus(KpiExerciseID: $KpiExerciseID, StatusToChange: $StatusToChange, Comment: $Comment)
}
    `;
export type SubordinateKpiChangeStatusMutationFn = ApolloReactCommon.MutationFunction<SubordinateKpiChangeStatusMutation, SubordinateKpiChangeStatusMutationVariables>;

/**
 * __useSubordinateKpiChangeStatusMutation__
 *
 * To run a mutation, you first call `useSubordinateKpiChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubordinateKpiChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subordinateKpiChangeStatusMutation, { data, loading, error }] = useSubordinateKpiChangeStatusMutation({
 *   variables: {
 *      KpiExerciseID: // value for 'KpiExerciseID'
 *      StatusToChange: // value for 'StatusToChange'
 *      Comment: // value for 'Comment'
 *   },
 * });
 */
export function useSubordinateKpiChangeStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubordinateKpiChangeStatusMutation, SubordinateKpiChangeStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SubordinateKpiChangeStatusMutation, SubordinateKpiChangeStatusMutationVariables>(SubordinateKpiChangeStatusDocument, baseOptions);
      }
export type SubordinateKpiChangeStatusMutationHookResult = ReturnType<typeof useSubordinateKpiChangeStatusMutation>;
export type SubordinateKpiChangeStatusMutationResult = ApolloReactCommon.MutationResult<SubordinateKpiChangeStatusMutation>;
export type SubordinateKpiChangeStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SubordinateKpiChangeStatusMutation, SubordinateKpiChangeStatusMutationVariables>;